import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import AakaarLogo from "../../src/asset/AakaarLogo.png";
import Loginbg from "../../src/asset/Loginbg.png";
import Loader from "../Pages/Component/Loader";
import { BaseUrl } from "./Url";
import Swal from 'sweetalert2';
function Register() {
  const navigate = useNavigate();
  const [brandName, setBrandName] = useState("");
  const [errorMessage, setErrorMessage] = useState("Field is Required");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");

  const [numberValidation, setnumberValidation] = useState("");
  const [GstValidation, setGstValidation] = useState("");
  const [FASSAIValidation, setFASSAIValidation] = useState("");
  const [subcategory, setsubCategory] = useState("");
  const [data, setData] = useState([]);
  const [EmailValidation, setEmailValidation] = useState("");
  const generateFactoryId = () => {
    return `F${Math.floor(1000 + Math.random() * 9000)}`; // Example: Generates IDs like F1001, F2345, etc.
  };
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [factoryData, setFactoryData] = useState({
    factoryId: generateFactoryId(),
    factoryName: "",
    contactPerson: "",
    contactNo: "",
    email: "",
    website: "",
    factoryAddress: "",
    city: "",
    postalCode: "",
    country: "",
    State: "",
    FASSAINumber: "",
    GSTNumber: "",
    password: "",
    products: [],
    CommissionDoneAmount:'',
    role: "Factory",
  });
  console.log(factoryData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmailValidation("");
    }
    if (name === "GSTNumber") {
      setGstValidation("");
    }
    if (name === "FASSAINumber") {
      setFASSAIValidation("");
    }
    setFactoryData({
      ...factoryData,
      [name]: value,
    });
  };
  const handleInputChangeforNumber = (e) => {
    const { name, value } = e.target;
    if (name === "contactNo") {
      setnumberValidation("");
    }
    // Regular expression to check for digits only and limit to 10 characters
    const isValid = /^\d{0,10}$/.test(value);

    if (isValid) {
      setFactoryData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleAdd = () => {
    debugger;
    if (brandName && category && subcategory) {
      // Update factoryData state
      setFactoryData({
        ...factoryData,
        products: [
          ...factoryData.products,
          { brandName, category, subcategory },
        ],
      });

      // Update data state (assuming data is an array state)
      setData([...data, { brandName, category, subcategory }]);

      // Clear input fields
      setBrandName("");
      setCategory("");
      setsubCategory("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !factoryData.factoryName ||
      !factoryData.contactPerson ||
      !factoryData.contactNo ||
      !factoryData.email ||
      !factoryData.factoryAddress ||
      !factoryData.city ||
      !factoryData.postalCode ||
      !factoryData.country ||
      !factoryData.State ||
      !factoryData.FASSAINumber ||
      !factoryData.GSTNumber
    ) {
      setError(true);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(factoryData.email)) {
      debugger;
      // alert('Please enter a valid email address.');
      setError(true);
      setEmailValidation("Please enter a valid email address.");
      return;
    }
    if (factoryData.GSTNumber.length !== 15) {
      // Optionally set an error state or message
      setError(true);
      setGstValidation("GST Number must be exactly 15 digits.");

      return; // Exit if validation fails
    }
    if (factoryData.FASSAINumber.length !== 14) {
      // Optionally set an error state or message
      setError(true);
      setFASSAIValidation("FSSAI Number must be exactly 14 digits.");

      return; // Exit if validation fails
    }
    if (factoryData.contactNo.length !== 10) {
      // Optionally set an error state or message
      setError(true);
      setnumberValidation("Contact number must be exactly 10 digits.");

      return; // Exit if validation fails
    }
    setLoading(true);
      try {
    const response = await fetch(`${BaseUrl}/Register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(factoryData),
    });

    const result = await response.json();
    setLoading(false);

    if (response.ok) {
      // Display success message with SweetAlert
      Swal.fire({
        icon: 'success',
        title: 'Registration Successful',
        text: 'You have been registered successfully!',
        confirmButtonText: 'OK',
      }).then(() => {
        // Navigate to login or any desired page after clicking OK
        navigate("/login");
      });
    } else {
      // Handle specific error message when email already exists
      if (result.message === "User already exists") {
        Swal.fire({
          icon: 'error',
          title: 'Registration Failed',
          text: 'User already exists with this email address.',
          confirmButtonText: 'OK',
        });
      } else {
        // General error handling
        setErrorMessage(result.message || "Registration failed");
        setError(true);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: result.message || 'Registration failed. Please try again.',
          confirmButtonText: 'OK',
        });
      }
    }
    } catch (error) {
      setLoading(false);
      setErrorMessage("An error occurred while registering");
      setError(true);
    }
  };
  const handleKeyDown = (e) => {
    // Prevent 'e', '+', '-', '.' characters and allow only digits
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <div
      style={{
        backgroundColor: "rgba(251, 251, 251, 1)",
        backgroundImage: `url(${Loginbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="flex justify-center">
        <img src={AakaarLogo} alt="Aakaar Logo" className="h-24 mt-8 w-50" />
      </div>
      <div className="bg-white shadow-xl rounded-xl w-full max-w-[1140px] mt-8 h-full">
        <form className="p-8">
          <h1 className="text-[40px]  text-center">REGISTER HERE</h1>
          {/* <p className="text-center">Fill below details! </p> */}

          <div>
             <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <div>
                  <p className="text-[16px] font-semibold mt-2">
                    Factory Information
                  </p>

                  <div className="mt-2">
                    <p>
                      Factory Name<span className="text-red-500 ">*</span>{" "}
                    </p>
                    <input
                      type="text"
                      className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                      placeholder="Name"
                      name="factoryName"
                      value={factoryData.factoryName}
                      onChange={handleInputChange}
                    />
                  </div>
                  {factoryData.factoryName === "" && error && (
                    <p className="mt-2 text-red-500">{errorMessage}</p>
                  )}
                  <div className="mt-2">
                    <p>
                      Contact Person<span className="text-red-500 ">*</span>{" "}
                    </p>
                    <input
                      type="text"
                      className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                      placeholder="contact Person"
                      name="contactPerson"
                      value={factoryData.contactPerson}
                      onChange={handleInputChange}
                    />
                  </div>

                  {factoryData.contactPerson === "" && error && (
                    <p className="mt-2 text-red-500">{errorMessage}</p>
                  )}
                  <div className="mt-2">
                    <p>
                      Contact No<span className="text-red-500 ">*</span>{" "}
                    </p>
                    <input
                      type="text"
                      className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                      placeholder="Contact No"
                      name="contactNo"
                      value={factoryData.contactNo}
                      onChange={handleInputChangeforNumber}
                      onKeyDown={handleKeyDown}
                      maxLength={10} // Ensures the input length is restricted to 10 characters
                    />
                  </div>
                  {numberValidation !== "" && error && (
                    <p className="mt-2 text-red-500">{numberValidation}</p>
                  )}
                  {factoryData.contactNo === "" && error && (
                    <p className="mt-2 text-red-500">{errorMessage}</p>
                  )}
                  <div className="mt-2">
                    <p>
                      Email ID<span className="text-red-500 ">*</span>{" "}
                    </p>
                    <input
                      type="email"
                      className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                      placeholder="email"
                      name="email"
                      value={factoryData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  {EmailValidation !== "" && error && (
                    <p className="mt-2 text-red-500">{EmailValidation}</p>
                  )}
                  {factoryData.email === "" && error && (
                    <p className="mt-2 text-red-500">{errorMessage}</p>
                  )}
                  <div className="mt-2">
                    <p>Website (If any) </p>
                    <input
                      type="text"
                      className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                      placeholder="website"
                      name="website"
                      value={factoryData.website}
                      onChange={handleInputChange}
                    />
                  </div>

                 
                  <div className="mt-2">
                    <p>
                      Create Password <span className="text-red-500">*</span>
                    </p>
                    <div className="relative">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                        placeholder="Password"
                        name="password"
                        value={factoryData.password}
                        onChange={handleInputChange}
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute text-gray-600 transform -translate-y-1/2 right-2 top-1/2"
                      >
                        {isPasswordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                      </button>
                    </div>
                  </div>
                  {factoryData.password === "" && error && (
                    <p className="mt-2 text-red-500">{errorMessage}</p>
                  )}
                </div>
              </div>
              <div className="text-start">
                <div>
                  <div>
                    <p className="text-[16px] font-semibold mt-2">
                      Location Information
                    </p>
                    <div className="mt-2">
                      <p>
                        Factory Address<span className="text-red-500 ">*</span>{" "}
                      </p>
                      <input
                        type="text"
                        className="p-2 bg-gray-100 border rounded-xl md:w-[500px] mt-2"
                        placeholder="factory Address"
                        name="factoryAddress"
                        value={factoryData.factoryAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                    {factoryData.factoryAddress === "" && error && (
                      <p className="mt-2 text-red-500">{errorMessage}</p>
                    )}
                    <div className="gap-3 mt-5 md:justify-between md:flex">
                      <div>
                        <label className="block">
                          {" "}
                          City<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className="p-2 bg-gray-100 border rounded-xl md:after:w-[234px] mt-2"
                          placeholder="city"
                          name="city"
                          value={factoryData.city}
                          onChange={handleInputChange}
                        />
                       
                        {factoryData.city === "" && error && (
                          <p className="mt-2 text-red-500">{errorMessage}</p>
                        )}
                      </div>

                      <div>
                        <p>
                          State<span className="text-red-500 ">*</span>
                        </p>

                        <input
                          type="text"
                          className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                          placeholder="State"
                          name="State"
                          value={factoryData.State}
                          onChange={handleInputChange}
                        />
                        {factoryData.State === "" && error && (
                          <p className="mt-2 text-red-500">{errorMessage}</p>
                        )}
                      </div>
                    </div>

                    <div className="mt-5 md:justify-between md:flex">
                      <div>
                        <label className="block">
                          {" "}
                          Postal Code<span className="text-red-500 ">*</span>
                        </label>
                        <input
                          type="text"
                          className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                          placeholder="postal Code"
                          name="postalCode"
                          maxLength="6"
                          value={factoryData.postalCode}
                          onChange={handleInputChange}
                        />
                        {factoryData.postalCode === "" && error && (
                          <p className="mt-2 text-red-500">{errorMessage}</p>
                        )}
                      </div>

                      <div>
                        <p>
                          Country<span className="text-red-500 ">*</span>
                        </p>

                        <input
                          type="text"
                          className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                          placeholder="country"
                          name="country"
                          value={factoryData.country}
                          onChange={handleInputChange}
                        />
                        {factoryData.country === "" && error && (
                          <p className="mt-2 text-red-500">{errorMessage}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <p className="text-[16px] font-semibold mt-2">
                    Business Information
                  </p>
                  <div className="md:justify-between md:flex">
                    <div className="mt-2">
                      <p>
                        GST Number<span className="text-red-500 ">*</span>{" "}
                      </p>
                      <input
                        type="text"
                        className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                        placeholder="GSTNumber"
                        name="GSTNumber"
                        maxLength="15"
                        value={factoryData.GSTNumber}
                        onChange={handleInputChange}
                      />
                      {GstValidation !== "" && error && (
                        <p className="mt-2 text-center text-red-500">
                          {GstValidation}
                        </p>
                      )}
                      {factoryData.GSTNumber === "" && error && (
                        <p className="mt-2 text-red-500">{errorMessage}</p>
                      )}
                    </div>

                    <div className="mt-2">
                      <p>
                        FSSAI Number <span className="text-red-500 ">*</span>
                      </p>
                      <input
                        type="text" // Use "text" to handle maxLength easily
                        className="p-2 bg-gray-100 border rounded-xl w-[234px] mt-2"
                        placeholder="FSSAI Number"
                        name="FASSAINumber"
                        value={factoryData.FASSAINumber}
                        onChange={handleInputChange}
                        inputMode="numeric" // Show numeric keyboard on mobile devices
                        pattern="\d{14}" // Regex pattern to allow exactly 14 digits
                        title="Please enter a 14-digit number"
                        maxLength="14"
                      />
                    {FASSAIValidation !=="" && error && (
                <p className='mt-2 text-center text-red-500 w-[200px]'>{FASSAIValidation}</p>
              )}
                      {factoryData.FASSAINumber === "" && error && (
                        <p className="mt-2 text-red-500">{errorMessage}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="pl-12 mt-10">
            <p className="text-[16px] font-semibold mt-2">
              Products Information
            </p>
            <div className="flex gap-10">
              <div className="mt-2">
                <p>Add Category </p>
                <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl w-[200px] mt-2"
                                  placeholder="Category"
                                   value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
               <div className="mt-2">
                <p>Add subCategory </p>
                <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl w-[200px] mt-2"
                                  placeholder="Category"
                                   value={subcategory}
                  onChange={(e) => setsubCategory(e.target.value)}
                />
              </div>
              <div className="mt-2">
                <p>Add Brand Name </p>
                <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl w-[200px] mt-2"
                                  placeholder=" Brand Name"
                                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </div>
              <div className="mt-10">
                <button className=" rounded-lg bg-[#0EA652] text-white w-[110px] h-[40px]" onClick={handleAdd}>
                  ADD
                </button>
              </div>
            </div>
             {factoryData.products.length < 1 && error && (
                <p className='mt-2 text-red-500'>Product And Brand Name is Required.</p>
              )} 
          </div> */}

          {/* {factoryData.products.length > 0 && (
            <div className='p-5 pl-10'>
               <div className=" w-[720px] h-[242px]  border-gray-200 border-2 overflow-x-auto pl-5">
            <div className="mt-5">
               <table className="w-full bg-white rounded-lg">
                <thead className="">
                  <tr>
                    <th className="p-2">Sr. No.</th>
                      <th className="p-2">Category</th>
                       <th className="p-2">subCategory</th>
                    <th className="p-2">Brand Name</th>
                  </tr>
                </thead>
                <tbody>
                  {factoryData.products.map((item, index) => (
                    <tr
                      key={index}
                      className={`border-t ${
                        index % 2 === 0 ? "bg-white" : "bg-gray-100"
                      }`}
                    >
                      <td className="p-2 text-center">{index + 1}</td>
                      <td className="p-2 text-center">{item.category}</td>
                      <td className="p-2 text-center">{item.subcategory}</td>
                      <td className="p-2 text-center">{item.brandName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
            </div>
                     
                  )} */}
          <div className="flex justify-center mt-10">
            <button
              type="submit"
              className=" h-12 rounded-xl text-white text-xl font-semibold text-[16px] bg-amber-950 w-[484px]"
              onClick={handleSubmit}
            >
              REGISTER
            </button>
          </div>

          <div className="mt-5 text-center">
            <p>
              Already have been to Aakar?{" "}
              <span className="text-[#0855FF]">
                {" "}
                <Link to="/login">Login Now</Link>
              </span>{" "}
            </p>
          </div>
        </form>
      </div>
      <Loader loading={loading} message={"Please Wait Register the Factory."} />
    </div>
  );
}

export default Register;
