import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { BaseUrl } from '../../auth/Url';
import Add_Product_Quatation from '../Component/Add_Product_Quatation';
import EmailConfirmation from '../Component/EmailConfirmation';
import Loader from '../Component/Loader';
function Add_Quatation() {
  const [selectedQuantities, setSelectedQuantities] = useState([]);
  console.log(selectedQuantities,"selectedQuantities")
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [emailmodalIsOpen, setEmailModalIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productCode, setProductCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('Field is Required');
  const [selectedProducts, setSelectedProducts] = useState([]);
  console.log(selectedProducts, "selectedProducts")
    const [subtotal, setSubtotal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [productTotals, setProductTotals] = useState([]);
  console.log(subtotal,totalDiscount)
  const navigate = useNavigate();
   const [EmailerrorMessage, setEmailErrorMessage] = useState('');
 const token = localStorage.getItem('token');
  const [dataToSend, setDataToSend] = useState({
  Action:0,
    ShopInformation: {
      ShopName: "",
      ShopOwnerContactPerson: "", 
      Contact: "",
      EmailID: "",
      BillingAddress:""
    },
    AddDetails: {
      QuotationID: "",
      QuotationDate: "", 
      ExpiryDate: "",
    },
    ProductDetails: {
      selectedProducts: [],
      Subtotal: subtotal,
      TotalDiscount:totalDiscount
    },
     selection:[],
  });
 useEffect(() => {
  let subtotalValue = 0;
  let totalDiscountValue = 0;
  const totalsArray = []; // Array to store total and discount for each product

  selectedProducts.forEach((product) => {
    const hasDiscount = product.discount && parseFloat(product.discount) > 0;
    const discountedPrice = hasDiscount
      ? product.price - (product.price * (parseFloat(product.discount) / 100))
      : product.price;

    const calculateDiscountAmount = () => {
      if (!hasDiscount) return 0;

      const quantity30kg = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '30kg')?.quantity || 0;
      const quantity50kg = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '50kg')?.quantity || 0;
      const quantity25kg = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '25kg')?.quantity || 0;
       const quantity100kg = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '100kg')?.quantity || 0;

      const discount30kg = (product.price * (parseFloat(product.discount) / 100)) * 30 * quantity30kg;
      const discount50kg = (product.price * (parseFloat(product.discount) / 100)) * 50 * quantity50kg;
      const discount25kg = (product.price * (parseFloat(product.discount) / 100)) * 25 * quantity25kg;
       const discount100kg = (product.price * (parseFloat(product.discount) / 100)) * 70 * quantity100kg;

      return (discount30kg + discount50kg + discount25kg + discount100kg).toFixed(2);
    };

    const getTotalPrice = (size, sizeInKg) => {
      const quantity = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === size)?.quantity || 0;
      return quantity * discountedPrice * sizeInKg;
    };

    const discountAmount = calculateDiscountAmount();
    const totalProductPrice = getTotalPrice('30kg', 30) + getTotalPrice('50kg', 50) + getTotalPrice('25kg', 25)+ getTotalPrice('100kg', 100);

    // Update the subtotal and total discount
    subtotalValue += totalProductPrice;
    totalDiscountValue += parseFloat(discountAmount);

    // Store totals for each product
    totalsArray.push({
      productId: product.id,
      total: totalProductPrice,
      discount: parseFloat(discountAmount)
    });
  });

  // Set the state after calculating
  setSubtotal(subtotalValue);
  setTotalDiscount(totalDiscountValue);
  
  // Update dataToSend state
  setDataToSend((prevData) => ({
    ...prevData,
    ProductDetails: {
      ...prevData.ProductDetails,
      Subtotal: subtotalValue, // Update the Subtotal with the new value
      TotalDiscount: totalDiscountValue, // Include total discount
      selectedProducts: selectedProducts.map((product) => {
        // Find the corresponding total and discount for each product
        const totals = totalsArray.find(total => total.productId === product.id);

        // Get the selection for the current product
        const productSelection = selectedQuantities.find(item => item.productId === product.id)?.selection || [];

        return {
          id: product.id,
          category: product.category,
          discount: product.discount,
          selectedImages: product.selectedImages,
          Commission: product.Commission,
          productName: product.productName,
          price: product.price,
          subCategory: product.subCategory,
          productCode: product.productCode,
          selection: productSelection, // Add the selection here
          Dis_Amt: totals ? totals.discount : 0, // Set discount amount, or 0 if not found
          Total: totals ? totals.total : 0 // Set total, or 0 if not found
        };
      })
    },
    selection: selectedQuantities // Keep selection from state
  }));
}, [selectedProducts, selectedQuantities]);


console.log(dataToSend,"dataTosend",productTotals)

  const handleShopInfoChange = (e) => {
    const { name, value } = e.target;

    // Check if the field name is "Contact"
    if (name === "Contact") {
        // Allow only numbers
        const onlyDigits = value.replace(/\D/g, ''); // Replace non-digit characters with an empty string
        setDataToSend(prevData => ({
            ...prevData,
            ShopInformation: {
                ...prevData.ShopInformation,
                [name]: onlyDigits // Update state with the filtered value
            }
        }));
    } else {
        // For other fields, handle normally (if you have other fields that require different handling)
        setDataToSend(prevData => ({
            ...prevData,
            ShopInformation: {
                ...prevData.ShopInformation,
                [name]: value // Update state with the original value
            }
        }));
    }
};


  const handleAddDetailsChange = (e) => {
    const { name, value } = e.target;
    setDataToSend(prevData => ({
      ...prevData,
      AddDetails: {
        ...prevData.AddDetails,
        [name]: value
      }
    }));
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  // const openEmailConfirmationModal = () => {
  //   debugger
  //   if (dataToSend.ShopInformation.ShopName === "" || dataToSend.ShopInformation.EmailID === "" || dataToSend.ShopInformation.Contact === "" || dataToSend.ShopInformation.BillingAddress === "" || dataToSend.AddDetails.ExpiryDate === "" || dataToSend.AddDetails.QuotationDate === "" || dataToSend.AddDetails.QuotationID === "" || dataToSend.ProductDetails.selectedProducts.length < 1) {
  //     setError(true)
  //     return
  //   }
  //    setDataToSend(prevData => ({
  //     ...prevData,
  //     Action: 1 // Set Action to 0 when saving
  //   }));
  //   setEmailModalIsOpen(true);
  // };
const openEmailConfirmationModal = () => {
  

  // Regular expression for validating email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let errorMessages = [];

  // Check for empty fields and validate email
  if (
    dataToSend.ShopInformation.ShopName === "" ||
    dataToSend.ShopInformation.EmailID === "" ||
    dataToSend.ShopInformation.Contact === "" ||
    dataToSend.ShopInformation.BillingAddress === "" ||
    dataToSend.AddDetails.ExpiryDate === "" ||
    dataToSend.AddDetails.QuotationDate === "" ||
    dataToSend.AddDetails.QuotationID === "" 
    
  ) {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: 'Please fill all required fields.',
    });
    return;
  }

  // Validate contact number
  if (dataToSend.ShopInformation.Contact.length < 10) {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: 'Invalid Contact Number',
    });
    return;
  }
  if (dataToSend.ProductDetails.selectedProducts.length < 1) {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: 'Please select At Least One Product For Quotation.',
    });
    return;
  }

  // Validate email format
  if (!emailRegex.test(dataToSend.ShopInformation.EmailID)) {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: 'Enter a Valid Email',
    });
    return;
  }

  // Validate selected products and quantities
  let invalidProduct = null;
  const isValid = dataToSend.ProductDetails.selectedProducts.every((product) => {
    const quantityForProduct = dataToSend.selection.find(
      (q) => q.productId === product.id
    );

    // Check if the product has a valid selection with quantity > 0
    const hasValidSelection =
      quantityForProduct &&
      quantityForProduct.selection.some((sel) => sel.quantity > 0);

    // If invalid, capture the first invalid product
    if (!hasValidSelection) {
      invalidProduct = product; // Capture the first invalid product
    }

    return hasValidSelection;
  });

  // If any product is invalid, show an error message
  if (!isValid && invalidProduct) {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: `Please add at least one valid bag size with a quantity greater than 0 for product "${invalidProduct.productName}" (Code: ${invalidProduct.productCode}).`,
    });
    return;
  }

  // If all validations pass, update the state and open the modal
  setDataToSend((prevData) => ({
    ...prevData,
    Action: 1, // Set Action to 1 when preparing to send
  }));
  setEmailModalIsOpen(true);
};



  const closeEmailConfirmationModal = () => {
    setEmailModalIsOpen(false);
  };

  const handleAddProduct = (product) => {
    setSelectedProducts(prevProducts => [...prevProducts, product]);
  };

  const handleDeleteProduct = (index) => {
    setSelectedProducts(prevProducts => prevProducts.filter((_, i) => i !== index));
  };

  const calculateTotal = () => {
    // let subtotal = 0;
    selectedProducts.forEach(product => {
      subtotal += parseFloat(product.totalPrice);
    });

    const tax = subtotal * 0.15; // Example tax calculation
    const total = subtotal + tax;

    setDataToSend(prevData => ({
      ...prevData,
      ProductDetails: {
        ...prevData.ProductDetails,
        selectedProducts: selectedProducts,
        Subtotal: subtotal,
         TotalDiscount:totalDiscount
       
      }
    }));
  };
  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index] = {
      ...updatedProducts[index],
      [field]: value,
    };
    setSelectedProducts(updatedProducts);
  };

  // Update ProductDetails.selectedProducts whenever selectedProducts changes
  useEffect(() => {
    setDataToSend(prevData => ({
      ...prevData,
      ProductDetails: {
        ...prevData.ProductDetails,
        selectedProducts: selectedProducts
      }
    }));
    // calculateTotal();
  }, [selectedProducts]);


  const handleSaveQute = async () => {
  
     setDataToSend(prevData => ({
      ...prevData,
      Action: 0 // Set Action to 0 when saving
    }));
     if (dataToSend.ShopInformation.ShopName === "" || dataToSend.ShopInformation.EmailID === "" || dataToSend.ShopInformation.Contact === "" || dataToSend.ShopInformation.BillingAddress === "" || dataToSend.AddDetails.ExpiryDate === "" || dataToSend.AddDetails.QuotationDate === "" || dataToSend.AddDetails.QuotationID === "" || dataToSend.ProductDetails.selectedProducts.length < 1) {
      setError(true)
      return
    }
   
    
setLoading(true)
    try {
      // Replace 'your-token' with the actual token you want to pass
      
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      const response = await axios.post(`${BaseUrl}/Quatation/AddQuate`, dataToSend, config);
      console.log(response.data);
      setLoading(false)// Handle success response
      navigate('/quotation')
      // Optionally, reset form or show success message
    } catch (error) {
      console.error('Error saving quotation:', error);
      // Handle error response
    }
  };

  const generateQuotationID = () => {
    // Generate a UUID (Universal Unique Identifier)
    const newQuotationID = uuidv4();

    // Update the state with the new Quotation ID
    setDataToSend(prevData => ({
      ...prevData,
      AddDetails: {
        ...prevData.AddDetails,
        QuotationID: newQuotationID
      },
      ProductDetails: {
        ...prevData.ProductDetails,
        QuotationID: newQuotationID
      }
    }));

    // Optionally, update productCode state (if needed)
    setProductCode(newQuotationID);

    // Log the updated dataToSend state
    console.log(dataToSend);
  };
  const handleQuantityChange = (productId, size, value) => {
 
  const quantity = value ? Number(value) : 0; // Convert value to number, default to 0 if empty

  setSelectedQuantities((prev) => {
    // Find the product in the previous state
    const existingProduct = prev.find((item) => item.productId === productId);

    if (existingProduct) {
      // Update the selection array for the product
      const updatedSelection = existingProduct.selection
        .map((selection) => {
          if (selection.size === size) {
            return { ...selection, quantity }; // Update quantity for the matching size
          }
          return selection; // Return original selection if size doesn't match
        })
        .filter((selection) => selection.quantity > 0); // Filter out any selections with 0 quantity

      // If the size is not already in the selection and quantity > 0, add it
      if (!existingProduct.selection.some((selection) => selection.size === size) && quantity > 0) {
        updatedSelection.push({ size, quantity });
      }

      // Return updated product selection
      return prev.map((item) =>
        item.productId === productId
          ? { ...item, selection: updatedSelection }
          : item
      );
    } else {
      // If the product is not already in the state, add it only if quantity is greater than 0
      if (quantity > 0) {
        return [
          ...prev,
          { productId, selection: [{ size, quantity }] },
        ];
      }
      return prev; // No changes if the quantity is 0 and the product isn't already in the state
    }
  });
};


  return (
    <div style={{ backgroundColor: 'rgba(217, 217, 217, 1)', minHeight: "100vh" }}>
       <div  className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] ">
      <div className='p-5'>
        
        <div className='p-2 mt-2 bg-white rounded-lg'>
          <div className='md:flex'>
            <div className='p-2 '>
              <p className='font-semibold '>Shop Information</p>
              <div className='justify-between mt-2 md:flex'>
                <p className='mt-2'>Shop Name</p><input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] w-full"
                  placeholder=""
                  name="ShopName"
                  value={dataToSend.ShopInformation.ShopName}
                  onChange={handleShopInfoChange}
                />
                
              </div>
              {dataToSend.ShopInformation.ShopName ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}

              <div className='justify-between mt-2 md:flex'>
                <p className='mt-2'>Shop Owner</p><input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] w-full"
                  placeholder=""
                  name="ShopOwnerContactPerson"
                  value={dataToSend.ShopInformation.ShopOwnerContactPerson}
                  onChange={handleShopInfoChange}
                />
              </div>
              {dataToSend.ShopInformation.ShopOwnerContactPerson ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
             <div className='justify-between mt-2 md:flex'>
    <p className='mt-2'>Contact Number</p>
    <input
        type="text"
        className="p-2 bg-gray-100 border rounded-xl md:w-[400px] w-full"
        placeholder=""
        name="Contact"
        value={dataToSend.ShopInformation.Contact}
        onChange={handleShopInfoChange}
        maxLength={10} // Limit input length to 10 characters
        pattern="\d{10}" // Allow only digits and ensure it is exactly 10 digits
        title="Please enter a valid 10-digit phone number" // Tooltip for invalid input
        required // Makes the field required
    />
</div>

               {dataToSend.ShopInformation.Contact ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
              <div className='justify-between mt-2 md:flex'>
                <p className='mt-2'>Email ID</p><input
                  type="email"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] w-full"
                  placeholder=""
                  name="EmailID"
                  value={dataToSend.ShopInformation.EmailID}
                  onChange={handleShopInfoChange}
                />
              </div>
               {dataToSend.ShopInformation.EmailID ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
              <div className='justify-between mt-2 md:flex'>
                <p className='mt-2'>Billing Address</p><input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] w-full"
                  placeholder=""
                  name="BillingAddress"
                  value={dataToSend.ShopInformation.BillingAddress}
                  onChange={handleShopInfoChange}
                />
              </div>
              {dataToSend.ShopInformation.BillingAddress ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
            </div>
             
            <div className='p-2'>
              <p className='font-semibold'>Add Details</p>
              <div className='justify-between gap-10 mt-2 md:flex'>
                <p className='mt-2'>Quotation ID</p>
               
                 <div className="grid items-center mt-2 md:flex">
        <input
          type="text"
          className="p-2 bg-gray-100 border md:w-[200px]"
          placeholder="#----"
          value={productCode}
          readOnly
                      />
                    
                           <button
          type="button"
                      className="p-2 mt-2 ml-2 text-white md:mt-0"
                       style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }}
           onClick={generateQuotationID}
        >
          Generate
        </button>
       
                    </div>
              </div>
               {productCode ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
              <div className='flex justify-between gap-10 mt-2'>
                <p className='mt-2'>Quotation Date</p><input
                  type="date"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[300px]"
                  placeholder=""
                  name="QuotationDate"
                  value={dataToSend.AddDetails.QuotationDate}
                  onChange={handleAddDetailsChange}
                />
               
              </div>
                {dataToSend.AddDetails.QuotationDate ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
              <div className='flex justify-between gap-10 mt-2'>
                <p className='mt-2'>Expiry Date</p><input
                  type="date"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[300px]"
                  placeholder=""
                  name="ExpiryDate"
                  value={dataToSend.AddDetails.ExpiryDate}
                  onChange={handleAddDetailsChange}
                />
              </div>
               {dataToSend.AddDetails.ExpiryDate ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
            </div>
          </div>

          <div className="flex justify-between p-2 mt-8">
            <div className='font-semibold'>
              Product Details
            </div>
            <div>
              <button onClick={openModal} className="flex gap-2 p-2 text-white border rounded-lg bg-slate-900"  style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }}>
                <IoMdAddCircleOutline className='w-5 h-5 mt-0.5' /> ADD PRODUCT
              </button>
            </div>
          </div>
            {
              selectedProducts.length > 0 && (
                <div className='w-full mt-5 overflow-x-auto'>
            <table className='border-collapse md:w-full w-[200px] '>
              <thead style={{ borderBottom: '2px solid black' }}>
                <tr>
                    <th className='p-2'>Product Name</th>
                     <th className='p-2'>Bag Capacity</th>
                     <th className='p-2'>Qty</th>
                  <th className='p-2'>Category</th>
                  <th className='p-2'>MRP/kg</th>
                 
                  <th className='p-2'>Discount</th>
                  <th className='p-2'>Dis Amt</th>
                  <th className='p-2'>Total Price</th>
                  <th className='p-2'>Actions</th>
                </tr>
              </thead>
               <tbody>
         
          {(() => {
            let subtotal = 0;
       
   
      

            return (
              <>
                {selectedProducts.map((product, index) => {
                  const hasDiscount = product.discount && parseFloat(product.discount) > 0;
                  const discountedPrice = hasDiscount
                    ? product.price - (product.price * (parseFloat(product.discount) / 100))
                    : product.price;

                  const calculateDiscountAmount = (product) => {
                    if (!hasDiscount) return 0;

                    const quantity30kg = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '30kg')?.quantity || 0;
                    const quantity50kg = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '50kg')?.quantity || 0;
                    const quantity25kg = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '25kg')?.quantity || 0;
                     const quantity100kg = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '100kg')?.quantity || 0;

                    const discount30kg = (product.price * (parseFloat(product.discount) / 100)) * 30 * quantity30kg;
                    const discount50kg = (product.price * (parseFloat(product.discount) / 100)) * 50 * quantity50kg;
                    const discount25kg = (product.price * (parseFloat(product.discount) / 100)) * 25 * quantity25kg;
                      const discount100kg = (product.price * (parseFloat(product.discount) / 100)) * 70 * quantity100kg;

                    return (discount30kg + discount50kg + discount25kg + discount100kg).toFixed(2);
                  };

                  const getTotalPrice = (size, sizeInKg) => {
                    const quantity = selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === size)?.quantity || 0;
                    return quantity * discountedPrice * sizeInKg;
                  };

                  const discountAmount = calculateDiscountAmount(product);
                  const totalProductPrice = getTotalPrice('30kg', 30) + getTotalPrice('50kg', 50) + getTotalPrice('25kg', 25) + getTotalPrice('100kg', 100);

                  // Update the subtotal
                  subtotal += totalProductPrice;
                      // Set the state after calculating
    

                  return (
                    <tr key={index} className='text-center border'>
                            <td className='p-2'>
                              <div className="flex items-center justify-center gap-5">
                                {/* <img src={product.selectedImages[0]} alt={product.productName} className="w-[60px] h-[40px] object-cover" /> */}
                                <p className="text-center">{product.productName}</p>
                              </div>
                            </td>
                            <td className="p-4 ">
                        <div className="gap-2">
                           {product?.Commission?.dealer25Kg > 0 && (
                                  <div className="flex flex-col items-center">
                                    <div className="border rounded p-2 cursor-pointer bg-gray-200 hover:bg-gray-300 w-[100px] h-[40px] mt-1">
                                      25 Kg
                                    </div>
                                  </div>
                          )}
                                {product?.Commission?.dealer30Kg > 0 && (
                                  <div className="flex flex-col items-center">
                                    <div className="border rounded p-2 cursor-pointer bg-gray-200 hover:bg-gray-300 w-[100px] h-[40px]">
                                      30 Kg
                                    </div>
                                  </div>
                                )}
                                {product?.Commission?.dealer50Kg > 0 && (
                                  <div className="flex flex-col items-center">
                                    <div className="border rounded p-2 cursor-pointer bg-gray-200 hover:bg-gray-300 w-[100px] h-[40px] mt-1">
                                      50 Kg
                                    </div>
                                  </div>
                                )}
                               
                            {product?.Commission?.dealer100Kg > 0 && (
                                  <div className="flex flex-col items-center">
                                    <div className="border rounded p-2 cursor-pointer bg-gray-200 hover:bg-gray-300 w-[100px] h-[40px] mt-1">
                                      100 Kg
                                    </div>
                                  </div>
                                )}
                                {!(product?.Commission?.dealer30Kg > 0 || product?.Commission?.dealer50Kg > 0 ||
                                  product?.Commission?.dealer25Kg > 0 ||
                                  product?.Commission?.dealer100Kg > 0) && (
                                    <div className="text-gray-500">N/A</div>
                                  )}
                              </div>
                            </td>
                             <td className="p-4">
                        <div className="gap-2">
                           {product.Commission.dealer25Kg > 0 && (
      <div className="flex flex-col items-center">
        <input
          type="number"
          min="1"
          max="100"
          placeholder="25kg Qty"
          value={
            selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '25kg')?.quantity || ''
          }
          onChange={(e) => {
            const value = e.target.value;
            // Only allow numbers between 1 and 100
            if (/^\d{0,3}$/.test(value) && (value === '' || (Number(value) >= 1 && Number(value) <= 100))) {
              handleQuantityChange(product.id, '25kg', value);
            }
          }}
          onKeyPress={(e) => {
            // Allow only numeric input
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          className="mt-1 border rounded p-1 w-[100px] text-center"
        />
      </div>
                          )}
    {product.Commission.dealer30Kg > 0 && (
      <div className="flex flex-col items-center">
        <input
          type="number"
          min="1"
          max="100"
          placeholder="30kg Qty"
          value={
            selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '30kg')?.quantity || ''
          }
          onChange={(e) => {
            const value = e.target.value;
            // Only allow numbers between 1 and 100
            if (/^\d{0,3}$/.test(value) && (value === '' || (Number(value) >= 1 && Number(value) <= 100))) {
              handleQuantityChange(product.id, '30kg', value);
            }
          }}
          onKeyPress={(e) => {
            // Allow only numeric input
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          className="mt-1 border rounded p-1 w-[100px] text-center"
        />
      </div>
    )}
    {product.Commission.dealer50Kg > 0 && (
      <div className="flex flex-col items-center">
        <input
          type="number"
          min="1"
          max="100"
          placeholder="50kg Qty"
          value={
            selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '50kg')?.quantity || ''
          }
          onChange={(e) => {
            const value = e.target.value;
            // Only allow numbers between 1 and 100
            if (/^\d{0,3}$/.test(value) && (value === '' || (Number(value) >= 1 && Number(value) <= 100))) {
              handleQuantityChange(product.id, '50kg', value);
            }
          }}
          onKeyPress={(e) => {
            // Allow only numeric input
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          className="mt-1 border rounded p-1 w-[100px] text-center"
        />
      </div>
    )}
   
                           {product.Commission.dealer100Kg > 0 && (
      <div className="flex flex-col items-center">
        <input
          type="number"
          min="1"
          max="100"
          placeholder="100kg Qty"
          value={
            selectedQuantities.find(item => item.productId === product.id)?.selection.find(sel => sel.size === '100kg')?.quantity || ''
          }
          onChange={(e) => {
            const value = e.target.value;
            // Only allow numbers between 1 and 100
            if (/^\d{0,3}$/.test(value) && (value === '' || (Number(value) >= 1 && Number(value) <= 100))) {
              handleQuantityChange(product.id, '100kg', value);
            }
          }}
          onKeyPress={(e) => {
            // Allow only numeric input
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          className="mt-1 border rounded p-1 w-[100px] text-center"
        />
      </div>
    )}
    {!(product.Commission.dealer30Kg > 0 || product.Commission.dealer50Kg > 0 || product.Commission.dealer25Kg > 0|| product.Commission.dealer100Kg > 0) && (
      <div className="text-gray-500">N/A</div>
    )}
  </div>
</td>

                            {/* <td className='p-2'>{product.name}</td> */}
                            <td className='p-2'>{product.category}</td>
                            <td className="p-4">
                              {hasDiscount ? (
                                <>
                                  <del className="mr-2 text-gray-500">₹{product.price}</del>
                                  <span className="text-green-500">₹{discountedPrice.toFixed(2)}</span>
                                </>
                              ) : (
                                <span>₹{product.price}</span>
                              )}
                            </td>
                   
                            <td className="p-4">
                              {product.discount ? `${product.discount}%` : '0'}
                            </td>
                            <td className='p-2'>
        {/* Display the discount amount */}
        ₹{discountAmount}
      </td>
                            {/* <td className='p-2'>{product.discountAmount || 0}</td> */}
                            <td className="p-4">
    {/* Calculate the total price with size-based multipliers */}
    ₹{(
        getTotalPrice('30kg', 30) + 
        getTotalPrice('50kg', 50) + 
                          getTotalPrice('25kg', 70) +
                            getTotalPrice('100kg', 100)
    ).toFixed(2)}
            </td>
                            <td className='p-2'>
                              <button onClick={() => handleDeleteProduct(index)} className="text-red-500">
                                <MdDelete className='w-5 h-5' />
                              </button>
                            </td>
                          </tr>
                  );
                })}

              
                 <tr className='text-center'>
                  <td colSpan="5" className='p-2'></td>
                  <td className='p-2 text-start'></td>
                  <td className='p-2'>Subtotal </td>
                   <td colSpan="2" className='p-2'>₹{subtotal.toFixed(2)} Rs</td>
                </tr>
                 <tr className='text-center'>
                  <td colSpan="5" className='p-2'></td>
                  <td className='p-2 text-start'> </td>
                  <td className='p-2 text-red-500'>Total Discount</td>
                  <td colSpan="2" className='p-2'>- ₹{totalDiscount.toFixed(2)} Rs</td>
                </tr>
              </>
            );
          })()}
          
        </tbody>
            </table>
          </div>
              )
}
          
          <div>
            {dataToSend?.ProductDetails?.selectedProducts?.length < 1 && error && (
                <p className='mt-2 text-center text-red-500'>At Least One Product Add For Quotation</p>
              )}
            <div className="flex justify-end gap-2 p-5 mt-10">
              {/* <div>
                <button className="p-2 text-white border border-black rounded-lg bg-slate-900 w-[100px]" onClick={handleSaveQute}>
                  SAVE 
                </button>
              </div> */}
              <div>
                <button className="p-2 text-white bg-green-700 border rounded-lg w-[100px]" onClick={openEmailConfirmationModal}>
                  SEND 
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <Add_Product_Quatation onClose={closeModal} isModalOpen={modalIsOpen} setSelectedProducts={setSelectedProducts} selectedProducts={selectedProducts} onAddProduct={handleAddProduct} />
      <EmailConfirmation isModalOpen={emailmodalIsOpen} onClose={closeEmailConfirmationModal} ProductObject={dataToSend} />
        {loading && <Loader loading={loading} message={"Please Wait..."} />}
    </div>
    </div>
   
  );
}

export default Add_Quatation;
