import axios from 'axios';
import React, { useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { updateOrderStatus, updateOrderStatuscancelandpending } from '../../Service/OrderManagment/OrderManagmentApi';
import { BaseUrl } from '../../auth/Url';

function Order_Managment_Model({ isModalOpen, onClose, ModelAction, orderData,selectedActions }) {
  const [isToggled, setIsToggled] = useState(false);
  const [reason, setReason] = useState('');
  console.log(orderData,"orderData")
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  if (!isModalOpen) return null;

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleOkClick = async () => {
  debugger;
  if (reason.trim() === '') {
    setShowError(true);
  } else {
    setShowError(false);
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    
    try {
      const response = await updateOrderStatuscancelandpending(token, orderData, selectedActions, reason,orderData.customerInfo.EmailID , isToggled);

      if (response.status === 201) {
        console.log('Order action recorded successfully');
        onClose();
        setReason('');
        navigate("/ordermanagement");
      }
    } catch (error) {
      // Error is already logged in updateOrderStatus
      // Additional error handling if needed
    }
  }
};

  const handleReasonChange = (e) => {
    setReason(e.target.value);
    if (showError && e.target.value.trim() !== '') {
      setShowError(false);
    }
  };



const handleProceedToFactory = async () => {
       // Adjust as per your token storage method

        try {
          const message = await updateOrderStatus(orderData.orderId, orderData.ProductDetails.ProductID,
            // orderData.customerInfo.EmailID,
           orderData.customerInfo.EmailID,
            isToggled);
            console.log(message);
            navigate('/ordermanagement');
        } catch (error) {
            console.error('Error updating order status:', error);
        }
  };
  
  const handleConfirmRefund = async () => {
  try {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage

    const response = await axios.post(
        `${BaseUrl}/Order/ChangeRefundOrderStatus`,
      {
        orderId: orderData.orderId,
        productId: orderData.ProductDetails.ProductID,
        action: ModelAction,
        reason:reason
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      }
    );

    if (response.status === 200) {
      console.log('Refund status updated successfully');
    } else {
      console.log('Failed to update refund status');
    }

    onClose(); // Close the modal after successful update
  } catch (error) {
    console.error('Error updating refund status:', error);
  }
};
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {ModelAction === "accept" && (
        <div className="relative p-6 bg-white rounded-lg shadow-lg w-[700px]">
          <div className="flex justify-end">
            <MdCancel onClick={onClose} className="cursor-pointer w-7 h-7" />
          </div>
          <div className="text-xl text-center">
            <p>Order Confirmation</p>
          </div>
          <div className="mt-5 text-center">
            <p>You have accepted the order <span className='text-blue-600'>{orderData.orderId}</span> from <span className='text-blue-600'>{orderData.customerInfo.ShopName}</span>. 
Please proceed to fill in the shipping details in <span className='font-semibold'>Dispatch Management</span> to continue 
with the dispatch process.
            </p>
          </div>
          <div className="flex gap-2 p-2 mt-10 text-center bg-gray-200">
            <div className="mb-2 text-lg">Send Confirmation email to customer ?</div>
            <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
              <input
                type="checkbox"
                name="toggle"
                id="toggle"
                checked={isToggled}
                onChange={handleToggle}
                className="sr-only"
              />
              <label
                htmlFor="toggle"
                className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                  isToggled ? 'bg-[#0855FF]' : ''
                }`}
              >
                <span
                  className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                    isToggled ? 'translate-x-6' : ''
                  }`}
                ></span>
              </label>
            </div>
          </div>
          <div className="flex justify-end gap-5 mt-10">
            <button
              onClick={onClose}
              className="px-4 py-2 text-black transition bg-gray-300 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleProceedToFactory}
             
              className="px-4 py-2 text-white transition rounded-lg"
              style={{ backgroundColor: '#4C3F35' }}
            >
             PROCEED TO FACTORY
            </button>
          </div>
        </div>
      )}
       {ModelAction === "ApproveRefund" && (
        <div className="relative p-6 bg-white rounded-lg shadow-lg w-[700px]">
          <div className="flex justify-end">
            <MdCancel onClick={onClose} className="cursor-pointer w-7 h-7" />
          </div>
          <div className="text-xl text-center">
            <p>Return Order Confirmation</p>
          </div>
          <div className="mt-5 text-center">
            <p>You have accepted the Return order  from {orderData.customerInfo.ShopName}. 
Please Payment status in Refund List.
            </p>
          </div>
          <div className="flex gap-2 p-2 mt-10 text-center bg-gray-200">
            <div className="mb-2 text-lg">Send Confirmation email to customer ?</div>
            <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
              <input
                type="checkbox"
                name="toggle"
                id="toggle"
                checked={isToggled}
                onChange={handleToggle}
                className="sr-only"
              />
              <label
                htmlFor="toggle"
                className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                  isToggled ? 'bg-blue-700' : ''
                }`}
              >
                <span
                  className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                    isToggled ? 'translate-x-6' : ''
                  }`}
                ></span>
              </label>
            </div>
          </div>
          <div className="flex justify-end gap-5 mt-10">
            <button
              onClick={onClose}
              className="px-4 py-2 text-black transition bg-gray-300 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmRefund}
              // to={"/fillDetails"}
              className="px-4 py-2 text-white transition rounded-lg"
              style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
            >
            Confirm Refund
            </button>
          </div>
        </div>
      )}
       {ModelAction === "RejectRefund" && (
       <div className="relative p-6 bg-white rounded-lg shadow-lg w-[800px]">
          <div className="flex justify-end">
            <MdCancel onClick={onClose} className="cursor-pointer w-7 h-7" />
          </div>
          <div className="text-xl text-center">
            <p>Order Issue Notification</p>
          </div>
          <div className="mt-5">
            <p>Product ID:<span className='text-blue-500'>{selectedActions.ProductID}</span> </p>
            <p>Current Status:<span className='text-[#FF9900]'>{selectedActions.Action}</span> </p>
          </div>
          <div className='mt-2'>
            <div>
              <p className='font-semibold'>Specify Reason</p>
            </div>
            <div className='flex gap-2 mt-2'>
              <div>
                 <input
                type="text"
                value={reason}
                onChange={handleReasonChange}
                className={`p-2 bg-gray-100 border rounded-xl w-[490px]  h-[50px]${showError ? 'border-red-500' : ''}`}
                placeholder="write"
              />
              </div>
              <div>
                 Send Email? {"          "}
              <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
              <input
                type="checkbox"
                name="toggle"
                id="toggle"
                checked={isToggled}
                onChange={handleToggle}
                className="sr-only"
              />
              <label
                htmlFor="toggle"
                className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                  isToggled ? 'bg-[#0855FF]' : ''
                }`}
              >
                <span
                  className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                    isToggled ? 'translate-x-6' : ''
                  }`}
                ></span>
              </label>
            </div>
                </div>
             
             
             
            </div>
          </div>
           {showError && <p className="mt-2 text-red-500">Required this field</p>}
          <div className="flex justify-end gap-5 mt-10">
            <button
              onClick={onClose}
              className="px-4 py-2 text-black transition bg-gray-300 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmRefund}
              className="px-4 py-2 text-white transition rounded-lg"
              style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
            >
             CONFIRM STATUS
            </button>
          </div>
        </div>
      )}
      {/* {(ModelAction === "Pending" || ModelAction === "Cancel"   ) &&(
        <div className="relative p-6 bg-white rounded-lg shadow-lg w-[800px]">
          <div className="flex justify-end">
            <MdCancel onClick={onClose} className="cursor-pointer w-7 h-7" />
          </div>
          <div className="text-xl text-center">
            <p>Order Issue Notification</p>
          </div>
          <div className="mt-5">
            <p>Product ID:<span className='text-blue-500'>{selectedActions.ProductID}</span> </p>
            <p>Current Status:<span className='text-[#FF9900]'>{selectedActions.Action}</span> </p>
          </div>
          <div className='mt-2'>
            <div>
              <p className='font-semibold'>Specify Reason</p>
            </div>
            <div className='flex gap-2 mt-2'>
              <div>
                 <input
                type="text"
                value={reason}
                onChange={handleReasonChange}
                className={`p-2 bg-gray-100 border rounded-xl w-[490px]  h-[50px]${showError ? 'border-red-500' : ''}`}
                placeholder="write"
              />
              </div>
              <div>
                 Send Email? {"          "}
              <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
              <input
                type="checkbox"
                name="toggle"
                id="toggle"
                checked={isToggled}
                onChange={handleToggle}
                className="sr-only"
              />
              <label
                htmlFor="toggle"
                className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                  isToggled ? 'bg-[#0855FF]' : ''
                }`}
              >
                <span
                  className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                    isToggled ? 'translate-x-6' : ''
                  }`}
                ></span>
              </label>
            </div>
                </div>
             
             
             
            </div>
          </div>
           {showError && <p className="mt-2 text-red-500">Required this field</p>}
          <div className="flex justify-end gap-5 mt-10">
            <button
              onClick={onClose}
              className="px-4 py-2 text-black transition bg-gray-300 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleOkClick}
              className="px-4 py-2 text-white transition rounded-lg"
              style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
            >
             CONFIRM STATUS
            </button>
          </div>
        </div>
      )} */}
      {(ModelAction === "Pending" || ModelAction === "Cancel") && (
  <div className="relative p-6 bg-white rounded-lg shadow-lg max-w-[800px] mx-auto md:w-[500px]">
    <div className="flex justify-end">
      <MdCancel onClick={onClose} className="cursor-pointer w-7 h-7" />
    </div>
    <div className="text-lg text-xl text-center md:text-xl">
      <p>Order Issue Notification</p>
    </div>
    <div className="mt-5">
      <p>
        Product ID: <span className="text-blue-500">{selectedActions.ProductID}</span>
      </p>
      <p>
        Current Status: <span className="text-[#FF9900]">{selectedActions.Action}</span>
      </p>
    </div>
    <div className="mt-2">
      <div>
        <p className="font-semibold">Specify Reason</p>
      </div>
      <div className="flex flex-col gap-2 mt-2 md:flex-row">
        <div className="flex-1">
          <input
            type="text"
            value={reason}
            onChange={handleReasonChange}
            className={`p-2 bg-gray-100 border rounded-xl h-[50px] md:w-[300px] ${showError ? 'border-red-500' : ''}`}
            placeholder="write"
          />
        </div>
        <div className="flex items-center">
          <span className="mr-2">Send Email?</span>
          <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
            <input
              type="checkbox"
              name="toggle"
              id="toggle"
              checked={isToggled}
              onChange={handleToggle}
              className="sr-only"
            />
            <label
              htmlFor="toggle"
              className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${isToggled ? 'bg-[#0855FF]' : ''}`}
            >
              <span
                className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${isToggled ? 'translate-x-6' : ''}`}
              ></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    {showError && <p className="mt-2 text-red-500">Required this field</p>}
    <div className="flex justify-end gap-5 mt-10">
      <button
        onClick={onClose}
        className="px-4 py-2 text-black transition bg-gray-300 rounded-lg"
      >
        Cancel
      </button>
      <button
        onClick={handleOkClick}
        className="px-4 py-2 text-white transition rounded-lg"
        style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
      >
        CONFIRM STATUS
      </button>
    </div>
  </div>
)}

       {ModelAction === "NoFactory" && (
        <div className="relative p-6 bg-white rounded-lg shadow-lg w-[700px]">
          <div className="flex justify-end">
            <MdCancel onClick={onClose} className="cursor-pointer w-7 h-7" />
          </div>
          <div className="text-xl text-center">
            <p>No Factories Available</p>
          </div>
          <div className="mt-5 mb-10 text-center">
            <p>No factories are found selling products in this selected category.
            </p>
          </div>
         
       
        </div>
      )}
    </div>
  );
}

export default Order_Managment_Model;
