import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { BaseUrl } from '../../auth/Url';
import Swal from 'sweetalert2';
 // Adjust this import based on your project structure

function ShopDealerEdit({ isModalOpen, onClose, ModelAction, DealerInfo,FetchDealer }) {
  const [formData, setFormData] = useState({
    shopName: '',
    gstNumber: '',
    FSSAINumber: '',
    contactPerson: '',
    email: '',
    contactNumber: '',
    password: '',
    shopId: '',
    factoryAddress: '',
    ShopAddress:''
  });

  useEffect(() => {
    if (DealerInfo) {
      setFormData({
        shopName: DealerInfo.shopName || '',
        ShopAddress: DealerInfo.ShopAddress || '',
        gstNumber: DealerInfo.gstNumber || '',
        FSSAINumber: DealerInfo.FSSAINumber || '',
        contactPerson: DealerInfo.contactPerson || '',
        email: DealerInfo.email || '',
        contactNumber: DealerInfo.contactNumber || '',
        password: DealerInfo.password || '',
        shopId: DealerInfo.shopId || '',
        factoryAddress: DealerInfo.factoryAddress || ''
      });
    }
  }, [DealerInfo, ModelAction]);

 const handleInputChange = (e) => {
  const { name, value } = e.target;

  // If the input is for contact number, validate it
  if (name === 'contactNumber') {
    // Allow only digits
    if (!/^\d*$/.test(value)) {
      return; // Ignore any non-digit characters
    }
    
    // Update the state with the valid value
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  } else {
    // Update state for other fields
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }
};

  const isViewMode = ModelAction === "View";

  const handleUpdateDealer = async () => {
  // Validation checks
  if (!formData.shopName || !formData.ShopAddress || !formData.gstNumber || 
      !formData.FSSAINumber || !formData.contactPerson || !formData.email || 
      !formData.contactNumber || !formData.password) {
    await Swal.fire({
      icon: 'warning',
      title: 'Validation Failed',
      text: 'Please fill in all required fields.',
      confirmButtonText: 'OK'
    });
    return; // Stop execution if validation fails
  }

  // Additional validation for specific fields
  if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
    await Swal.fire({
      icon: 'warning',
      title: 'Validation Failed',
      text: 'Please enter a valid email address.',
      confirmButtonText: 'OK'
    });
    return;
  }

  if (!/^\d{10}$/.test(formData.contactNumber)) {
    await Swal.fire({
      icon: 'warning',
      title: 'Validation Failed',
      text: 'Contact number must be 10 digits long.',
      confirmButtonText: 'OK'
    });
    return;
  }
// Validation for GST Number (exactly 14 characters, can include digits and letters)
if (formData.gstNumber.length !== 15) {
  await Swal.fire({
    icon: 'warning',
    title: 'Validation Failed',
    text: 'GST Number must be exactly 15 characters long.',
    confirmButtonText: 'OK'
  });
  return;
}


  // FSSAI Number validation (maximum 15 numeric characters)
  if (!/^\d{1,14}$/.test(formData.FSSAINumber)) {
    await Swal.fire({
      icon: 'warning',
      title: 'Validation Failed',
      text: 'FSSAI Number must be a maximum of 14 digits.',
      confirmButtonText: 'OK'
    });
    return;
  }
  try {
    const response = await axios.put(`${BaseUrl}/Dealer/update/${formData.shopId}`, formData);
    
    // Show success alert
    await Swal.fire({
      icon: 'success',
      title: 'Update Successful',
      text: `Dealer information updated for Shop ID: ${formData.shopId}`,
      confirmButtonText: 'OK'
    });

    console.log('Update successful:', response.data);
    FetchDealer();
    onClose(); // Close modal after successful update
  } catch (error) {
    console.error('Error updating dealer:', error);
    
    // Show error alert
    await Swal.fire({
      icon: 'error',
      title: 'Update Failed',
      text: 'Failed to update dealer information.',
      confirmButtonText: 'Try Again'
    });
  }
};


  if (!isModalOpen) return null;

  return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="relative p-8 bg-white rounded-lg shadow-lg">
//         <div className="flex justify-between">
//           <p className="text-[18px] font-semibold">
//             {ModelAction === "View" ? "View- Shop Details" : "Edit- Shop Details"} (ShopId-{formData.shopId})
//           </p>
//           <MdOutlineCancel className="w-5 h-5 cursor-pointer" onClick={onClose} />
//         </div>

//         {/* Form Fields */}
//         <div className="mt-2">
//           <p>Shop Name</p>
//           <input
//             type="text"
//             name="shopName"
//             value={formData.shopName}
//             onChange={handleInputChange}
//             className="p-2 bg-gray-100 border rounded-xl w-[560px] mt-2"
//             disabled={isViewMode}
//           />
//         </div>
//           <div className="mt-2">
//           <p>Shop Address</p>
//           <input
//             type="text"
//             name="ShopAddress"
//             value={formData.ShopAddress}
//             onChange={handleInputChange}
//             className="p-2 bg-gray-100 border rounded-xl w-[560px] mt-2"
//             disabled={isViewMode}
//           />
//         </div>

//         <div className="flex justify-between mt-5">
//           <div>
//             <p>GST Number</p>
//             <input
//               type="text"
//               name="gstNumber"
//                 maxLength={14}
//               value={formData.gstNumber}
//               onChange={handleInputChange}
//               className="p-2 bg-gray-100 border rounded-xl w-[274px] mt-2"
//               disabled={isViewMode}
//             />
//           </div>
//         <div>
//   <p>FSSAI Number</p>
//   <input
//     type="text"
//               name="FSSAINumber"
//               maxLength={15}
//     value={formData.FSSAINumber}
//     onChange={handleInputChange}
//     className="p-2 bg-gray-100 border rounded-xl w-[274px] mt-2"
//     disabled={isViewMode}
//     pattern="[0-9]*" // This allows only numeric input
//     onKeyPress={(e) => {
//       if (!/[0-9]/.test(e.key)) {
//         e.preventDefault(); // Prevent input if it's not a number
//       }
//     }}
//   />
// </div>
//         </div>

//         <div className="mt-2">
//           <p>Contact Person</p>
//           <input
//             type="text"
//             name="contactPerson"
//             value={formData.contactPerson}
//             onChange={handleInputChange}
//             className="p-2 bg-gray-100 border rounded-xl w-[560px] mt-2"
//             disabled={isViewMode}
//           />
//         </div>

//         <div className="mt-2">
//           <p>Email ID</p>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleInputChange}
//             className="p-2 bg-gray-100 border rounded-xl w-[560px] mt-2"
//             disabled={isViewMode}
//           />
//         </div>

//         <div className="flex justify-between mt-5">
//          <div>
//   <p>Contact Number</p>
//   <input
//     type="text" // Change type to "text" to allow regex validation
//               name="contactNumber"
//               maxLength={10}
//     value={formData.contactNumber}
//     onChange={handleInputChange}
//     className="p-2 bg-gray-100 border rounded-xl w-[274px] mt-2"
//     disabled={isViewMode}
//   />
// </div>

//           <div>
//             <p>Password</p>
//             <input
//               type="text"
//               name="password"
//               value={formData.password}
//               onChange={handleInputChange}
//               className="p-2 bg-gray-100 border rounded-xl w-[274px] mt-2"
//               disabled={isViewMode}
//             />
//           </div>
//         </div>

//         {ModelAction === "Edit" && (
//           <div className="flex justify-center gap-5 mt-2">
//             <button
//               onClick={handleUpdateDealer}
//               className="px-4 py-2 mt-4 text-white transition rounded-lg bg-[#4C3F35] w-[87px] h-[37px]"
//             >
//               Save
//             </button>
//           </div>
//         )}
//       </div>
    //     </div>
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
  <div className="relative p-8 bg-white rounded-lg shadow-lg w-[90%] max-w-[600px] sm:w-[85%] md:w-[75%] lg:w-[60%] h-[600px] overflow-auto">
    <div className="flex justify-between">
      <p className="text-[16px] sm:text-[18px] font-semibold">
        {ModelAction === "View" ? "View - Shop Details" : "Edit - Shop Details"} (ShopId-{formData.shopId})
      </p>
      <MdOutlineCancel className="w-5 h-5 cursor-pointer" onClick={onClose} />
    </div>

    {/* Form Fields */}
    <div className="mt-4">
      <p className="text-sm">Shop Name</p>
      <input
        type="text"
        name="shopName"
        value={formData.shopName}
        onChange={handleInputChange}
        className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
        disabled={isViewMode}
      />
    </div>

    <div className="mt-4">
      <p className="text-sm">Shop Address</p>
      <input
        type="text"
        name="ShopAddress"
        value={formData.ShopAddress}
        onChange={handleInputChange}
        className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
        disabled={isViewMode}
      />
    </div>

    <div className="flex flex-col justify-between mt-4 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
      <div className="w-full sm:w-1/2">
        <p className="text-sm">GST Number</p>
        <input
          type="text"
          name="gstNumber"
          maxLength={14}
          value={formData.gstNumber}
          onChange={handleInputChange}
          className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
          disabled={isViewMode}
        />
      </div>
      <div className="w-full sm:w-1/2">
        <p className="text-sm">FSSAI Number</p>
        <input
          type="text"
          name="FSSAINumber"
          maxLength={15}
          value={formData.FSSAINumber}
          onChange={handleInputChange}
          className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
          disabled={isViewMode}
          pattern="[0-9]*"
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
        />
      </div>
    </div>

    <div className="mt-4">
      <p className="text-sm">Contact Person</p>
      <input
        type="text"
        name="contactPerson"
        value={formData.contactPerson}
        onChange={handleInputChange}
        className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
        disabled={isViewMode}
      />
    </div>

    <div className="mt-4">
      <p className="text-sm">Email ID</p>
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
        disabled={isViewMode}
      />
    </div>

    <div className="flex flex-col justify-between mt-4 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
      <div className="w-full sm:w-1/2">
        <p className="text-sm">Contact Number</p>
        <input
          type="text"
          name="contactNumber"
          maxLength={10}
          value={formData.contactNumber}
          onChange={handleInputChange}
          className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
          disabled={isViewMode}
        />
      </div>

      <div className="w-full sm:w-1/2">
        <p className="text-sm">Password</p>
        <input
          type="text"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
          disabled={isViewMode}
        />
      </div>
    </div>

    {ModelAction === "Edit" && (
      <div className="flex justify-center gap-5 mt-4">
        <button
          onClick={handleUpdateDealer}
          className="px-4 py-2 text-white transition rounded-lg bg-[#4C3F35] w-[87px] h-[37px]"
        >
          Save
        </button>
      </div>
    )}
  </div>
</div>

  );
}

export default ShopDealerEdit;
