// import React from 'react'

// function NewComponent() {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default NewComponent
import React, { useState, useEffect } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';

const NewComponent = ({ selectedProducts, selectedQuantities, handleQuantityChange, handleDeleteProduct ,totalPrices,setTotalPrices,setSelectedProducts}) => {
   // State to store total prices of all products

   useEffect(() => {
        // Function to calculate total prices for all products
        const calculateTotalPrices = () => {
            const newTotalPrices = []; // Initialize an array to store total prices

            selectedProducts.forEach(product => {
                const hasDiscount = product.discount && parseFloat(product.discount) > 0;
                const discountedPrice = hasDiscount
                    ? product.price - (product.price * (parseFloat(product.discount) / 100))
                    : product.price;

                const getTotalPrice = (size, sizeInKg) => {
                    const quantity =
                        selectedQuantities.find(item => item.productId === product._id)?.selection.find(sel => sel.size === size)?.quantity || 0;
                    return quantity * discountedPrice * sizeInKg; 
                };

                // Store the total price for each product in the array
                newTotalPrices.push({
                    productId: product._id,
                    totalPrice: (
                        getTotalPrice('30kg', 30) + 
                        getTotalPrice('50kg', 50) + 
                        getTotalPrice('25kg', 25) +
                        getTotalPrice('100kg', 100)
                    ).toFixed(2)
                });
            });

            setTotalPrices(newTotalPrices); // Update the state with new total prices
        };

        calculateTotalPrices(); // Call the function to calculate total prices
   }, [selectedProducts, selectedQuantities]); 
    
const handlePriceChange = (productId, newPrice) => {
    setSelectedProducts((prevProducts) =>
        prevProducts.map((product) =>
            product._id === productId ? { ...product, price: parseFloat(newPrice) || 0 } : product
        )
    );
};

    return (
        <table className="min-w-full border border-collapse border-gray-200">
            <thead>
                <tr>
                    <th className="p-4 border">Product Name</th>
                    <th className="p-4 border">Bag Capacity</th>
                    <th className="p-4 border">Qty</th>
                    <th className="p-4 border">Discount</th>
                    <th className="p-4 border">MRP/kg</th>
                    <th className="p-4 border">Category</th>
                    <th className="p-4 border">Total Price</th>
                    <th className="p-4 border">Action</th>
                </tr>
            </thead>
            <tbody>
                {selectedProducts.map((product) => {
                    const hasDiscount = product.discount && parseFloat(product.discount) > 0;
                    const discountedPrice = hasDiscount
                        ? product.price - (product.price * (parseFloat(product.discount) / 100))
                        : product.price;

                    return (
                        <tr key={product._id} className="text-center border-b border-gray-200">
                            <td className="flex items-center gap-5 p-4">
                                <img src={product.selectedImages[0]} alt={product.productName} className="w-[60px] h-[40px] object-cover" />
                                <div>
                                    <p>{product.productCode}</p>
                                    <p>{product.productName}</p>
                                </div>
                            </td>
                            <td className="p-4">
                                <div className="gap-2">
                                    {/* Bag capacity options */}
                                     {product.Commission.dealer25Kg > 0 && (
                                        <div className="flex flex-col items-center">
                                            <div className="border rounded p-2 cursor-pointer bg-gray-200 hover:bg-gray-300 w-[100px] h-[40px]">
                                                25 Kg
                                            </div>
                                        </div>
                                    )}
                                    {product.Commission.dealer30Kg > 0 && (
                                        <div className="flex flex-col items-center">
                                            <div className="border rounded p-2 cursor-pointer bg-gray-200 hover:bg-gray-300 w-[100px] h-[40px]">
                                                30 Kg
                                            </div>
                                        </div>
                                    )}
                                    {product.Commission.dealer50Kg > 0 && (
                                        <div className="flex flex-col items-center">
                                            <div className="border rounded p-2 cursor-pointer bg-gray-200 hover:bg-gray-300 w-[100px] h-[40px] mt-1">
                                                50 Kg
                                            </div>
                                        </div>
                                    )}
                                   
                                     {product.Commission.dealer100Kg > 0 && (
                                        <div className="flex flex-col items-center">
                                            <div className="border rounded p-2 cursor-pointer bg-gray-200 hover:bg-gray-300 w-[100px] h-[40px] mt-1">
                                                100 Kg
                                            </div>
                                        </div>
                                    )}
                                    {!(product.Commission.dealer30Kg > 0 || product.Commission.dealer50Kg > 0 || product.Commission.dealer25Kg > 0 || product.Commission.dealer100Kg > 0) && (
                                        <div className="text-gray-500">N/A</div>
                                    )}
                                </div>
                            </td>
                            <td className="p-4">
                                <div className="gap-2">
                                    {product.Commission.dealer25Kg > 0 && (
                                        <div className="flex flex-col items-center">
                                            <input
                                                type="number"
                                                min="1"
                                                max="100"
                                                placeholder="25kg Qty"
                                                value={
                                                    selectedQuantities.find(item => item.productId === product._id)?.selection.find(sel => sel.size === '25kg')?.quantity || ''
                                                }
                                                 onChange={(e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Allow only numbers or an empty value
            handleQuantityChange(product._id, '25kg', value);
        }
    }}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                className="mt-1 border rounded p-1 w-[100px] text-center"
                                            />
                                        </div>
                                    )}
                                    {product.Commission.dealer30Kg > 0 && (
                                        <div className="flex flex-col items-center">
                                            <input
                                                type="number"
                                                min="1"
                                                max="100"
                                                placeholder="30kg Qty"
                                                value={
                                                    selectedQuantities.find(item => item.productId === product._id)?.selection.find(sel => sel.size === '30kg')?.quantity || ''
                                                }
                                               onChange={(e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Allow only numbers or an empty value
            handleQuantityChange(product._id, '30kg', value);
        }
    }}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                className="mt-1 border rounded p-1 w-[100px] text-center"
                                            />
                                        </div>
                                    )}
                                    {product.Commission.dealer50Kg > 0 && (
                                        <div className="flex flex-col items-center">
                                            <input
                                                type="number"
                                                min="1"
                                                max="100"
                                                placeholder="50kg Qty"
                                                value={
                                                    selectedQuantities.find(item => item.productId === product._id)?.selection.find(sel => sel.size === '50kg')?.quantity || ''
                                                }
                                                onChange={(e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Allow only numbers or an empty value
            handleQuantityChange(product._id, '50kg', value);
        }
    }}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                className="mt-1 border rounded p-1 w-[100px] text-center"
                                            />
                                        </div>
                                    )}
                                    
                                      {product.Commission.dealer100Kg > 0 && (
                                        <div className="flex flex-col items-center">
                                            <input
                                                type="number"
                                                min="1"
                                                max="100"
                                                placeholder="100kg Qty"
                                                value={
                                                    selectedQuantities.find(item => item.productId === product._id)?.selection.find(sel => sel.size === '100kg')?.quantity || ''
                                                }
                                                 onChange={(e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Allow only numbers or an empty value
            handleQuantityChange(product._id, '100kg', value);
        }
    }}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                className="mt-1 border rounded p-1 w-[100px] text-center"
                                            />
                                        </div>
                                    )}
                                    {!(product.Commission.dealer30Kg > 0 || product.Commission.dealer50Kg > 0 || product.Commission.dealer25Kg > 0 || product.Commission.dealer100Kg > 0) && (
                                        <div className="text-gray-500">N/A</div>
                                    )}
                                </div>
                            </td>
                            <td className="p-4">
                                {product.discount ? `${product.discount}%` : '0'}
                            </td>
                            {/* <td className="p-4">
                                {hasDiscount ? (
                                    <>
                                        <del className="mr-2 text-gray-500">₹{product.price}</del>
                                        <span className="text-green-500">₹{discountedPrice.toFixed(2)}</span>
                                    </>
                                ) : (
                                    <span>₹{product.price}</span>
                                )}
                            </td> */}
                            <td className="p-4">
    <input
                                    type="text"
                                    placeholder='Enter MRP'
        value={product.price}
        onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and decimal points
                handlePriceChange(product._id, value);
            }
        }}
        onKeyPress={(e) => {
            if (!/[0-9.]/.test(e.key)) {
                e.preventDefault();
            }
        }}
        className="border rounded p-1 w-[100px] text-center"
    />
</td>

                            <td className="p-4">{product.category}</td>
                           <td className="p-4">
    {
        // Find the total price for the current product
        totalPrices.find(price => price.productId === product._id)?.totalPrice || '₹0.00'
    }
</td>

                            <td className="flex items-center justify-center p-4">
                                <RiDeleteBinLine onClick={() => handleDeleteProduct(product._id)} className="text-red-600 cursor-pointer" />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default NewComponent;
