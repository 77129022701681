// import React from 'react'

// function ViewFactory_Details() {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default ViewFactory_Details


import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoPrintOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../auth/Url";

function ViewFactory_Details() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedProductImages, setSelectedProductImages] = useState([]);
  console.log("selectedProductImages",selectedProductImages)
  const [productObject, setProductObject] = useState({
    productName: "",
    productDescription: "",
    category: "",
    subCategory: "",
    unit: "",
    productCode: "",
    price: "",
    qualityVariety: "",
    selectedImages: [],
    supplierName: "",
    supplierCity: "",
    contactNumber: "",
    discount: "",
    effectiveDate: "",
    expiryDate: "",
    visibility: false, // Assuming visibility is a boolean
  });
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

  // Function to fetch product details from API
  useEffect(() => {
    const fetchProductDetails = async (productId) => {
      try {
        const response = await axios.post(
          `${BaseUrl}/products/GetSingleproducts`,
          { productId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (
          response.data &&
          response.data.result &&
          response.data.SingleProductList
        ) {
          const productData = response.data.SingleProductList;

          setProductObject({
            productName: productData.productName || "",
            productDescription: productData.productDescription || "",
            category: productData.category || "",
            subCategory: productData.subCategory || "",
            unit: productData.unit || "",
            productCode: productData.productCode || "",
            price: productData.price || "",
            qualityVariety: productData.qualityVariety || "",
            selectedImages: productData.selectedImages || [],
            supplierName: productData.supplierName || "",
            supplierCity: productData.supplierCity || "",
            contactNumber: productData.supplierContactNumber || "",
            discount: productData.discount || "",
            effectiveDate: productData.effectiveDate || "",
            expiryDate: productData.expiryDate || "",
            visibility: productData.isVisible || false,
          });

          setSelectedProductImages(productData.selectedImages || []);
        } else {
          console.error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    if (location.state && location.state.Productcode) {
      fetchProductDetails(location.state.Productcode);
    } else {
      console.error("Missing Productcode in location state");
    }
  }, [location.state, token]);

  // Function to handle printing
  const handlePrint = () => {
    window.print();
  };

  // Function to navigate to manage inventory page
  const handleNavigate = () => {
    navigate("/manageinventory");
  };

  // Function to navigate to previous image
  const goToPrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedProductImages.length - 1 : prevIndex - 1
    );
  };

  // Function to navigate to next image
  const goToNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedProductImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to handle removing an image from selected images
  const handleRemoveImage = (indexToRemove) => {
    setSelectedProductImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <>
      <div style={{ backgroundColor: "#D9D9D9", minHeight: "100vh" }}>
        <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] pt-5">
          <div className="flex flex-grow gap-20 p-5 mt-10">
            <div className="items-center p-4 bg-white border border-gray-300 rounded-lg shadow-md">
              <div className="mt-10 border w-[600px] print-container">
                <div className="mt-2">
                  <p className="p-2 font-semibold">Supplier’s info</p>
                  <div className="">
                    <div className="flex p-2">
                      <p className="w-[180px]">Factory Name:</p>
                      <p>Grain Haven</p>
                    </div>
                    <div className="flex p-2">
                      <p className="w-[180px]">Address:</p>
                      <p>Jehan Circle, Gangapur Road, Nahik-411004</p>
                    </div>
                    <div className="flex p-2">
                      <p className="w-[180px]">Contact:</p>
                      <p>+91 8692956354</p>
                    </div>
                    <div className="flex p-2">
                      <p className="w-[180px]">Email ID:</p>
                      <p>grainhaven@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div className="flex p-2">
                  <p className="w-[180px]">Product Name:</p>
                  <p>{productObject.productName}</p>
                </div>
                <hr className="my-2" />
                <div className="flex gap-20 p-2">
                  <p className="w-[100px]">Category:</p>
                  <p>{productObject.category}</p>
                </div>
                <hr className="my-2" />
                <div className="flex gap-20 p-2">
                  <p className="w-[100px]">Sub Category:</p>
                  <p>{productObject.subCategory}</p>
                </div>
                <hr className="my-2" />
                <div className="flex gap-20 p-2">
                  <p className="w-[100px]">Product ID:</p>
                  <p>{productObject.productCode}</p>
                </div>
                <hr className="my-2" />
                <div className="flex gap-20 p-2">
                  <p className="w-[100px]">Unit:</p>
                  <p>{productObject.unit}</p>
                </div>
                <hr className="my-2" />
                <div className="flex gap-20 p-2">
                  <p className="w-[100px]">Base Price:</p>
                  <p>{productObject.price}</p>
                </div>
                <hr className="my-2" />
                <div className="flex gap-20 p-2">
                  <p className="w-[100px]">Discount:</p>
                  <p>{productObject.discount}</p>
                </div>
                <hr className="my-2" />
                <div className="flex gap-20 p-2">
                  <p className="w-[100px]">Status:</p>
                  <p>{productObject.visibility ? "Active" : "Inactive"}</p>
                </div>
                <hr className="my-2" />
                <div className="flex gap-20 p-2">
                  <p className="w-[100px]">Description:</p>
                  <p>{productObject.productDescription}</p>
                </div>
              </div>
              
            </div>
            <div>
                <div className="p-5 bg-white border border-gray-300 rounded-lg shadow-md h-[350px] w-90">
              <p className="font-bold text-start">Product Images</p>
              <div className="flex items-center justify-center mt-5 w-80 h-60">
                <div className="relative w-full h-full p-12 bg-gray-100">
                  {selectedProductImages.length > 0 ? (
                  <img
        src={selectedProductImages[currentImageIndex]}
        alt="Product"
        className="object-cover w-full h-full rounded-lg"
      />

                  ) : (
                    <div className="flex items-center justify-center w-full h-full">
                      <p>No images selected</p>
                    </div>
                  )}
                  <div className="absolute inset-y-0 left-0 flex items-center justify-center w-12">
                    <button
                      onClick={goToPrevious}
                      className="text-2xl text-white focus:outline-none"
                    >
                      <span className="p-3 text-black bg-gray-300 border rounded-lg">
                        {" "}
                        &lt;
                      </span>
                    </button>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center justify-center w-12">
                    <button
                      onClick={goToNext}
                      className="text-2xl text-white focus:outline-none"
                    >
                      <span className="p-3 text-black bg-gray-300 border rounded-lg">
                        {" "}
                        &gt;
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              
            </div>
                  <div className="flex justify-end mt-2">
                <button
                  className="flex gap-3 p-3 text-white border-red-700 rounded-lg"
                  style={{ backgroundColor: "#FB3232" }}
                  onClick={handlePrint}
                >
                  <IoPrintOutline className="w-5 h-5" />
                  Print
                </button>
              </div>
              </div>
          
        
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewFactory_Details;
