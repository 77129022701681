import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchOrderData } from '../../Service/OrderManagment/OrderManagmentApi';
import Order_Managment_Model from '../Component/Order_Managment_Model';

function ViewRequest() {
  const location = useLocation();
  const { orderId, productId } = location.state || {};

  const [selectedActions, setSelectedActions] = useState({ action: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    if (orderId && productId) {
      const getData = async () => {
        try {
          const data = await fetchOrderData(orderId, productId);
          setOrderData(data);
        } catch (error) {
          console.error('Error fetching order data:', error);
        }
      };

      getData();
    }
  }, [orderId, productId]);

  const handleActionChange = (productId, action) => {
    setSelectedActions({ ProductID: productId, Action: action });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getButtonClass = (action) => {
    switch (action) {
      case 'ApproveRefund':
        return 'bg-green-500 text-white';
      case 'RejectRefund':
        return 'bg-red-500 text-white';
      default:
        return 'text-black';
    }
  };

  return (
    <div style={{ backgroundColor: 'rgba(217, 217, 217, 1)', minHeight: '100vh' }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className="p-5">
          <div className="p-5 mt-5 bg-white rounded-lg">
            <div className="flex justify-between">
              <div>
                <p className="text-[28px] font-semibold">Order {orderId}</p>
                <p>
                  <span className="text-gray-500">Ordered on</span> {orderData?.ProductDetails?.OrderDate || 'N/A'}
                </p>
              </div>
            </div>
            <hr className="mt-5 border-1" />
            <div className="flex gap-10 pl-10 mt-5">
              <div className="p-2 border w-[300px] h-[200px] rounded-lg">
                <p className="font-semibold">Customer info</p>
                <div>
                  <p>Customer Name: {orderData?.customerInfo?.CustomerName || 'N/A'}</p>
                  <p>Shop Name: {orderData?.customerInfo?.ShopName || 'N/A'}</p>
                  <p>Contact No: {orderData?.customerInfo?.ContactNo || 'N/A'}</p>
                  <p>Email ID: {orderData?.customerInfo?.EmailID || 'N/A'}</p>
                  <p>PO: #{orderId}</p>
                  <div className="flex justify-between">
                    <p>Payment Method: {orderData?.ProductDetails?.PaymentMethod || 'N/A'}</p>
                    {orderData?.ProductDetails?.Duepayment === "0.00" ? (
                      <div className="p-1 text-center text-white bg-green-600 border border-green-800 rounded-lg cursor-pointer" style={{ width: '80px', height: '30px' }}>
                        Paid
                      </div>
                    ) : (
                      <div className="p-1 text-center text-white bg-[#FF9900] border border-[#FF9900] rounded-lg cursor-pointer" style={{ width: '80px', height: '30px' }}>
                        Pending
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-2 border w-[300px] h-[200px] rounded-lg">
                <p className="font-semibold">Billing Address</p>
                <div>{orderData?.ProductDetails?.OrderTrackingDetails?.Billing_Address || 'N/A'}</div>
              </div>
              <div className="p-2 border w-[300px] h-[200px] rounded-lg">
                <p className="font-semibold">Shipping Address</p>
                <div>{orderData?.ProductDetails?.OrderTrackingDetails?.Shipping_Address || 'N/A'}</div>
              </div>
            </div>
            <div className="flex items-center pl-5 pr-5 mt-10">
              <span className="mr-2 font-semibold">Product Details</span>
              <hr className="flex-grow" />
            </div>
            <div className="mt-10">
              <table className="min-w-full border-collapse">
                <thead style={{ borderBottom: '2px solid black' }}>
                  <tr>
                    <th className="p-2 font-normal">Product ID</th>
                    <th className="p-2 font-normal">Supplier/ Factory</th>
                    <th className="p-2 font-normal">Qty</th>
                    <th className="p-2 font-normal">MRP</th>
                    <th className="p-2 font-normal">Total</th>
                    <th className="p-2 font-normal">Payment</th>
                    <th className="p-2 font-normal">Due Payment</th>
                    <th className="p-2 font-normal">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData?.ProductDetails ? (
                    <tr className="text-center">
                      <td className="p-2">{orderData.ProductDetails.ProductID}</td>
                      <td className="p-2">{orderData?.ProductDetails?.SupplierInfo?.FactoryName || 'N/A'}</td>
                      <td className="p-2">{orderData.ProductDetails.QTY}</td>
                      <td className="p-2">{orderData.ProductDetails.MRP}</td>
                      <td className="p-2">{orderData.ProductDetails.Total}</td>
                      <td className="p-2">{orderData.ProductDetails.PaymentDoneAmount}</td>
                      <td className="p-2 text-red-500">{orderData.ProductDetails.Duepayment}</td>
                      <td className="flex gap-2 p-2">
                        <button
                          className={`border border-[#28C76F] p-2 rounded-lg ${getButtonClass(selectedActions.Action === 'ApproveRefund' ? 'ApproveRefund' : '')}`}
                          onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'ApproveRefund')}
                        >
                          Approve
                        </button>
                        <button
                          className={`border border-[#FB3232] p-2 rounded-lg ${getButtonClass(selectedActions.Action === 'RejectRefund' ? 'RejectRefund' : '')}`}
                          onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'RejectRefund')}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="8" className="p-4 text-center">No product details available</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="flex justify-end gap-5 mt-8">
                <Link className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg" to={"/ordermanagement"}>Cancel</Link>
                <button
                  className="px-4 py-2 mt-4 text-white transition rounded-lg"
                  style={{ backgroundColor: '#4C3F35' }}
                  onClick={openModal}
                >
                  Confirm & Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        <Order_Managment_Model
          isModalOpen={isModalOpen}
          onClose={closeModal}
          ModelAction={selectedActions.Action}
          orderData={orderData}
          selectedActions={selectedActions}
        />
      </div>
    </div>
  );
}

export default ViewRequest;
