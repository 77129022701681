import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../auth/Url';
import Swal from 'sweetalert2';
import Loader from '../Component/Loader';
function OrderPlaceManually() {
  const location = useLocation();
    const [product, setProduct] = useState();
    console.log(product,"product")
  const [totalSavingAmount, setTotalSavingAmount] = useState();
  const [state, setState] = useState('');
  const [locality, setLocality] = useState('');
  const [landmark, setLandmark] = useState('');
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState(false);
  const [alternativePhone, setAlternativePhone] = useState('');
  const [pincode, setPincode] = useState('');
  const navigate = useNavigate(); 
  // Error states for validation
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { orderData, totalSaving } = location.state || {};
    console.log(orderData)
    if (orderData && totalSaving) {
      setProduct(orderData,"orderData");
      setTotalSavingAmount(totalSaving);
    }
  }, [location.state]);

      const handleStateChange = (e) => {
    setState(e.target.value);
    if (errors.state) setErrors((prev) => ({ ...prev, state: undefined })); // Clear error
  };

  const handleLocalityChange = (e) => {
    setLocality(e.target.value);
    if (errors.locality) setErrors((prev) => ({ ...prev, locality: undefined })); // Clear error
  };

  const handleLandmarkChange = (e) => {
    setLandmark(e.target.value);
    if (errors.landmark) setErrors((prev) => ({ ...prev, landmark: undefined })); // Clear error
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    if (errors.city) setErrors((prev) => ({ ...prev, city: undefined })); // Clear error
  };

  const handleAlternativePhoneChange = (e) => {
    setAlternativePhone(e.target.value);
    if (errors.alternativePhone) setErrors((prev) => ({ ...prev, alternativePhone: undefined })); // Clear error
  };

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
    if (errors.pincode) setErrors((prev) => ({ ...prev, pincode: undefined })); // Clear error
  };
 

  // Handle Place Order button click
  const handleOrder = async () => {
   setLoading(true)
 
    const token = localStorage.getItem('token');
    
   
    // Create a new order object that includes the existing product data
    const orderData = {
      ...product,
      customerInfo: {
        ...product.customerInfo,
        Billing_Address: product.customerInfo.ShopAddress,
        Shipping_Address: product.customerInfo.ShopAddress,
      },
    };

    try {
        const response = await fetch(`${BaseUrl}/Order/CreateOrder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
          body: JSON.stringify(orderData), // Send the updated order data
        });

      if (!response.ok) {
           setLoading(false)
          throw new Error('Network response was not ok');
         
        }

        const data = await response.json();
        console.log("Order created successfully:", data);
      
       Swal.fire({
  icon: 'success',
  title: 'Order Placed!',
  text: 'Your order has been successfully placed.',
  confirmButtonText: 'OK',
  customClass: {
    confirmButton: 'custom-confirm-button', // Custom class for confirm button
  },
       }).then(() => {
   setLoading(false)
  // Navigate to order management after the alert is closed
  navigate('/ordermanagement');
});

    } catch (error) {
       setLoading(false)
        console.error("There was a problem with the fetch operation:", error);
      }
  
};


  return (
//     <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
//       <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] pt-10">
//         {/* <Link to='/Add_New_Order'>Back</Link> */}
//         <div className='flex gap-10'>
//           <div className='w-[660px] h-[370px] bg-[#FFFFFF] rounded-[18px] p-5'>
//             <div>
//               <p className="font-poppins text-[14px] font-bold leading-[21px] text-left">Delivery Address</p>
//             </div>
//             <div className='flex gap-5 mt-2'>
//               <div className='mt-2'>
//                 <p>State<span className="text-red-500">*</span></p>
//                 <input
//                   type="text"
//                   placeholder="State"
//                   className={`w-[300px] px-4 py-2 border ${errors.state ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
//                   value={state}
//                   onChange={handleStateChange}
//                 />
//                 {errors.state && <p className="text-sm text-red-500">{errors.state}</p>}
//               </div>
//               <div>
//                 <div>
//                   <p className="mb-2">Locality<span className="text-red-500">*</span></p>
//                   <input
//                     type="text"
//                     placeholder="Locality"
//                     className={`w-[300px] px-4 py-2 border ${errors.locality ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
//                     value={locality}
//                     onChange={handleLocalityChange}
//                   />
//                   {errors.locality && <p className="text-sm text-red-500">{errors.locality}</p>}
//                 </div>
//               </div>
//             </div>
//             <div className='flex gap-5 mt-2'>
//               <div className='mt-2'>
//                 <p>Landmark (Optional)<span className="text-red-500">*</span></p>
//                 <input
//                   type="text"
//                   placeholder="Landmark (Optional)"
//                   className="w-[300px] px-4 py-2 border border-gray-300 rounded-lg bg-[#F7F7F7]"
//                   value={landmark}
//                   onChange={handleLandmarkChange}
//                               />
//                                 {errors.landmark && <p className="text-sm text-red-500">{errors.landmark}</p>}
//               </div>
//               <div className='mt-2'>
//                 <p>City<span className="text-red-500">*</span></p>
//                 <input
//                   type="text"
//                   placeholder="City"
//                   className={`w-[300px] px-4 py-2 border ${errors.city ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
//                   value={city}
//                   onChange={handleCityChange}
//                 />
//                 {errors.city && <p className="text-sm text-red-500">{errors.city}</p>}
//               </div>
//             </div>
//             <div className='flex gap-5 mt-2'>
//   <div className='mt-2'>
//     <p>Alternative Phone<span className="text-red-500">*</span></p>
//     <input
//       type="text"
//       placeholder="Alternative Phone"
//       className={`w-[300px] px-4 py-2 border ${errors.alternativePhone ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
//                   value={alternativePhone}
//                   maxLength={10}
//       onChange={(e) => {
//         const value = e.target.value;
//         // Allow only numbers
//         if (/^\d*$/.test(value)) {
//           handleAlternativePhoneChange(e); // Call your handler if value is valid
//         }
//       }}
//     />
//     {errors.alternativePhone && <p className="text-sm text-red-500">{errors.alternativePhone}</p>}
//   </div>
//   <div className='mt-2'>
//     <p>Pincode<span className="text-red-500">*</span></p>
//     <input
//       type="text"
//       placeholder="Pincode"
//                   className={`w-[300px] px-4 py-2 border ${errors.pincode ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
//                   maxLength={6}
//       value={pincode}
//       onChange={(e) => {
//         const value = e.target.value;
//         // Allow only numbers
//         if (/^\d*$/.test(value)) {
//           handlePincodeChange(e); // Call your handler if value is valid
//         }
//       }}
//     />
//     {errors.pincode && <p className="text-sm text-red-500">{errors.pincode}</p>}
//   </div>
// </div>

//           </div>
//           <div className="w-[372px] h-[302px] rounded-[20px] shadow-lg border bg-white items-center mt-5">
//             <p className="p-5" style={{  fontSize: "16px", fontWeight: 500, lineHeight: "20px", textAlign: "left" }}>
//               PRICE DETAILS
//             </p>
//             <hr className="text-black"></hr>
//             <div className="p-5">
//               <p style={{ display: "flex", justifyContent: "space-between" }}>
//                 <span className="text-[#6D6D6D]">Price ({product?.ProductDetails?.length} item)</span>
//                 <span>₹ {parseFloat(product?.Total).toFixed(2)}</span>
//               </p>
//             </div>
//             <hr className="text-black"></hr>
//             <div className="pt-2 pl-5 pr-5">
//               <p style={{ display: "flex", justifyContent: "space-between", fontSize: "16px", fontWeight: 500,  }} className="mt-2">
//                 <span className="text-[#222222] text-[16px] font-[500]">Total Payable</span>
//                 <span>₹ {parseFloat(product?.Total).toFixed(2)}</span>
//               </p>
//             </div>
//             <hr className="mt-2 text-black"></hr>
//             <p className="text-[#3E8E3C] text-center text-[15px] mt-4" style={{  }}>
//               Your Total Saving on this order ₹{totalSavingAmount}
//             </p>
//             <div className='flex justify-center mt-3'>
//               <button
//                 className="w-[306px] h-[50px] bg-[#FF9F00] text-white rounded-[26.5px] relative overflow-hidden"
//                 onClick={handleOrder}
//               >
//                 Place Order
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//       {loading && <Loader loading={loading}  />}
    //     </div>
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
  <div className="mx-auto md:ml-[20%] xl:w-[80%] lg:w-[60%] pt-10 px-4">
    <div className="flex justify-center gap-10">
      {/* <div className="w-full md:w-[660px] md:h-[370px] bg-[#FFFFFF] rounded-[18px] p-5 h-auto">
        <p className="font-poppins text-[14px] font-bold leading-[21px] text-left">Delivery Address</p>
        
       
        <div className="flex flex-col gap-5 mt-2 md:flex-row">
          <div className="w-full mt-2">
            <p>State<span className="text-red-500">*</span></p>
            <input
              type="text"
              placeholder="State"
              className={`w-full px-4 py-2 border ${errors.state ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
              value={state}
              onChange={handleStateChange}
            />
            {errors.state && <p className="text-sm text-red-500">{errors.state}</p>}
          </div>
          <div className="w-full mt-2">
            <p className="mb-2">Locality<span className="text-red-500">*</span></p>
            <input
              type="text"
              placeholder="Locality"
              className={`w-full px-4 py-2 border ${errors.locality ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
              value={locality}
              onChange={handleLocalityChange}
            />
            {errors.locality && <p className="text-sm text-red-500">{errors.locality}</p>}
          </div>
        </div>

       
        <div className="flex flex-col gap-5 mt-2 md:flex-row">
          <div className="w-full mt-2">
            <p>Landmark (Optional)<span className="text-red-500">*</span></p>
            <input
              type="text"
              placeholder="Landmark (Optional)"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-[#F7F7F7]"
              value={landmark}
              onChange={handleLandmarkChange}
            />
            {errors.landmark && <p className="text-sm text-red-500">{errors.landmark}</p>}
          </div>
          <div className="w-full mt-2">
            <p>City<span className="text-red-500">*</span></p>
            <input
              type="text"
              placeholder="City"
              className={`w-full px-4 py-2 border ${errors.city ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
              value={city}
              onChange={handleCityChange}
            />
            {errors.city && <p className="text-sm text-red-500">{errors.city}</p>}
          </div>
        </div>

       
        <div className="flex flex-col gap-5 mt-2 md:flex-row">
          <div className="w-full mt-2">
            <p>Alternative Phone<span className="text-red-500">*</span></p>
            <input
              type="text"
              placeholder="Alternative Phone"
              className={`w-full px-4 py-2 border ${errors.alternativePhone ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
              value={alternativePhone}
              maxLength={10}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleAlternativePhoneChange(e);
                }
              }}
            />
            {errors.alternativePhone && <p className="text-sm text-red-500">{errors.alternativePhone}</p>}
          </div>
          <div className="w-full mt-2">
            <p>Pincode<span className="text-red-500">*</span></p>
            <input
              type="text"
              placeholder="Pincode"
              className={`w-full px-4 py-2 border ${errors.pincode ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[#F7F7F7]`}
              maxLength={6}
              value={pincode}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handlePincodeChange(e);
                }
              }}
            />
            {errors.pincode && <p className="text-sm text-red-500">{errors.pincode}</p>}
          </div>
        </div>
      </div> */}

      {/* Price Details Section */}
      <div className="w-full md:w-[372px] h-[302px] rounded-[20px] shadow-lg border bg-white mt-5 ">
        <p className="p-5" style={{ fontSize: "16px", fontWeight: 500, lineHeight: "20px", textAlign: "left" }}>
          PRICE DETAILS
        </p>
        <hr className="text-black" />
        <div className="p-5">
          <p className="flex justify-between">
            <span className="text-[#6D6D6D]">Price ({product?.ProductDetails?.length} item)</span>
            <span>₹ {parseFloat(product?.Total).toFixed(2)}</span>
          </p>
        </div>
        <hr className="text-black" />
        <div className="px-5 pt-2">
          <p className="flex justify-between font-[500] mt-2">
            <span className="text-[#222222] text-[16px]">Total Payable</span>
            <span>₹ {parseFloat(product?.Total).toFixed(2)}</span>
          </p>
        </div>
        <hr className="mt-2 text-black" />
        <p className="text-[#3E8E3C] text-center text-[15px] mt-4">
          Your Total Saving on this order ₹{totalSavingAmount}
        </p>
        <div className='flex justify-center mt-3'>
          <button
            className="w-full md:w-[306px] h-[50px] bg-[#FF9F00] text-white rounded-[26.5px] relative overflow-hidden"
            onClick={handleOrder}
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  </div>
  {loading && <Loader loading={loading} />}
</div>

  );
}

export default OrderPlaceManually;
