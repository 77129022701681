// import React from 'react'

// function Shop_Dealer_Purchaseorder() {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default Shop_Dealer_Purchaseorder
import React, { useState } from 'react';
import { IoEyeOutline } from "react-icons/io5";
import wheateImage from "../../asset/sihorewheate.png";

const Purchasejson =[
  {
    "orderID": "PO001",
    "supplier": "Supplier A",
    "products": "Product 1",
    "shopCustomer": "Shop A",
    "orderDate": "2024-07-01",
    "status": "Pending"
  },
  {
    "orderID": "PO002",
    "supplier": "Supplier B",
    "products": "Product 3",
    "shopCustomer": "Customer B",
    "orderDate": "2024-07-02",
    "status": "Shipped"
  },
  {
    "orderID": "PO003",
    "supplier": "Supplier C",
    "products": "Product 4",
    "shopCustomer": "Shop C",
    "orderDate": "2024-07-03",
    "status": "Delivered"
  },
  {
    "orderID": "PO004",
    "supplier": "Supplier D",
    "products": "Product 6",
    "shopCustomer": "Customer D",
    "orderDate": "2024-07-04",
    "status": "Returned"
  },
  {
    "orderID": "PO005",
    "supplier": "Supplier E",
    "products": "Product 7",
    "shopCustomer": "Shop E",
    "orderDate": "2024-07-05",
    "status": "Pending"
  },
  {
    "orderID": "PO006",
    "supplier": "Supplier F",
    "products": "Product 8",
    "shopCustomer": "Customer F",
    "orderDate": "2024-07-06",
    "status": "Shipped"
  },
  {
    "orderID": "PO007",
    "supplier": "Supplier G",
    "products": "Product 10",
    "shopCustomer": "Shop G",
    "orderDate": "2024-07-07",
    "status": "Delivered"
  },
  {
    "orderID": "PO008",
    "supplier": "Supplier H",
    "products": "Product 11",
    "shopCustomer": "Customer H",
    "orderDate": "2024-07-08",
    "status": "Returned"
  },
  {
    "orderID": "PO009",
    "supplier": "Supplier I",
    "products": "Product 13",
    "shopCustomer": "Shop I",
    "orderDate": "2024-07-09",
    "status": "Pending"
  },
  {
    "orderID": "PO010",
    "supplier": "Supplier J",
    "products": "Product 14",
    "shopCustomer": "Customer J",
    "orderDate": "2024-07-10",
    "status": "Shipped"
  },
  {
    "orderID": "PO011",
    "supplier": "Supplier K",
    "products": "Product 15",
    "shopCustomer": "Shop K",
    "orderDate": "2024-07-11",
    "status": "Delivered"
  },
  {
    "orderID": "PO012",
    "supplier": "Supplier L",
    "products": "Product 17",
    "shopCustomer": "Customer L",
    "orderDate": "2024-07-12",
    "status": "Returned"
  },
  {
    "orderID": "PO013",
    "supplier": "Supplier M",
    "products": "Product 18",
    "shopCustomer": "Shop M",
    "orderDate": "2024-07-13",
    "status": "Pending"
  },
  {
    "orderID": "PO014",
    "supplier": "Supplier N",
    "products": "Product 19",
    "shopCustomer": "Customer N",
    "orderDate": "2024-07-14",
    "status": "Shipped"
  },
  {
    "orderID": "PO015",
    "supplier": "Supplier O",
    "products": "Product 21",
    "shopCustomer": "Shop O",
    "orderDate": "2024-07-15",
    "status": "Delivered"
  },
  {
    "orderID": "PO016",
    "supplier": "Supplier P",
    "products": "Product 22",
    "shopCustomer": "Customer P",
    "orderDate": "2024-07-16",
    "status": "Returned"
  },
  {
    "orderID": "PO017",
    "supplier": "Supplier Q",
    "products": "Product 24",
    "shopCustomer": "Shop Q",
    "orderDate": "2024-07-17",
    "status": "Pending"
  },
  {
    "orderID": "PO018",
    "supplier": "Supplier R",
    "products": "Product 25",
    "shopCustomer": "Customer R",
    "orderDate": "2024-07-18",
    "status": "Shipped"
  },
  {
    "orderID": "PO019",
    "supplier": "Supplier S",
    "products": "Product 27",
    "shopCustomer": "Shop S",
    "orderDate": "2024-07-19",
    "status": "Delivered"
  },
  {
    "orderID": "PO020",
    "supplier": "Supplier T",
    "products": "Product 28",
    "shopCustomer": "Customer T",
    "orderDate": "2024-07-20",
    "status": "Returned"
  }
]

function Shop_Dealer_Purchaseorder() {
  const [selectedTab, setSelectedTab] = useState('All Orders');
  const [searchQuery, setSearchQuery] = useState('');

  const filterOrders = (status, query) => {
    let filtered = Purchasejson;
    if (status !== 'All Orders') {
      filtered = filtered.filter(order => order.status === status);
    }
    if (query) {
      filtered = filtered.filter(order => order.orderID.toLowerCase().includes(query.toLowerCase()));
    }
    return filtered;
  };

  const filteredOrders = filterOrders(selectedTab, searchQuery);

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className="flex items-center justify-between pt-5">
          <div>
            <p className="text-[14px]">Factory Summary</p>
            <div className="flex bg-white rounded-lg w-[500px] justify-evenly mt-2">
              <div className="p-5">
                <p className='text-[12px]'>All Orders</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">20</p>
              </div>
              <div className="flex items-center justify-center">
                <div className="h-14 bg-gray-400 text-gray-400 w-0.5"></div>
              </div>
              <div className="p-5">
                <p className='text-[12px]'>Completed</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">8</p>
              </div>
              <div className="flex items-center justify-center">
                <div className="h-14 bg-gray-400 text-gray-400 w-0.5"></div>
              </div>
              <div className="p-5">
                <p className='text-[12px]'>Return</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">4</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between pl-3 pr-3 mt-2 bg-white">
          <div className='flex gap-5 p-2 mt-2 ml-4'>
            {['All Orders', 'Pending', 'Shipped', 'Delivered', 'Returned'].map((tab) => (
              <p
                key={tab}
                className={`cursor-pointer ${selectedTab === tab ? 'font-bold' : ''}`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab} ({filterOrders(tab, searchQuery).length})
              </p>
            ))}
          </div>

          <div className="flex justify-between gap-5 p-2">
            <div className="relative">
              <input
                type="text"
                placeholder="Search Product By Order ID"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-64 px-3 py-2 pl-10 bg-gray-100 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
              />
              <div className="absolute inset-y-0 flex items-center p-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <table className="w-full border border-collapse border-gray-300">
            <thead style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }} className="text-white">
              <tr>
                <th className="p-2"></th>
                <th className="p-2 font-normal">Order ID</th>
                <th className="p-2 font-normal">Shop/ Dealer</th>
                <th className="p-2 font-normal">Products</th>
                <th className="p-2 font-normal">Supplier</th>
                <th className="p-2 font-normal">Order Date</th>
                <th className="p-2 font-normal">Status</th>
                <th className="p-2 font-normal">View</th>
              </tr>
            </thead>
            <tbody className="text-black bg-white">
              {filteredOrders.map((order) => (
                <tr key={order.orderID} className="border-b border-gray-200">
                  <td className="p-2 pl-2 text-center"></td>
                  <td className="p-2 text-center">{order.orderID}</td>
                  <td className="p-2">{order.shopCustomer}</td>
                  <td className="p-2">
                    <div className="flex items-center justify-center gap-5">
                      <img src={wheateImage} alt="Wheat" className="w-10 h-10" />
                      <p className="text-center">{order.products}</p>
                    </div>
                  </td>
                  <td className="p-2 text-center">{order.shopCustomer}</td>
                  <td className="p-2 text-center">{order.orderDate}</td>
                  <td className="p-2 text-center">
                    {order.status === "Shipped" && (
                      <button className='border rounded-lg w-[100px] h-7 bg-[#C77E28] text-white'>{order.status}</button>
                    )}
                    {order.status === "Pending" && (
                      <button className='border rounded-lg w-[100px] h-7 bg-[#FF9900] text-white'>{order.status}</button>
                    )}
                    {order.status === "Delivered" && (
                      <button className='border rounded-lg w-[100px] h-7 bg-[#28C76F] text-white'>{order.status}</button>
                    )}
                    {order.status === "Returned" && (
                      <button className='border rounded-lg w-[100px] h-7 bg-[#FF0000] text-white'>{order.status}</button>
                    )}
                  </td>
                  <td className="p-2 text-center">
                    <button className="px-2 py-1 rounded-md"><IoEyeOutline /></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}


export default Shop_Dealer_Purchaseorder;
