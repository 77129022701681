import React, { useState } from 'react';
import { IoArrowBackSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import wheateImage from "../../asset/sihorewheate.png";
import Product_status from '../Component/Product_status';

function Fill_Order_Details() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState({});

  const products = [
    { id: '#2323', product: 'Sihore Wheat', qty: '500kg', price: '120/kg', },
    { id: '#2324', product: 'Basmati Rice', qty: '300kg', price: '80/kg', },
    { id: '#2325', product: 'Chana Dal', qty: '200kg', price: '60/kg', },
    { id: '#2326', product: 'Toor Dal', qty: '150kg', price: '70/kg', },
    { id: '#2327', product: 'Urad Dal', qty: '100kg', price: '90/kg', }
  ];

  const openModal = (status, productId) => {
    const supplier = selectedSuppliers[productId] || ''; // Get the selected supplier for this product

    const existingIndex = orderStatus.findIndex(entry => entry.productId === productId);

    if (existingIndex !== -1) {
      const updatedOrderStatus = [...orderStatus];
      updatedOrderStatus[existingIndex] = { productId, status, supplier };
      setOrderStatus(updatedOrderStatus);
    } else {
      setOrderStatus(prevOrderStatus => [...prevOrderStatus, { productId, status, supplier }]);
    }

    // setIsModalOpen(true); // Open the modal
  };

  const handleSupplierChange = (event, productId) => {
    const supplier = event.target.value;
    setSelectedSuppliers(prevSuppliers => ({
      ...prevSuppliers,
      [productId]: supplier,
    }));
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
//  const handleOpenModel = () =>{
//    setIsModalOpen(true);
//   }

  return (
    <div style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}>
      <div className="p-5">
        <div className="flex justify-between">
          <div className="flex gap-5">
            <Link
              to={"/ordermanagement"}
              className="p-3 border-2 border-black rounded-full cursor-pointer hover:bg-gray-700 hover:text-white hover:border-white"
            >
              <IoArrowBackSharp height={10} width={10} />
            </Link>
            <p className="mt-2 text-2xl">
              <span>Order Details</span>
            </p>
          </div>
        </div>
        <div className='p-2 mt-5 bg-white rounded-lg'>
          <div className='pt-2 pl-10 '>
            <p className='text-2xl '>Order #2323</p>
            <p className=''><span className='text-gray-500'>Ordered on</span> 03 April at 16:56</p>
          </div>
          <hr className='mt-2'></hr>
          <div className='flex gap-10 pl-10 mt-5'>
            <div className='p-2 border w-[300px] h-[200px] rounded-lg'>
              <p className='font-semibold'>Customer info</p>
              <div>
                <p>Customer Name: Mr. John Doe</p>
                <p>Shop Name: Shri Hari Stores</p>
                <p>Contact No: +91 785965656</p>
                <p>Email ID: grainhaven@gmail.com</p>
                <p>PO: #2323</p>
                <div className='flex justify-between text-center'>
                  <p>Payment Method: Online</p>
                  <div className='p-1 text-orange-500 border rounded-lg cursor-pointer hover:bg-orange-600 hover:text-white' style={{ borderColor: 'rgba(255, 153, 0, 1)', width: '80px', height: '30px', opacity: '1' }}>
                    Partial
                  </div>
                </div>
              </div>
            </div>
            <div className='p-2 border w-[300px] h-[200px] rounded-lg'>
              <p className='font-semibold'>Billing Address</p>
              <div>
                Shop no. 28, Central bay, Near Zonal Area
                Nashik-411002
              </div>
            </div>
            <div className='p-2 border w-[300px] h-[200px] rounded-lg'>
              <p className='font-semibold'>Shipping Address</p>
              <div>
                Shop no. 28, Central bay, Near Zonal Area
                Nashik-411002
              </div>
            </div>
          </div>
          <div className='mt-5'>
            <div className='flex items-center pl-5 pr-5'>
              <span className='mr-2 font-semibold'>Product Details</span>
              <hr className='flex-grow' />
            </div>
          </div>
          <div className='mt-5'>
            <table className='min-w-full border-collapse'>
              <thead style={{ borderBottom: '2px solid black' }}>
                <tr>
                  <th className='p-2'>Product ID</th>
                  <th className='p-2'>Product</th>
                  <th className='p-2'>Qty</th>
                  <th className='p-2'>Base Price</th>
                  <th className='p-2'>Add Supplier</th>
                  <th className='p-2'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => {
                  const orderStatusEntry = orderStatus.find(entry => entry.productId === product.id);
                  const status = orderStatusEntry ? orderStatusEntry.status : product.status;

                  return (
                    <tr key={product.id} className='text-center'>
                      <td className='p-2'>{product.id}</td>
                      <td className={`p-2`}>
                        <div className="flex items-center justify-center gap-5">
                          <img src={wheateImage} alt="Wheat" className="w-10 h-10 " />
                          <p className="text-center">{product.product}</p>
                        </div>
                      </td>
                      
                      <td className='p-2'>{product.qty}</td>
                      <td className='p-2'>{product.price}</td>
                      <td className='p-2'>
                        <div>
                          <div className="relative">
                            <select
                              className="block w-[250px] p-2 pr-8 bg-gray-100 border border-gray-300 rounded-xl appearance-none focus:outline-none focus:border-blue-500"
                              defaultValue="" // Set the default value if needed
                              onChange={(e) => handleSupplierChange(e, product.id)} // Capture supplier change
                              required // Ensures user selects an option
                            >
                              <option value="" disabled hidden>Select Supplier</option>
                              <option value="Supplier A">Supplier A</option>
                              <option value="Supplier B">Supplier B</option>
                              <option value="Supplier C">Supplier C</option>
                            </select>
                            <div className="absolute inset-y-0 flex items-center px-2 pointer-events-none right-16">
                              <svg
                                className="w-5 h-5 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="p-2 text-center">
                        <div className='flex items-center justify-center gap-2'>
                          <Link
                            className='p-1 text-green-500 border rounded-lg cursor-pointer hover:bg-green-700 hover:text-white'
                            style={{ backgroundColor: status === 'accepted' ? 'green' : 'transparent', color: status === 'accepted' ? 'white' : 'green', borderColor: 'green', width: '80px', height: '30px' }}
                            onClick={() => openModal('accepted', product.id)}
                          >
                            Accept
                          </Link>
                          <div
                            className='p-1 text-red-500 border rounded-lg cursor-pointer hover:bg-red-600 hover:text-white'
                            style={{ backgroundColor: status === 'Decline' ? 'red' : 'transparent', color: status === 'Decline' ? 'white' : 'red', borderColor: 'red', width: '80px', height: '30px' }}
                            onClick={() => openModal('Decline', product.id)}
                          >
                            Decline
                          </div>
                          <div
                            className='p-1 text-orange-500 border rounded-lg cursor-pointer hover:bg-orange-600 hover:text-white'
                            style={{ backgroundColor: status === 'pending' ? 'orange' : 'transparent', color: status === 'pending' ? 'white' : 'orange', borderColor: 'rgba(255, 153, 0, 1)', width: '80px', height: '30px' }}
                            onClick={() => openModal('pending', product.id)}
                          >
                            Pending
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end gap-5 mt-10">
            <button
              className="px-4 py-2 text-black transition bg-gray-300 rounded-lg"
            >
              CANCEL
            </button>
            <button
              onClick={() => setIsModalOpen(true)}
              className="px-4 py-2 text-white transition rounded-lg"
              style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
            >
              SAVE & CONTINUE
            </button>
          </div>
        </div>
      </div>
      <Product_status isModalOpen={isModalOpen} onClose={closeModal} orderStatus={orderStatus}  />
    </div>
  );
}

export default Fill_Order_Details;
