// import React from 'react'

// function FactoriesManageinvetory() {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default FactoriesManageinvetory
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { IoStar, IoStarOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pages/Component/pagination";
import { BaseUrl } from "../auth/Url";

function FactoriesManageinvetory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [products, setProducts] = useState([]);
  const [productscounts, setProductscounts] = useState([]);
  const [price, setPrice] = useState(products.price);
  const [allProducts, setAllProducts] = useState([]);
  const [draftedProducts, setDraftedProducts] = useState([]);
const [activeTab, setActiveTab] = useState('AllProduct');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [priceChanges, setPriceChanges] = useState({});
    const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
            
  
  

  
  useEffect(() => {
    const fetchProductsDetails = async () => {
      const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
      const token = localStorage.getItem('token');
      const factoryId = personalInfo?.factoryId;

      if (!factoryId) {
        setError('Factory ID not found');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${BaseUrl}/products/FactoryProductList/${factoryId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }

        const data = await response.json();
        const allProducts = data.productsList.filter(product => product.action === "1");
        const draftedProducts = data.productsList.filter(product => product.action === "0");

        setAllProducts(allProducts);
        setDraftedProducts(draftedProducts);
        setProducts(allProducts);
        setProductscounts(data.productsList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductsDetails();
  }, []);

 

  const handleNavigateAddProduct = () => {
    navigate("/manageinventory/Add_Update_product");
  };

  const handleNavigateEditProduct = (productDetails, modelAction) => {
    const productData = {
      Productcode: productDetails._id,
      ModelAction: modelAction,
    };

    navigate('/manageinventory/Add_Update_product', { state: productData });
  };

   const handleNavigateViewProduct = (productDetails) => {
    const productData = {
      Productcode: productDetails._id,
    
    };

    navigate('/ViewProducts', { state: productData });
  };
 const handleVisibilityToggle = async (productCode, currentVisibility) => {
 

  try {
    const response = await axios.post(
      `${BaseUrl}/products/updateVisibility`,
      {
        productCode,
        isVisible: !currentVisibility
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    // Update the product's visibility locally
    setProducts(products.map(product =>
      product.productCode === productCode ? { ...product, isVisible: !currentVisibility } : product
    ));
  } catch (error) {
    setError(error.response ? error.response.data.message : error.message);
  }
};

  const indexOfLastRecord = currentPage * perPage;
  const indexOfFirstRecord = indexOfLastRecord - perPage;
  const currentRecords = products.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(products.length / perPage);

  const handleStarClick = (id) => {
    setSelectedProductIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((productId) => productId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };
const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'AllProduct') {
      setProducts(allProducts);
    } else {
      setProducts(draftedProducts);
    }
    setCurrentPage(1);
  };

    const handlePriceChange = (productId, newPrice) => {
    setPriceChanges({
      ...priceChanges,
      [productId]: newPrice,
    });
  };

const updatePrice = async (productId, newPrice) => {
  // Ensure newPrice is set to 0 if it's null, undefined, or NaN
  if (newPrice === null || newPrice === undefined || isNaN(newPrice)) {
    newPrice = 0;
  }

  try {
    await axios.post(
      `${BaseUrl}/products/updatePrice`,
      {
        productId,
        price: newPrice
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    // Update the product locally
    setProducts(products.map(product =>
      product._id === productId ? { ...product, price: newPrice } : product
    ));
  } catch (error) {
    console.error('Error updating price:', error);
  }
};

  return (
    <div style={{ backgroundColor: "#F6F6F6",minHeight: "100vh" }}>
      <div  className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] " >
        <div className="">
          <div className="w-full p-3 sm:w-auto">
            <div>
              <p className="text-[14px]">Inventory Summary</p>
            <div className="flex bg-white rounded-lg md:w-[500px] justify-evenly mt-2">
              <div className="p-5" >
                <p>All Products
                 </p>
                  <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold"> {productscounts.length}</p>
              </div>
              <div class="flex items-center justify-center ">
  <div class="h-10 w-px bg-gray-400"></div>
</div>
              <div className="p-5">
                <p>Active Products
                 </p>
                  <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{allProducts.length} </p>
              </div>
            </div>
            </div>
            
          
          </div>
         
          <div className="justify-between pl-3 pr-3 mt-2 bg-white md:flex">
           <div className='flex gap-5 p-2 mt-2 ml-4 '>
              <p
                className={`cursor-pointer ${activeTab === 'AllProduct' ? 'font-bold underline underline-green' : ''}`}
                onClick={() => handleTabClick('AllProduct')}
              >
                All Products({allProducts.length})
              </p>
              <p
                className={`cursor-pointer ${activeTab === 'drafted' ? 'font-bold underline underline-green' : ''}`}
                onClick={() => handleTabClick('drafted')}
              >
               Drafted({draftedProducts.length})
              </p>
            </div>

            <div className="flex justify-between gap-5 p-2 mt-5 md:mt-0">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Product.."
                  className="w-64 px-3 py-2 pl-10 bg-gray-100 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                />
                <div className="absolute inset-y-0 flex items-center p-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
  <table className="min-w-full border border-collapse border-gray-300">
    <thead style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }} className="text-white">
      <tr>
        
        <th className="p-2 font-normal">Product ID</th>
        <th className="p-2 font-normal">Product</th>
        <th className="p-2 font-normal">Supplier</th>
        <th className="p-2 font-normal">MRP/ kg</th>
        <th className="p-2 font-normal">Visibility</th>
        <th className="p-2 font-normal">View</th>
      </tr>
    </thead>
    <tbody className="text-black bg-white">
      {currentRecords.map((record) => (
        <tr key={record._id} className="border-b border-gray-200">
        
          <td className="p-2 text-center">
            <p>{record.productCode}</p>
          </td>
          <td className="p-2">
            <div className="flex items-center gap-5">
              {record.selectedImages.length > 0 ? (
                <img
                  src={record.selectedImages[0]}
                  alt={record.productName}
                  className="w-10 h-10"
                />
              ) : (
                <div className="flex items-center justify-center w-10 h-10 bg-gray-300">
                  <span>No Image</span>
                </div>
              )}
              <p className="text-center">{record.productName}</p>
            </div>
          </td>
          <td className="p-2 text-center">{record.category}</td>
          <td className="p-2 text-center">
            <input
              type="number" // Use type="number" for numeric input
              value={priceChanges[record._id] !== undefined ? priceChanges[record._id] : record.price}
              onChange={(e) => handlePriceChange(record._id, parseFloat(e.target.value))}
              onBlur={() => updatePrice(record._id, parseFloat(priceChanges[record._id] !== undefined ? priceChanges[record._id] : record.price))}
              className="p-2 border rounded-full w-28"
              min="0" // Set min attribute to allow zero and positive values
            />
          </td>
          <td className="p-2 text-center">
            <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
              <input
                type="checkbox"
                name="toggle"
                id={`toggle-${record.productCode}`}
                checked={record.isVisible}
                onChange={() => handleVisibilityToggle(record.productCode, record.isVisible)}
                className="sr-only"
              />
              <label
                htmlFor={`toggle-${record.productCode}`}
                className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${record.isVisible ? "bg-blue-600" : ""}`}
              >
                <span
                  className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${record.isVisible ? "translate-x-6 bg-green-400" : ""}`}
                ></span>
              </label>
            </div>
          </td>
          <td className="p-2 text-center">
            <FaEye className="w-5 h-5 ml-10 cursor-pointer" onClick={() => handleNavigateViewProduct(record)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

        </div>
        {products.length > 5 && (
          <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
        )}
      </div>
    </div>
  );
}

export default FactoriesManageinvetory;
