// manageinventoryapi.jsx
import axios from 'axios';
import { BaseUrl } from '../../auth/Url';

const token = localStorage.getItem('token');
const fetchProducts = async () => {
  const response = await axios.get(`${BaseUrl}/products/GetProducts`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.data;
};

const updateVisibility = async (productCode, currentVisibility) => {
  const response = await axios.post(
    `${BaseUrl}/products/updateVisibility`,
    {
      productCode,
      isVisible: !currentVisibility
    },
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  );
  return response.data;
};

const updatePriceApi = async (productId, newPrice) => {
  await axios.post(
    `${BaseUrl}/products/updatePrice`,
    {
      productId,
      price: newPrice
    },
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  );
};

const fetchFactories = async ( category, subCategory) => {
  const response = await fetch(`${BaseUrl}/GetFactories`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ category, subCategory })
  });

  if (!response.ok) {
    throw new Error('Failed to fetch factories');
  }

  return await response.json();
};


const addProduct = async (productObject) => {
  const response = await axios.post(
    `${BaseUrl}/products/addProducts`,
    productObject,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    }
  );
  return response.data;
};

// Function to update an existing product
const updateProduct = async (productId, productObject) => {
  const response = await axios.put(
    `${BaseUrl}/products/updateProduct/${productId}`,
    productObject,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    }
  );
  return response.data;
};

const fetchProductDetails = async (productId) => {
  const response = await axios.post(
    `${BaseUrl}/products/GetSingleproducts`,
    { productId },
    {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export { fetchProducts, updateVisibility, updatePriceApi,fetchFactories,addProduct,updateProduct,fetchProductDetails };
