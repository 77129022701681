import React, { useState } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { BaseUrl } from '../../auth/Url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function ReturnOrderModel({ isModalOpen, onClose, ModelAction, orderData, selectedActions }) {
  const [notes, setNotes] = useState('');
  const [isToggled, setIsToggled] = useState(false);
  const [rejectedReason, setRejectedReason] = useState(''); // State for rejected reason
  const [productTotalAmount, setProductTotalAmount] = useState(orderData.ProductDetails
.productTotalAmount || 0); // State for product total amount
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  console.log(productTotalAmount, "productTotalAmount")
  const navigate = useNavigate();
  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleReasonChange = (e) => {
    setRejectedReason(e.target.value);
    setErrorMessage(''); // Clear error message when the input changes
  };

  const handleSubmit = () => {
    let payload;

    if (selectedActions.Action === 'ApproveRefund') {
      // Prepare the data for approving the refund
      payload = {
        orderId: orderData.orderId,
        productId: orderData.ProductDetails.ProductID,
          productTotalAmount,
        selectedActions:selectedActions.Action
        // sendConfirmationEmail: isToggled
      };
    } else if (selectedActions.Action === 'RejectRefund') {
    
      if (!rejectedReason) {
        setErrorMessage('Reason for rejection is required.');
        return; 
      }

      payload = {
        orderId: orderData.orderId,
        productId: orderData.ProductDetails.ProductID,
          rejectedReason,
         selectedActions:selectedActions.Action
      };
    }

    // Log the payload for testing (remove this in production)
    console.log('Payload to send:', payload);

    // Call your API here
    fetch(`${BaseUrl}/purchase-return/UpdateStatusReturnProduct`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      Swal.fire({
            title: selectedActions.Action === 'ApproveRefund' ? 'Refund Approved!' : 'Refund Rejected!',
            text: selectedActions.Action === 'ApproveRefund' ? 'The refund has been approved successfully.' : 'The refund has been rejected.',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {
                // Navigate to the Shop page after the alert is confirmed
                navigate('/PurchaseReturn');
            }
        });;

        // Handle successful response
        onClose(); // Close the modal after submitting
    })
    .catch((error) => {
       // Show error SweetAlert
        Swal.fire({
            title: 'Error!',
            text: 'There was a problem updating the status. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
      // Handle error response
    });
  };

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[700px] bg-white rounded-lg h-[400px]">
        <div className="flex items-center justify-between p-6">
          <h2 className="text-[20px] font-semibold">Return Order Confirmation</h2>
          <MdOutlineCancel className="text-xl cursor-pointer" onClick={onClose} />
        </div>

        <div className="p-2 mt-12 mb-4">
          {selectedActions.Action === 'ApproveRefund' && (
  <p className="px-4 text-sm text-center text-gray-600 md:px-8 lg:px-16 md:text-base lg:text-lg">
    You have accepted the Return order 
    <span className="text-blue-600"> {orderData.orderId} </span> from 
    {orderData.customerInfo.ShopName}. Please check Payment status in 
    <span className="font-semibold"> Refund.</span>
  </p>
)}
{selectedActions.Action === 'RejectRefund' && (
  <p className="px-4 text-sm text-center text-gray-600 md:px-8 lg:px-16 md:text-base lg:text-lg">
    Are you sure you want to reject the return request for Order ID: 
    <span className="text-blue-600"> {orderData.orderId} </span>, placed by 
    {orderData.customerInfo.ShopName}?
  </p>
)}

        </div>

        {selectedActions.Action === 'ApproveRefund' && (
  // <div className="mt-10 bg-[#D9D9D9] max-w-md md:max-w-lg lg:max-w-2xl w-full h-[52px] p-5 flex items-center gap-5">
  //   <p className="text-sm md:text-base lg:text-lg">Send Confirmation email to customer?</p>
  //   <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
  //     <input
  //       type="checkbox"
  //       name="toggle"
  //       id="toggle"
  //       checked={isToggled}
  //       onChange={handleToggle}
  //       className="sr-only"
  //     />
  //     <label
  //       htmlFor="toggle"
  //       className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-500 ${isToggled ? 'bg-green-400' : ''}`}
  //     >
  //       <span
  //         className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${isToggled ? 'translate-x-6' : ''}`}
  //       ></span>
  //     </label>
  //   </div>
          // </div>
          <>
            </>
)}

{selectedActions.Action === 'RejectRefund' && (
  <div className='pl-4 mt-2 md:pl-10'>
    <div>
      <p className='text-sm font-semibold md:text-base lg:text-lg'>Specify Reason</p>
    </div>
    <div className='flex gap-2 mt-2'>
      <div className="w-full md:w-96 lg:w-[600px]">
        <input
          type="text"
          value={rejectedReason}
          onChange={handleReasonChange}
          className={`p-2 bg-gray-100 border rounded-xl w-full h-[50px]`}
          placeholder="write"
        />
      </div>
    </div>
    {errorMessage && <p className="text-red-500">{errorMessage}</p>} {/* Display error message */}
  </div>
)}

        <div className="flex justify-end gap-3 p-5 mt-5">
          <button
            className="px-4 py-2 text-gray-600 transition bg-gray-300 rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-white transition bg-[#C6A26F] rounded-lg"
            onClick={handleSubmit}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReturnOrderModel;
