import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Add_Product from "../../asset/AddImage.png";
import wheateImage from "../../asset/sihorewheate.png";
import Loader from './Loader';
import { BaseUrl } from '../../auth/Url';

const Add_Product_Quotation = ({ isModalOpen, onClose, setSelectedProducts, selectedProducts }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');
   
  

  useEffect(() => {
    if (isModalOpen) {
      fetchProducts(); // Fetch products when modal opens
    }
  }, [isModalOpen]);

  const fetchProducts = async () => {
    setLoading(true);
   

    try {
      const response = await axios.get(`${BaseUrl}/products/GetProducts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProducts(response.data.productsList);
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

 const handleSelect = (product) => {
  setSelectedProducts((prevSelectedProducts) => {
    const isSelected = prevSelectedProducts.some((selectedProduct) => selectedProduct.id === product._id);

    if (isSelected) {
      // If already selected, remove it from the list
      return prevSelectedProducts.filter((selectedProduct) => selectedProduct.id !== product._id);
    } else {
      // Otherwise, add it to the list with only the desired properties
      const selectedProduct = {
        id: product._id,
        category: product.category,
        discount: product.discount,
        
        Commission: product.Commission,
        productName: product.productName,
        price: product.price,
        subCategory: product.subCategory,
        productCode: product.productCode,
        selection:[],
        Dis_Amt: "",
        Total:""
      };
      return [...prevSelectedProducts, selectedProduct];
    }
  });
};



  const handleSave = () => {
    console.log('Selected Products:', selectedProducts);
    onClose();
    setSearchTerm('');
  };

  const handleCancel = () => {
    setSelectedProducts([]);
    onClose();
    setSearchTerm('');
  };

  const filteredProducts = searchTerm
  ? products.filter(product =>
      product.productName.toLowerCase().includes(searchTerm.toLowerCase()) && product.isVisible === true
    )
  : products.filter(product => product.isVisible === true);



  return (
//     <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${isModalOpen ? '' : 'hidden'}`}>
//       <div className="w-1/2 p-4 bg-white rounded-md">
//         <h2 className="mb-4 text-lg font-semibold">Add Products to Quotation</h2>
      
//         <div className="relative">
//           <input
//             type="text"
//             value={searchTerm}
//             onChange={handleSearch}
//             placeholder="Search products"
//             className="w-full px-3 py-2 pl-10 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
//           />
//           <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="16"
//               height="16"
//               fill="currentColor"
//               className="bi bi-search"
//               viewBox="0 0 16 16"
//             >
//               <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
//             </svg>
//           </div>
//         </div>
        
//         {filteredProducts.length === 0 && !loading && (
//           <div className="flex items-center justify-center h-48">
//             <img src={Add_Product} alt="No Products Found" className="w-20 h-20" />
//           </div>
//         )}

//         {!loading && filteredProducts.length > 0 && (
//           <div className="h-48 overflow-y-auto">
//             <table className="min-w-full leading-normal">
//               <thead>
//                 <tr>
//                   <th scope="col" className="px-5 py-3 font-semibold tracking-wider text-left text-gray-600 border-b-2 border-gray-200">
//                     Product
//                   </th>
//                   <th scope="col" className="px-5 py-3 font-semibold tracking-wider text-left text-gray-600 border-b-2 border-gray-200">
//                     Base Price
//                   </th>
//                   <th scope="col" className="px-5 py-3 font-semibold tracking-wider text-left text-gray-600 border-b-2 border-gray-200">
//                     Factory/Seller
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredProducts.map(product => (
//                   <tr key={product._id} className="border-b border-gray-200">
//                     <td className="flex gap-3 px-5 py-5 text-sm bg-white">
//                      <input
//   type="checkbox"
//   id={`product-${product._id}`}
//   checked={selectedProducts.some((selectedProduct) => selectedProduct.id === product._id)}
//   onChange={() => handleSelect(product)}
//   className="mr-2"
// />

//                       <img src={product.selectedImages[0]} alt="Wheat" className="w-10 h-10 " />
//                       <label htmlFor={`product-${product._id}`} className="mt-2 cursor-pointer">
//                         {product.productName}
//                       </label>
//                     </td>
//                     <td className="px-5 py-5 text-sm bg-white">
//                       ₹{product.price}/Kg
//                     </td>
//                     <td className="px-5 py-5 text-sm bg-white">
//                       {product.supplierName}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         )}

//         {loading && (
//           <div className="flex items-center justify-center h-48">
//             <img src={Add_Product} alt="Loading..." className="w-20 h-20" />
//           </div>
//         )}

//         {error && (
//           <div className="flex items-center justify-center h-48 text-red-500">
//             Error loading products: {error}
//           </div>
//         )}

//         <div className="flex justify-end mt-4">
//           <button onClick={handleCancel} className="px-4 py-2 mr-2 text-white bg-gray-500 rounded">Cancel</button>
//           <button onClick={handleSave} className="px-4 py-2 text-white bg-gray-800 rounded">Add</button>
//         </div>
//       </div>

//        {loading && <Loader loading={loading}  />}
    //     </div>
    
    <div
  className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${
    isModalOpen ? '' : 'hidden'
  }`}
>
  <div className="w-full max-w-2xl p-4 bg-white rounded-md sm:w-4/5 lg:w-1/2">
    <h2 className="mb-4 text-lg font-semibold text-center sm:text-left">Add Products to Quotation</h2>

    <div className="relative">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search products"
        className="w-full px-3 py-2 pl-10 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      />
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
        </svg>
      </div>
    </div>

    {filteredProducts.length === 0 && !loading && (
      <div className="flex items-center justify-center h-48">
        <img src={Add_Product} alt="No Products Found" className="w-20 h-20" />
      </div>
    )}

    {!loading && filteredProducts.length > 0 && (
      <div className="h-48 overflow-y-auto">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 font-semibold tracking-wider text-left text-gray-600 border-b-2 border-gray-200">
                Product
              </th>
              <th className="px-5 py-3 font-semibold tracking-wider text-left text-gray-600 border-b-2 border-gray-200">
                Base Price
              </th>
              <th className="px-5 py-3 font-semibold tracking-wider text-left text-gray-600 border-b-2 border-gray-200">
                Factory/Seller
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <tr key={product._id} className="border-b border-gray-200">
                <td className="flex gap-3 px-5 py-5 text-sm bg-white">
                  <input
                    type="checkbox"
                    id={`product-${product._id}`}
                    checked={selectedProducts.some(
                      (selectedProduct) => selectedProduct.id === product._id
                    )}
                    onChange={() => handleSelect(product)}
                    className="mr-2"
                  />
                  <img src={product.selectedImages[0]} alt="Wheat" className="w-10 h-10" />
                  <label
                    htmlFor={`product-${product._id}`}
                    className="mt-2 cursor-pointer"
                  >
                    {product.productName}
                  </label>
                </td>
                <td className="px-5 py-5 text-sm bg-white">₹{product.price}/Kg</td>
                <td className="px-5 py-5 text-sm bg-white">{product.supplierName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}

    {loading && (
      <div className="flex items-center justify-center h-48">
        <img src={Add_Product} alt="Loading..." className="w-20 h-20" />
      </div>
    )}

    {error && (
      <div className="flex items-center justify-center h-48 text-red-500">
        Error loading products: {error}
      </div>
    )}

    <div className="flex justify-end mt-4">
      <button
        onClick={handleCancel}
        className="px-4 py-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600"
      >
        Cancel
      </button>
      <button
        onClick={handleSave}
        className="px-4 py-2 text-white bg-gray-800 rounded hover:bg-gray-900"
      >
        Add
      </button>
    </div>
  </div>

  {loading && <Loader loading={loading} />}
</div>

  );
};

export default Add_Product_Quotation;
