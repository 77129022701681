import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import FactoryDashboard from "../FactoryPages/FactoryDashboard";
import Header from "../Pages/Component/Header";
import Dashboard from './Dashboard';
import FactoriesManageinvetory from "../FactoryPages/FactoriesManageinvetory";
import DispatchManagmentFactory from "../FactoryPages/DispatchManagmentFactory";
import Register from "./Register";
import ViewFactory_Details from "../FactoryPages/ViewFactory_Details";
import Profile from "../FactoryPages/Profile";
import FactoryCommission from "../FactoryPages/FactoryCommission";
import FillOrder_Factory from "../FactoryPages/FillOrder_Factory";
import AddCategoryProduct from "../Pages/factory/AddCategoryProduct";



const ProtectedRoute = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);
 const location = useLocation();
  const [moduleName, setModuleName] = useState('Manage Products');
  const [submoduleName, setsubModuleName] = useState('Manage Products');
  useEffect(() => {
    // Update the module name based on the current path
    switch (location.pathname) {
      case '/manageinventory':
        setModuleName('Manage Products');
        setsubModuleName('Manage Products');
        break;
       case '/DispatchRecords':
        setModuleName('Dispatch Management');
        setsubModuleName('Dispatch Management');
        break;
         case '/FactoryCommission':
        setModuleName('Factory Commission');
        setsubModuleName('Factory Commission');
        break;
      case '/DispatchRecords':
        setModuleName('Dispatch Records');
        setsubModuleName('Dispatch Records');
        break;
       case '/FactoryProduct':
        setModuleName('Factory Product');
        setsubModuleName('Factory Product');
        break;
      
      default:
        setModuleName('Dashboard');
        setsubModuleName('Dashboard');
    }
  }, [location.pathname]);
  useEffect(() => {
    setTimeout(() => {
      const token = localStorage.getItem("token");
      const personalInfo = localStorage.getItem("personalInfo");
      if (token && personalInfo) {
        const parsedInfo = JSON.parse(personalInfo);
        setCurrentUser({ ...parsedInfo, token });
      } else {
        setCurrentUser(null);
      }
    }, 50);
  }, []);

  if (currentUser === undefined) {
    return null;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Dashboard token={currentUser.token} />
      {
        currentUser.role ==="Factory" && (
          <Header  moduleName={moduleName} submoduleName={submoduleName} />
)
      }
      
      {currentUser.role === 'Factory' ? (
        <FactoryRoutes />
      ) : (
        children
      )}
    </>
  );
};

const FactoryRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FactoryDashboard />} />
      <Route path="/FactoryProduct" element={<FactoriesManageinvetory />} />
      <Route path="/DispatchRecords" element={<DispatchManagmentFactory />} />
      <Route path="/ViewProducts" element={<ViewFactory_Details />} />
      <Route path="/Profile" element={<Profile />} />
      <Route path="/FactoryCommission" element={<FactoryCommission />} />
       <Route path="/Fill_Order_details" element={<FillOrder_Factory />} />
     <Route path="/Factory_products_categories" element={<AddCategoryProduct />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ProtectedRoute;
