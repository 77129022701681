import React from 'react';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";

const Pagination = ({ currentPage, totalPages,setCurrentPage  }) => {
 const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
      <div className="flex justify-end pr-5 mt-5 mb-5">
          <div className="p-1 text-white  rounded-lg w-[40px] text-center" style={{ backgroundColor: '#C6A26F' }}>
            {currentPage}
          </div>
          <button
            className="px-3 py-1 mx-1 text-black bg-white rounded-lg"
            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
          >
            <MdOutlineArrowBackIosNew className="w-4 h-4"  />
          </button>
          <button
            className="px-3 py-1 mx-1 text-black bg-white rounded-lg"
            onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : currentPage)}
            disabled={currentPage === totalPages}
          >
           <MdOutlineArrowForwardIos className="w-4 h-4" />
          </button>
        </div>
  );
};

export default Pagination;
