import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AakaarLogo from "../asset/AakaarLogo.png";
import commissionManagmentIcon from '../asset/commissionManagment.png';
import dashboardIcon from '../asset/Dashboard.png';
import Dealer from '../asset/Dealer.png';
import DispatchManagment from '../asset/DispatchManagment.png';
import factory from '../asset/factory.png';
import manageInventoryIcon from '../asset/ManageInventory.png';
import orderManagmentIcon from '../asset/OrderManagement.png';
import Quatation from '../asset/Quoatation.png';
import TrasactionRecordsIcons from '../asset/TransactionRecords.png';
import { GlobalContext } from '../Pages/Context/GlobalContextForEcommerce';
export default function Dashboard() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [factoryinventoryOpen, setfactoryinventoryOpen] = useState(false);
  const [DealerShopOpen, setDealerShopOpen] = useState(false);
  const [DispatchOpen, setDispatchOpen] = useState(false);
   const [role, setRole] = useState('');
  const history = useNavigate();
   const { status, changeStatus } = useContext(GlobalContext);
  // const text = localStorage.getItem("@secure.s.text");
const [activeTab, setActiveTab] = useState(null);

  // Function to handle tab click
  const handleMenuClick = (menu) => {
    if (activeTab === menu) {
      // If the clicked tab is already active, close the dropdown
      setActiveTab(null);
    } else {
      // Otherwise, set the clicked tab as active
      setActiveTab(menu);
    }
  };
  // const clearStorage = () => {
  //   localStorage.clear();
  //   history("/");
  // };
   useEffect(() => {
    const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
    if (personalInfo) {
      setRole(personalInfo.role);
    }
  }, []);

  const Adminmenus = [
    'Dashboard', 'Manage Inventory', 'Order Management','Dispatch Management',
    'Commission Management',  'Transaction Records',
    'Factory', 'Dealer/Shop', 'Quotation'
  ];
  const Factorymenus = [
    'Dashboard', 'Factory Product', 'Dispatch Management'
    
  ];

  const getIcon = (menu) => {
    switch (menu) {
      case 'Dashboard': return dashboardIcon;
      case 'Manage Inventory': return manageInventoryIcon;
      case 'Order Management': return orderManagmentIcon;
      case 'Commission Management': return commissionManagmentIcon;
      case 'Dispatch Management': return DispatchManagment;
      case 'Transaction Records': return TrasactionRecordsIcons;
     
      case 'Factory': return factory;
      case 'Dealer/Shop': return Dealer;
      case 'Quotation': return Quatation;
      default: return null;
    }
  };

  const Sidebar = ({ activeMenu, onMenuClick, inventoryOpen,factoryinventoryOpen, DealerShopOpen ,DispatchOpen}) => (
  <div className="hidden h-screen pl-2 pr-2 overflow-y-auto text-white lg:block sidebar" style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>

      <div className="p-5 mb-6 bg-white rounded-bl-lg rounded-br-lg" >
  <img src={AakaarLogo} alt="Wheat" className=" h-30 w-70" />
  {/* <p className="text-lg text-center"><span className='font-bold'>AAKAR</span><br />Canvassing</p> */}
</div>
      {
        status === 'ecommerce' ?
          <>
          <ul className="mt-4">
      {/* Home Page Tab */}
      <li
                className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out 
          ${
          activeTab === "homeBanner" ? "bg-gray-200" : ""
                  }
                  
        `}
                 style={{
        backgroundColor: activeTab !== "homeBanner" ? 'transparent' : 'rgba(198, 162, 111, 1)',
      }}
        onClick={() => handleMenuClick("homeBanner")}
              >
                <Link to='/HomeBanner'> Home Banner</Link>
       
              </li>
               <li
                className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out 
          ${
          activeTab === "Total Count" ? "bg-gray-200" : ""
                  }
                  
        `}
                 style={{
        backgroundColor: activeTab !== "Total Count" ? 'transparent' : 'rgba(198, 162, 111, 1)',
      }}
        onClick={() => handleMenuClick("Total Count")}
              >
                <Link to='Counter'>  Total Count </Link>
     
              </li>
               <li
                className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out 
          ${
          activeTab === "Testimonials" ? "bg-gray-200" : ""
                  }
                  
        `}
                 style={{
        backgroundColor: activeTab !== "Testimonials" ? 'transparent' : 'rgba(198, 162, 111, 1)',
      }}
        onClick={() => handleMenuClick("Testimonials")}
      >
    <Link to='/testimonals'>Testimonials</Link>  
              </li>
              
               <li
                className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out 
          ${
          activeTab === "Terms&Condition" ? "bg-gray-200" : ""
                  }
                  
        `}
                 style={{
        backgroundColor: activeTab !== "Terms&Condition" ? 'transparent' : 'rgba(198, 162, 111, 1)',
      }}
        onClick={() => handleMenuClick("Terms&Condition")}
      >
    <Link to='/TermsAndCondition'>Terms & Condition </Link>  
              </li>
              {/* <li
                className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out 
          ${
          activeTab === "PrivacyPolicy" ? "bg-gray-200" : ""
                  }
                  
        `}
                 style={{
        backgroundColor: activeTab !== "PrivacyPolicy" ? 'transparent' : 'rgba(198, 162, 111, 1)',
      }}
        onClick={() => handleMenuClick("PrivacyPolicy")}
      >
    <Link to='/testimonals'>Privacy Policy </Link>  
      </li> */}

     
     

     
      
      
    
    </ul>
          </> :
          <>
           {
        role === "Admin" && (
           <ul className="pr-5 mt-5 list-none" >
        {Adminmenus.map((menu) => (
          <React.Fragment key={menu}>
            <li
      className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out ${
        activeMenu === menu ? 'bg-gray-700 text-white' : 'text-white'
      }`}
      style={{
        backgroundColor: activeMenu !== menu ? 'transparent' : 'rgba(198, 162, 111, 1)',
      }}
      onClick={() => onMenuClick(menu)}
    >
       <Link
    to={`/${menu === 'Dashboard' ? '' : menu.replace(/ /g, '').toLowerCase()}`}
    className="flex items-center"
  >
    <img
      src={getIcon(menu)}
      alt={`${menu} Icon`}
      className="inline-block w-5 h-5 mr-2 text-white"
      style={{ filter: activeMenu === menu ? 'invert(1)' : 'invert(1)' }}
    />
    <span className={`${activeMenu === menu ? 'text-white' : ''}`}>
      {menu}
    </span>
  </Link>
    </li>
            {menu === 'Manage Inventory' && inventoryOpen && (
              <ul className="pl-6 text-white rounded-lg " style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
                <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/manageinventory" className="flex-1 ml-2" onClick={() => onMenuClick('Manage Inventory', true)}>Products</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li>
                <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/manageinventory/Add_Update_product" className="flex-1 ml-2" onClick={() => onMenuClick('Manage Inventory', true)}>Add New Product</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li>
                {/* <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/manageinventory/categories" className="flex-1 ml-2" onClick={() => onMenuClick('Manage Inventory', true)}>Categories</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li> */}
              </ul>
            )}
             {menu === 'Factory' && factoryinventoryOpen && (
              <ul className="pl-6 text-white rounded-lg " style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
                <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/factory" className="flex-1 ml-2" onClick={() => onMenuClick('Factory', true)}>Factory List</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li>
                <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/factory/Add_Update_factory" className="flex-1 ml-2" onClick={() => onMenuClick('Factory', true)}>Add New Factory</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li>
                {/* <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/factory/PurchaseOrder" className="flex-1 ml-2" onClick={() => onMenuClick('Factory', true)}>Purchase</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li> */}
              </ul>
            )}
             {menu === 'Dealer/Shop' && DealerShopOpen && (
              <ul className="pl-6 text-white rounded-lg " style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
                <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/dealer/shop" className="flex-1 ml-2" onClick={() => onMenuClick('Dealer/Shop', true)}>Shop List</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li>
                <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/Add_Dealer_Shops" className="flex-1 ml-2" onClick={() => onMenuClick('Dealer/Shop', true)}>Add New Shop</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li>
                {/* <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/Dealer_Shops/PurchaseOrderList" className="flex-1 ml-2" onClick={() => onMenuClick('Dealer/Shop', true)}>Purchase</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li> */}
              </ul>
            )}
            {menu === 'Dispatch Management' && DispatchOpen && (
              <ul className="pl-6 text-white rounded-lg " style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
                <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/dispatchmanagement" className="flex-1 ml-2" onClick={() => onMenuClick('Dispatch Management', true)}>Dispatch Order</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li>
                <li className="flex items-center mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8" />
                  </svg> <Link to="/PurchaseReturn" className="flex-1 ml-2" onClick={() => onMenuClick('Dispatch Management', true)}>Purchase Return</Link>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 16 16">
                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </span>
                </li>
                
              </ul>
            )}
          </React.Fragment>
        ))}
      </ul>
        )
      }
          </>
}
     
      
      
   {
  role === "Factory" && (
    <ul className="pr-5 mt-5 list-none">
      <li
        className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out`}
        style={{
          backgroundColor: activeMenu === 'Dashboard' ? 'rgba(198, 162, 111, 1)' : 'transparent',
        }}
      >
        <Link
          to='/FactoryDashboard'
          className={`block w-full h-full `}
          onClick={() => onMenuClick('Dashboard')}
        >
          Dashboard
        </Link>
      </li>
      <li
        className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out`}
        style={{
          backgroundColor: activeMenu === 'Manage Product' ? 'rgba(198, 162, 111, 1)' : 'transparent',
        }}
      >
        <Link
          to='/FactoryProduct'
          className={`block w-full h-full `}
          onClick={() => onMenuClick('Manage Product')}
        >
          Manage Product
        </Link>
            </li>
            <li
        className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out`}
        style={{
          backgroundColor: activeMenu === 'Factory_products_categories' ? 'rgba(198, 162, 111, 1)' : 'transparent',
        }}
      >
        <Link
          to='/Factory_products_categories'
          className={`block w-full h-full `}
          onClick={() => onMenuClick('Factory_products_categories')}
        >
          Add Product category
        </Link>
            </li>
      <li
        className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out`}
        style={{
          backgroundColor: activeMenu === 'Dispatch Management' ? 'rgba(198, 162, 111, 1)' : 'transparent',
        }}
      >
        <Link
          to='/DispatchRecords'
          className={`block w-full h-full `}
          onClick={() => onMenuClick('Dispatch Management')}
        >
          Dispatch Management
        </Link>
            </li>
             <li
        className={`p-2 cursor-pointer mt-5 rounded-r-lg transition-colors duration-200 ease-in-out`}
        style={{
          backgroundColor: activeMenu === 'AakarCanvassing Commission' ? 'rgba(198, 162, 111, 1)' : 'transparent',
        }}
      >
        <Link
          to='/FactoryCommission'
          className={`block w-full h-full `}
          onClick={() => onMenuClick('AakarCanvassing Commission')}
        >
          Aakar Canvassing Commission 
        </Link>
      </li>
    </ul>
  )
}



     
    </div>
  );

  const onMenuClick = (menu, subMenu = false) => {
    setActiveMenu(menu);
    if (menu === 'Manage Inventory' && !subMenu) {
      setInventoryOpen(!inventoryOpen);
    } else if (menu === 'Factory'  && !subMenu) {
      setfactoryinventoryOpen(!factoryinventoryOpen)
       
    }
     else if (menu === 'Dealer/Shop'  && !subMenu) {
      setDealerShopOpen(!DealerShopOpen)
       
    }
     else if (menu === 'Dispatch Management'  && !subMenu) {
      setDispatchOpen(!DispatchOpen)
       
    }else {
      setInventoryOpen(false);
      setfactoryinventoryOpen(false);
      setDealerShopOpen(false);
      setDispatchOpen(false)
    }
  };

  return (
      <>
          
      <div className="flex flex-col ">
       
        <div className="flex flex-1">
          <div className="hidden bg-white shadow-lg md:block">
            <Sidebar activeMenu={activeMenu} onMenuClick={onMenuClick} inventoryOpen={inventoryOpen} factoryinventoryOpen={factoryinventoryOpen}  DealerShopOpen={DealerShopOpen} DispatchOpen={DispatchOpen} />
          </div>
        </div>

        {openSidebar && (
          <div className="fixed inset-0 z-50 flex md:hidden">
            <div className="fixed inset-0 bg-black opacity-50" onClick={() => setOpenSidebar(false)}></div>
            <div className="relative w-64 bg-white">
              <button
                className="absolute top-0 right-0 p-2 mt-4 mr-4 text-gray-500"
                onClick={() => setOpenSidebar(false)}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <Sidebar activeMenu={activeMenu} onMenuClick={onMenuClick} inventoryOpen={inventoryOpen} factoryinventoryOpen={factoryinventoryOpen} DealerShopOpen={DealerShopOpen} DispatchOpen={DispatchOpen}  />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
