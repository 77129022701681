import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose, IoMdHome } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../auth/Url';
import { GlobalContext } from '../Context/GlobalContextForEcommerce';
import NotificationBell from './Notification';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
function Header(props) {
    const { status, changeStatus } = useContext(GlobalContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState([]);
  const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
   const [isOpen, setIsOpen] = useState(false); // State to manage drawer visibility
const [isDropdownOpennew, setIsDropdownOpenNew] = useState(false);
  const [isActive, setIsActive] = useState(true);

  // Function to handle the toggle and make an API call
  const handleToggleCommissionSlab = async () => {
  try {
    const response = await fetch(`${BaseUrl}/api/togglePrice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newState: !isActive }), // Send the new state based on current state
    });

    const data = await response.json();
    if (data.success) {
      // Update the state based on the API response
      setIsActive(data.showPrice);
    } else {
      console.error('Failed to toggle');
    }
  } catch (error) {
    console.error('Error toggling commission slab:', error);
  }
};

useEffect(() => {
  const fetchInitialPriceStatus = async () => {
    try {
      const response = await fetch(`${BaseUrl}/api/GetPriceStatus`);
      const data = await response.json();
      if (data.success) {
        setIsActive(data.showPrice); // Set initial state based on backend response
      } else {
        console.error('Failed to fetch initial price status');
      }
    } catch (error) {
      console.error('Error fetching initial price status:', error);
    }
  };

  fetchInitialPriceStatus();
}, []);
  // Function to toggle the dropdown
  const toggleDropdownNew = () => {
    setIsDropdownOpenNew(prevState => !prevState);  // Toggle the state
  };
    const toggleDrawer = () => {
        setIsOpen(!isOpen); // Toggle drawer state
    };

  console.log(personalInfo,"personalInfo")
   const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    };

   const clearStorage = () => {
    localStorage.clear();
     
     navigate("/login");
      // window.location.reload()
 
  };
  // Call API to fetch notifications when the modal opens
 const fetchContactDetails = () => {
  axios.get(`${BaseUrl}/GetContactsDetails`)
    .then((response) => {
      if (response.data && response.data.length > 0) {
        const contactRecord = response.data[0]; // Assuming you're working with the first contact record
        const { contactInfo, count } = contactRecord;

        // Set all notifications (entire contactInfo array in reverse order)
        setNotifications(contactInfo.reverse());

        // Parse the count value and update notifications count
        const numOfNotifications = parseInt(count) || 0;
        setNotificationsCount(numOfNotifications);

        // Show notifications up to the count (or limit to 2)
        setVisibleNotifications(contactInfo.slice(0, 2)); // Correct access to contactInfo
      }
    })
    .catch((error) => {
      console.error('Error fetching notifications:', error);
    });
  };
  
  useEffect(() => {
  fetchContactDetails();
}, [BaseUrl]); 

// Added BaseUrl to dependency array to avoid stale closure


  const NavigateProfile = () => {
    // Retrieve personal information from localStorage
    const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));

    // Navigate to the profile page and pass the personal info as state
    navigate('/Profile', { state: { personalInfo } });
    setIsDropdownOpen(false)
  };
  const HandleChangeTab = (value) => {
    if (value === "Admin") {
       navigate('/')
        changeStatus('admin')
    } else {
        changeStatus('ecommerce')
  navigate('/HomeBanner')
}
    }

  return (
    <div  className='bg-white' >
      <div className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] ">
      {/* Header with search box and icons */}
      <div className="flex items-center justify-between p-4 ">
        {/* Left side: Search box */}
        <div className='hidden md:block'>
         
            
            <p className='text-[24px] font-semibold flex gap-2 '>{ props.moduleName}</p>
            <p className='flex '><IoMdHome  className='w-5 h-5'/> <span className='text-gray-400'> / { props.submoduleName} </span></p>
        </div>
<div className="relative">
            {/* Hamburger Menu Icon */}
            <div className="md:hidden" onClick={toggleDrawer}>
                <GiHamburgerMenu />
            </div>

            {/* Drawer */}
            {isOpen &&  personalInfo.role === "Admin" &&(
                <div className="fixed top-0 left-0 z-50 w-64 h-full overflow-auto transition-transform transform translate-x-0 bg-white shadow-lg md:hidden">
                <div className="p-4">
  <div className='flex justify-between'>
    <h2 className="text-lg font-bold">Menu</h2>
    <IoMdClose onClick={toggleDrawer} />
  </div>

  <ul className="mt-4">
    <div className='mt-5'>
      <Link className="py-2" to="/" onClick={toggleDrawer}>Dashboard</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/manageinventory" onClick={toggleDrawer}>Manage Inventory</Link>
    </div>  
    <div className='mt-7'>
      <Link className="py-2" to="/ordermanagement" onClick={toggleDrawer}>Order Management</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/dispatchmanagement" onClick={toggleDrawer}>Dispatch Management</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/PurchaseReturn" onClick={toggleDrawer}>Purchase Return Order</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/commissionmanagement" onClick={toggleDrawer}>Commission Management</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/transactionrecords" onClick={toggleDrawer}>Transaction Records</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/factory" onClick={toggleDrawer}>Factory List</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/dealer/shop" onClick={toggleDrawer}>Dealer List</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/quotation" onClick={toggleDrawer}>Quotation</Link>
    </div>

    <div className='mt-7'>
      <div onClick={toggleDropdownNew} className="flex justify-between cursor-pointer">
        <span>Ecommerce Tab </span>
        <span>{isDropdownOpennew ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</span>
      </div>

      {/* Dropdown options */}
      {isDropdownOpennew && (
        <div className="mt-2 ml-4">
          <div className="py-2">
            <Link to="/HomeBanner" onClick={toggleDrawer}>Home Banner</Link>
          </div>
          <div className="py-2">
            <Link to="/Counter" onClick={toggleDrawer}>Total Count</Link>
          </div>
          <div className="py-2">
            <Link to="/testimonals" onClick={toggleDrawer}>Testimonials</Link>
          </div>
          <div className="py-2">
            <Link to="/TermsAndCondition" onClick={toggleDrawer}>Terms & Condition</Link>
          </div>
        </div>
      )}
    </div>
  </ul>
</div>

                </div>
            )}
            {
              isOpen && personalInfo.role !== "Admin" &&(
                <>
                <div className="fixed top-0 left-0 z-50 w-64 h-full overflow-auto transition-transform transform translate-x-0 bg-white shadow-lg md:hidden">
                <div className="p-4">
  <div className='flex justify-between'>
    <h2 className="text-lg font-bold">Menu</h2>
    <IoMdClose onClick={toggleDrawer} />
  </div>

  <ul className="mt-4">
    <div className='mt-5'>
      <Link className="py-2" to="/" onClick={toggleDrawer}>Dashboard</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/FactoryProduct" onClick={toggleDrawer}>Manage Inventory</Link>
    </div>  
   
    <div className='mt-7'>
      <Link className="py-2" to="/DispatchRecords" onClick={toggleDrawer}>Dispatch Management</Link>
    </div>
    <div className='mt-7'>
      <Link className="py-2" to="/FactoryCommission" onClick={toggleDrawer}>Aakar Commission</Link>
    </div>
   
  </ul>
</div>

                </div>
                
                
                </>
              )
            }

            {/* Overlay */}
            {isOpen && (
                <div className="fixed inset-0 z-40 bg-black opacity-50" onClick={toggleDrawer}></div>
            )}
        </div>
        {/* Right side: Icons */}
        <div className="flex items-center">
         
            
            {
              personalInfo.role === "Admin" && (
                <>
                  <div className='flex gap-10'>
                    <div className='flex gap-2'>
                      <div className='mt-1'>
              <p>Price on/off-</p>
            </div>
            <input
        type="checkbox"
        checked={isActive}
        onChange={() => {
          console.log('Checkbox clicked');
          handleToggleCommissionSlab(); // Call the API when the checkbox is clicked
        }}
        className="mr-2 w-[15px] h-[15px] mt-2"
      />
                    </div>
                    <div>
 <NotificationBell isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} notifications={notifications} setNotifications={setNotifications} visibleNotifications={visibleNotifications} setVisibleNotifications={setVisibleNotifications} notificationsCount={notificationsCount} fetchContactDetails={fetchContactDetails} /> 
                    </div>
                  </div>
               
            
                </>
               ) 
}

              {
                personalInfo.role === "Admin" && (
                  <>
                  
                   {
                    status !== "ecommerce" ? <div className='p-2'>
                      
                     
<button
  onClick={() => HandleChangeTab('ecommerce')}
  className="hidden w-full px-4 py-2 text-left text-red-600 border rounded-md md:block hover:bg-red-700 hover:text-white"
>
  Ecommerce Tabs
</button>

            </div> :<div className='p-2'>
 <button
                // onClick={() => changeStatus('ecommerce')}
                onClick={()=>HandleChangeTab('Admin')}
        className="block w-full px-4 py-2 text-left text-red-600 border rounded-md hover:bg-red-700 hover:text-white"
      >
        Admin Tabs
      </button>
            </div>
            }
                  </>
                )
            }
           
            

            {/* User Icon */}
           
            <div className="flex gap-2" onMouseUp={toggleDropdown}>
              
           
              <button className="block w-full px-4 py-2 text-left text-red-600 border rounded-md hover:bg-red-700 hover:text-white" onClick={clearStorage}>
        Logout
      </button>
            </div>
           
          
        </div>
      </div>

    
    
        </div>
    </div>
    
  )
}

export default Header
