import axios from 'axios';
import { BaseUrl } from '../../auth/Url';

const token = localStorage.getItem('token');

// Define the function to fetch all orders
export const fetchAllOrders = async () => {
  try {
    const response = await axios.get(`${BaseUrl}/Order/GetAllOrderDetails`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Assuming the data is returned directly
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error; // Rethrow the error to handle it where the function is called
  }
};
export const fetchAllOrdersByFactory = async (factoryId) => {
  try {
    const response = await axios.get(`${BaseUrl}/Order/GetAllOrderDetails`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Assuming the data is returned directly
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error; // Rethrow the error to handle it where the function is called
  }
};

// Define the function to fetch order data by orderId and productId
export const fetchOrderData = async (orderId, productId) => {
  try {
    const response = await fetch(`${BaseUrl}/Order/GetOrderDetails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ orderId, productId }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch order data: ${response.statusText}`);
    }

    const data = await response.json();
    return data; // Assuming the data is returned as JSON
  } catch (error) {
    console.error('Error fetching order data:', error);
    throw error; // Rethrow the error to handle it where the function is called
  }
};

export const generateUniqueCode = () => {
    // Generate a unique code using timestamp and random number
    return `D${Math.floor(Math.random() * 10000)}`;
};

export const updateOrderStatus = async (orderId, productId,shopEmail,emailToggle) => {
    const uniqueCode = generateUniqueCode(); // Generate a unique code

    try {
        const response = await axios.post(`${BaseUrl}/Order/UpdateStatus`, {
            orderId,
            productId,
          uniqueCode,
            shopEmail,
            emailToggle
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.message;
    } catch (error) {
        console.error('Error updating order status:', error);
        throw error;
    }
};
export const fetchDispatchList = async () => {
  try {
    const response = await axios.get(`${BaseUrl}/Order/GetDispatchList`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching dispatch list:', error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};
export const fetchDispatchListOfFactory = async (factoryId) => {
  try {
    const response = await axios.get(`${BaseUrl}/Order/dispatch-orders/${factoryId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching dispatch list:', error);
    throw error; // Re-throw the error to handle it in the calling component
  }
};


export const updateDispatchDetails = async (data, formData) => {
  try {
    // Set up the headers with the token
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    };

    // Make the API request
    const response = await axios.post(`${BaseUrl}/Order/AddDetailsDispatch`, {
      orderId: data.orderId, // Assuming this is the ID for the dispatch
      productId: data.ProductID,
      estimatedDeliveryDate: formData.estimatedDeliveryDate,
      driverName: formData.driverName,
      contactNumber: formData.contactNumber,
      vehicleNumber: formData.vehicleNumber,
      taxesPaidBy: formData.taxesPaidBy,
      insurance: formData.insurance,
      weight: formData.weight,
      numberOfItems: formData.numberOfItems,
      Category: formData.Category,
    }, config);

    return response.data; // Return response data
  } catch (error) {
    console.error('Error updating dispatch:', error);
    throw error; // Rethrow the error to handle it where the function is called
  }
};


export const updateOrderStatusForOrderTracking = async (orderData, statusUpdate, note) => {
  try {

    // Set up the headers with the token
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    // Send the POST request with the token included in the headers
    const response = await axios.post( `${BaseUrl}/Order/ChangeTheOrderStatus`, {
      orderId: orderData.orderId,
      productId: orderData.ProductID, // Assuming updating the first product for simplicity
      status: statusUpdate,
      note: note,
    }, config); // Include the config with headers

    return response; // Return the response to handle it in the calling function
  } catch (error) {
    console.error('Error updating order status:', error);
    throw error; // Throw error to be handled by the calling function
  }
};

export const updateOrderStatuscancelandpending = async (token, orderData, selectedActions, reason, shopEmail, isToggled) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/Order/UpdateStatusPendingAndCancel`,
      {
        modelAction: selectedActions.Action,
        orderId: orderData.orderId,
        productId: selectedActions.ProductID,
        reason: reason,
         shopEmail,
        sendEmail: isToggled,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`, // Pass the token in the request headers
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Failed to record order action', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
