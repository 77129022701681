import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { updateDispatchDetails } from '../../Service/OrderManagment/OrderManagmentApi';
import Swal from 'sweetalert2';
function Fill_OrderDetailspage() {
  const location = useLocation();
   const navigate = useNavigate();
    const { data } = location.state || {};
console.log(data,"data of props")
    // Initialize state with default values
    const [formData, setFormData] = useState({
        numberOfItems: '1',  
        weight: '',
        dispatchID: data.dispatchShippingDetails.DispatchID,
        estimatedDeliveryDate: '',
        driverName: '',
        contactNumber: '',
        vehicleNumber: '',
        taxesPaidBy: 'Sender',
         insurance: 'Yes',
        Category:data.dispatchShippingDetails.Category
    });
  const [formErrors, setFormErrors] = useState({
    weight: '',
    estimatedDeliveryDate: '',
    driverName: '',
    contactNumber: '',
    vehicleNumber: ''
});

  console.log(formData)
 const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setFormErrors({
        weight: '',
        estimatedDeliveryDate: '',
        driverName: '',
        contactNumber: '',
        vehicleNumber: ''
    });

    // Validate form fields
    let isValid = true;
//   if (formData.contactNumber.length !== 10) {
//         setFormErrors((prev) => ({ ...prev, contactNumber: 'Contact Number must be exactly 10 digits' }));
//         isValid = false;
//     }
    // if (!formData.weight) {
    //     setFormErrors((prev) => ({ ...prev, weight: 'Weight is required' }));
    //     isValid = false;
    // }
    if (!formData.estimatedDeliveryDate) {
        setFormErrors((prev) => ({ ...prev, estimatedDeliveryDate: 'Estimated Delivery Date is required' }));
        isValid = false;
    }
    // if (!formData.driverName) {
    //     setFormErrors((prev) => ({ ...prev, driverName: 'Driver Name is required' }));
    //     isValid = false;
    // }
    // if (!formData.contactNumber) {
    //     setFormErrors((prev) => ({ ...prev, contactNumber: 'Contact Number is required' }));
    //     isValid = false;
    // }
    // if (!formData.vehicleNumber) {
    //     setFormErrors((prev) => ({ ...prev, vehicleNumber: 'Vehicle Number is required' }));
    //     isValid = false;
    // }

    // If the form is not valid, return early
    if (!isValid) {
        return;
    }

   try {
    // Call the updateDispatchDetails function
    const response = await updateDispatchDetails(data, formData);
    if (response.statusCode === 200) {
        Swal.fire({
            icon: 'success',
            title: 'Dispatch Updated',
            text: 'Dispatch details updated successfully!',
            confirmButtonText: 'OK',
        }).then(() => {
            navigate("/dispatchmanagement");
        });
    }
} catch (error) {
    Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating dispatch details. Please try again.',
        confirmButtonText: 'OK',
    });
    console.error('Error updating dispatch:', error);
}
};

    // Handle input changes
const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Clear the error for the specific field being modified
    setFormErrors(prevErrors => ({
        ...prevErrors,
        [name]: '' // Set the error for the current field to an empty string
    }));

    // Check if the name is contactNumber and validate it
    if (name === 'contactNumber') {
        // Remove any non-digit characters and also prevent 'e'
        const cleanedValue = value.replace(/[^0-9]/g, ''); // Remove everything that is not a digit

        // Only update if the cleaned value is up to 10 digits
        if (cleanedValue.length <= 10) {
            setFormData(prevState => ({
                ...prevState,
                [name]: cleanedValue
            }));
        }
    } else {
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
};


 
   
    return (
//         <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }} className='p-5'>
//             <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
//                 <div className="flex justify-between">
//                     {/* Add any additional controls here */}
//                 </div>
//                 <div className='p-2 mt-5 bg-white rounded-lg'>
//                     <div className='pt-2 pl-10'>
//                         <p className='text-[28px] font-semibold'>Dispatch {data?.dispatchShippingDetails.DispatchID}</p>
//                         <p><span className='text-gray-500'>Ordered Id</span> {data?.orderId}</p>
//                         <p><span className='text-gray-500'>Ordered on</span> {data?.OrderDate}</p>
//                     </div>
//                     <hr className='mt-2' />
//                     <div className='flex gap-4 pl-10 mt-5'>
//                         <div className='p-2 border w-[500px] h-[200px] rounded-lg'>
//     <p className='font-semibold'>Product Info</p>
//     <div>
//         <p>Product ID: {data?.ProductID}</p>
//         <p>Product Name: {data?.ProductName}</p>
//                            <p>
//   {data?.discount ? (
//     <>
//       <del>MRP: {data.MRP}/kg</del>
//       <br />
//       Discounted Price: {(data.MRP - (data.MRP * (data.discount / 100))).toFixed(2)}/kg
//     </>
//   ) : (
//     `MRP: ${data?.MRP}/kg`
//   )}
// </p>


//                                 <div className='flex gap-2'>
//  <p>Quantity:</p>
//         <ul>
//     {data?.selection.map((item) => (
//         <>
//             <li key={item._id}>
//             Bag size {item.size} with quantity {item.quantity}
//         </li>
        
//         </>
           
       
//     ))}
// </ul>
                                    
//                                 </div>
       
//     </div>
// </div>

//                         <div className='p-2 border w-[500px] h-[200px] rounded-lg'>
//                             <p className='font-semibold'>Supplier’s info</p>
//                             <div>
//                                 <p>Factory Name: {data?.SupplierInfo?.FactoryName}</p>
//                                 <p>Address: {data?.SupplierInfo?.FactoryAddress}</p>
//                                 <p>Contact: {data?.SupplierInfo?.FactoryContact}</p>
//                                 {/* <p>Email ID: {data?.SupplierInfo?.FactoryEmailID}</p> */}
//                             </div>
//                         </div>
//                     </div>
//                     <div className='mt-5'>
//                         <div className='flex items-center pl-5 pr-5'>
//                             <span className='mr-2 font-semibold'>Package Information</span>
//                             <hr className='flex-grow' />
//                         </div>
//                     </div>
//                     <div className='flex gap-10'>
//                         <div className='p-5'>
//                             <div>
//                                 <p>Number of Items</p>
//                                 <input
//                                     type="text"
//                                     name="numberOfItems1"
//                                     value={formData.numberOfItems}
//                                     onChange={handleChange}
//                                     className="p-2 bg-gray-100 border w-[300px] rounded-lg mt-2"
//                                     placeholder="Sub Category"
//                                 />
//                             </div>
                           
//                         </div>
//                         <div className='mt-5'>
//                             <div>
//                                 <p>Weight(kg)</p>
//                                 <input
//                                     type="text"
//                                     name="weight"
//                                     value={formData.weight}
//                                     onChange={handleChange}
//                                     className="p-2 bg-gray-100 border w-[200px] rounded-lg mt-2"
//                                     placeholder="weight"
//                                 />
                                
//                             </div>
//                             {formErrors.weight && <p className="text-red-500">{formErrors.weight}</p>}
//                         </div>
//                          <div className='mt-5'>
//                                 <p>Item Category</p>
//                                 <input
//                                     type="text"
//                                     name="Category"
//                                     value={formData.Category}
//                                     onChange={handleChange}
//                                     className="p-2 bg-gray-100 border w-[300px] rounded-lg mt-2"
//                                     placeholder="Sub Category"
//                                 />
//                             </div>
//                     </div>
//                     <div className='mt-5'>
//                         <div className='flex items-center pl-5 pr-5'>
//                             <span className='mr-2 font-semibold'>Shipping Details</span>
//                             <hr className='flex-grow' />
//                         </div>
//                         <div className='pl-5 pr-53'>
//                             <div className='flex gap-10'>
//                                 <div>
//                                     <div className='flex gap-2'>
//                                         <div className='mt-5'>
//                                             <p>Dispatch ID</p>
//                                             <input
//                                                 type="text"
//                                                 name="dispatchID"
//                                                 value={formData.dispatchID}
//                                                 onChange={handleChange}
//                                                 className="p-2 bg-gray-100 border w-[300px] rounded-lg mt-2"
//                           placeholder="Sub Category"
//                           disabled
//                                             />
//                                         </div>
//                                         <div className='mt-5'>
//                                             <p>Estimated Delivery Date</p>
//                                             <input
//                                                 type="date"
//                                                 name="estimatedDeliveryDate"
//                                                 value={formData.estimatedDeliveryDate}
//                                                 onChange={handleChange}
//                                                 className="p-2 bg-gray-100 border w-[300px] rounded-lg mt-2"
//                                                 placeholder="Sub Category"
//                                             />
//                                              {formErrors.estimatedDeliveryDate && <p className="text-red-500">{formErrors.estimatedDeliveryDate}</p>}
//                                         </div>
//                                     </div>
//                                     <div className='mt-5'>
//                                         <p>Driver Name</p>
//                                         <input
//                                             type="text"
//                                             name="driverName"
//                                             value={formData.driverName}
//                                             onChange={handleChange}
//                                             className="p-2 bg-gray-100 border w-[610px] rounded-lg mt-2"
//                                             placeholder="Driver Name"
//                                         />
//                                          {formErrors.driverName && <p className="text-red-500">{formErrors.driverName}</p>}
//                                     </div>
//                                     <div className='flex gap-2'>
//                                         <div className='mt-5'>
//                                             <p>Contact Number</p>
//                                            <input
//     type="text" // Keep it as text for controlled input
//     name="contactNumber"
//     value={formData.contactNumber}
//     onChange={handleChange}
//     className="p-2 bg-gray-100 border w-[300px] rounded-lg mt-2"
//                                                 placeholder="Contact Number"
//                                                 max={10}
// />

//                                               {formErrors.contactNumber && <p className="text-red-500">{formErrors.contactNumber}</p>}
//                                         </div>
//                                         <div className='mt-5'>
//                                             <p>Vehicle Number</p>
//                                             <input
//                                                 type="text"
//                                                 name="vehicleNumber"
//                                                 value={formData.vehicleNumber}
//                                                 onChange={handleChange}
//                                                 className="p-2 bg-gray-100 border w-[300px] rounded-lg mt-2"
//                                                 placeholder="Sub Category"
//                                             />
//                                             {formErrors.vehicleNumber && <p className="text-red-500">{formErrors.vehicleNumber}</p>}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 {/* <div className="mt-10 order-details">
//                                     <div className="field">
//                                         <label>Taxes & Duties paid by</label>
//                                         <div className='mt-2'>
//                                             <label>
//                                                 <input
//                                                     type="radio"
//                                                     name="taxesPaidBy"
//                                                     value="Sender"
//                                                     checked={formData.taxesPaidBy === 'Sender'}
//                                                     onChange={handleChange}
//                                                 />
//                                                 Sender
//                                             </label>
//                                             <br />
//                                             <label>
//                                                 <input
//                                                     type="radio"
//                                                     name="taxesPaidBy"
//                                                     value="Receiver"
//                                                     checked={formData.taxesPaidBy === 'Receiver'}
//                                                     onChange={handleChange}
//                                                 />
//                                                 Receiver
//                                             </label>
//                                         </div>
//                                     </div>
//                                     <div className="mt-5 field">
//                                         <label>Insurance</label>
//                                         <div>
//                                             <label>
//                                                 <input
//                                                     type="radio"
//                                                     name="insurance"
//                                                     value="Yes"
//                                                     checked={formData.insurance === 'Yes'}
//                                                     onChange={handleChange}
//                                                 />
//                                                 Yes
//                                             </label>
//                                             <br />
//                                             <label>
//                                                 <input
//                                                     type="radio"
//                                                     name="insurance"
//                                                     value="No"
//                                                     checked={formData.insurance === 'No'}
//                                                     onChange={handleChange}
//                                                 />
//                                                 No
//                                             </label>
//                                         </div>
//                                     </div>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </div>
                    
//                     <div className="flex justify-end gap-5 p-5">
//                         <Link to="/dispatchmanagement"
//                             className="px-4 py-2 text-black transition bg-gray-300 rounded-lg"
//                         >
//                             Cancel
//                         </Link>
//                         <button
//                             className="px-4 py-2 text-white transition rounded-lg"
//                 style={{ backgroundColor: '#4C3F35' }}
//                 onClick={handleSubmit}
//                         >
//                             Dispatch Order
//                         </button>
//                     </div>
//                 </div>
//             </div>
        //         </div>
        
        <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }} className='p-5'>
    <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className="flex justify-between">
            {/* Add any additional controls here */}
        </div>
        <div className='p-2 mt-5 bg-white rounded-lg'>
            <div className='pt-2 pl-10'>
                <p className='text-[28px] font-semibold'>Dispatch {data?.dispatchShippingDetails.DispatchID}</p>
                <p><span className='text-gray-500'>Ordered Id:</span> {data?.orderId}</p>
                <p><span className='text-gray-500'>Ordered on:</span> {data?.OrderDate}</p>
            </div>
            <hr className='mt-2' />
            <div className='flex flex-col gap-4 pl-10 mt-5 lg:flex-row'>
                <div className='p-2 border w-full lg:w-[500px] h-auto rounded-lg'>
                    <p className='font-semibold'>Product Info</p>
                    <div>
                        <p>Product ID: {data?.ProductID}</p>
                        <p>Product Name: {data?.ProductName}</p>
                        <p>
                            {data?.discount ? (
                                <>
                                    <del>MRP: {data.MRP}/kg</del>
                                    <br />
                                    Discounted Price: {(data.MRP - (data.MRP * (data.discount / 100))).toFixed(2)}/kg
                                </>
                            ) : (
                                `MRP: ${data?.MRP}/kg`
                            )}
                        </p>
                        <div className='flex gap-2'>
                            <p>Quantity:</p>
                            <ul>
                                {data?.selection.map((item) => (
                                    <li key={item._id}>
                                        Bag size {item.size} with quantity {item.quantity}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='p-2 border w-full lg:w-[500px] h-auto rounded-lg'>
                    <p className='font-semibold'>Supplier’s info</p>
                    <div>
                        <p>Factory Name: {data?.SupplierInfo?.FactoryName}</p>
                        <p>Address: {data?.SupplierInfo?.FactoryAddress}</p>
                        <p>Contact: {data?.SupplierInfo?.FactoryContact}</p>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <div className='flex items-center pl-5 pr-5'>
                    <span className='mr-2 font-semibold'>Package Information</span>
                    <hr className='flex-grow' />
                </div>
            </div>
            <div className='flex-col md:gap-10 md:flex lg:flex-row'>
                <div className='w-full p-5 lg:w-1/2'>
                    <div>
                        <p>Number of Items</p>
                        <input
                            type="text"
                            name="numberOfItems1"
                            value={formData.numberOfItems}
                            onChange={handleChange}
                            className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
                            placeholder="Sub Category"
                        />
                    </div>
                </div>
                <div className='w-full p-5 lg:w-1/2'>
                    <div>
                        <p>Bag Size and Qty(kg)</p>
                        <input
                            type="text"
                            name="weight"
                            value={formData.weight}
                            onChange={handleChange}
                            className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
                            placeholder="weight"
                        />
                        {/* {formErrors.weight && <p className="text-red-500">{formErrors.weight}</p>} */}
                    </div>
                </div>
                <div className='w-full p-5 lg:w-1/2'>
                    <p>Item Category</p>
                    <input
                        type="text"
                        name="Category"
                        value={formData.Category}
                        onChange={handleChange}
                        className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
                        placeholder="Sub Category"
                    />
                </div>
            </div>
            <div className='mt-5'>
                <div className='flex items-center pl-5 pr-5'>
                    <span className='mr-2 font-semibold'>Shipping Details</span>
                    <hr className='flex-grow' />
                </div>
                <div className='pl-5 pr-5'>
                    <div className='flex flex-col gap-10 lg:flex-row'>
                        <div>
                                    <div className='flex flex-col gap-2'>
                                        <div className='grid md:gap-20 md:flex'>
                                            <div className='mt-5'>
                                    <p>Dispatch ID</p>
                                    <input
                                        type="text"
                                        name="dispatchID"
                                        value={formData.dispatchID}
                                        onChange={handleChange}
                                        className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
                                        placeholder="Sub Category"
                                        disabled
                                    />
                                </div>
                                <div className='mt-5'>
                                    <p>Estimated Delivery Date<span className='text-red-500'>*</span></p>
                                    <input
                                        type="date"
                                        name="estimatedDeliveryDate"
                                        value={formData.estimatedDeliveryDate}
                                        onChange={handleChange}
                                        className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
                                        placeholder="Sub Category"
                                    />
                                    {formErrors.estimatedDeliveryDate && <p className="text-red-500">{formErrors.estimatedDeliveryDate}</p>}
                                </div>
                                        </div>
                                
                                <div className='mt-5'>
                                    <p>Driver Name</p>
                                    <input
                                        type="text"
                                        name="driverName"
                                        value={formData.driverName}
                                        onChange={handleChange}
                                        className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
                                        placeholder="Driver Name"
                                    />
                                   
                                </div>
                                <div className='flex flex-col gap-2 lg:flex-row'>
                                    <div className='w-full mt-5'>
                                        <p>Contact Number</p>
                                        <input
                                            type="text"
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleChange}
                                            className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
                                            placeholder="Contact Number"
                                        />
                                       
                                    </div>
                                    <div className='w-full mt-5'>
                                        <p>Vehicle Number</p>
                                        <input
                                            type="text"
                                            name="vehicleNumber"
                                            value={formData.vehicleNumber}
                                            onChange={handleChange}
                                            className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
                                            placeholder="Sub Category"
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-5 p-5">
                <Link to="/dispatchmanagement"
                    className="px-4 py-2 text-black transition bg-gray-300 rounded-lg"
                >
                    Cancel
                </Link>
                <button
                    className="px-4 py-2 text-white transition rounded-lg"
                    style={{ backgroundColor: '#4C3F35' }}
                    onClick={handleSubmit}
                >
                    Dispatch Order
                </button>
            </div>
        </div>
    </div>
</div>

    );
}

export default Fill_OrderDetailspage;
