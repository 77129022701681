import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BaseUrl } from '../../auth/Url';
import ReturnOrderModel from '../Component/ReturnOrderModel';

function ViewRequestForReturnProduct() {
     const location = useLocation();
  const { orderId, productId,returnMessage,module } = location.state || {};

   const [selectedActions, setSelectedActions] = useState({ ProductID: '', Action: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [validationError, setValidationError] = useState('');
console.log(orderData,"orderData")
    useEffect(() => {
    const token = localStorage.getItem("token"); // Retrieve token from localStorage

    if (orderId) {
      const getData = async () => {
        try {
          const response = await axios.post(
           `${BaseUrl}/Order/GetOrderDetails_ForPurchase` , 
            { orderId, productId},
            {
              headers: {
                Authorization: `Bearer ${token}`, // Attach the token in the request header
              },
            }
          );
          setOrderData(response.data); // assuming the API returns data in response.data
        } catch (error) {
          console.error('Error fetching order data:', error);
        }
      };

      getData();
    }
  }, [orderId]);
  const handleActionChange = (productId, action) => {
      setValidationError('')
    setSelectedActions({ ProductID: productId, Action: action });
  };

 const openModal = () => {
    // Check if an action is selected
    if (selectedActions.Action) {
      setIsModalOpen(true);
    } else {
      setValidationError('Please select either Approve or Reject before proceeding.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getButtonClass = (action) => {
    switch (action) {
      case 'ApproveRefund':
        return 'bg-green-500 text-white';
      case 'RejectRefund':
        return 'bg-red-500 text-white';
      default:
        return 'text-black';
    }
  };
  return (
//     <div style={{ backgroundColor: 'rgba(217, 217, 217, 1)', minHeight: '100vh' }}>
//       <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
//         <div className="p-5">
//           <div className="p-5 mt-5 bg-white rounded-lg">
//             <div className="flex justify-between">
//               <div>
//                 <p className="text-[28px] font-semibold">Order {orderId}</p>
//                 <p>
//                   <span className="text-gray-500">Ordered on</span> {orderData?.ProductDetails?.OrderDate || 'N/A'}
//                 </p>
//               </div>
//             </div>
//             <hr className="mt-5 border-1" />
//             <div className="flex gap-10 pl-10 mt-5">
//               <div className="p-2 border w-[300px] h-[200px] rounded-lg">
//                 <p className="font-semibold">Customer info</p>
//                 <div>
//                   <p>Customer Name: {orderData?.customerInfo?.CustomerName || 'N/A'}</p>
//                   <p>Shop Name: {orderData?.customerInfo?.ShopName || 'N/A'}</p>
//                   <p>Contact No: {orderData?.customerInfo?.ContactNo || 'N/A'}</p>
//                   <p>Email ID: {orderData?.customerInfo?.EmailID || 'N/A'}</p>
//                   <p>PO: #{orderId}</p>
//                   <div className="flex justify-between">
//                     {/* <p>Payment Method: {orderData?.ProductDetails?.PaymentMethod || 'N/A'}</p> */}
//                     {/* {orderData?.ProductDetails?.Duepayment === "0.00" ? (
//                       <div className="p-1 text-center text-white bg-green-600 border border-green-800 rounded-lg cursor-pointer" style={{ width: '80px', height: '30px' }}>
//                         Paid
//                       </div>
//                     ) : (
//                       <div className="p-1 text-center text-white bg-[#FF9900] border border-[#FF9900] rounded-lg cursor-pointer" style={{ width: '80px', height: '30px' }}>
//                         Pending
//                       </div>
//                     )} */}
//                   </div>
//                 </div>
//               </div>
//               <div className="p-2 border w-[300px] h-[200px] rounded-lg">
//                 <p className="font-semibold">Billing Address</p>
//                 <div>{orderData?.customerInfo?.Billing_Address || 'N/A'}</div>
//               </div>
//               <div className="p-2 border w-[300px] h-[200px] rounded-lg">
//                 <p className="font-semibold">Shipping Address</p>
//                 <div>{orderData?.customerInfo?.Shipping_Address || 'N/A'}</div>
//               </div>
//             </div>
//              <div className="pl-5 pr-5 mt-10 ">
//               <p className="mr-2 font-semibold">Return Reason:</p>
//               <p>{returnMessage}</p>
//             </div>
//             <div className="flex items-center pl-5 pr-5 mt-10">
//               <span className="mr-2 font-semibold">Product Details</span>
//               <hr className="flex-grow" />
//             </div>
//             <div className="mt-10">
//               <table className="min-w-full border-collapse">
//                 <thead style={{ borderBottom: '2px solid black' }}>
//                   <tr>
//                     <th className="p-2 font-normal">Product ID</th>
//                     <th className="p-2 font-normal">Supplier/ Factory</th>
//                     <th className="p-2 font-normal">Qty</th>
//                     <th className="p-2 font-normal">MRP</th>
//                      <th className="p-2 font-normal">Product TotalAmount</th>
//                     <th className="p-2 font-normal">Order Total</th>
//                     <th className="p-2 font-normal">Payment</th>
//                     <th className="p-2 font-normal">Due Payment</th>
//                     <th className="p-2 font-normal">Actions</th>
//                   </tr>
//                 </thead>
//            <tbody>
//   {orderData?.ProductDetails ? (
//     <tr className="text-center">
//       <td className="flex items-center gap-1 p-4">
//         <img
//           src={orderData.ProductDetails.selectedImages[0]} // Assuming there's a selectedImages property directly on the object
//           alt={orderData.ProductDetails.productName} // Assuming there's a productName property directly on the object
//           className="w-[60px] h-[40px] object-cover"
//         />
//         <div>
//           <p>{orderData.ProductDetails.ProductID}</p>
//           <p>{orderData.ProductDetails.ProductName}</p>
//         </div>
//       </td>
//       <td className="p-2">{orderData.ProductDetails?.SupplierInfo?.FactoryName || 'N/A'}</td>
//       <td className="p-2">
//         {orderData.ProductDetails.selection?.map((item, idx) => (
//           <div key={idx}>
//             {item.size} - {item.quantity}
//           </div>
//         ))}
//       </td>
//                       <td className="p-2">
//   {orderData.ProductDetails.discount ? (
//     <>
//       MRP: {" "}
//  {(orderData.ProductDetails.MRP - (orderData.ProductDetails.MRP * (orderData.ProductDetails.discount / 100))).toFixed(2)}/kg
//     {" "}  <del> {orderData.ProductDetails.MRP}/kg</del>
//     </>
//   ) : (
//     `MRP:${orderData.ProductDetails.MRP}/kg`
//   )}
// </td>
//                        <td className="p-2">{orderData.ProductDetails.productTotalAmount}</td>
//       <td className="p-2">{orderData?.Total || 'N/A'}</td>
//                       <td className="p-2">{(orderData.Total - orderData.Duepayment).toFixed(2)} </td>
//       <td className="p-2 text-red-500">{orderData?.Duepayment || 'N/A'}</td>
//       {/* Uncomment the buttons below if you want to include action functionality */}
//      <td className="gap-2 p-2">
//   {module === "" && (
//     <>
//       <button
//         className={`border border-[#28C76F] p-2 rounded-lg w-[100px] ${getButtonClass(selectedActions.Action === 'ApproveRefund' ? 'ApproveRefund' : '')}`}
//         onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'ApproveRefund')}
//       >
//         Approve
//       </button>
//       <br />
//       <button
//         className={`border mt-2 border-[#FB3232] p-2 rounded-lg w-[100px] ${getButtonClass(selectedActions.Action === 'RejectRefund' ? 'RejectRefund' : '')}`}
//         onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'RejectRefund')}
//       >
//         Reject
//       </button>
//     </>
//   )}
//   {module === "Refund" && (
//     <button
//       className={`border border-[#28C76F] p-2 rounded-lg w-[100px] bg-[#28C76F] text-white `}
//       onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'ApproveRefund')}
//     >
//       Approve
//     </button>
//   )}
//   {module === "Rejected" && (
//     <button
//       className={`border mt-2 border-[#FB3232] p-2 rounded-lg w-[100px] bg-[#FB3232] text-white ${getButtonClass(selectedActions.Action === 'RejectRefund' ? 'RejectRefund' : '')}`}
//       onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'RejectRefund')}
//     >
//       Reject
//     </button>
//   )}
// </td>
//     </tr>
//   ) : (
//     <tr>
//       <td colSpan="8" className="p-4 text-center">No product details available</td>
//     </tr>
//   )}
// </tbody>


//               </table>
               
//                {validationError && (
//                 <p className="mt-3 text-center text-red-500">{validationError}</p>
//               )}

//             <div className="flex justify-end gap-5 mt-8">
//   <Link className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg" to={"/PurchaseReturn"}>
//     Cancel
//   </Link>
//   <button
//     className="px-4 py-2 mt-4 text-white transition rounded-lg"
//     style={{
//       backgroundColor: module !== "" ? '#BEBEBE' : '#4C3F35',
//       cursor: module !== "" ? 'not-allowed' : 'pointer', // Change cursor based on disabled state
//     }}
//     onClick={openModal}
//     disabled={module !== ""}
//   >
//     Confirm & Proceed
//   </button>
// </div>


//             </div>
//           </div>
//         </div>
//         {/* <Order_Managment_Model
//           isModalOpen={isModalOpen}
//           onClose={closeModal}
//           ModelAction={selectedActions.Action}
//           orderData={orderData}
//           selectedActions={selectedActions}
//         /> */}
//         {isModalOpen && (
//           <ReturnOrderModel
//             isModalOpen={isModalOpen}
//             onClose={closeModal}
//             ModelAction={selectedActions.Action}
//             orderData={orderData}
//             selectedActions={selectedActions}
//           />
//         )}
//       </div>
    //     </div>
    <div className="min-h-screen bg-gray-200">
  <div className="container mx-auto md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
    <div className="p-5">
      <div className="p-5 mt-5 bg-white rounded-lg shadow-md">
        <div className="flex flex-col justify-between md:flex-row">
          <div>
            <p className="text-2xl font-semibold md:text-3xl">Order {orderId}</p>
            <p>
              <span className="text-gray-500">Ordered on</span> {orderData?.ProductDetails?.OrderDate || 'N/A'}
            </p>
          </div>
        </div>
        <hr className="mt-5 border-1" />
        <div className="grid grid-cols-1 gap-5 p-5 mt-5 md:grid-cols-3">
          <div className="p-4 border rounded-lg bg-gray-50">
            <p className="font-semibold">Customer info</p>
            <div>
              <p>Customer Name: {orderData?.customerInfo?.CustomerName || 'N/A'}</p>
              <p>Shop Name: {orderData?.customerInfo?.ShopName || 'N/A'}</p>
              <p>Contact No: {orderData?.customerInfo?.ContactNo || 'N/A'}</p>
              <p>Email ID: {orderData?.customerInfo?.EmailID || 'N/A'}</p>
              <p>PO: #{orderId}</p>
            </div>
          </div>
          <div className="p-4 border rounded-lg bg-gray-50">
            <p className="font-semibold">Billing Address</p>
            <div>{orderData?.customerInfo?.Billing_Address || 'N/A'}</div>
          </div>
          <div className="p-4 border rounded-lg bg-gray-50">
            <p className="font-semibold">Shipping Address</p>
            <div>{orderData?.customerInfo?.Shipping_Address || 'N/A'}</div>
          </div>
        </div>
        <div className="p-5 mt-10">
          <p className="mr-2 font-semibold">Return Reason:</p>
          <p>{returnMessage}</p>
        </div>
        <div className="flex items-center p-5 mt-10">
          <span className="mr-2 font-semibold">Product Details</span>
          <hr className="flex-grow" />
        </div>
        <div className="mt-10 overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-2 font-normal">Product ID</th>
                <th className="p-2 font-normal">Supplier/ Factory</th>
                <th className="p-2 font-normal">Qty</th>
                <th className="p-2 font-normal">MRP</th>
                <th className="p-2 font-normal">Product TotalAmount</th>
                <th className="p-2 font-normal">Order Total</th>
                <th className="p-2 font-normal">Payment</th>
                <th className="p-2 font-normal">Due Payment</th>
                <th className="p-2 font-normal">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orderData?.ProductDetails ? (
                <tr className="text-center">
                  <td className="flex items-center gap-1 p-4">
                    <img
                      src={orderData.ProductDetails.selectedImages[0]}
                      alt={orderData.ProductDetails.productName}
                      className="w-[60px] h-[40px] object-cover hidden md:block"
                    />
                    <div>
                      <p>{orderData.ProductDetails.ProductID}</p>
                      <p>{orderData.ProductDetails.ProductName}</p>
                    </div>
                  </td>
                  <td className="p-2">{orderData.ProductDetails?.SupplierInfo?.FactoryName || 'N/A'}</td>
                  <td className="p-2">
                    {orderData.ProductDetails.selection?.map((item, idx) => (
                      <div key={idx}>
                        {item.size} - {item.quantity}
                      </div>
                    ))}
                  </td>
                  <td className="p-2">
                    {orderData.ProductDetails.discount ? (
                      <>
                        MRP: {(
                          orderData.ProductDetails.MRP -
                          (orderData.ProductDetails.MRP * (orderData.ProductDetails.discount / 100))
                        ).toFixed(2)}/kg <del>{orderData.ProductDetails.MRP}/kg</del>
                      </>
                    ) : (
                      `MRP: ${orderData.ProductDetails.MRP}/kg`
                    )}
                  </td>
                  <td className="p-2">{orderData.ProductDetails.productTotalAmount}</td>
                  <td className="p-2">{orderData?.Total || 'N/A'}</td>
                  <td className="p-2">{(orderData.Total - orderData.Duepayment).toFixed(2)}</td>
                  <td className="p-2 text-red-500">{orderData?.Duepayment || 'N/A'}</td>
                  <td className="gap-2 p-2">
                    {module === "" && (
                      <>
                        <button
                          className={`border border-[#28C76F] p-2 rounded-lg w-[100px] ${getButtonClass(selectedActions.Action === 'ApproveRefund' ? 'ApproveRefund' : '')}`}
                          onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'ApproveRefund')}
                        >
                          Approve
                        </button>
                        <br />
                        <button
                          className={`border mt-2 border-[#FB3232] p-2 rounded-lg w-[100px] ${getButtonClass(selectedActions.Action === 'RejectRefund' ? 'RejectRefund' : '')}`}
                          onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'RejectRefund')}
                        >
                          Reject
                        </button>
                      </>
                    )}
                    {module === "Refund" && (
                      <button
                        className={`border border-[#28C76F] p-2 rounded-lg w-[100px] bg-[#28C76F] text-white`}
                        onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'ApproveRefund')}
                      >
                        Approve
                      </button>
                    )}
                    {module === "Rejected" && (
                      <button
                        className={`border mt-2 border-[#FB3232] p-2 rounded-lg w-[100px] bg-[#FB3232] text-white ${getButtonClass(selectedActions.Action === 'RejectRefund' ? 'RejectRefund' : '')}`}
                        onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'RejectRefund')}
                      >
                        Reject
                      </button>
                    )}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="9" className="p-4 text-center">No product details available</td>
                </tr>
              )}
            </tbody>
          </table>

          {validationError && (
            <p className="mt-3 text-center text-red-500">{validationError}</p>
          )}

          <div className="flex justify-end gap-5 mt-8">
            <Link className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg" to={"/PurchaseReturn"}>
              Cancel
            </Link>
            <button
              className="px-4 py-2 mt-4 text-white transition rounded-lg"
              style={{
                backgroundColor: module !== "" ? '#BEBEBE' : '#4C3F35',
                cursor: module !== "" ? 'not-allowed' : 'pointer',
              }}
              onClick={openModal}
              disabled={module !== ""}
            >
              Confirm & Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
    {isModalOpen && (
      <ReturnOrderModel
        isModalOpen={isModalOpen}
        onClose={closeModal}
        ModelAction={selectedActions.Action}
        orderData={orderData}
        selectedActions={selectedActions}
      />
    )}
  </div>
</div>

  )
}

export default ViewRequestForReturnProduct
