import React, { useEffect, useRef, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import AddImage from "../../asset/AddImage.png";
import NoimgSelecetd from "../../asset/Noimg.png";
import ProgressImg from "../../asset/progressimage.png";
import { BaseUrl } from "../../auth/Url";
import { addProduct, fetchFactories, fetchProductDetails, updateProduct } from '../../Service/ManageInventoryAdmin/ManageInventoryApi';
import Loader from '../Component/Loader';
import Order_Managment_Model from '../Component/Order_Managment_Model';
import UploadTextFile from "../Component/UploadTextFile";


function Add_Product() {
  const navigate = useNavigate();
  const location = useLocation();
  const [stockStatus, setStockStatus] = useState("");
  const [error, setError] = useState(false);
  const [factories, setFactories] = useState([]);
  console.log(factories)
  const [productVisible, setproductVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Field is Required');
  const [productCode, setProductCode] = useState('');
  const [action, setAction] = useState();
  const [loading, setLoading] = useState(false);
  const [ModuleName, setModuleName] = useState('');
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  console.log(selectedFiles)
   const [selectedSupplier, setSelectedSupplier] = useState('');
  const token = localStorage.getItem('token');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [textValue, setTextValue] = useState('');
  const [categories, setCategories] = useState([]);
  
   const [subCategories, setSubCategories] = useState([]);
  const [commissionRates, setCommissionRates] = useState({
    dealer30Kg: 0,
    supplier30Kg: 0,
    dealer50Kg: 0,
    supplier50Kg: 0,
    dealer25Kg: 0,
    supplier25Kg: 0,
    dealer100Kg: 0,
    supplier100Kg: 0
});
  console.log(commissionRates,"commissionRates")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [dateError, setDateError] = useState(false);
   const [dateErrorMsg, setDateErrorMsg] = useState('');
  console.log(category)
 
   const [brands, setBrands] = useState([]);
  
 const [ProductObject, setProductObject] = useState({
  productName: '',
  productDescription: textValue,
  category: '',
  subCategory: '',
  CommissionPercentage: '',
  unit: '',
  productCode: '',
  price: '',
  qualityVariety: '',
  selectedImages: [ // Set default image here
    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAA"
  ], 
  supplierName: '',
  supplierCity: '',
  contactNumber: '',
  discount: '',
  effectiveDate: '',
  expiryDate: '',
  Brand_Name: "",
  productId: "",
   FactoryId: "",
   FactoryGST: "",
  FactoryFSSAI:""
});
 

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetCommission-slab`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
           setCategories(result.CategoryList);
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [token]);
  useEffect(() => {
    setProductObject((prevProductObject) => ({
      ...prevProductObject,
      selectedImages: selectedFiles
    }));
  }, [selectedFiles]);

  
const formatDate = (date) => {
    if (!date) return '';
     const datedata = new Date(date);
    const formattedDate = datedata.toLocaleDateString('en-CA');
    return formattedDate;
};


  useEffect(() => {
    loadProductDetails();
  }, [location.state, token]);
  

  const loadProductDetails = async () => {
      if (location.state) {
        const { Productcode, ModelAction } = location.state;
        setModuleName(ModelAction);

        setLoading(true);
        setError(null);

        try {
          const data = await fetchProductDetails(Productcode);

          if (data && data.result && data.SingleProductList) {
            const productData = data.SingleProductList;

          setProductObject({
    productName: productData.productName || '',
    productDescription: productData.productDescription || '',
    category: productData.category || '',
    subCategory: productData.subCategory || '',
    unit: productData.unit || '',
    CommissionPercentage: productData.CommissionPercentage || '',
    productCode: productData.productCode || '',
    price: productData.price || '',
    qualityVariety: productData.qualityVariety || '',
    selectedImages: productData.selectedImages || [],
    supplierName: productData.supplierName || '',
    supplierCity: productData.supplierCity || '',
    FactoryGST: productData.FactoryGST || '',
   FactoryFSSAI: productData.FactoryFSSAI || '',
    contactNumber: productData.supplierContactNumber || '',
    discount: productData.discount || '',
    effectiveDate: formatDate(productData.effectiveDate),
    expiryDate: formatDate(productData.expiryDate),
    Brand_Name: productData.Brand_Name || '',
    productId: productData._id || ''
});

              const commissionData = productData.Commission || {};
        setCommissionRates({
          dealer30Kg: commissionData.dealer30Kg || 0,
          supplier30Kg: commissionData.supplier30Kg || 0,
          dealer50Kg: commissionData.dealer50Kg || 0,
          supplier50Kg: commissionData.supplier50Kg || 0,
          dealer25Kg: commissionData.dealer25Kg || 0,
          supplier25Kg: commissionData.supplier25Kg || 0,
          dealer100Kg: commissionData.dealer100Kg || 0,
          supplier100Kg: commissionData.supplier100Kg || 0
        });
            setProductCode(productData.productCode);
            setTextValue(productData.productDescription || '');
            setSelectedFiles(productData.selectedImages || []);
          } else {
            setError('Invalid response format');
          }
        } catch (err) {
          setError(err.response?.data?.message || 'Something went wrong');
        } finally {
          setLoading(false);
        }
      }
    };
  const closeModal = () => {
    setIsModalOpen(false)
  }
  const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
  const handleFileChange = async (event) => {
  const files = event.target.files;
  let totalSize = 0;

  Array.from(files).forEach((file) => {
    totalSize += file.size;
  });

  if (totalSize > 34 * 1024 * 1024) {
    alert("Total file size exceeds 34MB. Please select smaller files.");
    return;
  }

  console.log(totalSize, "totalSize");
  const percent = (totalSize / (34 * 1024 * 1024)) * 100;
  setUploadProgress(percent);

  const resizedFiles = await Promise.all(
    Array.from(files).map((file) => resizeImage(file, 800, 800)) // Resize to 800x800 max dimensions
  );

  const base64Files = await Promise.all(resizedFiles.map(blobToBase64));

  if (base64Files.length > 0 && selectedFiles.length < 3) {
    const filesArray = base64Files.slice(0, 3 - selectedFiles.length);
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...filesArray,
    ]);
    setProductObject((prev) => ({
      ...prev,
      selectedImages: [
        ...prev.selectedImages,
        ...filesArray,
      ],
    }));
  } else {
    alert("You can only upload up to 3 images.");
  }
};

const resizeImage = (file, maxWidth = 600, maxHeight = 600, quality = 0.3) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (event) => {
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions while maintaining aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // Compress the image using the given quality (e.g., 0.3 = 30% quality)
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', quality); // Default quality set to 0.3 for better compression
      };
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};


  const generateProductCode = () => {
    const uniqueCode = `#${Math.floor(Math.random() * 100000)}`;
    setProductCode(uniqueCode);
    setProductObject(prevState => ({
      ...prevState,
      productCode: uniqueCode
    }));
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductObject((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChangeForDiscount = (e) => {
    const { name, value } = e.target;

    // Regular expression to check for digits only and value between 0 to 100
    const isValid = /^[0-9]{0,3}$/.test(value) && (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 100));

    if (isValid) {
      setProductObject((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleKeyDown = (e) => {
    // Prevent 'e', '+', '-', '.' characters and allow only digits
    if (['e', 'E', '+', '-', '.'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleNavigate = () => {
    navigate("/manageinventory"); // Navigate to '/product' route when clicked
  };



 
  const handleSubmit = async (actionCode, visibility, id) => {
    debugger;
    
    if (ProductObject.productName === "" || ProductObject.category === "" || ProductObject.supplierName === "" || ProductObject.productCode === "" ||  ProductObject.subCategory === "" ||  ProductObject.price === "" ) {
        setError(true);
        return;
    }
    if (ProductObject.discount !== '' && (ProductObject.effectiveDate === '' || ProductObject.expiryDate === '')) {
        setDateError(true);
        setDateErrorMsg('EffectiveDate and ExpiryDate are required if you add the Discount');
        return;
    }

    setLoading(true);
    try {
        const ProductObjectToSend = {
            productName: ProductObject.productName,
            productDescription: ProductObject.productDescription,
            category: ProductObject.category,
            subCategory: ProductObject.subCategory,
            CommissionPercentage: ProductObject.CommissionPercentage, // Make sure this matches your schema
            Commission: commissionRates, // Commission object with default values or updated values
            unit: "Kg",
            price: ProductObject.price,
            supplierName: ProductObject.supplierName,
          supplierCity: ProductObject.supplierCity,
             FactoryGST: ProductObject.FactoryGST || '',
            FactoryFSSAI: ProductObject.FactoryFSSAI || '',
            supplierContactNumber: ProductObject.contactNumber,
            productCode: productCode,
            discount: ProductObject.discount,
            effectiveDate: ProductObject.effectiveDate,
            expiryDate: ProductObject.expiryDate,
            qualityVariety: ProductObject.qualityVariety,
            FactoryId: ProductObject.FactoryId,
            Brand_Name: ProductObject.Brand_Name,
            isVisible: visibility,
            action: actionCode,
            selectedImages: ProductObject.selectedImages
        };

        let response;
        if (ModuleName === "Update Product") {
            response = await updateProduct(ProductObject.productId, ProductObjectToSend);
        } else {
            response = await addProduct(ProductObjectToSend);
        }

        if (response.statusCode === 200 || response.statusCode === 201) {
            setProductObject({
                productName: '',
                productDescription: '',
                category: '',
                subCategory: '',
                CommissionPercentage: '',
                unit: '',
                price: '',
                discount: '',
                effectiveDate: '',
                expiryDate: '',
                qualityVariety: '',
                supplierName: '',
                supplierCity: "",
              contactNumber: "",
                 FactoryGST: '',
                FactoryFSSAI: '',
                Brand_Name: "",
                selectedImages: [],
            });
            setSelectedFiles([]);
            setTextValue('');
            setProductCode('');
            setTimeout(() => {
                setLoading(false);
                navigate("/manageinventory");
            }, 2000);
        }
    } catch (error) {
        console.error('Error creating/updating product:', error);
        setLoading(false);
    }
};

   const handleRemoveImage = (index) => {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleSupplierChange = (e) => {
  debugger
    const supplierName = e.target.value;
    setSelectedSupplier(supplierName);

    // Update the supplierName in the product object
    setProductObject(prevState => ({
        ...prevState,
        supplierName: supplierName,
    }));

    // Find the selected factory
    const selectedFactory = factories.find(factory => factory.factoryName === supplierName);
    if (selectedFactory) {
        // Filter brands based on selected supplier, category, and subcategory
        const brandsSet = new Set();
        
        selectedFactory.products.forEach(product => {
            if (product.category === ProductObject.category && product.subCategory === ProductObject.subCategory) {
                brandsSet.add(product.brandName);
            }
        });

        const brandsArray = Array.from(brandsSet);

        // Update the brands and Brand_Name
        setBrands(brandsArray);
        setProductObject(prevProductObject => ({
            ...prevProductObject,
            Brand_Name: brandsArray.length > 0 ? brandsArray[0] : '',
            supplierCity: selectedFactory.city,  // Set the supplier city
          contactNumber: selectedFactory.contactNo , // Set the contact number
          FactoryId: selectedFactory.factoryId,
          FactoryGST: selectedFactory.GSTNumber,
            FactoryFSSAI:selectedFactory.FASSAINumber

        }));
    } else {
        // If no factory is found with the selected name, clear the brands and Brand_Name
        setBrands([]);
        setProductObject(prevProductObject => ({
            ...prevProductObject,
            Brand_Name: '',
            supplierCity: '',  // Clear the supplier city
            contactNumber: ''  // Clear the contact number
        }));
    }

    // Reset error state if supplierName is not empty
   
};


const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setProductObject((prevState) => ({
        ...prevState,
        category: selectedCategory,
        subCategory: ""
    }));

    // Filter subcategories based on selected category
    const filteredSubCategories = categories
        .filter((cat) => cat.category === selectedCategory)
        .map((cat) => cat.subcategory);

    // Store commission objects for the selected category
    const categoryCommissions = categories
        .filter((cat) => cat.category === selectedCategory)
        .reduce((acc, cat) => {
            // Merge commissions, prefer the latest ones if multiple entries exist
            return {
                dealer30Kg: cat.commissions.dealer30Kg || acc.dealer30Kg,
                supplier30Kg: cat.commissions.supplier30Kg || acc.supplier30Kg,
                dealer50Kg: cat.commissions.dealer50Kg || acc.dealer50Kg,
                supplier50Kg: cat.commissions.supplier50Kg || acc.supplier50Kg,
                dealer25Kg: cat.commissions.dealer25Kg || acc.dealer25Kg,
              supplier25Kg: cat.commissions.supplier25Kg || acc.supplier25Kg,
                 dealer100Kg: cat.commissions.dealer100Kg || acc.dealer100Kg,
                supplier100Kg: cat.commissions.supplier100Kg || acc.supplier100Kg
            };
        }, {
            dealer30Kg: 0,
            supplier30Kg: 0,
            dealer50Kg: 0,
            supplier50Kg: 0,
            dealer25Kg: 0,
            supplier25Kg: 0,
            dealer100Kg: 0,
            supplier100Kg: 0
        });

    setSubCategories(filteredSubCategories);
    setCommissionRates(categoryCommissions); // Store commission objects
};

  //  const handleSubCategoryChange = async (e) => {
  //   const selectedSubCategory = e.target.value;
  //   const commissionPercentage = categories.find(
  //   (cat) => cat.category === ProductObject.category && cat.subcategory === selectedSubCategory
  // )?.commissionPerKg || "";

  // setProductObject((prevState) => ({
  //   ...prevState,
  //   subCategory: selectedSubCategory,
  //   CommissionPercentage: commissionPercentage
  // }));


  //   try {
  //     const productsData = await fetchFactories(ProductObject.category, selectedSubCategory);

  //     if (productsData.FactoryList.length === 0) {
  //       setIsModalOpen(true);
  //     }

  //     setFactories(productsData.FactoryList);

  //     console.log('Fetched products:', productsData);
  //   } catch (error) {
  //     console.error('Error fetching products by category:', error);
  //     // Handle error state or feedback
  //   }
  // };

const handleSubCategoryChange = async (e) => {
  const selectedSubCategory = e.target.value;

  // Find the category object that matches the selected category and subcategory
  const categoryObject = categories.find(
    (cat) => cat.category === ProductObject.category && cat.subcategory === selectedSubCategory
  );

  // Retrieve the commission data based on the category object
  const commissionData = categoryObject?.commissions || {};
  const commissionPercentage = commissionData[`dealer30Kg`] || ""; // Adjust key as needed based on requirements

  setProductObject((prevState) => ({
    ...prevState,
    subCategory: selectedSubCategory,
    CommissionPercentage: commissionPercentage
  }));

  try {
    // Fetch factories based on the selected category and subcategory
    const productsData = await fetchFactories(ProductObject.category, selectedSubCategory);

    if (productsData.FactoryList.length === 0) {
      setIsModalOpen(true);
    }

    setFactories(productsData.FactoryList);

    console.log('Fetched products:', productsData);
  } catch (error) {
    console.error('Error fetching products by category:', error);
    // Handle error state or feedback
  }
};



    const handleSubmitSupplier = (e) => {
        e.preventDefault();
        if (selectedSupplier === '') {
            setError(true);
            setErrorMessage('Supplier name is required');
            return;
        }
        // Proceed with form submission
        console.log('Selected Supplier:', selectedSupplier);
    };
  return (
    <div  style={{ backgroundColor: "rgba(217, 217, 217, 1)", minHeight: "100vh" }}>
       <div
     className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] "
    >
     

      <div className="grid gap-5 pb-5 md:flex-grow md:pr-10 md:pl-10 md:pt-10 md:flex">
       
          <div className="items-center p-4 bg-white rounded-lg shadow-md md:border-gray-300 md:p-6">
  <div className="p-2">
    <p className="text-lg font-bold">Select Options</p>
  </div>
  
  <div className="p-2 mt-5">
    <p>Category <span className="text-red-500">*</span></p>
    {ModuleName === "Update Product" ? (
      <input
        type="text"
        className="p-2 bg-gray-100 border rounded-xl w-full md:w-[600px] mt-2"
        value={ProductObject.category}
        disabled
        onChange={(e) => setProductObject(prevState => ({ ...prevState, Brand_Name: e.target.value }))}
      />
    ) : (
      <select
        name="category"
        className="p-2 bg-gray-100 border rounded-xl w-full md:w-[600px] mt-2"
        value={ProductObject.category}
        onChange={handleCategoryChange}
      >
        <option value="">Select Category</option>
        {categories.map((cat, index) => (
          <option key={index} value={cat.category}>{cat.category}</option>
        ))}
      </select>
    )}
    {ProductObject.category === "" && error && (
      <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
    )}
  </div>

  <div className="pl-2 mt-2">
    <div className="mt-5">
      <p>Sub Category <span className="text-red-500">*</span></p>
      {ModuleName === "Update Product" ? (
        <input
          type="text"
          className="p-2 bg-gray-100 border rounded-xl w-full md:w-[600px] mt-2"
          value={ProductObject.subCategory}
          disabled
          onChange={(e) => setProductObject(prevState => ({ ...prevState, Brand_Name: e.target.value }))}
        />
      ) : (
        <select
          name="subCategory"
          className="p-2 bg-gray-100 border rounded-xl w-full md:w-[600px] mt-2"
          value={ProductObject.subCategory}
          onChange={handleSubCategoryChange}
          disabled={!ProductObject.category}
        >
          <option value="">Select Sub Category</option>
          {subCategories.map((subCat, index) => (
            <option key={index} value={subCat}>{subCat}</option>
          ))}
        </select>
      )}
      {ProductObject.subCategory === "" && error && (
        <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
      )}
    </div>
  </div>

  <div className="p-2 mt-5">
    <p className="font-bold">Supplier Details</p>
  </div>

  <div className="pl-2 mt-5">
    <p>Supplier Name <span className="text-red-500">*</span></p>
    {ModuleName === "Update Product" ? (
      <input
        type="text"
        className="p-2 bg-gray-100 border rounded-xl w-full md:w-[600px] mt-2"
        value={ProductObject.supplierName}
        disabled
        onChange={(e) => setProductObject(prevState => ({ ...prevState, Brand_Name: e.target.value }))}
      />
    ) : (
      <select
        name="supplierName"
        className="p-2 bg-gray-100 border rounded-xl w-full md:w-[600px] mt-2"
        value={ProductObject.supplierName}
        onChange={handleSupplierChange}
        disabled={factories.length === 0}
      >
        <option value="">Select Supplier</option>
        {factories?.map(factory => (
          <option key={factory._id} value={factory.factoryName}>{factory.factoryName}</option>
        ))}
      </select>
    )}
    {selectedSupplier === "" && error && (
      <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
    )}
  </div>

  <div className="gap-5 pl-2 mt-5 md:flex">
    <div>
      <p>Supplier’s City <span className="text-red-500">*</span></p>
      <input
        type="text"
        name="Supplier’s City"
        className="p-2 bg-gray-100 border rounded-xl w-full md:w-[280px] mt-2"
        placeholder="Supplier’s City"
        value={ProductObject.supplierCity}
        disabled
        onChange={handleInputChange}
      />
      {ProductObject.supplierCity === "" && error && (
        <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
      )}
    </div>

    <div>
      <p>Contact Number <span className="text-red-500">*</span></p>
      <input
        type="number"
        name="contactNumber"
        className="p-2 bg-gray-100 border rounded-xl w-full md:w-[280px] mt-2"
        placeholder="Contact Number"
        value={ProductObject.contactNumber}
        disabled
        onChange={handleInputChange}
      />
      {ProductObject.contactNumber === "" && error && (
        <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
      )}
    </div>
  </div>

  <div className="p-2 mt-5">
    <p className="font-bold">Add Product Details</p>
    <div className="mt-2">
      <label>Brand Name</label>
    </div>
    <div className="mt-2">
      {ModuleName === "Update Product" ? (
        <input
          type="text"
          className="p-2 bg-gray-100 border rounded-xl w-full md:w-[600px]"
          value={ProductObject.Brand_Name}
          disabled
          onChange={(e) => setProductObject(prevState => ({ ...prevState, Brand_Name: e.target.value }))}
        />
      ) : (
        <select
          name="Brand_Name"
          className="p-2 bg-gray-100 border rounded-xl w-full md:w-[600px]"
          value={ProductObject.Brand_Name}
          onChange={(e) => setProductObject(prevState => ({ ...prevState, Brand_Name: e.target.value }))}
          disabled={!ProductObject.supplierName}
        >
          {brands.map((brand, index) => (
            <option key={index} value={brand}>{brand}</option>
          ))}
        </select>
      )}
    </div>
  </div>

  <div className="p-2 w-full md:w-[600px]">
    <div>
      <label>Product Name <span className="text-red-500">*</span></label>
    </div>
    <div className="mt-2">
      <input
        type="text"
        name="productName"
        className="w-full p-2 bg-gray-100 border rounded-xl"
        placeholder="Product"
        value={ProductObject.productName}
        onChange={handleInputChange}
      />
      {ProductObject.productName === "" && error && (
        <p className="mt-2 text-[12px] text-red-500">{errorMessage}</p>
      )}
    </div>

    <UploadTextFile
      setTextValue={setTextValue}
      text={textValue}
      setProductDetails={setProductObject}
    />
    {/* {ProductObject.productDescription === "" && error && (
      <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
    )} */}

    <div className="gap-10 mt-4 md:flex">
      <div>
        <label>MRP/kg <span className="text-red-500">*</span></label>
        <div className="relative mt-2">
          <input
            type="number"
            name="price"
            className="p-2 bg-gray-100 border md:w-[280px] rounded-lg"
            placeholder="₹"
            value={ProductObject.price}
            onChange={handleInputChange}
            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
            onKeyDown={(e) => ['e', 'E', '-', '+'].includes(e.key) && e.preventDefault()}
            required
          />
                  </div>
                  {ProductObject.price === "" && error && (
      <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
    )}
      </div>

      <div>
        {/* <label>Quantity</label> */}
        <div className="relative mt-2">
          {/* <input
            type="number"
            name="quantity"
            className="p-2 bg-gray-100 border md:w-[280px] rounded-lg"
            placeholder="Quantity"
            value={ProductObject.quantity}
            onChange={handleInputChange}
            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
            onKeyDown={(e) => ['e', 'E', '-', '+'].includes(e.key) && e.preventDefault()}
            required
          /> */}

                    <div className="flex gap-20 ">
                 <div>
      <p>Product Code<span className="text-red-500 ">*</span></p>
      <div className="flex items-center mt-2">
        <input
          type="text"
          className="p-2 bg-gray-100 border w-[180px] rounded-lg"
          placeholder="#----"
          value={productCode}
          readOnly
                      />
                      {
                        ModuleName !== "Update Product" && (
                            <button
                               style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }}
          type="button"
          className="p-2 ml-2 text-white bg-gray-800 border rounded`lg"
          onClick={generateProductCode}
        >
          Generate
        </button>
                        )
                      }
       
                    </div>
                      {ProductObject.productCode ==="" && error && (
                <p className='mt-2 text-red-500 text-[12px]'>{errorMessage}</p>
              )}
    </div>
                {/* <div className="flex gap-5">
                  <p className="mt-10 font-bold">Product Barcode</p>{" "}
                  <button
                    type="button"
                    className="p-2 mt-8 text-white bg-gray-800 border"
                  >
                    Generate
                  </button>
                </div> */}
                {/* <ProductBarcode /> */}
              </div>
              </div>

              
          
         
         
       

        <div> 
        </div>
      </div>
    </div>
  </div>
</div>

         
          
       

      <div className="flex flex-col gap-5 md:grid">
  {/* Upload Product Image */}
  <div className="p-5 bg-white border border-gray-300 rounded-lg shadow-md h-[500px] lg:w-[450px] w-full">
    <p className="font-bold text-start">
      Upload Product Image
    </p>
    <div className="flex items-center justify-center w-full mt-5 h-60">
      <div className="relative w-full h-full bg-gray-100">
        <p className="mt-5 text-center">
          Maximum allowed file size is 34 MB
        </p>
        <div className="flex items-center justify-center">
          <img src={AddImage} alt="Wheat" className="w-20 h-20 mt-2" />
        </div>
        <p className="mt-5 text-xs font-bold text-center">
          JPEG (or JPG), PNG, GIF of not more than 15MB
        </p>
        <div className="mt-2 text-center">
          <button
            style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }}
            className="p-2 text-xs text-white rounded-full w-[80px]"
            onClick={handleUploadClick}
          >
            UPLOAD
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
        {/* <div className="flex items-center gap-2 mt-2 bg-white">
         
          <div>
            <img src={ProgressImg} alt="Wheat" className="w-8 h-8 mt-2" />
          </div>
          <div>
            <p className="ml-3 text-xs">Wheat ({uploadProgress.toFixed(2)}%)</p>
            <div className="flex-1 mt-1 ml-2">
              <div className="w-full rounded-full h-2.5 bg-gray-200">
                <div
                  className="bg-green-600 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%`, backgroundColor: 'rgba(198, 162, 111, 1)' }}
                ></div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="flex gap-10 mt-10">
          {selectedFiles.length > 0 ? (
            selectedFiles.map((file, index) => (
              <div key={index} className="relative">
                <img
                  src={file}
                  alt={`Uploaded ${index + 1}`}
                  className="object-cover w-20 h-20"
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="absolute top-0 right-0 p-1 text-sm text-white rounded-full"
                >
                  <MdOutlineCancel className="w-5 h-5 bg-red-500 rounded-full" />
                </button>
              </div>
            ))
          ) : (
            Array(3)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="mt-10">
                  <img
                    src={NoimgSelecetd}
                    alt="No Image Selected"
                    className="object-cover md:w-30 md:h-30"
                  />
                </div>
              ))
          )}
        </div>
        {/* {ProductObject.selectedImages.length < 1 && error && (
          <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
        )} */}
      </div>
    </div>
  </div>

  {/* Discount Module */}
  <div className="mt-5 lg:w-[450px] w-full">
    <div className="h-auto p-5 bg-white rounded-lg">
      <p className="font-semibold">Discount Module</p>
      <div className="mt-5">
        <p>Discount</p>
        <input
          type="text"
          name="discount"
          className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
          placeholder="%"
          value={ProductObject.discount}
          onChange={handleInputChangeForDiscount}
          onKeyDown={handleKeyDown}
          maxLength={3}
        />
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="mt-2">
            <label className="block">Effective Date</label>
            <input
              type="date"
              name="effectiveDate"
              className="p-2 bg-gray-100 border w-full lg:w-[200px] rounded-xl mt-2"
              placeholder="00/00/0000"
              value={ProductObject.effectiveDate}
              onChange={handleInputChange}
            />
          </div>
          <div className="mt-2">
            <label className="block">Expiry Date</label>
            <input
              type="date"
              name="expiryDate"
              className="p-2 bg-gray-100 border w-full lg:w-[200px] rounded-xl mt-2"
              placeholder="00/00/0000"
              value={ProductObject.expiryDate}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {dateErrorMsg !== "" && dateError && (
          <p className="mt-2 text-red-500 text-[14px]">{dateErrorMsg}</p>
        )}
      </div>
    </div>
            </div>
            <div className="w-full h-auto p-5 mt-5 bg-white border border-gray-300 rounded-lg shadow-md">
  <p className="font-bold text-start">Quality/ Variety</p>
  <div>
    <label className="block mt-2">Quality Variety</label>
    <input
      type="text"
      name="qualityVariety"
      className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
      placeholder="Product"
      value={ProductObject.qualityVariety}
      onChange={handleInputChange}
    />
  </div>
  {ProductObject.qualityVariety === "" && error && (
    <p className="mt-2 text-red-500 text-[12px]">{errorMessage}</p>
  )}
</div>
</div>

{/* Quality/Variety Section */}


      </div>
     
     
      
      {
                        ModuleName !== "Update Product" && (
                            <div className="flex justify-end gap-2 p-5 pr-24 ">
            <div>
              <button className="p-2 bg-white border border-black rounded-lg" onClick={() => handleSubmit(0,false)}>
                SAVE DRAFT
              </button>
            </div>
            <div>
              <button  style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }} className="p-2 text-white border rounded-lg bg-slate-900" onClick={() => handleSubmit(1,true)}>
                SAVE PRODUCT
              </button>
            </div>
          </div>
                        )
        }
         {
                        ModuleName === "Update Product" && (
                            <div className="flex justify-end gap-2 p-5 pr-24 ">
            <div>
              <button className="p-2 bg-white border border-black rounded-lg" onClick={() => handleSubmit(0,false)}>
                SAVE DRAFT
              </button>
            </div>
            <div>
              <button  style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }} className="p-2 text-white border rounded-lg bg-slate-900" onClick={() => handleSubmit(1,true)}>
                UPDATE PRODUCT
              </button>
            </div>
          </div>
                        )
                      }
       {/* <div className="flex justify-end gap-2 p-5 pr-24 ">
            <div>
              <button className="p-2 text-xs bg-white border border-black rounded-lg" onClick={() => handleSubmit(0,false)}>
                SAVE DRAFT
              </button>
            </div>
            <div>
              <button  style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }} className="p-2 text-xs text-white border rounded-lg bg-slate-900" onClick={() => handleSubmit(1,true)}>
                SAVE PRODUCT
              </button>
            </div>
          </div> */}
        {loading && <Loader loading={loading} message={"Please Wait Adding Product."} />}
        <Order_Managment_Model isModalOpen={isModalOpen} onClose={closeModal} ModelAction={"NoFactory"
          }  />
    </div>
    </div>
   
  );
}

export default Add_Product;
