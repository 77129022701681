import React, { useState } from 'react';
import { GrFormDown, GrFormNext } from "react-icons/gr";
import { IoArrowBackSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Categories_Edit_model from '../Component/Categories_Edit_model';
import View_categories_model from '../Component/View_categories_model';


const categoriesData = [
  {
    id: 1,
    name: 'Grain Haven',
    subcategories: [
      {
        id: 11,
        name: 'Cereal Grains',
        products: [
          { id: 111, name: 'Wheat', quantity: 100, visibility: false },
          { id: 112, name: 'Rice', quantity: 200, visibility: false },
          { id: 113, name: 'Corn', quantity: 150, visibility: false },
        ],
      },
      {
        id: 12,
        name: 'Pulses',
        products: [
          { id: 121, name: 'Lentils', quantity: 50, visibility: false },
          { id: 122, name: 'Chickpeas', quantity: 75, visibility: false },
        ],
      },
    ],
    visibility: true,
  },
  {
    id: 2,
    name: 'Dairy Delights',
    subcategories: [
      {
        id: 21,
        name: 'Milk Products',
        products: [
          { id: 211, name: 'Milk', quantity: 500, visibility: false },
          { id: 212, name: 'Cheese', quantity: 60, visibility: false },
          { id: 213, name: 'Yogurt', quantity: 80, visibility: false },
        ],
      },
      {
        id: 22,
        name: 'Butter Products',
        products: [
          { id: 221, name: 'Butter', quantity: 40, visibility: false },
          { id: 222, name: 'Ghee', quantity: 30, visibility: false },
        ],
      },
    ],
    visibility: true,
  },
];

function Categorieslist() {
  const [isModalOpen, setIsModalOpen] = useState(false);
   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedSubcategory, setExpandedSubcategory] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/manageinventory");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
   const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleCategoryToggle = (categoryId) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
    setExpandedSubcategory(null); // Reset subcategory expansion when category is toggled
  };

  const handleSubcategoryToggle = (subcategoryId) => {
    setExpandedSubcategory(expandedSubcategory === subcategoryId ? null : subcategoryId);
  };

  return (
    <div style={{ backgroundColor: "rgba(217, 217, 217, 1)", minHeight: "100vh" }}>
       <div   className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] ">
      <div className="p-5">
        <div className="flex items-center justify-between p-3 mt-3">
          <div className='flex gap-5'>
            <div
              className="p-3 border-2 border-black rounded-full cursor-pointer hover:bg-gray-700 hover:text-white hover:border-white"
              onClick={handleNavigate}
            >
              <IoArrowBackSharp height={10} width={10} />
            </div>
            <p className="text-3xl font-semibold">Product Categories</p>
          </div>
          <button className="px-4 py-2 text-white rounded-lg" style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }} onClick={openModal}>
           ADD Category
          </button>
        </div>
      </div>
      <div className='pl-8'>
        <div className="relative ">
          <input
            type="text"
            placeholder="Search..."
            className="px-3 py-2 pl-10 bg-white border border-gray-300 rounded-full w-80 focus:outline-none focus:border-blue-500"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </div>
        </div>
      </div>
      <div className='pt-2 pl-8 font-semibold'>
        <p>7 Categories</p>
      </div>
      <div className="p-4">
        <table className="w-full border border-collapse border-gray-300">
          <thead style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }} className="text-white">
            <tr>
              <th className="p-2">Category</th>
              <th className="p-2">Products</th>
              <th className="p-2">Visibility</th>
              <th className="p-2">Edit</th>
              <th className="p-2">Delete</th>
            </tr>
          </thead>
          <tbody className="text-center text-black bg-white">
            {categoriesData.map(category => (
              <React.Fragment key={category.id}>
                <tr>
                  <td className="p-2 cursor-pointer" onClick={() => handleCategoryToggle(category.id)}>
                    <div className='flex gap-2'>
                    <span>{expandedCategory === category.id ? <GrFormDown className='w-5 h-5 font-bold ' /> : <GrFormNext className='w-5 h-5 font-bold '/>}</span>
                    {category.name}
                    </div>  
                  </td>
                  <td className="p-2">{category.subcategories.length}</td>
                  <td className="p-2">
                    <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
                      <input
                        type="checkbox"
                        name="toggle"
                        id={`toggle-${category.id}`}
                        checked={category.visibility}
                        onChange={() => {}}
                        className="sr-only"
                      />
                      <label
                        htmlFor={`toggle-${category.id}`}
                        className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                          category.visibility ? 'bg-blue-400' : ''
                        }`}
                      >
                        <span
                          className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                            category.visibility ? 'translate-x-6' : ''
                          }`}
                        ></span>
                      </label>
                    </div>
                  </td>
                  <td className="p-2"><MdEdit  onClick={openEditModal} className='cursor-pointer'/></td>
                  <td className="p-2 text-red-600"><RiDeleteBinLine /></td>
                </tr>
                {expandedCategory === category.id && category.subcategories.map(subcategory => (
                  <React.Fragment key={subcategory.id}>
                    <tr>
                      <td className="p-2 pl-8 cursor-pointer" onClick={() => handleSubcategoryToggle(subcategory.id)}>
                         <div className='flex gap-2'>
                    <span>{expandedCategory ===  subcategory.id ?<GrFormDown className='w-5 h-5 font-bold ' /> : <GrFormNext className='w-5 h-5 font-bold '/>}</span>
                     {subcategory.name}
                    </div> 
                      
                      </td>
                      <td className="p-2">{subcategory.products.length}</td>
                      <td className="p-2"><div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
                      <input
                        type="checkbox"
                        name="toggle"
                        id={`toggle-${category.id}`}
                        checked={category.visibility}
                        onChange={() => {}}
                        className="sr-only"
                      />
                      <label
                        htmlFor={`toggle-${category.id}`}
                        className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                          category.visibility ? 'bg-blue-400' : ''
                        }`}
                      >
                        <span
                          className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                            category.visibility ? 'translate-x-6' : ''
                          }`}
                        ></span>
                      </label>
                    </div></td>
                      <td className="p-2"></td>
                      <td className="p-2"></td>
                    </tr>
                    {expandedSubcategory === subcategory.id && subcategory.products.map((product, index) => (
                    
                      <tr key={index}>
                        <td className="p-2 pl-16">{product.name}</td>
                        <td className="p-2">{product.quantity}</td>
                        <td className="p-2"><div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
                      <input
                        type="checkbox"
                        name="toggle"
                        id={`toggle-${product.id}`}
                        checked={product.visibility}
                        onChange={() => {}}
                        className="sr-only"
                      />
                      <label
                        htmlFor={`toggle-${product.id}`}
                        className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                          product.visibility ? 'bg-blue-400' : ''
                        }`}
                      >
                        <span
                          className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                            product.visibility ? 'translate-x-6' : ''
                          }`}
                        ></span>
                      </label>
                    </div></td>
                        <td className="p-2"></td>
                        <td className="p-2"></td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <View_categories_model isModalOpen={isModalOpen} onClose={closeModal} />
      <Categories_Edit_model isEditModalOpen={isEditModalOpen} onCloseEdit={closeEditModal} />
    </div>
    </div>
   
  );
}

export default Categorieslist;
