import React, { useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import Modal from 'react-modal';
import { fetchDispatchList, updateOrderStatusForOrderTracking } from '../../Service/OrderManagment/OrderManagmentApi';
import Swal from 'sweetalert2';
const OrderTrackingModal = ({ isOpen, onRequestClose, orderData,getDispatchList }) => {
  const [statusUpdate, setStatusUpdate] = useState('');
  const [note, setNote] = useState('');
   const [errors, setErrors] = useState('');
console.log(errors,"errors")
  // Define progress stages based on orderData
  const progressStages = [
    {
      title: 'Order Placed',
      description: orderData?.OrderTrackingDetails?.PlaceNote || '',
      completed: orderData?.OrderTrackingDetails?.Place,
    },
    {
      title: 'Order Shipped',
      description: orderData?.OrderTrackingDetails?.ShippedNote || '',
      completed: orderData?.OrderTrackingDetails?.Shipped,
    },
    {
      title: 'Out for Delivery',
      description: orderData?.OrderTrackingDetails?.Out_for_Delivery_Note || '',
      completed: orderData?.OrderTrackingDetails?.Out_for_Delivery,
    },
    {
      title: 'Delivered',
      description: orderData?.OrderTrackingDetails?.DeliveredNote || '',
      completed: orderData?.OrderTrackingDetails?.Delivered,
    }
  ];

const handleStatusUpdate = async () => {
    const newErrors = {};

    // Validate the status update
    if (!statusUpdate.trim()) {
        setErrors('Status update is required.');
        return;
    }

    try {
        // Call the separate API function
        const response = await updateOrderStatusForOrderTracking(orderData, statusUpdate, note);

        if (response.status === 200) {
            // Show success alert with order ID and updated status
            Swal.fire({
                icon: 'success',
                title: 'Order Status Updated',
                text: `Order ${orderData?.orderId} status has been updated to ${statusUpdate}.`,
                confirmButtonText: 'OK',
            }).then(() => {
                getDispatchList(); // Refresh the dispatch list after the update
                onRequestClose();  // Close the modal or dialog
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'Failed to update order status. Please try again.',
                confirmButtonText: 'OK',
            });
        }
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: 'An error occurred while updating the order status. Please try again.',
            confirmButtonText: 'OK',
        });
    }
};
  const onClose=() => {
    onRequestClose()
    setErrors('')
  }
  return (
    <Modal
  isOpen={isOpen}
  onRequestClose={onRequestClose}
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '90%', // Change from fixed width to percentage for responsiveness
      maxWidth: '500px', // Set a max width
      padding: '20px',
      position: 'relative',
      backgroundColor: '#fff',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }}
>
  <div className="flex items-center justify-between">
    <p className="flex-1 text-center mb-4 text-[20px] font-bold mt-5">Order Tracker</p>
    <MdOutlineCancel className="w-[24px] h-[24px]" onClick={onClose} />
  </div>

  <div className="relative mb-4">
    <div className="absolute top-0 h-full border-l-2 border-gray-300 left-4"></div>
    {progressStages.map((stage, index) => (
      <div className="flex items-center mb-3" key={index}>
        <div className="relative">
          <div
            className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${
              stage.completed ? 'bg-green-500 border-green-500 text-white' : 'bg-white border-gray-300'
            }`}
          >
            {index + 1}
          </div>
          {index < progressStages.length - 1 && (
            <div className="absolute w-1 h-full transform -translate-x-1/2 bg-gray-300 left-1/2"></div>
          )}
        </div>
        <div className="ml-4">
          <span>{stage.title}</span>
          <br />
          <span className={`text-gray-500 ${!stage.completed ? 'text-red-500' : ''}`}>
            {stage.description}
          </span>
        </div>
      </div>
    ))}
  </div>

  <hr />
  <div className="mt-2">
    <p className="text-gray-500">Current Status:</p>
    <p>{orderData?.OrderTrackingDetails?.Delivered ? 'Delivered' : 'In-transit, Nashik'}</p>
  </div>

  <div className="mt-2">
    <div>
      <label className="block text-[14px]">Update Status <span className="text-red-500">*</span></label>
      <div className="mt-2">
        <div className="relative">
          <select
            name="statusUpdate"
            className="block w-full p-2 pr-8 bg-gray-100 border border-gray-300 appearance-none rounded-xl focus:outline-none focus:border-blue-500"
            value={statusUpdate}
            onChange={(e) => setStatusUpdate(e.target.value)}
            required
          >
            <option value="" disabled hidden>Select Status</option>
            <option value="Out for Delivery">Out for Delivery</option>
            <option value="Delivered">Delivered</option>
          </select>
          <div className="absolute inset-y-0 flex items-center px-2 pointer-events-none right-14">
            
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="mt-3">
    <div>
      <label className="block text-[14px]">Add Note</label>
      <input
        type="text"
        name="note"
        className="w-full p-2 mt-2 bg-gray-100 border rounded-xl" // Change width to full
        placeholder="Add a note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </div>
  </div>
  {errors && <p className="mt-2 text-center text-red-500">{errors}</p>}
  <div className="flex justify-end gap-5 mt-8">
    <button
      onClick={onClose}
      className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg"
    >
      Cancel
    </button>
    <button
      onClick={handleStatusUpdate}
      className="px-4 py-2 mt-4 text-white transition rounded-lg"
      style={{ backgroundColor: '#4C3F35' }}
    >
      Update Status
    </button>
  </div>
</Modal>

  );
};

export default OrderTrackingModal;
