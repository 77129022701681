import axios from 'axios';
import { BaseUrl } from '../../auth/Url';

const token = localStorage.getItem('token');
export const fetchCommissionSlabs = async () => {
  try {
    const response = await axios.get(`${BaseUrl}/GetCommission-slabForCommissionSlab`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.CategoryList;
  } catch (error) {
    console.error('Error fetching commission slabs', error);
    throw error; // Re-throw the error for handling in the component
  }
};

export const addCommissionSlab = async (newSlab) => {
  try {
    const response = await axios.post(`${BaseUrl}/CreateCommission-slab`, newSlab, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding commission slab', error);
    throw error; // Re-throw the error for handling in the component
  }
};

export const deleteCommissionSlab = async (id) => {
  try {
    await axios.delete(`${BaseUrl}/DeleteCommission-slab/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error('Error deleting commission slab', error);
    throw error; // Re-throw the error for handling in the component
  }
};

export const toggleCommissionSlab = async (id, isActive) => {
  try {
    const response = await axios.patch(`${BaseUrl}/UpdateCommission-slab/${id}`, {
      isActive: !isActive
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating commission slab status', error);
    throw error; // Re-throw the error for handling in the component
  }
};