import React, { useEffect, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import AddInstallMent from '../Component/AddInstallMent';
import { BaseUrl } from '../../auth/Url';
import Swal from 'sweetalert2'; // Import SweetAlert

function PaymentRequest() {
  const [records, setRecords] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [selectedRecord, setSelectedRecord] = useState(null); // State to hold selected record data

  // Function to fetch payment data from the API
  const fetchPaymentData = async () => {
    try {
      const response = await fetch(`${BaseUrl}/payments/list`);
      const data = await response.json();
      if (data.success) {
        setRecords(data.payments.reverse());
      }
    } catch (error) {
      console.error('Error fetching payment data:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchPaymentData();
  }, []);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleApprove = (record) => {
    setSelectedRecord(record); // Set the selected record
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedRecord(null); // Clear the selected record
  };
  const handleDelete = async (orderId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`${BaseUrl}/payments/PaymentSuccess/${orderId}`, {
          method: 'DELETE',
        });
        const data = await response.json();
        if (data.success) {
          // Update records state to remove the deleted record
          setRecords(records.filter(record => record.orderId !== orderId));
          Swal.fire('Deleted!', data.message, 'success'); // Show success message
        } else {
          Swal.fire('Error!', data.message, 'error'); // Show error message
        }
      } catch (error) {
        console.error('Error deleting payment record:', error);
        Swal.fire('Error!', 'An error occurred while deleting the record.', 'error');
      }
    }
  };
  return (
    // <div>
    //   {records.length === 0 ? (
    //     <div className="py-4 text-lg text-gray-500">
    //       No records found!
    //     </div>
    //   ) : (
    //     <table className='w-full rounded-lg'>
    //       <thead className='font-normal text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
    //         <tr>
    //           <th className='p-2 font-normal rounded-tl-lg'>Order Id</th>
    //           <th className='p-2 font-normal'>Date</th>
    //           <th className='p-2 font-normal'>Payment Method</th>
    //           <th className='p-2 font-normal'>Total Amt</th>
    //           <th className='p-2 font-normal'>Payment Done Amt</th>
    //           <th className='p-2 font-normal'>Due Payment After Approve</th>
    //           <th className='p-2 font-normal rounded-tr-lg'>Action</th>
    //         </tr>
    //       </thead>
    //       <tbody className='bg-white'>
    //         {records.map((record, index) => (
    //           <React.Fragment key={index}>
    //             <tr className='border-b-2 cursor-pointer'>
    //               <td className="px-4 py-2" title={record.orderId}>
    //                 {record.orderId.length > 10 ? `${record.orderId.slice(0, 10)}...` : record.orderId}
    //               </td>
    //               <td className='p-2 text-center'>{new Date(record.transactionDate).toLocaleDateString()}</td>
    //               <td className='p-2 text-center'>{record.paymentMethod}</td>
    //               <td className='p-2 text-center'>₹ {record.Total}</td>
    //               <td className='p-2 text-center'>₹ {parseFloat(record.paymentDoneAmount).toFixed(2)}</td>
    //               <td className={`p-2 text-center flex gap-2 justify-center mt-2 ${parseFloat(record.duePayment - record.paymentDoneAmount).toFixed(2) !== "0.00" ? "text-yellow-500" : "text-green-500"}`}>
    //                 ₹ {parseFloat(record.duePayment - record.paymentDoneAmount).toFixed(2)}
    //                 <div>
    //                   {parseFloat(record.duePayment - record.paymentDoneAmount).toFixed(2) !== "0.00" ? "Pending" : "Paid"}
    //                 </div>
    //               </td>
    //               <td className='gap-2 p-2'>
    //                 <div className='flex justify-center gap-2'>
    //                   <button className='p-2 text-white bg-green-500 border rounded-lg' onClick={() => handleApprove(record)}>Approve</button>
    //                     <button className='p-2 text-white bg-red-500 border rounded-lg' onClick={() => handleDelete(record.orderId)}>Delete</button>
    //                 </div>
    //               </td>
    //             </tr>
    //           </React.Fragment>
    //         ))}
    //       </tbody>
    //     </table>
    //   )}
    //   <AddInstallMent isModalOpen={isModalOpen} onClose={handleCloseModal} data={selectedRecord} action={"paymentRequest"} />
    // </div>
    <div className="overflow-x-auto">
  {records.length === 0 ? (
    <div className="py-4 text-lg text-gray-500">
      No records found!
    </div>
  ) : (
    <table className='min-w-full rounded-lg'>
      <thead className='font-normal text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
        <tr>
          <th className='p-2 font-normal text-left rounded-tl-lg'>Order Id</th>
          <th className='p-2 font-normal text-center'>Date</th>
          <th className='p-2 font-normal text-center'>Payment Method</th>
          <th className='p-2 font-normal text-center'>Total Amt</th>
          <th className='p-2 font-normal text-center'>Payment Done Amt</th>
          <th className='p-2 font-normal text-center'>Due Payment After Approve</th>
          <th className='p-2 font-normal text-center rounded-tr-lg'>Action</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {records.map((record, index) => (
          <React.Fragment key={index}>
            <tr className='border-b-2 cursor-pointer'>
              <td className="px-4 py-2" title={record.orderId}>
                {record.orderId.length > 10 ? `${record.orderId.slice(0, 10)}...` : record.orderId}
              </td>
              <td className='p-2 text-center'>{new Date(record.transactionDate).toLocaleDateString()}</td>
              <td className='p-2 text-center'>{record.paymentMethod}</td>
              <td className='p-2 text-center'>₹ {record.Total}</td>
              <td className='p-2 text-center'>₹ {parseFloat(record.paymentDoneAmount).toFixed(2)}</td>
              <td className={`p-2 text-center flex gap-2 justify-center mt-2 ${parseFloat(record.duePayment - record.paymentDoneAmount).toFixed(2) !== "0.00" ? "text-yellow-500" : "text-green-500"}`}>
                ₹ {parseFloat(record.duePayment - record.paymentDoneAmount).toFixed(2)}
                <div>
                  {parseFloat(record.duePayment - record.paymentDoneAmount).toFixed(2) !== "0.00" ? "Pending" : "Paid"}
                </div>
              </td>
              <td className='gap-2 p-2'>
                <div className='flex justify-center gap-2'>
                  <button className='p-2 text-white bg-green-500 border rounded-lg' onClick={() => handleApprove(record)}>Approve</button>
                  <button className='p-2 text-white bg-red-500 border rounded-lg' onClick={() => handleDelete(record.orderId)}>Delete</button>
                </div>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )}
  <AddInstallMent isModalOpen={isModalOpen} onClose={handleCloseModal} data={selectedRecord} action={"paymentRequest"} />
</div>

  );
}

export default PaymentRequest;
