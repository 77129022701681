import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaChevronDown } from "react-icons/fa";
import { ImSortAmountAsc } from "react-icons/im";
import { useNavigate } from 'react-router-dom';
import OrderTrackingModal from '../Component/OrderTrackingModal';
import Pagination from '../Component/pagination';
import { BaseUrl } from '../../auth/Url';

function PurchaseReturn() {
  const [orders, setOrders] = useState([]);
  console.log(orders,"orders")
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(6);
  const [activeTab, setActiveTab] = useState('Pending');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get(`${BaseUrl}/purchase-return/getAllReturnOrders`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setOrders(response.data.data.reverse()); // Assuming the return orders are in the 'data' key
      })
      .catch(error => {
        console.error("There was an error fetching the return orders!", error);
      });
  }, []);

  // Filter orders based on activeTab and search term
  const filteredOrders = orders.filter(order => {
    if (activeTab === 'All Orders' || order.productDetails[0].returnStatus === activeTab) {
      return order.OrderId.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });

  // Sort orders by date
  const sortedOrders = filteredOrders.sort((a, b) => {
    if (sortOrder === 'asc') {
      return new Date(a.createdAt) - new Date(b.createdAt);
    } else {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });

  const indexOfLastRecord = currentPage * perPage;
  const indexOfFirstRecord = indexOfLastRecord - perPage;
  const currentRecords = sortedOrders.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(sortedOrders.length / perPage);

  const openModal = (value) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const NavigateViewOrder = (orderId, productId,returnMessage,module) => {
    navigate("/View_Request_Return", { state: { orderId, productId,returnMessage,module } });
  }

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className='p-5 w-[350px] overflow-x-auto md:w-full'>
          <p className="text-[14px]">Return Order Summary</p>
          <div className="flex bg-white rounded-lg w-[400px] justify-evenly mt-2">
            <div className="p-5">
              <p className='text-[12px]'>All Returns</p>
              <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold"> {orders.length}</p>
            </div>
            <div className="flex items-center justify-center ">
              <div className="h-14 bg-gray-400 text-gray-400 w-0.5"></div>
            </div>
            <div className="p-5">
              <p className='text-[12px]'>Confirm Return</p>
              <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">
                {orders.filter(order => order.productDetails[0].returnStatus === 'Confirmed').length}
              </p>
            </div>
          </div>
        </div>

        <div className='flex justify-between pb-2 pl-5 pr-5'>
          <div>
            <p className='mt-2 font-bold'>Total Orders ({filteredOrders.length})</p>
          </div>
          <div className='flex gap-2 p-2 text-sm text-black bg-white border rounded-lg'>
            <ImSortAmountAsc className='mt-1' />
            <button onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
              Sort by Date
            </button>
            <FaChevronDown className='mt-1' />
          </div>
        </div>

        {/* <div className='pl-5 pr-5'>
          <div className='p-2 bg-white'>
            <div className='flex justify-between'>
              <div className='flex gap-5 mt-2 ml-4'>
                {['Pending', 'Rejected', 'Refund'].map(tab => (
                  <p
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    className={`cursor-pointer ${activeTab === tab ? 'underline underline-green' : ''}`}
                  >
                    {tab} ({orders.filter(order => order.productDetails[0].returnStatus === tab).length})
                  </p>
                ))}
              </div>
              <div className='flex gap-5'>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-64 px-3 py-2 pl-10 bg-gray-300 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className='px-5'>
  <div className='p-2 bg-white'>
    <div className='flex flex-col justify-between md:flex-row'>
      <div className='flex gap-5 mt-2 ml-4'>
        {['Pending', 'Rejected', 'Refund'].map(tab => (
          <p
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`cursor-pointer text-sm md:text-base ${activeTab === tab ? 'underline underline-green' : ''}`}
          >
            {tab} ({orders.filter(order => order.productDetails[0].returnStatus === tab).length})
          </p>
        ))}
      </div>
      <div className='flex gap-5 mt-4 md:mt-0'>
        <div className="">
          <input
            type="text"
            placeholder="Search By OrderID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 pl-10 bg-gray-300 border border-gray-300 rounded-full md:w-64 focus:outline-none focus:border-blue-500"
          />
          
        </div>
      </div>
    </div>
  </div>
</div>


        {/* <div className='pb-10'>
          <div className="pl-5 pr-5">
            <table className="w-full border border-collapse border-gray-300">
              <thead style={{ backgroundColor: '#4C3F35' }} className="text-white ">
                <tr>
                  <th className="p-2 font-normal">Sr. No.</th>
                  <th className="p-2 font-normal">Order ID</th>
                  <th className="p-2 font-normal">Shop/ Customer</th>
                  <th className="p-2 font-normal ">Return Date</th>
                  <th className="p-2 font-normal ">Products</th>
                  <th className="p-2 font-normal">Supplier</th>
                  <th className="p-2 font-normal">Actions</th>
                </tr>
              </thead>
              <tbody className="text-black bg-white">
                {currentRecords.map((order, index) => (
                  <tr key={order._id}>
                    <td className="p-2 text-center">{index + 1}</td>
                    <td className="p-2 text-center">{order.OrderId}</td>
                    <td className="p-2 text-center">{order.customerInfo.CustomerName}</td>
                    <td className="p-2 text-center">{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td className="p-2 text-center">{order.productDetails[0].ProductName}-{order.productDetails[0].ProductID}</td>
                    <td className="p-2 text-center">{order.SupplierInfo.FactoryName}</td>
                    <td className="flex justify-center p-2 text-center">
                      {order.productDetails[0].returnStatus === "Pending" && (
                        <button
                          onClick={() => NavigateViewOrder(order.OrderId,order.productDetails[0].ProductID,order.returnMessage,"")}
                          className="border-[#0855FF] rounded-lg text-[#0855FF] border p-1 cursor-pointer text-sm hover:bg-[#0855FF] hover:text-white"
                          style={{ width: '80px', height: '30px' }}
                        >
                          <span className='text-[10px]'>View Request</span>
                        </button>
                      )}
                      {order.productDetails[0].returnStatus === "Rejected" && (
                        <button
                          onClick={() => NavigateViewOrder(order.OrderId,order.productDetails[0].ProductID,order.returnMessage,"Rejected")}
                          className="border-[#0855FF] rounded-lg text-[#0855FF] border p-1 cursor-pointer text-sm hover:bg-[#0855FF] hover:text-white"
                          style={{ width: '80px', height: '30px' }}
                        >
                          <span className='text-[10px]'>View</span>
                        </button>
                      )}
                      {order.productDetails[0].returnStatus === "Refund" && (
                        <button
                          onClick={() => NavigateViewOrder(order.OrderId,order.productDetails[0].ProductID,order.returnMessage,"Refund")}
                          className="border-[#0855FF] rounded-lg text-[#0855FF] border p-1 cursor-pointer text-sm hover:bg-[#0855FF] hover:text-white"
                          style={{ width: '80px', height: '30px' }}
                        >
                          <span className='text-[10px]'>View</span>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
        <div className='pb-10'>
  <div className="pl-5 pr-5">
    <div className="overflow-x-auto">
      <table className="min-w-full border border-collapse border-gray-300">
        <thead style={{ backgroundColor: '#4C3F35' }} className="text-white">
          <tr>
            <th className="p-2 font-normal">Sr. No.</th>
            <th className="p-2 font-normal">Order ID</th>
            <th className="p-2 font-normal">Shop/ Customer</th>
            <th className="p-2 font-normal">Return Date</th>
            <th className="p-2 font-normal">Products</th>
            <th className="p-2 font-normal">Supplier</th>
            <th className="p-2 font-normal">Actions</th>
          </tr>
        </thead>
        <tbody className="text-black bg-white">
          {currentRecords.map((order, index) => (
            <tr key={order._id}>
              <td className="p-2 text-center">{index + 1}</td>
              <td className="p-2 text-center">{order.OrderId}</td>
              <td className="p-2 text-center">{order.customerInfo.CustomerName}</td>
              <td className="p-2 text-center">{new Date(order.createdAt).toLocaleDateString()}</td>
              <td className="p-2 text-center">{order.productDetails[0].ProductName}-{order.productDetails[0].ProductID}</td>
              <td className="p-2 text-center">{order.SupplierInfo.FactoryName}</td>
              <td className="flex justify-center p-2 text-center">
                {order.productDetails[0].returnStatus === "Pending" && (
                  <button
                    onClick={() => NavigateViewOrder(order.OrderId, order.productDetails[0].ProductID, order.returnMessage, "")}
                    className="border-[#0855FF] rounded-lg text-[#0855FF] border p-1 cursor-pointer text-sm hover:bg-[#0855FF] hover:text-white mt-5 md:mt-0"
                    style={{ width: '80px', height: '30px' }}
                  >
                    <span className='text-[10px]'>View Request</span>
                  </button>
                )}
                {["Rejected", "Refund"].includes(order.productDetails[0].returnStatus) && (
                  <button
                    onClick={() => NavigateViewOrder(order.OrderId, order.productDetails[0].ProductID, order.returnMessage, order.productDetails[0].returnStatus)}
                    className="border-[#0855FF] rounded-lg text-[#0855FF] border p-1 cursor-pointer text-sm hover:bg-[#0855FF] hover:text-white mt-10 md:mt-0"
                    style={{ width: '80px', height: '30px' }}
                  >
                    <span className='text-[10px]'>View</span>
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
</div>

        <div className="pb-5">
          {/* <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} /> */}
          <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
        </div>

        {isModalOpen && <OrderTrackingModal isOpen={isModalOpen} closeModal={closeModal} />}
      </div>
    </div>
  );
}

export default PurchaseReturn;
