import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEye, FaPlus } from "react-icons/fa";
import { IoStar, IoStarOutline } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'; // Import SweetAlert2
import { BaseUrl } from "../../auth/Url";
import { fetchProducts, updatePriceApi, updateVisibility } from "../../Service/ManageInventoryAdmin/ManageInventoryApi";
import Loader from "../Component/Loader";
import Pagination from "../Component/pagination";
function Products() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  console.log(selectedProductIds)
  const [products, setProducts] = useState([]);
  const [productscounts, setProductscounts] = useState([]);
  const [price, setPrice] = useState(products.price);
  const [allProducts, setAllProducts] = useState([]);
  const [draftedProducts, setDraftedProducts] = useState([]);
const [activeTab, setActiveTab] = useState('AllProduct');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [priceChanges, setPriceChanges] = useState({});
 const [searchQuery, setSearchQuery] = useState("");

  
useEffect(() => {
    
    
    loadProducts();
}, [token]);
  const loadProducts = async () => {
      setLoading(true);
      try {
        const data = await fetchProducts();
       const allProducts = data.productsList.filter(product => product.action === "1")
  .sort((a, b) => (b.DealWeek ? 1 : -1)); // Sort by DealWeek (true first)

const draftedProducts = data.productsList.filter(product => product.action === "0");

setAllProducts(allProducts);
setDraftedProducts(draftedProducts);

        setDraftedProducts(draftedProducts);
        setProducts(allProducts);
        setProductscounts(data.productsList);
          const dealWeekProductIds = allProducts
            .filter(product => product.DealWeek)  // Assuming `DealWeek` is a boolean
            .map(product => product._id);          // Change `_id` based on your product ID field

        setSelectedProductIds(dealWeekProductIds);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      } finally {
        setLoading(false);
      }
  };
  
//   const handleDeleteProduct = async (productId) => {
    
//     const isConfirmed = window.confirm('Are you sure you want to delete this product?');

    
//     if (isConfirmed) {
//         setLoading(true);
//         try {
//             const token = localStorage.getItem('token'); // Retrieve the token from localStorage

//             const response = await fetch(`${BaseUrl}/products/products/${productId}`, {
//                 method: 'DELETE',
//                 headers: {
//                     'Authorization': `Bearer ${token}`, // Add the Authorization header
//                     'Content-Type': 'application/json',
//                 },
//             });

//             if (response.ok) {
//                 alert('Product deleted successfully');
//                 loadProducts(); // Re-fetch the product list to update the UI
//             } else {
//                 const errorData = await response.json();
//                 alert(`Failed to delete product: ${errorData.message}`);
//             }
//         } catch (error) {
//             console.error('Error deleting product:', error);
//             alert('Failed to delete product');
//         } finally {
//             setLoading(false);
//         }
//     }
// };


const handleDeleteProduct = async (productId) => {
    // Show SweetAlert confirmation dialog
    const { isConfirmed } = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won’t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
    });

    if (isConfirmed) {
        setLoading(true);
        try {
            const token = localStorage.getItem('token'); // Retrieve the token from localStorage

            const response = await fetch(`${BaseUrl}/products/products/${productId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add the Authorization header
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                Swal.fire('Deleted!', 'Product deleted successfully.', 'success'); // SweetAlert success message
                loadProducts(); // Re-fetch the product list to update the UI
            } else {
                const errorData = await response.json();
                Swal.fire('Failed!', `Failed to delete product: ${errorData.message}`, 'error'); // SweetAlert error message
            }
        } catch (error) {
            console.error('Error deleting product:', error);
            Swal.fire('Error!', 'Failed to delete product', 'error'); // SweetAlert error message
        } finally {
            setLoading(false);
        }
    }
};


  const handleNavigateAddProduct = () => {
    navigate("/manageinventory/Add_Update_product");
  };

  const handleNavigateEditProduct = (productDetails, modelAction) => {
    const productData = {
      Productcode: productDetails._id,
      ModelAction: modelAction,
    };

    navigate('/manageinventory/Add_Update_product', { state: productData });
  };

   const handleNavigateViewProduct = (productDetails) => {
    const productData = {
      Productcode: productDetails._id,
    
    };

    navigate('/viewinventory', { state: productData });
  };

 const handleVisibilityToggle = async (productCode, currentVisibility) => {
    try {
      await updateVisibility(productCode, currentVisibility);
      setProducts(products.map(product =>
        product.productCode === productCode ? { ...product, isVisible: !currentVisibility } : product
      ));
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
    }
  };
   const filteredRecords = products.filter((record) =>
    record.productName.toLowerCase().includes(searchQuery.toLowerCase())
  );
 const indexOfLastRecord = currentPage * perPage;
  const indexOfFirstRecord = indexOfLastRecord - perPage;
  const currentRecords = filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredRecords.length / perPage);
  const addDealOfTheWeekProducts = async (productIds) => {
  try {
    const response = await axios.post(`${BaseUrl}/AddDealOfTheWeek`, { productIds }); // Adjust the URL as necessary
    return response.data; // Handle the response as needed
  } catch (error) {
    console.error('Error adding deal of the week products:', error);
    throw error; // Propagate the error to handle it where needed
  }
};

// Filter records based on the search query
  
const handleStarClick = async (id) => {
  setSelectedProductIds((prevSelected) => {
    const isSelected = prevSelected.includes(id);
    let newSelected;

    if (isSelected) {
      // Remove the product ID
      newSelected = prevSelected.filter((productId) => productId !== id);
      
      // If removing a product, call the API to remove it from Deal of the Week
      removeDealOfTheWeekProduct(id)
        .then(data => {
          console.log('Successfully removed product from deal of the week:', data);
        })
        .catch(error => {
          console.error('Failed to remove product from deal of the week:', error);
        });
    } else {
      // Add the new ID
      newSelected = [...prevSelected, id];

      // Ensure no more than 3 products are selected
      if (newSelected.length > 3) {
        console.log('Cannot select more than 3 products.');
        return prevSelected; // Return previous state without adding
      }

      // Call the API to save the selected products as "deal of the week" only if 3 are selected
      if (newSelected.length === 3) {
        addDealOfTheWeekProducts(newSelected)
          .then(data => {
            console.log('Successfully added deal of the week products:', data);
          })
          .catch(error => {
            console.error('Failed to add deal of the week products:', error);
          });
      }
    }

    return newSelected;
  });
};

// Function to remove a product from Deal of the Week
const removeDealOfTheWeekProduct = async (id) => {
  try {
    await fetch(`${BaseUrl}/remove/${id}`, {
      method: 'DELETE', // Assuming you set up a DELETE endpoint
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error removing product from Deal of the Week:', error);
  }
};


const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'AllProduct') {
      setProducts(allProducts);
    } else {
      setProducts(draftedProducts);
    }
    setCurrentPage(1);
  };

    const handlePriceChange = (productId, newPrice) => {
    setPriceChanges({
      ...priceChanges,
      [productId]: newPrice,
    });
  };


const updatePrice = async (productId, newPrice) => {
    if (newPrice === null || newPrice === undefined || isNaN(newPrice)) {
      newPrice = 0;
    }
    try {
      await updatePriceApi( productId, newPrice);
      setProducts(products.map(product =>
        product._id === productId ? { ...product, price: newPrice } : product
      ));
    } catch (error) {
      console.error('Error updating price:', error);
    }
  };
  return (
    <div style={{ backgroundColor: "#F6F6F6",minHeight: "100vh" }}>
      <div  className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] " >
        <div className="">
        <div className="flex flex-wrap items-center justify-between pt-5">
  <div className="w-full p-3 sm:w-auto">
    <p className="text-[14px]">Inventory Summary</p>
    <div className="flex bg-white rounded-lg w-full sm:w-[500px] justify-evenly mt-2 p-4">
      <div className="flex flex-col items-center p-5">
        <p>All Products</p>
        <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">
          {productscounts.length}
        </p>
      </div>
      <div class="flex items-center justify-center ">
  <div class="h-10 w-px bg-gray-400"></div>
</div>
      <div className="flex flex-col items-center p-5">
        <p>Active Products</p>
        <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">
          {allProducts.length}
        </p>
      </div>
    </div>
  </div>
          <div className="pl-24">
  <button
    className="flex items-center gap-2 px-4 py-2 mt-4 text-white rounded-lg sm:mt-0"
    style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }}
    onClick={handleNavigateAddProduct}
  >
    <FaPlus className="mt-1" />
    <p>Add a New Product</p>
  </button>
</div>

  
</div>

          {/* <div className="mt-5 mb-2 font-semibold ">
            <p>Ecommerce Products ({products.length})</p>
           </div> */}
         <div className="flex flex-col justify-between p-3 mt-2 bg-white md:flex-row">
  <div className='flex flex-wrap gap-5 p-2 mt-2 ml-4'>
    <p
      className={`cursor-pointer ${activeTab === 'AllProduct' ? 'font-bold underline underline-green' : ''}`}
      onClick={() => handleTabClick('AllProduct')}
    >
      Active Products({allProducts.length})
    </p>
    <p
      className={`cursor-pointer ${activeTab === 'drafted' ? 'font-bold underline underline-green' : ''}`}
      onClick={() => handleTabClick('drafted')}
    >
      Drafted({draftedProducts.length})
    </p>
  </div>

  <div className="flex justify-between gap-5 p-2">
    <div className="relative">
      <input
        type="text"
        placeholder="Search Product.."
        className="w-[320px] px-3 py-2 pl-10 bg-gray-100 border border-gray-300 rounded-full md:w-64 focus:outline-none focus:border-blue-500"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className="absolute inset-y-0 left-0 flex items-center p-3 pointer-events-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
        </svg>
      </div>
    </div>
  </div>
</div>


          {/* <div className="">
            <table className="w-full border border-collapse border-gray-300 ">
              <thead  style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }} className="text-white">
                <tr>
                  <th className="p-2"></th>
                  <th className="p-2 font-normal">Product ID</th>
                  <th className="p-2 font-normal">Product</th>
                  <th className="p-2 font-normal">Supplier</th>
                  <th className="p-2 font-normal">MRP/ kg</th>
                  <th className="p-2 font-normal">Visibility</th>
                  <th className="p-2 font-normal">View</th>
                  <th className="p-2 font-normal">Edit</th>
                  <th className="p-2 font-normal">Delete</th>
                </tr>
              </thead>
              <tbody className="text-black bg-white ">
                 {currentRecords.map((record) => (
            <tr key={record._id} className="border-b border-gray-200">
              <td className="p-2 pl-2 text-center">
                {selectedProductIds.includes(record._id) ? (
                  <IoStar
                    onClick={() => handleStarClick(record._id)}
                    className="cursor-pointer"
                    style={{ color: "rgba(255, 178, 30, 1)" }}
                  />
                ) : (
                  <IoStarOutline
                    onClick={() => handleStarClick(record._id)}
                    className="cursor-pointer"
                  />
                )}
              </td>
              <td className="p-2 text-center">
                <p>{record.productCode}</p>
              </td>
              <td className="p-2">
                <div className="flex items-center gap-5">
                  {record.selectedImages.length > 0 ? (
                    <img
                      src={record.selectedImages[0]}
                      alt={record.productName}
                      className="w-10 h-10"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-10 h-10 bg-gray-300">
                      <span>No Image</span>
                    </div>
                  )}
                  <p className="text-center">{record.productName}</p>
                </div>
              </td>
              <td className="p-2 text-center">{record.supplierName}</td>
              <td className="p-2 text-center">
                ₹{" "}
                <input
                  type="number"
                  value={
                    priceChanges[record._id] !== undefined
                      ? priceChanges[record._id]
                      : record.price
                  }
                  onChange={(e) =>
                    handlePriceChange(record._id, parseFloat(e.target.value))
                  }
                  onBlur={() =>
                    updatePrice(
                      record._id,
                      parseFloat(
                        priceChanges[record._id] !== undefined
                          ? priceChanges[record._id]
                          : record.price
                      )
                    )
                  }
                  className="p-2 border rounded-full w-28"
                  min="0"
                />
              </td>
              <td className="p-2 text-center">
                <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
                  <input
                    type="checkbox"
                    name="toggle"
                    id={`toggle-${record.productCode}`}
                    checked={record.isVisible}
                    onChange={() =>
                      handleVisibilityToggle(
                        record.productCode,
                        record.isVisible
                      )
                    }
                    className="sr-only"
                  />
                  <label
                    htmlFor={`toggle-${record.productCode}`}
                    className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                      record.isVisible ? "bg-blue-600" : ""
                    }`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                        record.isVisible
                          ? "translate-x-6 bg-green-400"
                          : ""
                      }`}
                    ></span>
                  </label>
                </div>
              </td>
              <td className="p-2 text-center">
                <FaEye
                  className="w-5 h-5 ml-10 cursor-pointer"
                  onClick={() => handleNavigateViewProduct(record)}
                />
              </td>
              <td className="p-2 text-center">
                <MdModeEdit
                  className="w-5 h-5 ml-10 cursor-pointer"
                  onClick={() =>
                    handleNavigateEditProduct(record, "Update Product")
                  }
                />
              </td>
              <td className="p-2 text-center">
                <div className="relative">
                  <button className="px-2 py-1 rounded-md">
                    <RiDeleteBinLine
                      className="w-5 h-5 text-red-600 cursor-pointer"
                      onClick={() => handleDeleteProduct(record._id)}
                    />
                  </button>
                </div>
              </td>
            </tr>
          ))}
              </tbody>
            </table>
          </div> */}
          <div className="p-2 overflow-x-auto md:p-0">
  <table className="min-w-full border border-collapse border-gray-300">
    <thead style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }} className="text-white">
      <tr>
        <th className="p-2"></th>
        <th className="p-2 font-normal">Product ID</th>
        <th className="p-2 font-normal">Product</th>
        <th className="p-2 font-normal">Supplier</th>
        <th className="p-2 font-normal">MRP/ kg</th>
        {activeTab !== "drafted" && <th className="p-2 font-normal">Visibility</th>}
        <th className="p-2 font-normal">View</th>
        <th className="p-2 font-normal">Edit</th>
        <th className="p-2 font-normal">Delete</th>
      </tr>
    </thead>
    <tbody className="text-black bg-white">
      {currentRecords.map((record) => (
        <tr key={record._id} className="border-b border-gray-200">
          <td className="p-2 pl-2 text-center">
            {selectedProductIds.includes(record._id) ? (
              <IoStar
                onClick={() => handleStarClick(record._id)}
                className="cursor-pointer"
                style={{ color: "rgba(255, 178, 30, 1)" }}
              />
            ) : (
              <IoStarOutline
                onClick={() => handleStarClick(record._id)}
                className="cursor-pointer"
              />
            )}
          </td>
          <td className="p-2 text-center">
            <p>{record.productCode}</p>
          </td>
          <td className="p-2">
            <div className="flex items-center gap-5">
              {record.selectedImages.length > 0 ? (
                <img
                  src={record.selectedImages[0]}
                  alt={record.productName}
                  className="w-10 h-10"
                />
              ) : (
                <div className="flex items-center justify-center w-10 h-10 bg-gray-300">
                  <span>No Image</span>
                </div>
              )}
              <p className="text-center">{record.productName}</p>
            </div>
          </td>
          <td className="p-2 text-center">{record.supplierName}</td>
          <td className="p-2 text-center">
            ₹{" "}
            <input
              type="number"
              value={
                priceChanges[record._id] !== undefined
                  ? priceChanges[record._id]
                  : record.price
              }
              onChange={(e) =>
                handlePriceChange(record._id, parseFloat(e.target.value))
              }
              onBlur={() =>
                updatePrice(
                  record._id,
                  parseFloat(
                    priceChanges[record._id] !== undefined
                      ? priceChanges[record._id]
                      : record.price
                  )
                )
              }
              className="p-2 border rounded-full w-28"
              min="0"
            />
          </td>
           {activeTab !== "drafted" && (
          <td className="p-2 text-center">
            <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
              <input
                type="checkbox"
                name="toggle"
                id={`toggle-${record.productCode}`}
                checked={record.isVisible}
                onChange={() =>
                  handleVisibilityToggle(record.productCode, record.isVisible)
                }
                className="sr-only"
              />
              <label
                htmlFor={`toggle-${record.productCode}`}
                className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${
                  record.isVisible ? "bg-blue-600" : ""
                }`}
              >
                <span
                  className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${
                    record.isVisible ? "translate-x-6 bg-green-400" : ""
                  }`}
                ></span>
              </label>
            </div>
          </td>
        )}
          <td className="p-2 text-center">
            <FaEye
              className="w-5 h-5 ml-10 cursor-pointer"
              onClick={() => handleNavigateViewProduct(record)}
            />
          </td>
          <td className="p-2 text-center">
            <MdModeEdit
              className="w-5 h-5 ml-10 cursor-pointer"
              onClick={() =>
                handleNavigateEditProduct(record, "Update Product")
              }
            />
          </td>
          <td className="p-2 text-center">
            <div className="relative">
              <button className="px-2 py-1 rounded-md">
                <RiDeleteBinLine
                  className="w-5 h-5 text-red-600 cursor-pointer"
                  onClick={() => handleDeleteProduct(record._id)}
                />
              </button>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

        </div>
         {loading && <Loader loading={loading}  />}
        {products.length > 5 && (
          <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
        )}
      </div>
    </div>
  );
}

export default Products;
