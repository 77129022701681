import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Dashboard from "./auth/Dashboard";
import Login from "./auth/Loginpage";
import ProtectedRoute from "./auth/protectedRoute";
import Register from "./auth/Register";
import Commission from './Pages/Commission_managment.jsx/Commission';
import CommissionSlab from "./Pages/Commission_managment.jsx/CommissionSlab";
import Header from "./Pages/Component/Header";
import DashboardHomeScreen from "./Pages/Dashboard/DashboardHomeScreen";
import DispatchList from "./Pages/DispatchOrder/DispatchList";
import Fill_OrderDetailspage from './Pages/DispatchOrder/Fill_OrderDetailspage';
import PurchaseReturn from "./Pages/DispatchOrder/PurchaseReturn";
import ViewRequest from "./Pages/DispatchOrder/ViewRequest";
import ViewRequestForReturnProduct from "./Pages/DispatchOrder/ViewRequestForReturnProduct";
import CountPage from "./Pages/EcommerceTab/CountPage";
import HomeBanner from "./Pages/EcommerceTab/HomeBanner";
import TermsAndCondition from "./Pages/EcommerceTab/TermsAndCondition";
import Testimonials from "./Pages/EcommerceTab/Testimonials";
import AddNewFactory from "./Pages/factory/AddNewFactory";
import Factory_Details from "./Pages/factory/Factory_Details";
import FactoryList from "./Pages/factory/FactoryList";
import Purchase_Order_List from "./Pages/factory/Purchase_Order_List";
import Add_Product from './Pages/Manage_Inventory/Add_Product';
import Categorieslist from './Pages/Manage_Inventory/Categorieslist';
import Products from './Pages/Manage_Inventory/Products';
import ViewInventory from './Pages/Manage_Inventory/ViewInventory';
import AddManuallyOrder from "./Pages/Order_Managment/AddManuallyOrder";
import Fill_Order_Details from './Pages/Order_Managment/Order_Confirmation';
import Order_management_page from './Pages/Order_Managment/Order_management_page';
import OrderPlaceManually from "./Pages/Order_Managment/OrderPlaceManually";
import View_Order from './Pages/Order_Managment/View_Order';
import Add_Quatation from './Pages/Quotation/Add_Quatation';
import Quotation_page from './Pages/Quotation/Quotation_page';
import AddShopDealer from "./Pages/ShopDealer/AddShopDealer";
import Shop_Dealer_List from "./Pages/ShopDealer/Shop_Dealer_List";
import Shop_Dealer_Purchaseorder from "./Pages/ShopDealer/Shop_Dealer_Purchaseorder";
import DetailsPurchaseReport from "./Pages/Trasaction/DetailsPurchaseReport";
import TrasactionList from "./Pages/Trasaction/TrasactionList";
import AddCategoryProduct from "./Pages/factory/AddCategoryProduct";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/dashboard" element={<DashboardHomeScreen />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

const DashboardLayout = () => {
  const location = useLocation();
  const [moduleName, setModuleName] = useState('Manage Products');
  const [submoduleName, setsubModuleName] = useState('Manage Products');
  useEffect(() => {
    // Update the module name based on the current path
    switch (location.pathname) {
      case '/manageinventory':
        setModuleName('Manage Products');
        setsubModuleName('Manage Products');
        break;
      case '/manageinventory/Add_Update_product':
        setModuleName('Add a New Product');
        setsubModuleName('Manage Products / Add a New Product');
        break;
      case '/viewinventory':
        setModuleName('View Product');
        setsubModuleName('Products / View Detail');
        break;
      case '/transactionrecords':
        setModuleName('Transaction Records');
        setsubModuleName('Transaction Records');
        break;
      case '/commissionmanagement':
        setModuleName('Commission Management');
        setsubModuleName('Commission Management');
        break;
      case '/detailsPurchaseReport':
        setModuleName('Detail Transaction Report');
        setsubModuleName('Transaction Records');
        break;
      case '/factory':
        setModuleName('Factory List');
        setsubModuleName('Factory List');
        break;
      case '/factory/Add_Update_factory':
        setModuleName('Add New Factory');
        setsubModuleName('Add New Factory');
        break;
      case '/factory/View_Factory':
        setModuleName('Factory Details');
        setsubModuleName('Factory List / View Factory Details');
        break;
      case '/factory/PurchaseOrder':
        setModuleName('Purchase Order List');
        setsubModuleName('Factory/ Purchase Order');
        break;
      case '/dealer/shop':
        setModuleName('Shop/ Dealer List');
        setsubModuleName('Shop List');
        break;
      case '/Add_Dealer_Shops':
        setModuleName('Add a New Shop');
        setsubModuleName('Shop List');
        break;
      case '/Dealer_Shops/PurchaseOrderList':
        setModuleName('Purchase Order List');
        setsubModuleName('Shops/ Purchase Order');
        break;
      case '/Add_Quotation':
        setModuleName('Add New Quotation');
        setsubModuleName('Quotation / Add new Quotation');
        break;
      case '/ordermanagement':
        setModuleName('Purchase Order');
        setsubModuleName('Order Management/ Purchase Order');
        break;
      case '/View_order':
        setModuleName('Purchase Order');
        setsubModuleName('Order Management/ Purchase Order');
        break;
      case '/dispatchmanagement':
        setModuleName('Dispatch Management');
        setsubModuleName('Dispatch Order');
        break;
      case '/Fill_Order_Details':
        setModuleName('Fill Details');
        setsubModuleName('Order Management/ Purchase Order/ Fill Details');
        break;
      case '/PurchaseReturn':
        setModuleName('Purchase Return');
        setsubModuleName('Purchase Return');
        break;
      case '/View_Request':
        setModuleName('Purchase Return');
        setsubModuleName('Purchase Return');
        break;
      case '/Add_Commission_Slab':
        setModuleName('Commission Slab');
        setsubModuleName('Commission Management / Add Commission Slab');
        break;
      case '/Place_Order':
        setModuleName('Place Order');
        setsubModuleName('Order Management/ Add new order/ Add Address');
        break;
      case '/HomeBanner':
        setModuleName('Home Banner');
        setsubModuleName('Home Banner');
        break;
      case '/quotation':
        setModuleName('Quotation');
        setsubModuleName('Quotation');
        break;
      default:
        setModuleName('Dashboard');
        setsubModuleName('Dashboard');
    }
  }, [location.pathname]);

  return (
    <>
      <Dashboard />
      <Header moduleName={moduleName} submoduleName={submoduleName} />
      <Routes>
        <Route path="/" element={<DashboardHomeScreen />} />
        <Route path="/manageinventory" element={<Products />} />
        <Route path="/manageinventory/Add_Update_product" element={<Add_Product />} />
        <Route path="/manageinventory/categories" element={<Categorieslist />} />
        <Route path="/ordermanagement" element={<Order_management_page />} />
        <Route path="/viewinventory" element={<ViewInventory />} />
        <Route path="/fillDetails" element={<Fill_Order_Details />} />
        <Route path="/View_order" element={<View_Order />} />
        <Route path="/quotation" element={<Quotation_page />} />
        <Route path="/commissionmanagement" element={<Commission />} />
        <Route path="/Fill_Order_Details" element={<Fill_OrderDetailspage />} />
        <Route path="/Add_Quotation" element={<Add_Quatation />} />
        <Route path="/transactionrecords" element={<TrasactionList />} />
        <Route path="/detailsPurchaseReport" element={<DetailsPurchaseReport />} />
        <Route path="/factory" element={<FactoryList />} />
        <Route path="/factory/Add_Update_factory" element={<AddNewFactory />} />
        <Route path="/factory/View_Factory" element={<Factory_Details />} />
        <Route path="/factory/PurchaseOrder" element={<Purchase_Order_List />} />
        <Route path="/dealer/shop" element={<Shop_Dealer_List />} />
        <Route path="/Add_Dealer_Shops" element={<AddShopDealer />} />
        <Route path="/Dealer_Shops/PurchaseOrderList" element={<Shop_Dealer_Purchaseorder />} />
        <Route path="/dispatchmanagement" element={<DispatchList />} />
        <Route path="/PurchaseReturn" element={<PurchaseReturn />} />
        <Route path="/View_Request" element={<ViewRequest />} />
        <Route path="/View_Request_Return" element={<ViewRequestForReturnProduct />} />
        <Route path="/Add_Commission_Slab" element={<CommissionSlab />} />
        <Route path="/Add_New_Order" element={<AddManuallyOrder />} />
        <Route path="/Place_Order" element={<OrderPlaceManually />} />
        <Route path="/HomeBanner" element={<HomeBanner />} />
        <Route path="/Counter" element={<CountPage />} />
        <Route path="/testimonals" element={<Testimonials />} />
        <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
        
      </Routes>
    </>
  );
};

export default App;
