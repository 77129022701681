import React, { useEffect, useState } from 'react';
import { FaBagShopping } from "react-icons/fa6";
import { IoAddOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import DashboardHomelogo from "../../asset/DashboardHomelogo.png";
import HomeDelete from "../../asset/HomeDelete.png";
import Industry from "../../asset/Industry.png";
import shoppingiconDashboard from "../../asset/shoppingiconDashboard.png";
import { fetchAllOrders } from '../../Service/OrderManagment/OrderManagmentApi';
import GraphComponent from '../Component/DashboardChart';
import { BaseUrl } from '../../auth/Url';
function DashboardHomeScreen() {
  const navigate = useNavigate();
   const [currentPage, setCurrentPage] = useState(1); // To track the current page
  const recordsPerPage = 8; // Number of records per page

    const [orders, setOrders] = useState([]);
  console.log(orders)
   const [countsData, setCountsData] = useState({});
 


  useEffect(() => {
    const fetchCountsData = async () => {
      try {
        const response = await fetch(`${BaseUrl}/DashboardCount/countsApi`);
        const result = await response.json();

        if (result.success) {
          setCountsData(result.data);
        } else {
          console.error('Failed to fetch counts data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCountsData();
  }, []);
    useEffect(() => {
  const getOrders = async () => {
    try {
      const response = await fetchAllOrders();
      // Reverse the list to get the latest orders first
      const reversedOrders = response.OrderList.reverse();
      setOrders(reversedOrders);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  };
  getOrders();
    }, []);
    
    const flattenedOrders = orders.flatMap(order =>
    order.ProductDetails.map(productDetail => ({
      ...order,
      ...productDetail
    }))
    );
    console.log(flattenedOrders, "flattenedOrders")
    const NavigateViewOrder = (orderId, productId,Action) => {
    navigate("/View_order", { state: { orderId, productId,Action } });
  }
     const countOrders = () => {
  let allOrdersCount = 0;
  let pendingCount = 0;
  let duePaymentCount = 0;
  let completedCount = 0;

  flattenedOrders.forEach(order => {
    allOrdersCount += 1; // Increment for all orders

    // Check if any product in ProductDetails has a pending status
    const hasPending = order.ProductDetails.some(product => 
      product.dispatchShippingDetails.DispatchStatus === 'pending'
    );

    if (hasPending) {
      pendingCount += 1; // Increment if at least one product is pending
    }

    // Check for due payment where dispatch status is completed
    const hasDuePayment = order.ProductDetails.some(product => {
      return product.dispatchShippingDetails.DispatchStatus === 'Completed' && product.Duepayment
 === '0';
    });

    if (hasDuePayment) {
      duePaymentCount += 1; // Increment if any product has due payment of 0 and completed status
    }

    // Check if any product in ProductDetails is completed
    const hasCompleted = order.ProductDetails.some(product => 
      product.dispatchShippingDetails.DispatchStatus === 'Completed'
    );

    if (hasCompleted) {
      completedCount += 1; // Increment if at least one product is completed
    }
  });

  return { allOrdersCount, pendingCount, duePaymentCount, completedCount };
};

// Usage
const { allOrdersCount, pendingCount, duePaymentCount, completedCount } = countOrders();

   // Filter pending orders and calculate pagination
  const pendingOrders = flattenedOrders.filter(
    record =>
      record.ProductDetails.length > 0 &&
      record.ProductDetails[0].dispatchShippingDetails.OrderStatus === 'Pending'
  );

  // Calculate the index of the first and last record for the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = pendingOrders.slice(indexOfFirstRecord, indexOfLastRecord);

  // Calculate total pages
  const totalPages = Math.ceil(pendingOrders.length / recordsPerPage);

  // Navigate to the next or previous page
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
      <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
              <div className='flex gap-5 p-5'>
                  <Link className='w-[309px] h-[159px]  bg-[#28C76F] border rounded-lg hover:scale-105 cursor-pointer  ' to='/factory' title='Factories'>
                      <div className='p-2'>
                          <img src={Industry} alt="Wheat" className="w-[36px] h-[36px] bg-[#FFFFFF29] p-2 rounded-lg" />
                      </div>
                      <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>Number of Factories</p>
                      </div>
                      <div className='p-2'>
              <p className='text-white'><span className='text-[24px]'>{countsData.factoryCount}</span> </p>
                      </div>
                     
                  </Link>
                   <Link className='w-[309px] h-[159px]  bg-[#FB3232] border rounded-lg   hover:scale-105  cursor-pointer ' to='/dealer/shop'  title='Shops'>
                      <div className='p-2'>
                          <img src={DashboardHomelogo} alt="Wheat" className="w-[36px] h-[36px] bg-[#FFFFFF29] p-2 rounded-lg" />
                      </div>
                      <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>Number of Shops</p>
                      </div>
                      <div className='p-2'>
              <p className='text-white'><span className='text-[24px] '>{countsData.dealerCount}</span> </p>
                      </div>
          </Link>
          <div className='hidden gap-5 md:flex md:block'> <Link className='w-[500px] h-[159px]  bg-[#4C3F35] border rounded-lg  hover:scale-105  cursor-pointer ' to='/manageinventory'  title='All Product'>
                       <div className='p-2' >
                          <img src={HomeDelete} alt="Wheat" className="w-[30px] h-[30px] bg-[#FFFFFF29] p-2 rounded-lg" />
                      </div>
                      <div className='flex gap-20'>
                          <div>
                                <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>All Products</p>
                      </div>
                      <div className='p-2'>
                  <p className='text-white'><span className='text-[24px] '>{countsData.allProductCount}</span> </p>
                      </div>
                          </div>
                          <div>
                                <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>Active</p>
                      </div>
                      <div className='p-2'>
                  <p className='text-white'><span className='text-[24px] '>{countsData.activeProductCount}</span> </p>
                      </div>
              </div>
            
                      </div>
                     
                  </Link></div>
          
                
              </div>
        <div  className='flex gap-5 p-5 md:hidden'>
             <Link className='w-[423px] h-[159px]  bg-[#4C3F35] border rounded-lg  hover:scale-105  cursor-pointer ' to='/manageinventory'  title='All Product'>
                       <div className='p-2' >
                          <img src={HomeDelete} alt="Wheat" className="w-[30px] h-[30px] bg-[#FFFFFF29] p-2 rounded-lg" />
                      </div>
                      <div className='flex gap-20'>
                          <div>
                                <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>All Products</p>
                      </div>
                      <div className='p-2'>
                  <p className='text-white'><span className='text-[24px] '>{countsData.allProductCount}</span> </p>
                      </div>
                          </div>
                          <div>
                                <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>Active</p>
                      </div>
                      <div className='p-2'>
                  <p className='text-white'><span className='text-[24px] '>{countsData.activeProductCount}</span> </p>
                      </div>
                          </div>
                      </div>
                     
                  </Link>
              </div>

            <div className='flex flex-col gap-5 pt-2 pb-5 pl-5 pr-5 lg:flex-row'>
  <div className='w-full'>
    <div className="w-full max-w-full lg:max-w-[638px] h-auto bg-[#FFFFFF] rounded-lg">
      <div className="flex flex-col items-start justify-between p-4 md:flex-row md:items-center">
        <div className="flex items-center space-x-2">
          <div className="w-[36px] h-[36px] bg-[#FFCC9129] p-2 rounded-lg">
            <FaBagShopping />
          </div>
          <p className="text-black text-[14px] font-semibold">Total Orders</p>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2 gap-4 p-5 sm:grid-cols-4">
          <div>
            <p className="text-[14px] text-[#8B8D97]">All Orders</p>
            <p className="text-[20px] mt-2 font-semibold">{countsData.totalProductCount}</p>
          </div>
          <div>
            <p className="text-[14px] text-[#8B8D97]">Pending</p>
            <p className="text-[20px] mt-2 font-semibold">{countsData.pendingProductCount}</p>
          </div>
          <div>
            <p className="text-[14px] text-[#8B8D97]">Cancelled</p>
            <p className="text-[20px] mt-2 font-semibold">{countsData.canceledProductCount}</p>
          </div>
          <div>
            <p className="text-[14px] text-[#8B8D97]">Completed</p>
            <p className="text-[20px] mt-2 font-semibold">{countsData.completedProductCount}</p>
          </div>
        </div>
      </div>
    </div>

    <div className='w-full lg:w-[638px] h-auto bg-[#FFFFFF] rounded-lg mt-4'>
  <div className='flex items-center justify-between p-4'>
    <div className='flex items-center space-x-2'>
      <div className="w-[36px] h-[36px] bg-[#FFCC9129] p-2 rounded-lg">
        <FaBagShopping />
      </div>
      <p className="text-black text-[14px] font-semibold sm:text-[16px]">Total Returns</p>
    </div>
  </div>
  
  <div className='flex flex-col gap-4 p-4 sm:flex-row sm:gap-10'>
    <div>
      <p className='text-[14px] text-[#8B8D97] sm:text-[16px]'>Pending Returns</p>
      <p className='text-[20px] sm:text-[24px] mt-2 font-semibold'>{countsData.pendingReturnProductCount}</p>
    </div>
    <div>
      <p className='text-[14px] text-[#8B8D97] sm:text-[16px]'>Rejected</p>
      <p className='text-[20px] sm:text-[24px] mt-2 font-semibold'>{countsData.rejectedReturnProductCount}</p>
    </div>
    <div>
      <p className='text-[14px] text-[#8B8D97] sm:text-[16px]'>Refunded</p>
      <p className='text-[20px] sm:text-[24px] mt-2 font-semibold'>{countsData.refundProductCount}</p>
    </div>
  </div>
</div>


    <div className='w-full lg:w-[638px] md:h-[338px] bg-[#FFFFFF] rounded-lg mt-4'>
      <GraphComponent AllOrders={flattenedOrders} />
    </div>
  </div>

  <div className='w-full md::w-[423px] bg-[#FFFFFF] rounded-lg p-5'>
    <p>Recent Orders</p>
    <div>
      {pendingOrders.length === 0 ? (
        <div className='text-center pt-28'>
          <div className='flex justify-center'>
            <div className='p-2'>
              <img src={shoppingiconDashboard} alt="No Orders" className="w-[140px] h-[140px] bg-[#FFFFFF29] p-2 rounded-lg" />
            </div>
          </div>
          <p className='text-[20px]'>No Orders Yet?</p>
          <p className='text-[#8B8D97] mt-2'>Add products to your store and start<br /> selling to see orders here.</p>
          <div className='flex justify-center mt-5'>
            <Link className='flex bg-[#C6A26F] p-2 text-white rounded-lg hover:scale-105' to="/manageinventory/Add_Update_product">
              <IoAddOutline className='w-5 h-5 mt-0.5' /> New Product
            </Link>
          </div>
        </div>
      ) : (
        <div className='mt-2'>
  <div className="overflow-x-auto">
    <table className='w-full rounded-lg'>
      <thead className='font-normal text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
        <tr>
          <th className='p-2 font-normal rounded-tl-lg'>Order Id</th>
          <th className='p-2 font-normal'>Customer</th>
          <th className='p-2 font-normal'>Product</th>
          <th className='p-2 font-normal rounded-tr-lg w-[80px] md:w-[100px]'>Action</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {currentRecords.map((record, index) => (
          <tr key={index} className='border-b-2 cursor-pointer'>
            <td className="px-4 py-2 text-sm sm:text-base" title={record?.orderId}>
              {record?.orderId.length > 10 ? `${record?.orderId.slice(0, 10)}...` : record?.orderId}
            </td>
            <td className='p-2 text-sm text-center sm:text-base'>{record.customerInfo.ShopName}</td>
            <td className='p-2 text-sm text-center sm:text-base'>
              {record.ProductDetails[0].ProductName.length > 10 ? `${record.ProductDetails[0].ProductName.slice(0, 10)}...` : record.ProductDetails[0].ProductName}
            </td>
            <td className='p-2'>
              <div className='flex justify-center gap-2'>
                <button onClick={() => NavigateViewOrder(record.orderId, record.ProductDetails[0].ProductID, "")}
                  className='p-2 text-[#0855FF] border border-[#0855FF] rounded-lg hover:bg-[#0855FF] hover:text-white text-[11px] sm:text-[12px] md:text-[14px] w-[80px] md:w-[100px]'>
                  View Orders
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  <div className='flex items-center justify-center mt-4'>
    <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}
      className={`p-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'bg-[#C6A26F]'} text-white rounded-lg mr-2`}>
      Previous
    </button>
    <span className="text-sm sm:text-base">Page {currentPage} of {totalPages}</span>
    <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}
      className={`p-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'bg-[#C6A26F]'} text-white rounded-lg ml-2`}>
      Next
    </button>
  </div>
</div>

      )}
    </div>
  </div>
</div>

          </div>
    
    </div>
  )
  
}

export default DashboardHomeScreen
