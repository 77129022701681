import JoditEditor from 'jodit-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const TermsAndConditionModel = ({ isOpen, onClose, onSubmit, editorContent, setEditorContent, title }) => {
  const editor = useRef(null);

  // Focus on editor when modal opens
  useEffect(() => {
    if (isOpen && editor.current) {
      editor?.current?.editor?.focus();
    }
  }, [isOpen]); // Trigger only when the modal is opened

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page refresh
    if (editorContent.trim() === '') {
      alert('Terms and conditions cannot be empty!');
      return;
    }
    onSubmit(); // Call the submit function passed as a prop
  };

  const handleEditorChange = (newContent) => {
    setEditorContent(newContent);
  };

  const handleEditorBlur = () => {
    // Handle blur, but don't lose the focus on modal interactions
    if (editor.current && editor.current.editor) {
      editor.current.editor.focus(); // Refocus editor to avoid unwanted loss
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold">{title}</h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="overflow-y-auto"> {/* Set height to 100px with overflow for scrolling */}
            <JoditEditor
              ref={editor}
              value={editorContent}
              tabIndex={1} // tabIndex for focus management
              onBlur={handleEditorChange} // Call the function to handle change
              
              config={{
                placeholder: 'Enter terms and conditions...',
                height: 400, // Adjust height as necessary
              }}
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-500 rounded"
            >
              {title === 'Edit Term' ? 'Update' : 'Add'} Term
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

TermsAndConditionModel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editorContent: PropTypes.string.isRequired,
  setEditorContent: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default TermsAndConditionModel;
