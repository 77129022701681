import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../auth/Url";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
function AddShopDealer() {
    // State for form fields and errors
    const [shopName, setShopName] = useState("");
     const [ShopAddress, setShopAddress] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [email, setEmail] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [FSSAINumber, setFSSAINumber] = useState("");
    const [contactNumber, setContactNumber] = useState("");
const navigate = useNavigate();
    // State for error messages
    const [errors, setErrors] = useState({
        shopName: "",
        contactPerson: "",
        email: "",
        gstNumber: "",
        password: "",
        confirmPassword: "",
        FSSAINumber: "",
        contactNumber: "",
        ShopAddress:""
        
    });

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            shopName: "",
            contactPerson: "",
            email: "",
            gstNumber: "",
            password: "",
            confirmPassword: "",
            FSSAINumber: "",
            contactNumber: "",
            ShopAddress:""
        };

        if (!shopName) {
            newErrors.shopName = "Shop name is required";
            valid = false;
        }
         if (!ShopAddress) {
            newErrors.ShopAddress = "Shop Address is required";
            valid = false;
        }
        if (!contactPerson) {
            newErrors.contactPerson = "Contact person is required";
            valid = false;
        }
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = "Valid email is required";
            valid = false;
        }
        if (!gstNumber || !/^[A-Za-z0-9]{15}$/.test(gstNumber)) {
            newErrors.gstNumber = "GST Number must be 15 characters .";
            valid = false;
        }
        if (!password) {
            newErrors.password = "Password is required";
            valid = false;
        }
        if (password !== confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match";
            valid = false;
        }
        if (!FSSAINumber || !/^\d{14}$/.test(FSSAINumber)) {
            newErrors.FSSAINumber = "FSSAI Number must be exactly 14 digits";
            valid = false;
        }
        if (!contactNumber || !/^\d{10}$/.test(contactNumber)) {
            newErrors.contactNumber = "Contact number must be exactly 10 digits";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleChange = (setter, field) => (e) => {
    const value = e.target.value;
    if (field === "contactNumber" || field === "FSSAINumber") {
        // Allow only numeric values for contact number and FSSAI number
        setter(value.replace(/[^0-9]/g, ""));
    } else {
        setter(value);
    }
    // Clear specific error message when user starts typing
    setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: ""
    }));
};

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const dealerData = {
            shopName,
            contactPerson,
            email,
            gstNumber,
            password,
            confirmPassword,
            FSSAINumber,
            contactNumber,
            CommissionDoneAmount: '',
            ShopAddress
        };

       try {
        const response = await fetch(`${BaseUrl}/Dealer/AddDealer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dealerData),
        });

        const result = await response.json();

        if (result.result) {
            // Display SweetAlert success message
            Swal.fire({
                icon: 'success',
                title: 'Dealer Registered Successfully',
                showConfirmButton: false,
                timer: 1500 // Auto-close after 1.5 seconds
            });

            // Clear form or redirect as needed
            setShopName("");
            setContactPerson("");
            setEmail("");
            setGstNumber("");
            setPassword("");
            setConfirmPassword("");
            setFSSAINumber("");
            setContactNumber("");
            setErrors({
                shopName: "",
                contactPerson: "",
                email: "",
                gstNumber: "",
                password: "",
                confirmPassword: "",
                FSSAINumber: "",
                contactNumber: ""
            });

            // Navigate to the shop page after successful registration
            setTimeout(() => {
                navigate('/dealer/shop');
            }, 1600); // Add a slight delay for SweetAlert animation
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Registration Failed',
                text: result.message,
            });
        }
    } catch (error) {
        console.error("Error:", error);
        Swal.fire({
            icon: 'error',
            title: 'An error occurred',
            text: 'Please try again.',
        });
    }
    };

    return (
        <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }} className="pt-10">
            <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] pl-10 pr-10">
                <div className="p-5 pt-5 bg-white rounded-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="gap-20 md:flex">
                            <div>
                                <p className="text-[16px] font-semibold mt-2">Shop Information</p>
                                <div className="mt-2">
                                    <p>Shop Name</p>
                                    <input
                                        type="text"
                                        className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                                        placeholder="Shop Name"
                                        value={shopName}
                                        onChange={handleChange(setShopName, "shopName")}
                                    />
                                    {errors.shopName && <p className="text-red-500">{errors.shopName}</p>}
                                </div>
                                 <div className="mt-2">
                                    <p>Shop Address</p>
                                    <input
                                        type="text"
                                        className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                                        placeholder="Shop Address"
                                        value={ShopAddress}
                                        onChange={handleChange(setShopAddress, "ShopAddress")}
                                    />
                                    {errors.ShopAddress && <p className="text-red-500">{errors.ShopAddress}</p>}
                                </div>
                                <div className="mt-2">
                                    <p>Contact Person</p>
                                    <input
                                        type="text"
                                        className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                                        placeholder="Contact Person"
                                        value={contactPerson}
                                        onChange={handleChange(setContactPerson, "contactPerson")}
                                    />
                                    {errors.contactPerson && <p className="text-red-500">{errors.contactPerson}</p>}
                                </div>
                                <div className="mt-2">
                                    <p>Contact No</p>
                                    <input
                                        type="text"
                                        className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                                        placeholder="Contact Number"
                      value={contactNumber}
                      maxLength={10}
                                        onChange={handleChange(setContactNumber, "contactNumber")}
                                    />
                                    {errors.contactNumber && <p className="text-red-500">{errors.contactNumber}</p>}
                                </div>
                                <div className="mt-2">
                                    <p>Email ID</p>
                                    <input
                                        type="email"
                                        className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                                        placeholder="Email ID"
                                        value={email}
                                        onChange={handleChange(setEmail, "email")}
                                    />
                                    {errors.email && <p className="text-red-500">{errors.email}</p>}
                                </div>
                            </div>
                            <div className="text-start">
                                <p className="text-[16px] font-semibold mt-2">Business Information</p>
                                <div className="gap-2 md:flex">
                                    <div className="mt-2">
                                        <p>GST Number</p>
                                        <input
                                            type="text"
                                            className="p-2 bg-gray-100 border rounded-xl md:w-[300px] mt-2"
                                            placeholder="GST Number"
                        value={gstNumber}
                        maxLength={15}
                                            onChange={handleChange(setGstNumber, "gstNumber")}
                                        />
                                        {errors.gstNumber && <p className="text-red-500">{errors.gstNumber}</p>}
                                    </div>
                                    
                                    <div className="mt-2">
                                        <p>FSSAI Number</p>
                                        <input
                                            type="text"
                                            className="p-2 bg-gray-100 border rounded-xl md:w-[300px] mt-2"
                                            placeholder="FSSAI Number"
                        value={FSSAINumber}
                        maxLength={14}
                                            onChange={handleChange(setFSSAINumber, "FSSAINumber")}
                                        />
                                        {errors.FSSAINumber && <p className="text-red-500">{errors.FSSAINumber}</p>}
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <p className="text-[16px] font-semibold mt-2">Login Credentials</p>
                                    <div className="mt-2">
                                        <p>Password</p>
                                        <input
                                            type="password"
                                            className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                                            placeholder="Password"
                                            value={password}
                                            onChange={handleChange(setPassword, "password")}
                                        />
                                        {errors.password && <p className="text-red-500">{errors.password}</p>}
                                    </div>
                                    <div className="mt-2">
                                        <p>Confirm Password</p>
                                        <input
                                            type="password"
                                            className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={handleChange(setConfirmPassword, "confirmPassword")}
                                        />
                                        {errors.confirmPassword && <p className="text-red-500">{errors.confirmPassword}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end mt-5">
                            <button type="submit" className="px-4 py-2 text-white bg-blue-500 rounded-lg">
                                Register
                            </button>
                            <Link to="/dealer/shop">
                                <button className="px-4 py-2 ml-2 text-white bg-gray-500 rounded-lg">
                                    Cancel
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
        
    );
}

export default AddShopDealer;
