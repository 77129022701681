import React from 'react';
import { CiCirclePlus } from "react-icons/ci";

function View_categories_model({ isModalOpen, onClose }) {
     if (!isModalOpen) return null;
  return (
   <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"  >
          <div className="relative p-8 bg-white rounded-lg shadow-lg w-[500px]">
              <div className='flex justify-end '>
                  {/* <MdCancel  className='cursor-pointer w-7 h-7'/> */}
              </div>
              <div className='flex justify-between mt-2'>
                  <div>
                      Category
                  </div>
                  <div className='flex gap-2'>
                      <div >
                          <CiCirclePlus  />
                      </div>
                      <div className='text-sm'>
                          Add Category 
                      </div>
                     
                  </div>
              </div>
              <div>
                <div className="mt-2">
                    <div className="relative">
                      <select
                        className="block w-[450px] p-2 pr-8 bg-gray-100 border border-gray-300 rounded-xl appearance-none focus:outline-none focus:border-blue-500"
                        defaultValue="" // Set the default value if needed
                        required // Ensures user selects an option
                      >
                        <option value="" disabled hidden></option>
                        <option value="category1">Category 1</option>
                        <option value="category2">Category 2</option>
                        <option value="category3">Category 3</option>
                        {/* Add more options as needed */}
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-black"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>  
              </div>

               <div className='flex justify-between mt-5'>
                  <div>
                      Sub-Category
                  </div>
                  <div className='flex gap-2'>
                      <div>
                          <CiCirclePlus />
                      </div>
                      <div className='text-sm'>
                         Add Sub-Category
                      </div>
                     
                  </div>
              </div>
              <div>
                <div className="mt-2">
                    <div className="relative">
                      <select
                        className="block w-[450px] p-2 pr-8 bg-gray-100 border border-gray-300 rounded-xl appearance-none focus:outline-none focus:border-blue-500"
                        defaultValue="" // Set the default value if needed
                        required // Ensures user selects an option
                      >
                        <option value="" disabled hidden></option>
                        <option value="category1">Category 1</option>
                        <option value="category2">Category 2</option>
                        <option value="category3">Category 3</option>
                        {/* Add more options as needed */}
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-black"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>  
        </div>
        

        <div className='flex justify-between mt-5'>
                  <div>
                      Sub-Category
                  </div>
                  <div className='flex gap-2'>
                      <div>
                          <CiCirclePlus />
                      </div>
                      <div className='text-sm'>
                         Add Sub-Category
                      </div>
                     
                  </div>
              </div>
                <div className="mt-2">
                    <input
          type="text"
          className="p-2 bg-gray-100 border w-[450px] rounded-lg"
          placeholder="Sub Category"
         
          
        />
                  </div>
              {/* <div className='mt-10'>
                  <p className='text-bold '>Products(6)</p>
              </div>
              <div className='mt-2 border '>
                  <p className='p-2'>Lokwan Wheat</p>
                  <hr></hr>
                       <p className='p-2'>Lokwan Wheat</p>
                  <hr></hr>
                   <p className='p-2'>Lokwan Wheat</p>
                  <hr></hr>

                   <p className='p-2'>Lokwan Wheat</p>
                  <hr></hr>
                   <p className='p-2'>Lokwan Wheat</p>
                  <hr></hr>
                   <p className='p-2'>Lokwan Wheat</p>
                  <hr></hr>
                  
              </div> */}
              <div className='flex justify-end gap-5 mt-20'>
                   <button
         onClick={onClose}
          className="flex gap-2 px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg "
        >
    cancel
        </button> 
                   <button
          onClick={onClose}
          className="flex gap-2 px-4 py-2 mt-4 text-white transition rounded-lg"style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
        >
     Save
        </button>
              </div>
             
       
      </div>
    </div>
  )
}

export default View_categories_model
