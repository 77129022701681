import React, { useRef } from 'react';
import { MdOutlineFileUpload } from "react-icons/md";

const UploadTextFile = (props) => {
  const fileInputRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        if (props.setTextValue) {
          props.setTextValue(fileContent);
        }
        if (props.setProductDetails) {
          props.setProductDetails(prevProductObject => ({
            ...prevProductObject,
            productDescription: fileContent
          }));
        }
      };
      reader.readAsText(file);
    }
  };

  const handleTextAreaChange = (event) => {
    const newValue = event.target.value;
    if (props.setTextValue) {
      props.setTextValue(newValue);
    }
    if (props.setProductDetails) {
      props.setProductDetails(prevProductObject => ({
        ...prevProductObject,
        productDescription: newValue
      }));
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="mt-4">
  <div className="flex flex-col items-start justify-between md:flex-row">
    <p className="mb-2 md:mb-0">Description </p>
    <div className="flex gap-2 text-blue-600 cursor-pointer" onClick={handleUploadClick}>
      <MdOutlineFileUpload className="mt-1" />
      <p>Upload .txt file</p>
    </div>
    <input
      type="file"
      ref={fileInputRef}
      style={{ display: 'none' }}
      onChange={handleFileUpload}
      accept=".txt"
    />
  </div>
  <div className="mt-2">
    <textarea
      className="w-[200px] md:w-[600px] h-32 border-2 p-2 rounded-lg"
      placeholder="Product information"
      value={props.text}
      onChange={handleTextAreaChange}
    />
  </div>
</div>

  );
};

export default UploadTextFile;
