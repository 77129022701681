import axios from 'axios';
import React, { useState } from "react";
import * as XLSX from 'xlsx';
import { BaseUrl } from '../../auth/Url';
const DealerInvoice = ({ isOpen, onClose, selectedDealer, selectedDealerCommission }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Prevent rendering if not open or selectedDealer is not provided
    if (!isOpen || !selectedDealer) return null;

    // const handleDownloadInvoice = async () => {
    //     if (startDate && endDate) {
    //         try {
    //             // Create date objects from selected dates
    //             const start = new Date(startDate);
    //             const end = new Date(endDate);
                
    //             // Set time for start and end dates to avoid timezone issues
    //             start.setUTCHours(0, 0, 0, 0);
    //             end.setUTCHours(23, 59, 59, 999);

    //             // Format dates as YYYY-MM-DD
    //             const formattedStartDate = start.toISOString().split('T')[0];
    //             const formattedEndDate = end.toISOString().split('T')[0];

    //             // API request to download the invoice
    //             const response = await axios.post(
    //                 `${BaseUrl}/Dealer/generate-Dealer-invoice`,
    //                 {
    //                     shopId: selectedDealer.shopId,
    //                     startDate: formattedStartDate,
    //                     endDate: formattedEndDate,
    //                     shopName: selectedDealer.shopName,
    //                     shopContact: selectedDealer.contactNumber,
    //                 },
    //                 {
    //                     responseType: 'blob', // To handle the PDF file response
    //                 }
    //             );

    //             // Create a blob and trigger a download
    //             const blob = new Blob([response.data], { type: 'application/pdf' });
    //             const link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = `Dealer_invoice_${selectedDealer.shopId}.pdf`;
    //             link.click();
    //         } catch (error) {
    //             console.error('Error downloading invoice:', error);
    //             alert('Failed to download the invoice');
    //         }
    //     } else {
    //         alert("Please select a valid date range");
    //     }
    // };

//      const handleDownloadInvoice = async () => {
//        debugger
//     if (startDate && endDate) {
//         try {
//             const start = new Date(startDate + 'T00:00:00Z');
//             const end = new Date(endDate + 'T23:59:59Z');

//             const formattedStartDate = start.toISOString().split('T')[0];
//             const formattedEndDate = end.toISOString().split('T')[0];

//             const response = await axios.post(
//                 `${BaseUrl}/Dealer/generate-Dealer-invoice`,
//                {
//                          shopId: selectedDealer.shopId,
//                          startDate: formattedStartDate,
//                          endDate: formattedEndDate,
//                         shopName: selectedDealer.shopName,
//                      shopContact: selectedDealer.contactNumber,
//                     },
//             );

//             console.log('API response:', response.data); // Debugging log

//             const orders = response.data.orders || response.data; // Adjust based on actual response
//             if (!Array.isArray(orders)) {
//                 throw new Error('Invalid data format: orders is not an array');
//             }

//           const sheetData = orders.map(order => {
//     return order.ProductDetails.map(product => {
//         // Check if product.selection and product.selection.sizes are defined
//         // const sizeQuantity = product.selection && product.selection
//         //     ? Object.entries(product.selection)
//         //         .map(([size, qty]) => `${size}: ${qty}`)
//         //         .join(', ')
//         //     : 'No size data'; // Fallback value if sizes are not available

//         return {
//             'Order ID': order.orderId,
//             'Order Date': new Date(product.OrderDate).toLocaleDateString(),
//             'Product Name': product.ProductName,
//             // s'Size-Quantity': sizeQuantity,
//             'Customer Name': order.customerInfo.CustomerName,
//             'Factory Name': product.SupplierInfo.FactoryName,
//             'Factory FactoryId': product.SupplierInfo.FactoryId,
//             'Shop Email': order.customerInfo.EmailID,
//             'Commission': order.totalCommission
//  ? order.totalCommission
//  : 'N/A', // Check for totalCommission
//         };
//     });
// }).flat();


//             const workbook = XLSX.utils.book_new();
//             const worksheet = XLSX.utils.json_to_sheet(sheetData);
//             XLSX.utils.book_append_sheet(workbook, worksheet, 'Factory Invoice');
//             XLSX.writeFile(workbook, `Shop_invoice_${selectedDealer.shopName}_${selectedDealer.shopId}.xlsx`);
//         } catch (error) {
//             console.error('Error downloading invoice:', error);
//             alert('Failed to download the invoice');
//         }
//     } else {
//         alert('Please select a valid date range');
//     }
    // };
    
    const handleDownloadInvoice = async () => {
    if (startDate && endDate) {
        try {
            const start = new Date(startDate + 'T00:00:00Z');
            const end = new Date(endDate + 'T23:59:59Z');

            const formattedStartDate = start.toISOString().split('T')[0];
            const formattedEndDate = end.toISOString().split('T')[0];

            const response = await axios.post(
                `${BaseUrl}/Dealer/generate-Dealer-invoice`,
                {
                    shopId: selectedDealer.shopId,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    shopName: selectedDealer.shopName,
                    shopContact: selectedDealer.contactNumber,
                }
            );

            console.log('API response:', response.data);

            const orders = response.data.orders || response.data;
            if (!Array.isArray(orders)) {
                throw new Error('Invalid data format: orders is not an array');
            }

            const sheetData = orders.map(order => {
                return order.ProductDetails.flatMap(product => {
                    return product.selection.map(select => ({
                        'Order ID': order.orderId,
                        'Order Date': new Date(product.OrderDate).toLocaleDateString(),
                        'Product Name': product.ProductName,
                        'Bag Size': select.size,
                        'Quantity': select.quantity,
                        'Shop ID': selectedDealer.shopId,
                        'Shop Name': order.customerInfo.CustomerName,
                        'Factory Name': product.SupplierInfo.FactoryName,
                        'Factory ID': product.SupplierInfo.FactoryId,
                        'Shop Email': order.customerInfo.EmailID,
                        'Commission': order.totalCommission || 'N/A',
                    }));
                });
            }).flat();

            // Create workbook and worksheet
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet([]);

            // Add custom header
            XLSX.utils.sheet_add_aoa(worksheet, [
                [`Shop Name: ${selectedDealer.shopName}`],
                [`Shop ID: ${selectedDealer.shopId}`],
                [`Contact: ${selectedDealer.contactNumber}`],
                [`Date Range: ${formattedStartDate} to ${formattedEndDate}`],
                [] // Empty row for spacing
            ], { origin: 0 });

            // Add table data
            XLSX.utils.sheet_add_json(worksheet, sheetData, {
                origin: -1, // Append after existing content
                skipHeader: false
            });

            // Adjust column widths
            worksheet['!cols'] = [
                { wch: 15 }, // Order ID
                { wch: 15 }, // Order Date
                { wch: 30 }, // Product Name
                { wch: 10 }, // Bag Size
                { wch: 10 }, // Quantity
                { wch: 20 }, // Shop Name
                { wch: 20 }, // Factory Name
                { wch: 15 }, // Factory ID
                { wch: 25 }, // Shop Email
                { wch: 15 }, // Commission
            ];

            // Append worksheet to workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Dealer Invoice');

            // Save file with appropriate name
            XLSX.writeFile(workbook, `Shop_invoice_${selectedDealer.shopName}_${selectedDealer.shopId}.xlsx`);
        } catch (error) {
            console.error('Error downloading invoice:', error);
            alert('Failed to download the invoice');
        }
    } else {
        alert('Please select a valid date range');
    }
};


    return (
        // <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        //     <div className="w-1/3 p-6 bg-white rounded shadow-lg">
        //         <h2 className="mb-4 text-xl font-bold">Dealer Details</h2>
        //         <p><strong>Shop Name:</strong> {selectedDealer?.shopName}</p>
        //         <p><strong>Shop ID:</strong> {selectedDealer?.shopId}</p>
        //         <p><strong>Total Commission:</strong> {selectedDealerCommission}</p>
                
        //         {/* Date range picker */}
        //         <div className="mt-4">
        //             <p><strong>Select Date Range:</strong></p>
        //             <div className="flex space-x-4">
        //                 <div>
        //                     <label className="block mb-2">From:</label>
        //                     <input
        //                         type="date"
        //                         value={startDate}
        //                         onChange={(e) => setStartDate(e.target.value)}
        //                         className="p-2 border rounded"
        //                     />
        //                 </div>
        //                 <div>
        //                     <label className="block mb-2">To:</label>
        //                     <input
        //                         type="date"
        //                         value={endDate}
        //                         onChange={(e) => setEndDate(e.target.value)}
        //                         min={startDate} // Ensure the end date is not before the start date
        //                         className="p-2 border rounded"
        //                     />
        //                 </div>
        //             </div>
        //         </div>

        //         {/* Download invoice button */}
        //         <button 
        //             className="px-4 py-2 mt-4 text-white bg-green-500 rounded"
        //             onClick={handleDownloadInvoice}
        //         >
        //             Download Report
        //         </button>

        //         <button 
        //             className="px-4 py-2 mt-4 ml-4 text-white bg-blue-500 rounded" 
        //             onClick={onClose}
        //         >
        //             Close
        //         </button>
        //     </div>
        // </div>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="w-full max-w-lg p-6 mx-4 bg-white rounded shadow-lg">
        <h2 className="mb-4 text-xl font-bold">Dealer Details</h2>
        <p><strong>Shop Name:</strong> {selectedDealer?.shopName}</p>
        <p><strong>Shop ID:</strong> {selectedDealer?.shopId}</p>
        <p><strong>Total Commission:</strong> {selectedDealerCommission}</p>
        
        {/* Date range picker */}
        <div className="mt-4">
            <p><strong>Select Date Range:</strong></p>
            <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1 mb-4 md:mb-0">
                    <label className="block mb-2">From:</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="flex-1">
                    <label className="block mb-2">To:</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={startDate} // Ensure the end date is not before the start date
                        className="w-full p-2 border rounded"
                    />
                </div>
            </div>
        </div>

        {/* Download invoice button */}
        <div className="flex flex-col md:flex-row md:space-x-4">
            <button 
                className="w-full px-4 py-2 mt-4 text-white bg-green-500 rounded hover:bg-green-600 md:w-auto"
                onClick={handleDownloadInvoice}
            >
                Download Report
            </button>

            <button 
                className="w-full px-4 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600 md:w-auto" 
                onClick={onClose}
            >
                Close
            </button>
        </div>
    </div>
</div>


    );
};

export default DealerInvoice;
