
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AakaarLogo from "../../src/asset/AakaarLogo.png";
import Loginbg from "../../src/asset/Loginbg.png";
import Loader from '../Pages/Component/Loader';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { BaseUrl } from './Url';

function Loginpage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showLogo, setShowLogo] = useState(true);
  const [userType, setUserType] = useState(null);
  const [Register, setRegister] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
   const [login, setLogin] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 2000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleUserTypeSelect = (type) => {
    setUserType(type);
  };
const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  
  const handleSubmit = async (e) => {
  setLoader(true);
  e.preventDefault();

  // Perform validation
  if (!email || !password) {
    alert('Please enter both email and password.');
    setLoader(false);
    return;
  }

  // Call API with credentials
  try {
    const response = await fetch(`${BaseUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, userType }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      // if (userType === "Admin") {
      //    setError("You are not Admin Please login with Factory Tab");
      // }
      // if (userType === "Factory") {
      //    setError("You are not Factory Please login with Admin Tab");
      // }
       setError(errorData.message);
      setLoader(false);
      return;
    }

    const data = await response.json();
    // Store token and personalInfo in localStorage
    localStorage.setItem('token', data.token);
    localStorage.setItem('personalInfo', JSON.stringify(data.personalInfo));
    setLoader(false);

    // Check role and navigate accordingly
    const role = data.personalInfo.role;
    if (role === 'Factory') {
      navigate('/FactoryInventory');
    } else {
      navigate('/');
    }
  } catch (error) {
    setTimeout(() => {
      setLoader(false);
    }, 2000); // 2 seconds delay
    setError('Invalid credentials');
  }
};


  const NavigateToRegister = () => {
  navigate('/Register');
}
  if (showLogo) {
    return (
      <div style={{
        backgroundColor: 'rgba(251, 251, 251, 1)',
        backgroundImage: `url(${Loginbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img src={AakaarLogo} alt="Aakaar Logo" className="h-24 w-50" />
      </div>
    );
  }


 
  return (
    <div style={{
      backgroundColor: 'rgba(251, 251, 251, 1)',
      backgroundImage: `url(${Loginbg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }} className='p-2 md:p-0'>
  <div className="w-full max-w-md p-4 mx-4 mt-8 bg-white shadow-xl rounded-xl md:w-1/3 md:mx-auto">
    <div className='flex justify-center'>
      <img src={AakaarLogo} alt="Aakaar Logo" className="w-auto h-24 mt-8" />
    </div>
    <form onSubmit={handleSubmit} className="p-8">
      <h1 className="text-2xl font-bold text-start">LOGIN</h1>
      <p>Hi, Welcome Back! <span className="wave">👋</span></p>
      <div className="mt-5">
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 mt-2 bg-gray-100 border rounded-lg"
          placeholder="Email id"
          required
        />
      </div>

      <div className="mt-4">
        <p>Password <span className="text-red-500">*</span></p>
        <div className="relative">
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            className="w-full p-2 mt-2 bg-gray-100 border rounded-xl"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute text-gray-600 transform -translate-y-1/2 right-2 top-1/2"
          >
            {isPasswordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
          </button>
        </div>
      </div>

      <p className='mt-2 text-center text-red-400'>
        {error !== "" && <>{error}</>}
      </p>

      <div className="flex justify-center mt-6">
        <button
          type="submit"
          className="w-full h-12 text-lg font-semibold text-white transition duration-300 rounded-xl bg-amber-950 hover:bg-amber-900"
        >
          LOGIN
        </button>
      </div>

      {userType !== "Admin" && (
        <div className='mt-4 text-center'>
          <p>New to Aakar? <span className='text-[#0855FF] cursor-pointer' onClick={NavigateToRegister}>Register Now</span></p>
        </div>
      )}
    </form>
  </div>
  <Loader loading={loader} message={"Please Wait Verify the Credentials."} />
</div>

  );
}

export default Loginpage;
