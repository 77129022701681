import React, { useState } from 'react';
import { MdEdit } from "react-icons/md";

function Categories_Edit_model({ isEditModalOpen, onCloseEdit }) {
    const [categorieEdit, setcategorieEdit] = useState(false);
      const [subcategorieEdit, setSubcategorieEdit] = useState(false);

    if (!isEditModalOpen) return null;

    const openEditCategoryModel = () => {
        setcategorieEdit(!categorieEdit);
    };

     const openEditSubCategoryModel = () => {
        setSubcategorieEdit(!subcategorieEdit);
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative p-8 bg-white rounded-lg shadow-lg w-[500px]">
                <div className='flex items-center justify-between'>
                    Grain Haven
                    <MdEdit onClick={openEditCategoryModel} className="cursor-pointer" />
                </div>
                <div>
                    {categorieEdit && (
                        <input
                            type="text"
                            className="p-2 bg-gray-100 border w-[450px] rounded-lg mt-4"
                            placeholder="Category"
                        />
                    )}
                </div>
                <div className='mt-2'>
                     <div className='flex items-center justify-between'>
                   sub categories
                    <MdEdit onClick={openEditSubCategoryModel} className="cursor-pointer" />
                </div>
                 
                  <div className="mt-2">
                    <div className="relative">
                      <select
                        className="block w-[450px] p-2 pr-8 bg-gray-100 border border-gray-300 rounded-xl appearance-none focus:outline-none focus:border-blue-500"
                        defaultValue="" // Set the default value if needed
                        required // Ensures user selects an option
                        
                      >
                        <option value="" disabled hidden></option>
                        <option value="category1">Kg</option>
                        <option value="category2"> Ltr</option>
                        <option value="category3">Category 3</option>
                        {/* Add more options as needed */}
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                 <div>
                    {subcategorieEdit && (
                        <input
                            type="text"
                            className="p-2 bg-gray-100 border w-[450px] rounded-lg mt-4"
                            placeholder="Category"
                        />
                    )}
                </div>
 <div className='flex items-center justify-between mt-2'>
                  Product List
                   
                </div>
                <div className=''>
                     <div>
                  
                        <input
                            type="text"
                            className="p-2 bg-gray-100 border w-[450px] rounded-lg mt-4"
                            placeholder="Category"
                        />
                
                </div>
                 <div>
                  
                        <input
                            type="text"
                            className="p-2 bg-gray-100 border w-[450px] rounded-lg mt-4"
                            placeholder="Category"
                        />
                
                </div>
                 <div>
                  
                        <input
                            type="text"
                            className="p-2 bg-gray-100 border w-[450px] rounded-lg mt-4"
                            placeholder="Category"
                        />
                
                </div>
                 <div>
                  
                        <input
                            type="text"
                            className="p-2 bg-gray-100 border w-[450px] rounded-lg mt-4"
                            placeholder="Category"
                        />
                
                </div>
                
                </div>
               
                <div className='flex justify-end gap-5 mt-20'>
                    <button
                        onClick={onCloseEdit}
                        className="flex gap-2 px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onCloseEdit}
                        className="flex gap-2 px-4 py-2 mt-4 text-white transition rounded-lg"
                        style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Categories_Edit_model;
