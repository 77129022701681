import 'chart.js/auto'; // Ensure chart.js is properly imported
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';

const GraphComponent = ({ AllOrders }) => {
  const [selectedData, setSelectedData] = useState('sales');

  // Function to count completed orders this year by month
  const countCompletedOrdersThisYear = () => {
    const currentYear = new Date().getFullYear();
    const completedOrders = Array(12).fill(0); // Initialize an array for all months (0-11)

    AllOrders.forEach(order => {
      const orderDate = new Date(order.OrderDate);
      const year = orderDate.getFullYear();

      if (year === currentYear) {
        const monthIndex = orderDate.getMonth(); // Get month index (0-11)
        const isCompleted = order.ProductDetails.some(
          product => product.dispatchShippingDetails.DispatchStatus === 'Completed'
        );

        if (isCompleted) {
          completedOrders[monthIndex] += 1; // Increment the count for the respective month
        }
      }
    });

    return completedOrders;
  };

  // Prepare data for the chart
  const prepareChartData = () => {
    const completedOrders = countCompletedOrdersThisYear();
    const labels = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const data = completedOrders; // Use the completed orders directly

    return {
      labels,
      datasets: [
        {
          label: 'Completed Orders',
          data,
          borderColor: '#D4AF37', // Gold color for the line
          backgroundColor: 'rgba(212, 175, 55, 0.2)', // Light gold for the fill
          borderWidth: 2,
          fill: true,
        },
      ],
    };
  };

  const data = prepareChartData();

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#4B5563', // Gray color for y-axis labels
        },
      },
      x: {
        ticks: {
          color: '#4B5563', // Gray color for x-axis labels
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Show the legend
      },
    },
  };

  return (
   <div className='md:w-[638px] md:h-[338px] bg-[#FFFFFF] rounded-lg mt-4 p-4 shadow '>
      <div className='flex items-center justify-between'>
        <h2 className='text-lg font-semibold'>Completed Orders This Year</h2>
        <div className='flex items-center'>
         
          <p className='bg-[#D4AF37] text-white px-2 py-2 rounded-lg hidden md:visible' >Purchase and Sale Records</p>
        </div>
      </div>
      <Line data={data} options={options} />
    </div>

  );
};

export default GraphComponent;
