import React, { useEffect, useRef, useState } from 'react';
import { FaPlus } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from 'react-router-dom';
import ShopDealerEdit from '../Component/ShopDealerEdit';
import { BaseUrl } from '../../auth/Url';
import Swal from 'sweetalert2';
function Shop_Dealer_List() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dealers, setDealers] = useState([]);
  const [filteredDealers, setFilteredDealers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [ModelAction, setModelAction] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [DealerInfo, setDealerInfo] = useState(null);
  // State variables for counts
  const [allShopsCount, setAllShopsCount] = useState(0);
  const [activeShopsCount, setActiveShopsCount] = useState(0);
  const [inactiveShopsCount, setInactiveShopsCount] = useState(0);
  const dropdownRef = useRef(null);

  // Handle clicks outside of the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null); // Close the dropdown if clicked outside
    }
  };

  useEffect(() => {
    if (dropdownOpen !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);
  const toggleDropdown = (index) => {
    if (dropdownOpen === index) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(index);
    }
  };

  // Fetch dealers data
  useEffect(() => {
   FetchDealer()
  }, []);
  const FetchDealer = () => {
     fetch(`${BaseUrl}/Dealer/Getdealers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result && data.statusCode === 200) {
          setDealers(data.data.reverse()); // Save dealers data in state
        }
      })
      .catch((error) => console.error('Error fetching dealers:', error));
  }

  useEffect(() => {
    // Calculate counts whenever dealers data changes
    const totalShops = dealers.length;
    const activeShops = dealers.filter(dealer => dealer.isAllowLogin).length;
    const inactiveShops = totalShops - activeShops;

    setAllShopsCount(totalShops);
    setActiveShopsCount(activeShops);
    setInactiveShopsCount(inactiveShops);
  }, [dealers]);

  useEffect(() => {
    // Filter dealers based on the search query
    setFilteredDealers(
      dealers.filter(dealer =>
        dealer.shopName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, dealers]);

  const handleLoginAccessToggle = async (shopId, isAllowLogin) => {
    try {
      const response = await fetch(`${BaseUrl}/Dealer/LoginAccess`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ shopId, isAllowLogin: !isAllowLogin }),
      });

      const data = await response.json();

      if (data.result && data.statusCode === 200) {
        // Update the local state with the new isAllowLogin value
        setDealers((prevDealers) =>
          prevDealers.map((dealer) =>
            dealer.shopId === shopId ? { ...dealer, isAllowLogin: !isAllowLogin } : dealer
          )
        );
      } else {
        console.error('Error updating login access:', data.message);
      }
    } catch (error) {
      console.error('Error updating login access:', error);
    }
  };

const openModal = (value, dealerInfo) => {
  // Set the dealer info
  setDealerInfo(dealerInfo);

  // Determine action based on value and set the modal action accordingly
  if (value === 1) {
    setModelAction("View");
  } else {
    setModelAction("Edit");
  }

  // Once DealerInfo and ModelAction are set, open the modal
  setIsModalOpen(true);
};


  const closeModal = () => {
    setIsModalOpen(false);
    setDropdownOpen(null);
  };
const handleDeleteDealer = async (shopId) => {
  // Show a confirmation dialog
  const confirmDelete = await Swal.fire({
    title: 'Are you sure?',
    text: 'Do you want to delete this dealer?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  });

  // If confirmed, proceed with deletion
  if (confirmDelete.isConfirmed) {
    try {
      const response = await fetch(`${BaseUrl}/Dealer/dealerDelete/${shopId}`, {
        method: 'DELETE',
      });

      const result = await response.json();
      if (response.ok) {
        Swal.fire('Deleted!', result.message, 'success');
        // Optionally refresh the dealer list here if needed
        setDropdownOpen(null)
        FetchDealer()
      } else {
        Swal.fire('Error!', result.message, 'error');
      }
    } catch (error) {
      console.error('Error deleting dealer:', error);
      Swal.fire('Error!', 'Failed to delete dealer', 'error');
    }
  }
};

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] p-5 md:p-0">
        <div className="items-center justify-between pt-5 md:flex">
          <div>
            <p className="text-[14px]">Shops Summary</p>
            <div className="flex bg-white rounded-lg  w-[350px] overflow-x-auto md:w-[500px] justify-evenly mt-2">
              <div className="p-5">
                <p>All Shops</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{allShopsCount}</p>
              </div>
              <div className="flex items-center justify-center">
                <div className="w-px h-10 bg-gray-400"></div>
              </div>
              <div className="p-5">
                <p>Active</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{activeShopsCount}</p>
              </div>
              <div className="flex items-center justify-center">
                <div className="w-px h-10 bg-gray-400"></div>
              </div>
              <div className="p-5">
                <p>In-Active</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{inactiveShopsCount}</p>
              </div>
            </div>
          </div>
          <Link
            to="/Add_Dealer_Shops"
            className="flex gap-2 px-4 py-2 mt-5 text-white rounded-lg md:mt-0"
            style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }}
          >
            <FaPlus className="mt-1" />
            <p>Add new Shop</p>
          </Link>
        </div>

        <div className="mt-5">
          <input
            type="text"
            placeholder="Search by shop name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 mb-4 border rounded-lg"
          />
          {/* <table className="w-full rounded-lg">
            <thead className="font-normal text-white rounded-lg" style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
              <tr>
                <th className="p-2 font-normal rounded-tl-lg">Shop ID</th>
                <th className="p-2 font-normal">Shop Name</th>
                <th className="p-2 font-normal">Email</th>
                <th className="p-2 font-normal">Contact Person</th>
                <th className="p-2 font-normal">GST Number</th>
                <th className="p-2 font-normal">FSSAI Number</th>
                <th className="p-2 font-normal">Access</th>
                <th className="p-2 font-normal">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredDealers.length > 0 ? (
                filteredDealers.map((dealer, index) => (
                  <tr key={dealer.shopId} className='bg-white'>
                    <td className="px-4 py-2 border">{dealer.shopId}</td>
                    <td className="px-4 py-2 border">{dealer.shopName}</td>
                    <td className="px-4 py-2 border">{dealer.email}</td>
                    <td className="px-4 py-2 border">{dealer.contactPerson}</td>
                    <td className="px-4 py-2 border">{dealer.gstNumber}</td>
                    <td className="px-4 py-2 border">{dealer.FSSAINumber}</td>
                    <td className="p-2 text-center border">
                      <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
                        <input
                          type="checkbox"
                          name="toggle"
                          id={`toggle-${dealer.shopId}`}
                          checked={dealer.isAllowLogin}
                          onChange={() => handleLoginAccessToggle(dealer.shopId, dealer.isAllowLogin)}
                          className="sr-only"
                        />
                        <label
                          htmlFor={`toggle-${dealer.shopId}`}
                          className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${dealer.isAllowLogin ? 'bg-green-400' : ''}`}
                        >
                          <span
                            className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${dealer.isAllowLogin ? 'translate-x-6 bg-green-400' : ''}`}
                          ></span>
                        </label>
                      </div>
                    </td>
                    <td className="px-4 py-2 border">
                      <HiDotsVertical className="flex justify-center cursor-pointer" onClick={() => toggleDropdown(index)} />
                     {dropdownOpen === index && (
        <div ref={dropdownRef} className="absolute right-0 z-10 w-32 p-2 bg-white border rounded shadow-lg">
          <div className="p-2 cursor-pointer hover:bg-gray-200" onClick={() => openModal(1, dealer)}>
            View
          </div>
          <div className="p-2 cursor-pointer hover:bg-gray-200" onClick={() => openModal(0, dealer)}>
            Edit
          </div>
          <div className="p-2 cursor-pointer hover:bg-gray-200" onClick={() => handleDeleteDealer(dealer.shopId)}>
            Delete
          </div>
        </div>
      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="py-4 text-center">No records found</td>
                </tr>
              )}
            </tbody>
          </table> */}
          <div className="overflow-x-auto">
  <table className="w-full min-w-full rounded-lg">
    <thead className="font-normal text-white rounded-lg" style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
      <tr>
        <th className="p-2 font-normal text-left rounded-tl-lg">Shop ID</th>
        <th className="p-2 font-normal text-left">Shop Name</th>
        <th className="p-2 font-normal text-left">Email</th>
        <th className="p-2 font-normal text-left">Contact Person</th>
        <th className="p-2 font-normal text-left">GST Number</th>
        <th className="p-2 font-normal text-left">FSSAI Number</th>
        <th className="p-2 font-normal text-center">Access</th>
        <th className="p-2 font-normal text-center rounded-tr-lg">Action</th>
      </tr>
    </thead>
    <tbody>
      {filteredDealers.length > 0 ? (
        filteredDealers.map((dealer, index) => (
          <tr key={dealer.shopId} className="bg-white">
            <td className="px-2 py-2 border sm:px-4">{dealer.shopId}</td>
            <td className="px-2 py-2 border sm:px-4">{dealer.shopName}</td>
            <td className="px-2 py-2 border sm:px-4">{dealer.email}</td>
            <td className="px-2 py-2 border sm:px-4">{dealer.contactPerson}</td>
            <td className="px-2 py-2 border sm:px-4">{dealer.gstNumber}</td>
            <td className="px-2 py-2 border sm:px-4">{dealer.FSSAINumber}</td>
            <td className="p-2 text-center border">
              <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
                <input
                  type="checkbox"
                  name="toggle"
                  id={`toggle-${dealer.shopId}`}
                  checked={dealer.isAllowLogin}
                  onChange={() => handleLoginAccessToggle(dealer.shopId, dealer.isAllowLogin)}
                  className="sr-only"
                />
                <label
                  htmlFor={`toggle-${dealer.shopId}`}
                  className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${dealer.isAllowLogin ? 'bg-green-400' : ''}`}
                >
                  <span
                    className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${dealer.isAllowLogin ? 'translate-x-6 bg-green-400' : ''}`}
                  ></span>
                </label>
              </div>
            </td>
            <td className="relative px-4 py-2 border">
              <HiDotsVertical className="flex justify-center cursor-pointer" onClick={() => toggleDropdown(index)} />
              {dropdownOpen === index && (
                <div ref={dropdownRef} className="absolute right-0 z-10 w-32 p-2 bg-white border rounded shadow-lg">
                  <div className="p-2 cursor-pointer hover:bg-gray-200" onClick={() => openModal(1, dealer)}>
                    View
                  </div>
                  <div className="p-2 cursor-pointer hover:bg-gray-200" onClick={() => openModal(0, dealer)}>
                    Edit
                  </div>
                  <div className="p-2 cursor-pointer hover:bg-gray-200" onClick={() => handleDeleteDealer(dealer.shopId)}>
                    Delete
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="8" className="py-4 text-center">No records found</td>
        </tr>
      )}
    </tbody>
  </table>
</div>

        </div>
      </div>

      {/* Modal component */}
      <ShopDealerEdit isModalOpen={isModalOpen} onClose={closeModal} ModelAction={ModelAction} DealerInfo={DealerInfo} FetchDealer={FetchDealer} />
    </div>
  );
}

export default Shop_Dealer_List;
