import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BaseUrl } from '../../auth/Url';
import TestimonialModal from './TestimonialsModel';
import Swal from 'sweetalert2';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState({
    id: null,
    review: '',
    shopName: '',
    personName: '',
  });
    console.log(currentTestimonial)

  // Fetch testimonials from the API
  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/GetTestimonials`);
      setTestimonials(response.data.testimonial);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const openModal = () => {
    setCurrentTestimonial({ id: null, review: '', shopName: '', personName: '' });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

const handleSubmit = async () => {
  try {
    let response;
    if (currentTestimonial.id === null) {
      response = await axios.post(`${BaseUrl}/AddTestimonials`, currentTestimonial);
      setTestimonials([...testimonials, response.data]);

      // SweetAlert for success on adding testimonial
      Swal.fire({
        title: 'Success!',
        text: 'Testimonial added successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      response = await axios.put(`${BaseUrl}/UpdateTestimonials/${currentTestimonial.id}`, currentTestimonial);
      setTestimonials(testimonials?.map(testimonial =>
        testimonial._id === currentTestimonial.id ? response.data : testimonial
      ));

      // SweetAlert for success on updating testimonial
      Swal.fire({
        title: 'Success!',
        text: 'Testimonial updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    }

    closeModal(); // Close the modal after successful submission
  } catch (error) {
    console.error("Error submitting testimonial:", error);

    // SweetAlert for error handling
    Swal.fire({
      title: 'Error!',
      text: 'There was an issue submitting the testimonial. Please try again later.',
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }
};


  const handleEdit = (testimonial) => {
    setCurrentTestimonial({
      id: testimonial._id, // Assuming you want to store the `_id` for editing
      review: testimonial.review,
      shopName: testimonial.shopName,
      personName: testimonial.personName,
    });
    setModalIsOpen(true);
  };
  const handleDelete = async (id) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await axios.delete(`${BaseUrl}/DeleteTestimonials/${id}`);
        fetchTestimonials(); // Refresh the testimonials list after deletion

        Swal.fire(
          'Deleted!',
          'The testimonial has been deleted.',
          'success'
        );
      } catch (error) {
        console.error("Error deleting testimonial:", error);
        Swal.fire(
          'Error!',
          'There was a problem deleting the testimonial.',
          'error'
        );
      }
    }
  });
};

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh", padding: "20px" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className='flex justify-between p-5'>
          <h2 className="mb-4 font-bold md:text-2xl">Testimonials</h2>
          <button onClick={openModal} className='p-2 text-white bg-orange-400 border rounded-lg'>
            Add Testimonial
          </button>
        </div>

        {/* <table className="min-w-full overflow-hidden bg-white rounded-lg shadow-md">
          <thead className="font-normal text-white rounded-lg" style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
            <tr>
              <th className="px-4 py-3">Review</th>
              <th className="px-4 py-3">Shop Name</th>
              <th className="px-4 py-3">Person Name</th>
              <th className="px-4 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {testimonials.map(testimonial => (
              <tr key={testimonial._id} className="border-b hover:bg-gray-100">
                <td className="px-4 py-3 w-[500px]" dangerouslySetInnerHTML={{ __html: testimonial.review }} />
                <td className="px-4 py-3">{testimonial.shopName}</td>
                <td className="px-4 py-3">{testimonial.personName}</td>
                <td className="px-4 py-3">
                  <button onClick={() => handleEdit(testimonial)} className="p-2 mr-2 border btn btn-edit hover:bg-gray-400 hover:text-white">
                    Edit
                  </button>
                  <button onClick={() => handleDelete(testimonial._id)} className="p-2 border btn btn-delete hover:bg-gray-400 hover:text-white">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table> */}
        <table className="min-w-full overflow-hidden bg-white rounded-lg shadow-md">
  <thead className="font-normal text-white rounded-lg" style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
    <tr>
      <th className="px-2 py-3 text-xs sm:px-4 sm:text-base">Review</th>
      <th className="px-2 py-3 text-xs sm:px-4 sm:text-base">Shop Name</th>
      <th className="px-2 py-3 text-xs sm:px-4 sm:text-base">Person Name</th>
      <th className="px-2 py-3 text-xs sm:px-4 sm:text-base">Actions</th>
    </tr>
  </thead>
  <tbody>
    {testimonials?.map(testimonial => (
      <tr key={testimonial._id} className="border-b hover:bg-gray-100">
        <td className="px-2 py-3 w-[300px] sm:w-[500px] text-xs sm:text-base" dangerouslySetInnerHTML={{ __html: testimonial.review }} />
        <td className="px-2 py-3 text-xs sm:text-base">{testimonial.shopName}</td>
        <td className="px-2 py-3 text-xs sm:text-base">{testimonial.personName}</td>
        <td className="px-2 py-3 text-xs sm:text-base">
          <button
            onClick={() => handleEdit(testimonial)}
            className="p-1 mr-1 text-xs border sm:p-2 sm:mr-2 btn btn-edit hover:bg-gray-400 hover:text-white sm:text-base"
          >
            Edit
          </button>
          <button
            onClick={() => handleDelete(testimonial._id)}
            className="p-1 text-xs border sm:p-2 btn btn-delete hover:bg-gray-400 hover:text-white sm:text-base"
          >
            Delete
          </button>
        </td>
      </tr>
    ))}
            {
              testimonials.length <= 0 && (
                <p>no Testimonials Found</p>
              )
            }
  </tbody>
</table>


        <TestimonialModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          currentTestimonial={currentTestimonial}
          setCurrentTestimonial={setCurrentTestimonial}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default Testimonials;
