import axios from 'axios';
import React, { useState } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { BaseUrl } from '../../auth/Url';

function EmailConfirmation({ isModalOpen, onClose, ProductObject }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  if (!isModalOpen) return null;
  const HandleSaveAndSendQute = async () => {
   setLoading(true)

    try {
      // Replace 'your-token' with the actual token you want to pass
     
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      const response = await axios.post( `${BaseUrl}/Quatation/AddQuate`, ProductObject, config);
      console.log(response.data);
      onClose()// Handle success response
      setLoading(false)
      navigate('/quotation')
      

      // Optionally, reset form or show success message
    } catch (error) {
      console.error('Error saving quotation:', error);
       setLoading(false)
      // Handle error response
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative p-5 bg-white rounded-lg shadow-lg w-[600px]">
              <p className='flex justify-end'><MdOutlineCancel  className='w-5 h-5 cursor-pointer'  onClick={onClose}/></p>
              <p className='font-semibold text-center'>Confirm Email Sending</p>
              <div className='mt-10'>
                  <p>Are you sure you want to send this quotation email to the customer?</p>
                  <div className='mt-5'>
                      <p className='mt-1'>Customer Name: <b>{ProductObject.ShopInformation.ShopName}</b></p>
            <p className='mt-1'>Quotation ID: <b>{ProductObject.AddDetails.QuotationID}</b></p>
            <p className='mt-1'> Customer Email ID:<b>{ProductObject.ShopInformation.EmailID}</b> </p>
                  </div>
                  <div className='mt-5'>
                    <p className='font-semibold'>Please review the details carefully before proceeding.</p> 
                </div>


              </div>
              <div className='flex justify-end gap-5 mt-8'>
                   <button
          onClick={onClose}
          className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg"
        >
        Cancel
        </button>
                   <button
          onClick={HandleSaveAndSendQute}
          className="px-4 py-2 mt-4 text-white transition rounded-lg"style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
        >
        Send Quotation
        </button>
              </div>
             
       
      </div>
      {loading && <Loader loading={loading} message={"Please Wait Sending Email..."} />}
    </div>
  )
}

export default EmailConfirmation
