import JoditEditor from 'jodit-react';
import React, { useState } from 'react';
import Modal from 'react-modal';

// Set Modal App Element
Modal.setAppElement('#root');

// Custom styles for the modal
const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Adjust width as needed
    maxWidth: '600px', // Max width for larger screens
    padding: '20px',
    borderRadius: '8px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark background with opacity
  },
};

const TestimonialModal = ({ modalIsOpen, closeModal, currentTestimonial, setCurrentTestimonial, handleSubmit }) => {
  const [errors, setErrors] = useState({});
  console.log(errors)
 const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check the field name and limit the input to 20 characters for both shopName and personName
    if ((name === 'shopName' || name === 'personName') && value.length <= 20) {
      setCurrentTestimonial({ ...currentTestimonial, [name]: value });
    } else if (name !== 'shopName' && name !== 'personName') {
      // If it's any other field, allow the value to update without restrictions
      setCurrentTestimonial({ ...currentTestimonial, [name]: value });
    }
  };

  const handleEditorChange = (newContent) => {
    setCurrentTestimonial({ ...currentTestimonial, review: newContent });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentTestimonial.shopName) {
      newErrors.shopName = 'Shop Name is required';
    }
    if (!currentTestimonial.personName) {
      newErrors.personName = 'Person Name is required';
    }
    if (!currentTestimonial.review) {
      newErrors.review = 'Review is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // return true if no errors
  };

  const handleSubmitClick = () => {
    if (validateFields()) {
      handleSubmit();
      closeModal();
      setErrors({})
    }
  };
  const HandleClose = () => {
    closeModal()
    setErrors({})
  }
  return (
    <div className='h-[500px] overflow-auto p-10'>
  <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customModalStyles}
        contentLabel="Testimonial Modal"
       
  >
    <h2 className="mb-4 text-lg font-bold text-center sm:text-left">
      {currentTestimonial.id ? 'Edit Testimonial' : 'Add Testimonial'}
    </h2>
    <div className="space-y-4">
      <label className="block">
        Shop Name:
        <input
          type="text"
          name="shopName"
          value={currentTestimonial.shopName}
          onChange={handleInputChange}
          className="block w-full p-2 mb-2 text-sm border border-gray-300 rounded sm:text-base"
          required
        />
        {errors.shopName && <p className="mb-2 text-sm text-red-500">{errors.shopName}</p>}
      </label>

      <label className="block">
        Person Name:
        <input
          type="text"
          name="personName"
          value={currentTestimonial.personName}
          onChange={handleInputChange}
          className="block w-full p-2 mb-2 text-sm border border-gray-300 rounded sm:text-base"
          required
        />
        {errors.personName && <p className="mb-2 text-sm text-red-500">{errors.personName}</p>}
      </label>

      <label className="block">
        Review:
        <JoditEditor
          value={currentTestimonial.review}
          onChange={handleEditorChange}
          className="mb-4"
        />
        {errors.review && <p className="mb-2 text-sm text-red-500">{errors.review}</p>}
      </label>
    </div>

    <div className="flex justify-end mt-4 space-x-2">
      <button
        onClick={handleSubmitClick}
        className="p-2 text-sm text-white bg-orange-400 border rounded-lg sm:text-base"
      >
        {currentTestimonial.id ? 'Update Testimonial' : 'Add Testimonial'}
      </button>
      <button
        onClick={HandleClose}
        className="p-2 text-sm text-white bg-gray-500 border rounded-lg sm:text-base"
      >
        Cancel
      </button>
    </div>
  </Modal>
</div>

   
  );
};

export default TestimonialModal;
