// import React from 'react'

// function FactoryDashboard() {
//   return (
//    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
//       <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
//               hii
//      </div>
//     </div>
//   )
// }

// export default FactoryDashboard
import React, { useEffect, useState } from 'react';
import { FaBagShopping } from "react-icons/fa6";
import { IoAddOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
// import DashboardHomelogo from "../../asset/DashboardHomelogo.png";
import DashboardHomelogo from "../../src/asset/DashboardHomelogo.png";
import HomeDelete from "../../src/asset/HomeDelete.png";
import Industry from "../../src/asset/Industry.png";
import shoppingiconDashboard from "../../src/asset/shoppingiconDashboard.png";
// import { fetchAllOrders } from '../../Service/OrderManagment/OrderManagmentApi';
// import GraphComponent from '../Component/DashboardChart';
import { fetchAllOrders } from '../Service/OrderManagment/OrderManagmentApi';
import GraphComponent from '../Pages/Component/DashboardChart';
import { BaseUrl } from '../auth/Url';
function FactoryDashboard() {
    const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const factoryId = JSON.parse(localStorage.getItem('personalInfo')).factoryId;
  console.log(orders)
  const [countsData, setCountsData] = useState({
    factoryId: '',
    pendingDispatchCount: 0,
    deliveredCount: 0,
    shippedCount: 0,
    refundCount: 0
  });

  // useEffect(() => {
  //   const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
  //     const token = localStorage.getItem('token');
  //     const factoryId = personalInfo?.factoryId;

  //   // Fetch the API data
  //   const fetchCounts = async () => {
  //     try {
  //       const response = await fetch(`http://localhost:5000/DashboardCount/countsApi/${factoryId}`);
  //       const result = await response.json();
  //       // Store the data in state
  //       if (result.success) {
  //         setCountsData(result);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching the counts:', error);
  //     }
  //   };

  //   fetchCounts();
  // }, []); 
 useEffect(() => {
   // Fetch the API data
   const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
   const factoryId = personalInfo?.factoryId;
    const fetchCounts = async () => {
      try {
        const response = await fetch(`${BaseUrl}/DashboardCount/countsApi/${factoryId}`);
        const result = await response.json();
        // Store the data in state
        if (result.success) {
          setCountsData(result.data);
        }
      } catch (error) {
        console.error('Error fetching the counts:', error);
      }
    };

    fetchCounts();
  }, []);
   useEffect(() => {
  const getOrders = async () => {
    try {
      const response = await fetchAllOrders();
      // Reverse the list to get the latest orders first
      const reversedOrders = response.OrderList.reverse();
      
      // Filter orders where at least one product's SupplierInfo.FactoryId matches the factoryId
      const filteredOrders = reversedOrders.filter(order => 
        order.ProductDetails.some(product => product.SupplierInfo.FactoryId === factoryId)
      );
      
      // Store only the filtered orders
      setOrders(filteredOrders);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  };

  getOrders();
}, []);
    
   
  const flattenedOrders = orders
  .flatMap(order =>
    order.ProductDetails.map(productDetail => ({
      ...order,
      ...productDetail
    }))
  )
    .filter(product => product.SupplierInfo?.FactoryId === factoryId); 
  

    console.log(flattenedOrders, "flattenedOrders")
    const NavigateViewOrder = (orderId, productId) => {
    navigate("/View_order", { state: { orderId, productId } });
    }
     const countOrders = () => {
  let allOrdersCount = 0;
  let pendingCount = 0;
  let duePaymentCount = 0;
  let completedCount = 0;

  flattenedOrders.forEach(order => {
    allOrdersCount += 1; // Increment for all orders

    // Check if any product in ProductDetails has a pending status
    const hasPending = order.ProductDetails.some(product => 
      product.dispatchShippingDetails.DispatchStatus === 'pending'
    );

    if (hasPending) {
      pendingCount += 1; // Increment if at least one product is pending
    }

    // Check for due payment where dispatch status is completed
    const hasDuePayment = order.ProductDetails.some(product => {
      return product.dispatchShippingDetails.DispatchStatus === 'Completed' && product.Duepayment
 === '0';
    });

    if (hasDuePayment) {
      duePaymentCount += 1; // Increment if any product has due payment of 0 and completed status
    }

    // Check if any product in ProductDetails is completed
    const hasCompleted = order.ProductDetails.some(product => 
      product.dispatchShippingDetails.DispatchStatus === 'Completed'
    );

    if (hasCompleted) {
      completedCount += 1; // Increment if at least one product is completed
    }
  });

  return { allOrdersCount, pendingCount, duePaymentCount, completedCount };
};

// Usage
const { allOrdersCount, pendingCount, duePaymentCount, completedCount } = countOrders();

 const naviagteAddDetails = (data, id) => {
    navigate("/Fill_Order_details", { state: { data, id } });
  }
  return (
      <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
              {/* <div className='flex gap-5 p-5'>
                  <Link className='w-[309px] h-[159px]  bg-[#28C76F] border rounded-lg hover:scale-105 cursor-pointer  ' to='/factory' title='Factories'>
                      <div className='p-2'>
                          <img src={Industry} alt="Wheat" className="w-[36px] h-[36px] bg-[#FFFFFF29] p-2 rounded-lg" />
                      </div>
                      <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>Number of Factories</p>
                      </div>
                      <div className='p-2'>
                          <p className='text-white'><span className='text-[24px]'>54</span> <span className='text-[12px]'>+0.00%</span></p>
                      </div>
                     
                  </Link>
                   <Link className='w-[309px] h-[159px]  bg-[#FB3232] border rounded-lg   hover:scale-105  cursor-pointer ' to='/dealer/shop'  title='Shops'>
                      <div className='p-2'>
                          <img src={DashboardHomelogo} alt="Wheat" className="w-[36px] h-[36px] bg-[#FFFFFF29] p-2 rounded-lg" />
                      </div>
                      <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>Number of Shops</p>
                      </div>
                      <div className='p-2'>
                          <p className='text-white'><span className='text-[24px] '>162</span> <span className='text-[12px]'>+0.00%</span></p>
                      </div>
                  </Link>
                   <Link className='w-[423px] h-[159px]  bg-[#4C3F35] border rounded-lg  hover:scale-105  cursor-pointer ' to='/manageinventory'  title='All Product'>
                       <div className='p-2' >
                          <img src={HomeDelete} alt="Wheat" className="w-[30px] h-[30px] bg-[#FFFFFF29] p-2 rounded-lg" />
                      </div>
                      <div className='flex gap-20'>
                          <div>
                                <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>All Products</p>
                      </div>
                      <div className='p-2'>
                          <p className='text-white'><span className='text-[24px] '>18</span> <span className='text-[12px]'>+0.00%</span></p>
                      </div>
                          </div>
                          <div>
                                <div className='p-2 mt-5'>
                          <p className='text-sm text-white'>Active</p>
                      </div>
                      <div className='p-2'>
                          <p className='text-white'><span className='text-[24px] '>18</span> <span className='text-[12px]'>+0.00%</span></p>
                      </div>
                          </div>
                      </div>
                     
                  </Link>
              </div> */}
              

              <div className='gap-5 pt-2 pb-5 pl-5 pr-5 md:flex'>
                  <div>
                      <div className='md:w-[638px] md:h-[145px] bg-[#FFFFFF] rounded-lg'>
                         <div className='flex items-center justify-between p-2'>
                              <div className='flex items-center space-x-2'>
                                  <div   className="w-[36px] h-[36px] bg-[#FFCC9129] p-2 rounded-lg" >
                                      <FaBagShopping />
                                  </div>
    
    <p className="text-black text-[14px] font-semibold">Total Orders</p>
  </div>
  
</div>
                <div>
      <div className='gap-20 p-5 md:flex'>
        
        <div>
          <p className='text-[12px] text-[#8B8D97]'>Pending Dispatch Orders</p>
                    <p className='text-[20px] mt-2 font-semibold'>{countsData.pendingDispatchCount||0}</p>
                  </div>
                  <div>
          <p className='text-[12px] text-[#8B8D97]'>Dispatch Orders</p>
          <p className='text-[20px] mt-2 font-semibold'>{countsData.shippedCount||0}</p>
        </div>
        {/* <div>
          <p className='text-[12px] text-[#8B8D97]'>Due Payment</p>
          <p className='text-[20px] mt-2 font-semibold'>{duePaymentCount}</p>
        </div> */}
        <div>
          <p className='text-[12px] text-[#8B8D97]'>Completed Delivered Orders</p>
          <p className='text-[20px] mt-2 font-semibold'>{countsData.deliveredCount||0}</p>
        </div>
      </div>
    </div>
                      </div>
                      <div className='md:w-[638px] h-[145px] bg-[#FFFFFF] rounded-lg mt-4'>
                         <div className='flex items-center justify-between p-2'>
                              <div className='flex items-center space-x-2'>
                                  <div   className="w-[36px] h-[36px] bg-[#FFCC9129] p-2 rounded-lg" >
                                      <FaBagShopping />
                                  </div>
    {/* <img 
      src={Industry} 
      alt="Wheat" 
      className="w-[36px] h-[36px] bg-[#FFCC9129] p-2 rounded-lg" 
      style={{ filter: 'invert(0)' }} // Ensures the image color is black
    /> */}
    <p className="text-black text-[14px] font-semibold">Total Returns</p>
  </div>
 
</div>
                          <div >
                              <div className='flex gap-24 p-5'>
                                  {/* <div>
                                      <p className='text-[14px] text-[#8B8D97]'>Pending Returns</p>
                                      <p className='text-[20px] mt-2 font-semibold'>154</p>
                                  </div>
                                   <div>
                                      <p className='text-[14px] text-[#8B8D97]'>Rejected</p>
                                      <p className='text-[20px] mt-2 font-semibold'>154</p>
                                  </div> */}
                                   <div>
                                      <p className='text-[12px] text-[#8B8D97]'>Refunded List count</p>
                                      <p className='text-[20px] mt-2 font-semibold'>{countsData.refundCount||0}</p>
                                  </div>
                                
                              </div>
</div>
                      </div>
                     <div className='w-full lg:w-[638px] md:h-[338px] bg-[#FFFFFF] rounded-lg mt-4'>
                          <GraphComponent AllOrders={flattenedOrders} />
                      </div>
                  </div>
                  <div>
                       <div className='md:w-[423px] h-[666px] bg-[#FFFFFF] rounded-lg p-5'>
                          <p>Recent Dispatch Order</p>
                         
                              
                                     {/* <div>
  {flattenedOrders.filter(record =>
   
    record.ProductDetails[0].dispatchShippingDetails.DispatchStatus === 'pending'
  ).length === 0 ? (
    // No pending orders section
    <div className='pt-32 text-center'>
      <div className='flex justify-center'>
        <div className='p-2'>
          <img src={shoppingiconDashboard} alt="Wheat" className="w-[140px] h-[140px] bg-[#FFFFFF29] p-2 rounded-lg " />
        </div>
      </div>
      <div>
        <p className='text-[20px]'>No Dispatch Order Yet?</p>
      
        <div className='flex justify-center mt-5'>
         
        </div>
      </div>
    </div>
  ) : (
    // Table for pending orders section
    <div className='mt-2'>
      <table className='w-full rounded-lg'>
        <thead className='font-normal text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
          <tr>
            <th className='p-2 font-normal rounded-tl-lg'>Order Id</th>
            <th className='p-2 font-normal'>Customer</th>
            <th className='p-2 font-normal'>Product</th>
            <th className='p-2 font-normal rounded-tr-lg w-[30px]'>Action</th>
          </tr>
        </thead>
        <tbody className='bg-white'>
          {flattenedOrders.filter(record =>
          
            record.ProductDetails[0].dispatchShippingDetails.DispatchStatus === 'pending'
          ).map((record, index) => (
            <React.Fragment key={index}>
              <tr className='border-b-2 cursor-pointer'>
                <td className="px-4 py-2 text-sm" title={record.orderId}>
                  {record.orderId.length > 10 ? `${record.orderId.slice(0, 10)}...` : record.orderId}
                </td>
                <td className='p-2 text-sm text-center'>{record.customerInfo.ShopName}</td>
                <td className='p-2 text-sm text-center'>
                  {record.ProductDetails[0].ProductName.length > 10 ? `${record.ProductDetails[0].ProductName.slice(0, 10)}...` : record.ProductDetails[0].ProductName}
                </td>
                <td className='p-2'>
                  <div className='flex justify-center gap-2'>
                    <button
                             onClick={() => naviagteAddDetails(record, record._id)}
                           
                            // to={"/Fill_Order_Details"}
                    className={`  border-[#0855FF] rounded-lg  text-[#0855FF]  border p-1 cursor-pointer text-[15px] hover:bg-[#0855FF] hover:text-white text-sm`}
                   
                  >
                  <span className=''>Add Details</span>
                  </button>  
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )}
</div> */}
              
              <div>
  {flattenedOrders.filter(record =>
    record.ProductDetails[0].dispatchShippingDetails.DispatchStatus === 'pending'
  ).length === 0 ? (
    // No pending orders section
    <div className='pt-32 text-center'>
      <div className='flex justify-center'>
        <div className='p-2'>
          <img src={shoppingiconDashboard} alt="Wheat" className="w-[140px] h-[140px] bg-[#FFFFFF29] p-2 rounded-lg" />
        </div>
      </div>
      <div>
        <p className='text-[20px]'>No Dispatch Order Yet?</p>
        <div className='flex justify-center mt-5'></div>
      </div>
    </div>
  ) : (
    // Table for pending orders section
    <div className='mt-2'>
      <div className='overflow-x-auto'>
        <table className='w-full rounded-lg'>
          <thead className='font-normal text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
            <tr>
              <th className='p-2 font-normal rounded-tl-lg'>Order Id</th>
              <th className='p-2 font-normal'>Customer</th>
              <th className='p-2 font-normal'>Product</th>
              <th className='p-2 font-normal rounded-tr-lg w-[30px]'>Action</th>
            </tr>
          </thead>
          <tbody className='bg-white'>
            {flattenedOrders.filter(record =>
              record.ProductDetails[0].dispatchShippingDetails.DispatchStatus === 'pending'
            ).map((record, index) => (
              <React.Fragment key={index}>
                <tr className='border-b-2 cursor-pointer'>
                  <td className="px-4 py-2 text-sm" title={record.orderId}>
                    {record.orderId.length > 10 ? `${record.orderId.slice(0, 10)}...` : record.orderId}
                  </td>
                  <td className='p-2 text-sm text-center'>{record.customerInfo.ShopName}</td>
                  <td className='p-2 text-sm text-center'>
                    {record.ProductDetails[0].ProductName.length > 10 ? `${record.ProductDetails[0].ProductName.slice(0, 10)}...` : record.ProductDetails[0].ProductName}
                  </td>
                  <td className='p-2'>
                    <div className='flex justify-center gap-2'>
                      <button
                        onClick={() => naviagteAddDetails(record, record._id)}
                        className={`border-[#0855FF] rounded-lg text-[#0855FF] border p-1 cursor-pointer text-[15px] hover:bg-[#0855FF] hover:text-white text-sm`}
                      >
                        <span className=''>Add Details</span>
                      </button>  
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )}
</div>


                      </div>
                  </div>
              </div>
          </div>
    
    </div>
  )
}

export default FactoryDashboard
