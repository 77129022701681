import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../auth/Url';
import Swal from 'sweetalert2';
function CountPage() {
  const [counts, setCounts] = useState({
    totalProducts: "",
    teamMembers: "",
    satisfiedCustomers: "",
    awardsWon: "",
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [errors, setErrors] = useState({});

  // Fetch counts from the backend
  useEffect(() => {
   

    fetchCounts();
  }, []);
 const fetchCounts = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/counts`);
        setCounts(response.data);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };
 const handleInputChange = (e) => {
  const { name, value } = e.target;

  // Allow only numeric characters (0-9) and set to "" if input is empty
  const numericValue = value.replace(/[^0-9]/g, ''); // Remove any non-numeric characters

  // Update state with the numeric value or empty string
  setCounts((prevCounts) => ({
    ...prevCounts,
    [name]: numericValue === "" ? "" : Number(numericValue),
  }));
};

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!counts.totalProducts) newErrors.totalProducts = "Total Products is required";
    if (!counts.teamMembers) newErrors.teamMembers = "Team Members is required";
    if (!counts.satisfiedCustomers) newErrors.satisfiedCustomers = "Satisfied Customers is required";
    if (!counts.awardsWon) newErrors.awardsWon = "Awards Won is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  const validationErrors = validate();

  if (Object.keys(validationErrors).length === 0) {
    try {
      await axios.post(`${BaseUrl}/Addcounts`, counts);
      setModalVisible(false); // Close modal on success
      setErrors({}); // Clear any previous errors
      fetchCounts(); // Fetch the updated counts

      // Show SweetAlert success message
      Swal.fire({
        title: 'Success!',
        text: 'Counts have been successfully added.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error updating counts:', error);
      
      // Show SweetAlert error message
      Swal.fire({
        title: 'Error!',
        text: 'Failed to add counts. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  } else {
    setErrors(validationErrors);
    
    // Show SweetAlert validation error message
    Swal.fire({
      title: 'Validation Error!',
      text: 'Please correct the errors in the form before submitting.',
      icon: 'warning',
      confirmButtonText: 'OK'
    });
  }
};


  const handleModalClose = () => {
      setModalVisible(false);
      fetchCounts()
    setErrors({}); // Clear errors when closing the modal
  };

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh", padding: "20px" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
       
       <div className='flex flex-col justify-between p-2 md:flex-row'>
  <div><h1 className="mb-4 text-xl font-bold md:text-2xl">Counts Overview</h1></div>
  <div className="mt-2 md:mt-0">
    <button 
      onClick={() => setModalVisible(true)} 
      className="p-2 text-white bg-orange-400 border rounded-lg"
    >
      Update Counts
    </button>
  </div>
</div>

<table className="min-w-full bg-white border border-gray-300">
  <thead className="font-normal text-white rounded-lg" style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
    <tr>
      <th className="px-2 py-2 border md:px-4">Category</th>
      <th className="px-2 py-2 border md:px-4">Count</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="px-2 py-2 border md:px-4">Total Products</td>
      <td className="px-2 py-2 border md:px-4">{counts?.totalProducts}</td>
    </tr>
    <tr>
      <td className="px-2 py-2 border md:px-4">Team Members</td>
      <td className="px-2 py-2 border md:px-4">{counts?.teamMembers}</td>
    </tr>
    <tr>
      <td className="px-2 py-2 border md:px-4">Satisfied Customers</td>
      <td className="px-2 py-2 border md:px-4">{counts?.satisfiedCustomers}</td>
    </tr>
    <tr>
      <td className="px-2 py-2 border md:px-4">Awards Won</td>
      <td className="px-2 py-2 border md:px-4">{counts?.awardsWon}</td>
    </tr>
  </tbody>
</table>

{/* Modal */}
{modalVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="w-full max-w-md p-4 bg-white rounded shadow-md md:p-6">
      <h2 className="mb-4 text-lg font-bold">Update Counts</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Total Products:</label>
          <input 
            type="number" 
            name="totalProducts" 
            value={counts?.totalProducts}
            onChange={handleInputChange} 
            className="w-full px-3 py-2 border md:px-4"
          />
          {errors.totalProducts && <p className="text-sm text-red-500">{errors.totalProducts}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Team Members:</label>
          <input 
            type="number" 
            name="teamMembers"
            value={counts?.teamMembers}
            onChange={handleInputChange} 
            className="w-full px-3 py-2 border md:px-4"
          />
          {errors.teamMembers && <p className="text-sm text-red-500">{errors.teamMembers}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Satisfied Customers:</label>
          <input 
            type="number" 
            name="satisfiedCustomers"
            value={counts?.satisfiedCustomers}
            onChange={handleInputChange} 
            className="w-full px-3 py-2 border md:px-4"
          />
          {errors.satisfiedCustomers && <p className="text-sm text-red-500">{errors.satisfiedCustomers}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Awards Won:</label>
          <input 
            type="number" 
            name="awardsWon"
            value={counts?.awardsWon}
            onChange={handleInputChange} 
            className="w-full px-3 py-2 border md:px-4"
          />
          {errors.awardsWon && <p className="text-sm text-red-500">{errors.awardsWon}</p>}
        </div>
        <div className="flex justify-between">
          <button 
            type="submit" 
            className="px-3 py-2 text-white bg-blue-500 rounded md:px-4"
          >
            Submit
          </button>
          <button 
            type="button" 
            onClick={handleModalClose} 
            className="px-3 py-2 bg-gray-300 rounded md:px-4"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
)}

      </div>
    </div>
  );
}

export default CountPage;
