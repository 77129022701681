import React, { useState } from 'react';
import { MdCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

function Product_status({ isModalOpen, onClose, orderStatus }) {
    console.log(orderStatus,"orderStatus")
     const [isToggled, setIsToggled] = useState(false);
   const [reason, setReason] = useState('');
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate(); // Initialize the navigate function from useNavigate

  

    if (!isModalOpen) return null;

    const handleReasonChange = (e) => {
        
    setReason(e.target.value);
    if (showError && e.target.value.trim() !== '') {
      setShowError(false);
    }
    };
    
    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    const handleNaviagteFillOrdersScreen = () => {
    //    if ( reason!== '') {
    //   setShowError(false);
    //    } else {
    //        setShowError(true);
    //        return
    // }
      navigate("/Fill_Order_Details");
    }
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative p-8 bg-white rounded-lg shadow-lg w-[800px]">
            <div className='flex items-center justify-between p-2'>
  <p className='flex-grow text-lg font-semibold text-center'>Order Issue Notification</p>
  <MdCancel className='h-7 w-7'  onClick={onClose}/>
              </div>
              <div className='h-[300px] overflow-auto'>
                  {
                 orderStatus
.map((value, index) => {
                      return (
                          <>
                            <div className='mt-5 mb-5'>
                              <p className='text-sm font-semibold'>Product ID:<span className='text-blue-600'> {value.productId}</span></p>
                  <p className='mt-2 text-sm font-semibold'>Current Status:<span className='text-orange-400'>{value.status}</span></p>
 {value.status !== "accepted" && (
        <div className='flex gap-16 mt-5'>
          <div>
            <div>
              <p className='text-sm font-semibold'>Specify Reason</p>
            </div>
            <div className='mt-2'>
              <input
                type="text"
                value={reason}
                onChange={handleReasonChange}
                className={`p-2 bg-gray-100 border rounded-xl w-[455px] ${showError ? 'border-red-500' : ''}`}
                placeholder="write a Reason"
              />
             
            </div> 
          </div>
          <div className="flex justify-between gap-5 mt-10 text-center">
            <div className="mt-2 text-sm">Send Email?</div>
            <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
              <input
                type="checkbox"
                name="toggle"
                id="toggle"
                checked={isToggled}
                onChange={handleToggle}
                className="w-5 h-5 sr-only"
              />
              <label
                htmlFor="toggle"
                className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${isToggled ? 'bg-green-400' : ''}`}
              >
                <span
                  className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${isToggled ? 'translate-x-6' : ''}`}
                ></span>
              </label>
            </div>
          </div>
          
                                      </div>
                                      
      )}
        {showError && <p className="mt-2 text-red-500">Required this field</p>}          
                 
</div>
                              <hr></hr>
                      </>  
                          
                      )
                    
                  })
              }
              </div>
              
        
              
              <div className='flex justify-end gap-5 mt-10'>
                   <button
          onClick={onClose}
          className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg"
        >
        Cancel
        </button>
                   <button
           onClick={handleNaviagteFillOrdersScreen}
          className="px-4 py-2 mt-4 text-white transition rounded-lg"style={{ backgroundColor: 'rgba(32, 22, 57, 1)' }}
        >
        UPDATE & CONTINUE
        </button>
              </div>
             
       
      </div>
    </div>
  )
}

export default Product_status
