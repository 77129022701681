import React, { useEffect, useState } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BaseUrl } from '../../auth/Url';
import Loader from './Loader';
function AddInstallMent({ isModalOpen, onClose, data,action }) {
  console.log(data,action)
   const [paymentMode, setPaymentMode] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [chequeNumber, setChequeNumber] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [cashError, setcashError] = useState('');
  const [DateError, setDateError] = useState('');
  const [SelectError, setSelectError] = useState('');
   const [loading, setLoading] = useState(false);
  console.log(date,"date")
  const navigate = useNavigate();
  const handlePaymentModeChange = (event) => {
      setDateError('')
    setcashError('')
  setPaymentMode('');
  setBankName('');
  setAccountHolderName('');
  setAccountNumber('');
  setIfscCode('');
  setChequeNumber('');
  setPaymentAmount('');
  setDate('');
  
  setErrors({
    bankName: '',
    accountHolderName: '',
    accountNumber: '',
    chequeNumber: '',
    paymentAmount: ''
  });
  
  setBankErrors({
    bankName: '',
    accountHolderName: '',
    accountNumber: '',
    ifscCode: '',
    paymentAmount: ''
  });

    setPaymentMode(event.target.value);
  };

  const [bankErrors, setBankErrors] = useState({
    bankName: '',
    accountHolderName: '',
    accountNumber: '',
    ifscCode: '',
    paymentAmount: '',
  });
  const [errors, setErrors] = useState({
  bankName: '',
  accountHolderName: '',
  accountNumber: '',
  chequeNumber: '',
  paymentAmount: ''
});
  useEffect(() => {
  if (action === 'paymentRequest' && data) {
    console.log('Action is paymentRequest and data is available:', data);

    if (data.paymentMethod === "bank" && data.bankDetails) {
      setPaymentMode(data.paymentMethod);
      setBankName(data.bankDetails.bankName || '');
      setAccountHolderName(data.bankDetails.accountHolderName || '');
      setAccountNumber(data.bankDetails.accountNumber || '');
      setIfscCode(data.bankDetails.ifscCode || '');
      setPaymentAmount(data.bankDetails.paymentAmount || '');

      // Check if the transactionDate exists and is valid
      const transactionDate = data.transactionDate;
      if (transactionDate) {
        // Convert to YYYY-MM-DD format
        const date = new Date(transactionDate);
        const formattedDate = date.toLocaleDateString('en-CA'); // 'en-CA' formats as YYYY-MM-DD
        setDate(formattedDate);
        console.log('Formatted Date:', formattedDate); // Example output: 2024-09-19
      } else {
        setDate('');
        console.log('Transaction Date is missing or invalid.');
      }
    } else if (data.paymentMethod === "cheque" && data.chequeDetails) {
      setPaymentMode(data.paymentMethod);
      setBankName(data.chequeDetails.bankName || '');
      setAccountHolderName(data.chequeDetails.accountHolderName || '');
      setAccountNumber(data.chequeDetails.accountNumber || '');
      setChequeNumber(data.chequeDetails.chequeNumber || '');
      setPaymentAmount(data.chequeDetails.paymentAmount || '');
      const transactionDate = data.transactionDate;
      if (transactionDate) {
        // Convert to YYYY-MM-DD format
        const date = new Date(transactionDate);
        const formattedDate = date.toLocaleDateString('en-CA'); // 'en-CA' formats as YYYY-MM-DD
        setDate(formattedDate);
        console.log('Formatted Date:', formattedDate); // Example output: 2024-09-19
      } else {
        setDate('');
        console.log('Transaction Date is missing or invalid.');
      }
    } else if (data.paymentMethod === "cash" && data.cashDetails) { 
      setPaymentMode(data.paymentMethod);
        setPaymentAmount(data.cashDetails.paymentAmount || '');
      const transactionDate = data.transactionDate;
      if (transactionDate) {
        // Convert to YYYY-MM-DD format
        const date = new Date(transactionDate);
        const formattedDate = date.toLocaleDateString('en-CA'); // 'en-CA' formats as YYYY-MM-DD
        setDate(formattedDate);
        console.log('Formatted Date:', formattedDate); // Example output: 2024-09-19
      } else {
        setDate('');
        console.log('Transaction Date is missing or invalid.');
      }
    } else{
      console.log('Bank details are missing or invalid.');
    }
  }
}, [action, data]);

  
  const HandleClose = () => {
    setDateError('')
    setcashError('')
  setPaymentMode('');
  setBankName('');
  setAccountHolderName('');
  setAccountNumber('');
  setIfscCode('');
  setChequeNumber('');
  setPaymentAmount('');
  setDate('');
  
  setErrors({
    bankName: '',
    accountHolderName: '',
    accountNumber: '',
    chequeNumber: '',
    paymentAmount: ''
  });
  
  setBankErrors({
    bankName: '',
    accountHolderName: '',
    accountNumber: '',
    ifscCode: '',
    paymentAmount: ''
  });

  onClose();
};

  const validateBankDetails = () => {
   
  const latestTransaction = (data?.TransactionData && data.TransactionData.length > 0) 
  ? data.TransactionData[data.TransactionData.length - 1] 
  : null; // or handle it as needed

    if (action === 'paymentRequest') {
    if (!latestTransaction) {
        console.error("No transaction data found.");
        // return null; // or some fallback UI
    }
    } else {
        if (!latestTransaction) {
        console.error("No transaction data found.");
       return null; // or some fallback UI
    }
}
  

    const duePaymentFromLatestTransaction = parseFloat(latestTransaction?.Duepayment);
    let errors = {
        bankName: '',
        
        paymentAmount: '',
    };
    
    if (!bankName) {
        errors.bankName = 'Bank Name is required';
    }
    
    if (!paymentAmount) {
        errors.paymentAmount = 'Payment Amount is required';
    }
     if (Number(paymentAmount) > duePaymentFromLatestTransaction) {
       errors.paymentAmount = `Payment amount must be at least ${duePaymentFromLatestTransaction} 
                to cover the due payment.`;
               
            }

    setBankErrors(errors);
    
    // Return true if no errors
    return Object.values(errors).every(error => error === '');
  };
   
  const validateFields = () => {
    debugger
    const latestTransaction = (data?.TransactionData && data.TransactionData.length > 0) 
  ? data.TransactionData[data.TransactionData.length - 1] 
  : null; // or handle it as needed


      if (action === 'paymentRequest') {
    if (!latestTransaction) {
        console.error("No transaction data found.");
        // return null; // or some fallback UI
    }
    } else {
        if (!latestTransaction) {
        console.error("No transaction data found.");
       return null; // or some fallback UI
    }
}

    const duePaymentFromLatestTransaction = parseFloat(latestTransaction?.Duepayment);
  let validationErrors = {};

  if (!bankName) validationErrors.bankName = 'Bank Name is required';
 
    if (!paymentAmount || !/^[0-9]*$/.test(paymentAmount)) validationErrors.paymentAmount = 'Payment Amount must be a valid number';
     if (Number(paymentAmount) > duePaymentFromLatestTransaction) {
                validationErrors.paymentAmount =`Payment amount must be at least ${duePaymentFromLatestTransaction} to cover the due payment.`;
               
            }

  setErrors(validationErrors);

  return Object.keys(validationErrors).length === 0;
};

  const handleSubmit = async (event) => {
    debugger
    event.preventDefault();
    setLoading(true)
    if (paymentMode === '') {
      setSelectError("Please Select the Payment Method")
       setLoading(false)
      return
    }
const latestTransaction = (data?.TransactionData && data.TransactionData.length > 0) 
  ? data.TransactionData[data.TransactionData.length - 1] 
  : null; // or handle it as needed


     if (action === 'paymentRequest') {
    if (!latestTransaction) {
        console.error("No transaction data found.");
        // return null; // or some fallback UI
    }
    } else {
        if (!latestTransaction) {
          console.error("No transaction data found.");
           setLoading(false)
       return null; // or some fallback UI
    }
}

    const duePaymentFromLatestTransaction = parseFloat(latestTransaction?.Duepayment);
    if (paymentMode === 'cash') {
            // Check if payment amount is provided
            if (!paymentAmount) {
              setcashError('Payment amount is required.');
               setLoading(false)
                return;
            }
            // Check if payment amount is a positive number
            if (isNaN(paymentAmount) || Number(paymentAmount) <= 0) {
              setcashError('Payment amount must be a positive number.');
               setLoading(false)
                return;
            }

            // Check if payment amount is less than the due payment amount
             if (Number(paymentAmount) > duePaymentFromLatestTransaction) {
                setcashError(`Payment amount must be at least ${duePaymentFromLatestTransaction} to cover the due payment.`);
                 setLoading(false)
                return;
            }
            // Clear error if all validations are successful
            setcashError('');
            // Proceed with your submission logic here
            console.log('Processing cash payment:', paymentAmount);
            // Add your payment processing logic here
    }
     if (paymentMode === 'bank') {
        const isValid = validateBankDetails();
       if (!isValid) {
           setLoading(false)
            // Optionally handle the case when the form is invalid
            return;
       }
       
        if (Number(paymentAmount) > duePaymentFromLatestTransaction) {
          setcashError(`Payment amount must be at least ${duePaymentFromLatestTransaction} to cover the due payment.`);
           setLoading(false)
                return;
            }
        // Proceed with the payment request
    }
      if (paymentMode === 'cheque') {
        const isValid = validateFields();
        if (!isValid) {
           setLoading(false)
            // Optionally handle the case when the form is invalid
            return;
       }
       
       
        // Proceed with the payment request
    }
    if (paymentMode === 'Discount') {
      if (!paymentAmount) {
        setcashError('Payment amount is required.');
         setLoading(false)
                return;
            }
            // Check if payment amount is a positive number
            if (isNaN(paymentAmount) || Number(paymentAmount) <= 0) {
              setcashError('Payment amount must be a positive number.');
               setLoading(false)
                return;
            }

            // Check if payment amount is less than the due payment amount
             if (Number(paymentAmount) > duePaymentFromLatestTransaction) {
               setcashError(`Payment amount must be at least ${duePaymentFromLatestTransaction} to cover the due payment.`);
                setLoading(false)
                return;
            }
            // Clear error if all validations are successful
            setcashError('');
            // Proceed with your submission logic here
            console.log('Processing cash payment:', paymentAmount);
    }
      if (!date) {
        setDateError('Date is required.');
         setLoading(false)
            return;
        }
  
    if (action !== "paymentRequest") {
      const latestTransaction = data?.TransactionData[data?.TransactionData.length - 1];

  if (!latestTransaction) {
    console.error("No transaction data found.");
     setLoading(false)
    return;
  }


  const duePaymentFromLatestTransaction = parseFloat(latestTransaction.Duepayment);

  // Calculate the new due payment
  const duePaymentAmount = duePaymentFromLatestTransaction - paymentAmount;


    const transactionData = {
      orderId: data.orderId, // Example order ID
      PaymentDoneAmount: paymentAmount,
      PaymentMethod: paymentMode,
      Duepayment:duePaymentAmount.toString(), // Example value
      Total: data.Total, // Example total
      transactionDate: new Date(date).toISOString(),
    };

    if (paymentMode === 'bank') {
      transactionData.BankDetails = {
        BankName: bankName,
        AccountHolderName: accountHolderName,
        AccountNumber: accountNumber,
        IFSCCode: ifscCode,
        PaymentAmount: paymentAmount
      };
    } else if (paymentMode === 'cheque') {
      transactionData.chequeDetails = {
        BankName: bankName,
        AccountHolderName: accountHolderName,
        ChequeNumber: chequeNumber,
         AccountNumber: accountNumber,
        PaymentAmount: paymentAmount
      };
    }else if (paymentMode === 'cash') {
      transactionData.cashDetails = {    
        PaymentAmount: paymentAmount
      };
    }else if (paymentMode === 'Discount') {
      transactionData.DiscountDetails = {    
        PaymentAmount: paymentAmount
      };
    }
   console.log(transactionData,"transactionData")
    try {
      const response = await fetch(`${BaseUrl}/Addtransactions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transactionData),
      });

      const result = await response.json();
      console.log('Success:', result);
      // navigate("/transactionrecords")
      if (result.statusCode === 200) {
         setLoading(false)
                Swal.fire({
                    title: 'Transaction Successful!',
                    text: `Transaction for Order ID ${data.orderId} has been updated.`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                  // Optionally navigate after alert is closed
                  
                    navigate("/transactionrecords");
                    HandleClose();
                });
            }
    } catch (error) {
       setLoading(false)
      console.error('Error:', error);
    }
    } else {
      
    const transactionData = {
      orderId: data.orderId, // Example order ID
      PaymentDoneAmount: paymentAmount,
      PaymentMethod: data.paymentMethod,
      Duepayment : (data.duePayment - data.paymentDoneAmount).toString(),
 // Example value
      Total: data.Total, // Example total
      transactionDate: data.transactionDate,
    };

    if (data.paymentMethod === 'bank') {
      transactionData.BankDetails = {
        BankName: bankName,
        AccountHolderName: accountHolderName,
        AccountNumber: accountNumber,
        IFSCCode: ifscCode,
        PaymentAmount: paymentAmount
      };
    } else if (data.paymentMethod === 'cheque') {
      transactionData.chequeDetails = {
        BankName: bankName,
        AccountHolderName: accountHolderName,
        ChequeNumber: chequeNumber,
         AccountNumber: accountNumber,
        PaymentAmount: paymentAmount
      };
    }else if (data.paymentMethod === 'cash') {
      transactionData.cashDetails = {    
        PaymentAmount: paymentAmount
      };
    }else if (data.paymentMethod === 'Discount') {
      transactionData.DiscountDetails = {    
        PaymentAmount: paymentAmount
      };
    }
console.log(transactionData,"transactionData")
    try {
      const response = await fetch(`${BaseUrl}/Addtransactions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transactionData),
      });

      const result = await response.json();
      console.log('Success:', result);
       if (result.statusCode===200) {
      console.log('Transaction Success:', result);

      // If the transaction is successful, call the delete payment API
      const deleteResponse = await fetch(`${BaseUrl}/payments/PaymentSuccess/${data.orderId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const deleteResult = await deleteResponse.json();

     if (deleteResult.statusCode === 200) {
            console.log('Delete Success:', deleteResult);

            // Show SweetAlert for delete success
            Swal.fire({
                title: 'Payment Request Accepted!',
                text: `Payment request for Order ID ${data.orderId} has been accepted successfully.`,
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(() => {
                // Navigate to transaction records after closing the alert
                navigate('/transactionrecords');
              HandleClose();
              window.location.reload()
            });
        } else {
            console.error('Delete Error:', deleteResult);
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred while deleting the payment request.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }
      navigate("/transactionrecords")
       HandleClose()
      
    } catch (error) {
      console.error('Error:', error);
    }
    }
  
  };
  console.log(data,"data")
  if (!isModalOpen) return null;
const latestTransaction = data?.TransactionData?.[data.TransactionData.length - 1];
  const duePayment = latestTransaction?.Duepayment;
  
  const handlePaymentAmountChange = (e) => {
        const value = e.target.value;
        // Allow only numeric values (including decimal point)
        const regex = /^[0-9]*\.?[0-9]*$/; // Matches only numbers and optional decimal point
        if (regex.test(value) || value === '') {
            setPaymentAmount(value);
        }
  };
 
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
     <div className="relative p-8 bg-white rounded-lg shadow-lg h-[600px] overflow-y-auto p-2">
        <div className='flex items-center'>
          <p className='flex-1 text-center text-[20px]'>
  { action === 'paymentRequest' ? 'Approve Installment' : 'Add Installment' }
</p>

          <p><MdOutlineCancel className='w-5 h-5 cursor-pointer'   onClick={HandleClose}/></p>
        </div>
        <div className='p-5'>
           <div className='flex gap-5 '>
          <div>
            <label className="block">Payment Mode<span className="text-red-500 ">*</span></label>
            <div className="mt-1">
              <div className="relative">
                <select
              name="paymentMode"
              value={paymentMode}
              onChange={handlePaymentModeChange}
              className="block md:w-[500px] p-2 pr-8 bg-gray-100 border border-gray-300 rounded-xl appearance-none focus:outline-none focus:border-blue-500"
                    required
                     disabled={action === 'paymentRequest'}
            >
              <option value="" disabled hidden>Select Payment Mode</option>
              <option value="cash">Cash</option>
              <option value="bank">Bank</option>
                    <option value="cheque">cheque</option>
                     <option value="Discount">Discount</option>
            </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg className="w-5 h-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
              </div>
              {SelectError && <p className="mt-2 text-sm text-red-500">{SelectError}</p>}
          </div>
          
  
         
         
          </div>
         {paymentMode === 'cheque' && (
      <>
        <div className="flex mt-2 space-x-4">
          <div className="flex-1">
            <p>Bank Name<span className="text-red-500 ">*</span></p>
            <input
              type="text"
              className="w-full p-2 bg-gray-100 border rounded-lg"
              placeholder="Enter Bank Name"
              value={bankName}
              onChange={(e) => {
                setBankName(e.target.value);
                // Remove error on typing
                setErrors((prevErrors) => ({ ...prevErrors, bankName: '' }));
              }}
              disabled={action === 'paymentRequest'}
            />
            {errors.bankName && <p className="text-sm text-red-500">{errors.bankName}</p>}
          </div>

          <div className="flex-1">
            <p>Account Holder Name</p>
            <input
              type="text"
              className="w-full p-2 bg-gray-100 border rounded-lg"
              placeholder="Enter Account Holder Name"
              value={accountHolderName}
              onChange={(e) => {
                setAccountHolderName(e.target.value);
                // Remove error on typing
                setErrors((prevErrors) => ({ ...prevErrors, accountHolderName: '' }));
              }}
              disabled={action === 'paymentRequest'}
            />
            {errors.accountHolderName && <p className="text-sm text-red-500">{errors.accountHolderName}</p>}
          </div>
        </div>

        <div className="flex mt-4 space-x-4">
          <div className="flex-1">
            <p>Account Number</p>
            <input
              type="text"
              className="w-full p-2 bg-gray-100 border rounded-lg"
              placeholder="Enter Account Number"
              value={accountNumber}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || /^[0-9]*$/.test(value)) {
                  setAccountNumber(value);
                  setErrors((prevErrors) => ({ ...prevErrors, accountNumber: '' }));
                }
              }}
              disabled={action === 'paymentRequest'}
            />
            {errors.accountNumber && <p className="text-sm text-red-500">{errors.accountNumber}</p>}
          </div>

          <div className="flex-1">
            <p>Cheque Number</p>
            <input
              type="text"
              className="w-full p-2 bg-gray-100 border rounded-lg"
              placeholder="Enter Cheque Number"
              value={chequeNumber}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || /^[0-9]*$/.test(value)) {
                  setChequeNumber(value);
                  setErrors((prevErrors) => ({ ...prevErrors, chequeNumber: '' }));
                }
              }}
              disabled={action === 'paymentRequest'}
            />
            {errors.chequeNumber && <p className="text-sm text-red-500">{errors.chequeNumber}</p>}
          </div>
        </div>

        <div className="flex mt-4 space-x-4">
          <div className="flex-1">
            <p>Payment Amount<span className="text-red-500 ">*</span></p>
            <input
              type="text"
              className="w-full p-2 bg-gray-100 border rounded-lg"
              placeholder="Enter Payment Amount"
              value={paymentAmount}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || /^[0-9]*$/.test(value)) {
                  setPaymentAmount(value);
                  setErrors((prevErrors) => ({ ...prevErrors, paymentAmount: '' }));
                }
              }}
              disabled={action === 'paymentRequest'}
            />
            {errors.paymentAmount && <p className="text-sm text-red-500">{errors.paymentAmount}</p>}
          </div>
        </div>
      </>
    )}
{paymentMode === 'bank' && (
    <>
        <div className="flex mt-2 space-x-4">
            <div className="flex-1">
                <p>Bank Name<span className="text-red-500 ">*</span></p>
                <input
                    type="text"
                    className="w-full p-2 bg-gray-100 border rounded-lg"
                    placeholder="Enter Bank Name"
                    value={bankName}
                    onChange={(e) => {
                        setBankName(e.target.value);
                        // Clear the error for this field
                        setBankErrors((prevErrors) => ({ ...prevErrors, bankName: '' }));
                    }}
                    disabled={action === 'paymentRequest'}
                />
                {bankErrors.bankName && <p className="text-sm text-red-500">{bankErrors.bankName}</p>}
            </div>
            <div className="flex-1">
                <p>Account Holder Name</p>
                <input
                    type="text"
                    className="w-full p-2 bg-gray-100 border rounded-lg"
                    placeholder="Enter Account Holder Name"
                    value={accountHolderName}
                    onChange={(e) => {
                        setAccountHolderName(e.target.value);
                        // Clear the error for this field
                        setBankErrors((prevErrors) => ({ ...prevErrors, accountHolderName: '' }));
                    }}
                    disabled={action === 'paymentRequest'}
                />
                {bankErrors.accountHolderName && <p className="text-sm text-red-500">{bankErrors.accountHolderName}</p>}
            </div>
        </div>

        <div className="flex mt-4 space-x-4">
            <div className="flex-1">
                <p>Account Number</p>
            <input
    type="text" // Use type text to implement custom validation
    className="w-full p-2 bg-gray-100 border rounded-lg"
    placeholder="Enter Account Number"
    value={accountNumber}
    onChange={(e) => {
        const value = e.target.value;
        // Allow only digits and empty input (to clear the field)
        if (value === '' || /^[0-9]*$/.test(value)) {
            setAccountNumber(value);
            // Clear the error for this field
            setBankErrors((prevErrors) => ({ ...prevErrors, accountNumber: '' }));
        }
    }}
    disabled={action === 'paymentRequest'}
/>
                {bankErrors.accountNumber && <p className="text-sm text-red-500">{bankErrors.accountNumber}</p>}
            </div>
            <div className="flex-1">
                <p>IFSC Code</p>
                <input
                    type="text"
                    className="w-full p-2 bg-gray-100 border rounded-lg"
                    placeholder="Enter IFSC Code"
                    value={ifscCode}
                    onChange={(e) => {
                        setIfscCode(e.target.value);
                        // Clear the error for this field
                        setBankErrors((prevErrors) => ({ ...prevErrors, ifscCode: '' }));
                    }}
                    disabled={action === 'paymentRequest'}
                />
                {bankErrors.ifscCode && <p className="text-sm text-red-500">{bankErrors.ifscCode}</p>}
            </div>
        </div>

        <div className="flex mt-4 space-x-4">
            <div className="flex-1">
                <p>Payment Amount<span className="text-red-500 ">*</span></p>
              <input
    type="text" // Use type text to implement custom validation
    className="w-full p-2 bg-gray-100 border rounded-lg"
    placeholder="Enter Payment Amount"
    value={paymentAmount}
    onChange={(e) => {
        const value = e.target.value;
        // Allow only digits and empty input (to clear the field)
        if (value === '' || /^[0-9]*$/.test(value)) {
            setPaymentAmount(value);
            // Clear the error for this field
            setBankErrors((prevErrors) => ({ ...prevErrors, paymentAmount: '' }));
        }
    }}
    disabled={action === 'paymentRequest'}
/>
                  {bankErrors.paymentAmount && <p className="text-sm text-red-500">{bankErrors.paymentAmount}</p>}
                   {/* {cashError && <p className="text-red-500">{cashError}</p>} */}
            </div>
        </div>
    </>
)}

 {
            paymentMode === 'cash' && (
              <>
               <div className="flex-1 mt-2">
        <p>Payment Amount<span className="text-red-500 ">*</span></p>
         <input
                            type="text"
                            className="w-full p-2 bg-gray-100 border rounded-lg"
                            placeholder="Enter Payment Amount"
                            value={paymentAmount}
                            onChange={handlePaymentAmountChange} // Update the onChange handler
                            disabled={action === 'paymentRequest'}
                        />
                </div>
                {cashError && <p className="text-sm text-red-500">{cashError}</p>}
              </>
            )
          }

           {
            paymentMode === 'Discount' && (
              <>
               <div className="flex-1 mt-3">
        <p>Discounted Amount<span className="text-red-500 ">*</span></p>
         <input
                            type="text"
                            className="w-full p-2 bg-gray-100 border rounded-lg"
                            placeholder="Enter Payment Amount"
                            value={paymentAmount}
                            onChange={handlePaymentAmountChange} // Update the onChange handler
                            disabled={action === 'paymentRequest'}
                        />
                </div>
                {cashError && <p className="text-red-500">{cashError}</p>}
              </>
            )
          }

         <div className='flex gap-5 mt-2'>
        <div>
          <p>Date<span className="text-red-500 ">*</span></p>
          <input
            type='date'
            className='p-2 bg-gray-100 border rounded-lg md:w-[500px]'
            value={date} // Bind the date input to the state
            onChange={(e) => setDate(e.target.value)} // Update the state on input change
                placeholder='Select date'
                 disabled={action === 'paymentRequest'}
          />
            </div>
            
          </div>
           {DateError && <p className="text-sm text-red-500">{DateError}</p>}

</div>
        {
          
          action !== "paymentRequest" && (
            <>
            <div className='mt-5'>
          <table className='w-full bg-white rounded-lg'>
            <thead className='text-white' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
              <tr>
                <th className='p-2 font-normal rounded-tl-lg'>Installment No.</th>
                <th className='p-2 font-normal'>Mode</th>
                <th className='p-2 font-normal'>Date</th>
                <th className='p-2 font-normal rounded-tr-lg'>Amount</th>
              </tr>
            </thead>
           <tbody>
  {data?.TransactionData?.filter(installment => installment.PaymentMethod !== "").length > 0 ? (
    data.TransactionData
      .filter(installment => installment.PaymentMethod !== "")
      .map((installment, index) => {
        // Format the date and time
        const transactionDate = new Date(installment.TransactionDate).toLocaleString('en-IN', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false // 24-hour format
        });

        return (
          <tr key={index} className='border-t'>
            <td className='p-2 text-center'>#{index + 1}</td>
            <td className='p-2 text-center'>{installment.PaymentMethod}</td>
            <td className='p-2 text-center'>{transactionDate}</td>
            <td className='p-2 text-center'>
              {installment.PaymentDoneAmount.toLocaleString('en-IN', { maximumFractionDigits: 2 })}
            </td>
          </tr>
        );
      })
  ) : (
    <tr>
      <td className='p-2 text-center' colSpan={4}>No records found</td>
    </tr>
  )}

  
      <tr className='border-t'>
        <td className='p-2 text-center'></td>
        <td className='p-2 text-center'></td>
        <td className='p-2 text-center'></td>
        <td className='p-2 font-semibold'>TOTAL ₹ {data.Total}</td>
      </tr>

     
      <tr>
        <td className='p-2 text-center'></td>
        <td className='p-2 text-center'></td>
        <td className='p-2 text-center'></td>
        {duePayment !== "0" ? (
         <td className='p-2 font-semibold'>
  DUE PAYMENT <span className='text-red-500'>₹ {parseFloat(duePayment).toFixed(2)}</span>
</td>

        ) : (
          <td className='p-2 text-center'></td>
        )}
      </tr>
   
</tbody>

          </table>
        </div>
            </>
          )
       }
        {
          
          action === "paymentRequest" && (
            <div className='p-5'>
              <p className='text-[15px]'>total ₹ {data.Total}</p>
              <p className='text-[15px]'>Due Payment After Approve</p>
                <span className={`  flex gap-2   ${parseFloat(data.duePayment - data.paymentDoneAmount).toFixed(2) !== "0.00" ? "text-yellow-500" : "text-green-500"}`}>
                    ₹ {parseFloat(data.duePayment - data.paymentDoneAmount).toFixed(2)}
                    <div>
                      {parseFloat(data.duePayment - data.paymentDoneAmount).toFixed(2) !== "0.00" ? "Pending" : "Paid"}
                    </div>
                  </span>
            </div>
          )}

        <div className='flex justify-end gap-5 mt-2'>
          <button
            onClick={HandleClose}
            className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 mt-4 text-white transition rounded-lg" style={{ backgroundColor: '#4C3F35' }}
          >
            UPDATE 
          </button>
        </div>
      </div>
        {loading && <Loader loading={loading}  />}
    </div>
  );
}

export default AddInstallMent;
