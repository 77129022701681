import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { BaseUrl } from '../../auth/Url';
import TermsAndConditionModel from './TermsAndConditionModel';
const TermsAndCondition = () => {
  const [terms, setTerms] = useState([]);
  const [editorContent, setEditorContent] = useState('');
  const [editTermId, setEditTermId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  // Fetch all terms from the API
  const fetchTerms = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseUrl}/GetTermsAndCondition`);
      setTerms(response.data);
    } catch (error) {
      console.error('Error fetching terms:', error);
    } finally {
      setLoading(false);
    }
  };

  // Add a new term
  const addTerm = async () => {
    if (!editorContent) {
      alert('Content cannot be empty');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${BaseUrl}/AddTermsAndCondition`, {
        content: editorContent,
      });
      setTerms([response.data]); // Update state to hold only the new term
      setEditorContent('');
      setIsModalOpen(false); // Close modal after adding
    } catch (error) {
      console.error('Error adding term:', error);
    } finally {
      setLoading(false);
    }
  };

  // Update an existing term
  const updateTerm = async () => {
    if (!editorContent) {
      alert('Content cannot be empty');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.put(`${BaseUrl}/UpdateTermsAndCondition/${editTermId}`, {
        content: editorContent,
      });
      setTerms([response.data]); // Update state to hold only the updated term
      setEditorContent('');
      setEditTermId(null);
      setIsModalOpen(false); // Close modal after updating
    } catch (error) {
      console.error('Error updating term:', error);
    } finally {
      setLoading(false);
    }
  };

  // Delete a term
 const deleteTerm = async (id) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then(async (result) => {
    if (result.isConfirmed) {
      setLoading(true); // Show loader while deleting
      try {
        await axios.delete(`${BaseUrl}/DeleteTermsAndCondition/${id}`);
        setTerms([]); // Clear terms if the only term is deleted

        Swal.fire(
          'Deleted!',
          'The term has been deleted.',
          'success'
        );
      } catch (error) {
        console.error('Error deleting term:', error);
        Swal.fire(
          'Error!',
          'There was a problem deleting the term.',
          'error'
        );
      } finally {
        setLoading(false); // Hide loader once the operation is complete
      }
    }
  });
};

  useEffect(() => {
    fetchTerms(); // Fetch terms when component mounts
  }, []);

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh", padding: "20px" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className='flex justify-between p-5'>
          <h1 className="mb-4 text-xl font-bold">Terms and Conditions</h1>
          {/* Show the button only if there are no terms */}
          {terms.length === 0 && (
            <button
              onClick={() => {
                setIsModalOpen(true);
                setEditTermId(null);
                setEditorContent(''); // Reset editor content for new term
              }}
              className='p-2 text-white bg-orange-400 border rounded-lg'
            >
              Add Term
            </button>
          )}
        </div>

        <h2>Existing Terms:</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table className="min-w-full border border-collapse border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-2 border border-gray-300">Content</th>
                <th className="p-2 border border-gray-300">Actions</th>
              </tr>
            </thead>
            <tbody>
              {terms.map((term) => (
                <tr key={term._id} className="border-b border-gray-200">
                  <td
                    className="p-2 border border-gray-300"
                    dangerouslySetInnerHTML={{ __html: term.content }} 
                  />
                  <td className="flex gap-10 p-2 border border-gray-300">
                    <button
                      onClick={() => {
                        setEditorContent(term.content);
                        setEditTermId(term._id);
                        setIsModalOpen(true); // Open modal for editing
                      }}
                      className="px-2 py-1 text-white bg-blue-500 rounded"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => deleteTerm(term._id)}
                      className="px-2 py-1 ml-2 text-white bg-red-500 rounded"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Modal for Adding/Editing Terms */}
        <TermsAndConditionModel
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={editTermId ? updateTerm : addTerm}
          editorContent={editorContent}
          setEditorContent={setEditorContent}
          title={editTermId ? 'Edit Term' : 'Add Term'}
        />
      </div>
    </div>
  );
};

export default TermsAndCondition;
