import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEye, FaPlus } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../auth/Url';
import Pagination from '../Component/pagination';
import ViewQuate from './ViewQuate';
import Swal from 'sweetalert2';
function QuotationPage() {
  const [quotations, setQuotations] = useState([]);
  const [draftQuotations, setDraftQuotations] = useState([]);
  const [sentQuotations, setSentQuotations] = useState([]);
  const [displayedQuotations, setDisplayedQuotations] = useState([]);
  const [activeTab, setActiveTab] = useState('sent'); // 'sent' or 'draft'
  const navigate = useNavigate();
 const token = localStorage.getItem('token');// replace with your actual token
 const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
const [searchTerm, setSearchTerm] = useState(''); // Search term state
const indexOfLastRecord = currentPage * perPage;
const indexOfFirstRecord = indexOfLastRecord - perPage;
 const [modalVisible, setModalVisible] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
// Filter displayed quotations based on the search term
const filteredQuotations = displayedQuotations.filter(quotation => 
  quotation.customer.toLowerCase().includes(searchTerm.toLowerCase())
);

// Paginate the filtered quotations
const currentRecords = filteredQuotations.slice(indexOfFirstRecord, indexOfLastRecord);
const totalPages = Math.ceil(filteredQuotations.length / perPage)
  useEffect(() => {
    

    fetchQuotations();
  }, []);
const fetchQuotations = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/Quatation/GetAllQuote`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.data.result && response.data.statusCode === 200) {
          const fetchedDraftQuotations = [];
          const fetchedSentQuotations = [];

          response.data.quotationsList.reverse().forEach((quotation, index) => {
            const quotationData = {
              id: index + 1,
              _id:quotation._id,
              quotationNo: quotation.AddDetails.QuotationID,
              customer: quotation.ShopInformation.ShopName,
              generated: new Date(quotation.AddDetails.QuotationDate),
              expiryDate: new Date(quotation.AddDetails.ExpiryDate),
              action: quotation.AddDetails.action,
            };

            if (quotation.Action === 0) {
              fetchedDraftQuotations.push(quotationData);
            } else if (quotation.Action === 1) {
              fetchedSentQuotations.push(quotationData);
            }
          });

          setDraftQuotations(fetchedDraftQuotations);
          setSentQuotations(fetchedSentQuotations);
          setDisplayedQuotations(fetchedSentQuotations); // Initialize with sent quotations
        }
      } catch (error) {
        console.error('Error fetching quotations:', error);
      }
    };
  const formatDate = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  };

  const isExpired = (expiryDate) => {
    const currentDate = new Date();
    return expiryDate < currentDate;
  };

  const getStatus = (expiryDate) => {
    return isExpired(expiryDate) ? "Expired" : "Valid";
  };

  const NaviagteAddQuotation = () => {
    navigate("/Add_Quotation");
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'sent') {
      setDisplayedQuotations(sentQuotations);
    } else {
      setDisplayedQuotations(draftQuotations);
    }
  };
const handleDelete = async (id) => {
  // Show confirmation dialog
  const confirmDelete = await Swal.fire({
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this quotation!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
  });

  // If user confirms deletion
  if (confirmDelete.isConfirmed) {
    const response = await fetch(`${BaseUrl}/Quatation/DeleteQuote/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'The quotation has been deleted successfully.',
        confirmButtonText: 'OK',
      });

      // Optionally, refresh the records or update the state
      fetchQuotations();
      setCurrentPage(1);
    } else {
      // Show error message
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'There was an error deleting the quotation.',
        confirmButtonText: 'OK',
      });
    }
  } else {
    // If user cancels the deletion
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The quotation is safe!',
      confirmButtonText: 'OK',
    });
  }
};


  const openModal = (quotation) => {
    setSelectedQuotation(quotation._id);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedQuotation(null);
  };
  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] p-5 md:p-0">
         <div className="items-center justify-between pt-5 md:flex">
            <div>
              <p className="text-[14px]">Quotation Summary</p>
            <div className="flex bg-white rounded-lg md:w-[500px] justify-evenly mt-2">
              <div className="p-5" >
                <p>All Quotation
                 </p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">
  {(Array.isArray(draftQuotations) ? draftQuotations.length : 0) + (Array.isArray(sentQuotations) ? sentQuotations.length : 0)}
</p>

              </div>
              
             
            </div>
            </div>
            
            <button
              className="flex gap-2 px-4 py-2 mt-10 text-white rounded-lg md:mt-0"
              style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }}
             onClick={NaviagteAddQuotation}
            >
              <FaPlus className="mt-1"/><p>Create New Quotation</p> 
            </button>
          </div>
      <div className="p-3">
      

       
          <div className='flex justify-between gap-5 p-3 mt-2 bg-white rounded-lg'>
            <div className='flex hidden gap-10 mt-2 md:block'>
               <p
            className={`cursor-pointer ${activeTab === 'sent' ? 'font-bold underline underline-green' : ''}`}
            onClick={() => handleTabClick('sent')}
          >
            Sent Quotation( {Array.isArray(sentQuotations) ? sentQuotations.length : 0})
          </p>
        
            </div>
         
             <div className="relative">
               <input
                type="text"
                placeholder="Search by customer name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                className="w-64 px-3 py-2 pl-10 bg-white border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>
        </div>
        {/* <div className="">
          <table className="w-full border border-collapse border-gray-300">
            <thead style={{ backgroundColor: "#4C3F35" }} className="text-white ">
                <tr>
                   <th className="p-2 font-normal"> </th>
                <th className="p-2 font-normal">Sr No.</th>
                <th className="p-2 font-normal">Quotation No.</th>
                <th className="p-2 font-normal">Customer</th>
                  <th className="p-2 font-normal">Generated On</th>
                    <th className="p-2 font-normal">expiry On</th>
                  <th className="p-2 font-normal">Status</th>
                   {
                    activeTab ==="sent"?"": <th className="p-2 font-normal">Edit</th>
                  }
                 <th className="p-2 font-normal">View</th>
                <th className="p-2 font-normal">Delete</th>
              </tr>
            </thead>
           <tbody className="text-black bg-white">
        {currentRecords.map((quotation, index) => (
          <tr key={quotation.id} style={{ borderBottom: "1px solid #000" }}>
            <td className="p-2 pl-2 text-center"></td>
             <td className="px-4 py-2 ">{indexOfFirstRecord + index + 1}</td>
            <td className="p-2 text-center">{quotation.quotationNo}</td>
            <td className="p-2 text-center">{quotation.customer}</td>
            <td className="p-2 text-center">{new Date(quotation.generate).toLocaleDateString()}</td>
<td className="p-2 text-center">{new Date(quotation.expiryDate).toLocaleDateString()}</td>

            <td className="p-2 text-center">
              {getStatus(quotation.expiryDate) === "Valid" ? (
                <button className="px-2 py-1 text-white bg-green-500 rounded-full w-[100px]">Valid</button>
              ) : (
                <button className="px-2 py-1 text-white bg-red-500 rounded-full w-[100px]">Expired</button>
              )}
            </td>
           
              <td className="p-2 text-center pl-7" onClick={() => openModal(quotation)}>
                <FaEye className='cursor-pointer' />
              </td>
            
            <td className="p-2 text-center text-red-500 pl-7" onClick={() => handleDelete(quotation._id)}>
              <RiDeleteBinLine className='cursor-pointer' />
            </td>
          </tr>
        ))}
      </tbody>

          </table>
        </div> */}
          <div className="overflow-x-auto">
  <table className="min-w-full border border-collapse border-gray-300 ">
    <thead style={{ backgroundColor: "#4C3F35" }} className="text-white">
      <tr>
        <th className="p-2 font-normal"></th>
        <th className="p-2 font-normal">Sr No.</th>
        <th className="p-2 font-normal">Quotation No.</th>
        <th className="p-2 font-normal">Customer</th>
        <th className="p-2 font-normal">Generated On</th>
        <th className="p-2 font-normal">Expiry On</th>
        <th className="p-2 font-normal">Status</th>
        {activeTab === "sent" ? "" : <th className="p-2 font-normal">Edit</th>}
        <th className="p-2 font-normal">View</th>
        <th className="p-2 font-normal">Delete</th>
      </tr>
    </thead>
    <tbody className="text-black bg-white">
      {currentRecords.map((quotation, index) => (
        <tr key={quotation.id} className="border-b border-black">
          <td className="p-2 pl-2 text-center"></td>
          <td className="px-4 py-2">{indexOfFirstRecord + index + 1}</td>
          <td className="p-2 text-center">{quotation.quotationNo}</td>
          <td className="p-2 text-center">{quotation.customer}</td>
          <td className="p-2 text-center">{new Date(quotation.generate).toLocaleDateString()}</td>
          <td className="p-2 text-center">{new Date(quotation.expiryDate).toLocaleDateString()}</td>
          <td className="p-2 text-center">
            {getStatus(quotation.expiryDate) === "Valid" ? (
              <button className="px-2 py-1 text-white bg-green-500 rounded-full w-[100px]">Valid</button>
            ) : (
              <button className="px-2 py-1 text-white bg-red-500 rounded-full w-[100px]">Expired</button>
            )}
          </td>
          <td className="p-2 text-center pl-7" onClick={() => openModal(quotation)}>
            <FaEye className="cursor-pointer" />
          </td>
          <td className="p-2 text-center text-red-500 pl-7" onClick={() => handleDelete(quotation._id)}>
            <RiDeleteBinLine className="cursor-pointer" />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

      </div>
      </div>
       {sentQuotations.length > 10 && (
          <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
      )}
      
      {modalVisible && (
        <ViewQuate
          quotation={selectedQuotation}
          onClose={closeModal}
        />
      )}
    </div>
   
  );
}

export default QuotationPage;
