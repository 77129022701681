import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../auth/Url';
import NewComponent from './NewComponent';
import ProductSearchModal from './ProductAddModel';
function AddManuallyOrder() {
    const [dealers, setDealers] = useState([]); 
    const [selectedShopId, setSelectedShopId] = useState(''); 
   const navigate = useNavigate();
    const [dealerDetails, setDealerDetails] = useState(null); 
    console.log(dealerDetails,"dealerDetails for order")
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]); // State to store selected 
    console.log(selectedProducts,"selectedProducts for order")
    const [totalAmount, settotalAmount] = useState(''); 
   
const [errorMsg, setErrorMsg] = useState(""); // State for storing the error message
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedQuantities, setSelectedQuantities] = useState([]);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [Billing_Address, setBilling_Address] = useState(''); 
  const [Shipping_Address, setShipping_Address] = useState(''); 
  
    const [totalSaving, setTotalSaving] = useState(0);
 const [totalPrices, setTotalPrices] = useState([]); 
    console.log(totalPrices, "setTotalPrices")
     const [orderDate, setOrderDate] = useState('');

  const handleDateChange = (event) => {
    setOrderDate(event.target.value);
  };
    useEffect(() => {
        const calculateTotalSaving = () => {
            return selectedProducts.reduce((acc, product) => {
                const originalTotalPrice = (size, sizeInKg) => {
                    const quantity =
                        selectedQuantities.find(item => item.productId === product._id)?.selection.find(sel => sel.size === size)?.quantity || 0;
                    return quantity * product.price * sizeInKg;
                };

                const discountedTotalPrice = (size, sizeInKg) => {
                    const quantity =
                        selectedQuantities.find(item => item.productId === product._id)?.selection.find(sel => sel.size === size)?.quantity || 0;
                    const discountedPrice = product.discount
                        ? product.price - (product.price * (parseFloat(product.discount) / 100))
                        : product.price;
                    return quantity * discountedPrice * sizeInKg;
                };

                const totalSaving =
                    originalTotalPrice('30kg', 30) +
                    originalTotalPrice('50kg', 50) +
                    originalTotalPrice('25kg', 25) -
                    (discountedTotalPrice('30kg', 30) +
                        discountedTotalPrice('50kg', 50) +
                        discountedTotalPrice('25kg', 25));

                return acc + totalSaving;
            }, 0).toFixed(2);
        };

        // Store the calculated saving in the state
        setTotalSaving(calculateTotalSaving());
    }, [selectedProducts, selectedQuantities]);
    useEffect(() => {
        fetch(`${BaseUrl}/Dealer/Getdealers`)
            .then((response) => response.json())
            .then((data) => setDealers(data.data)) 
            .catch((error) => console.error('Error fetching dealers:', error));
    }, []);

    useEffect(() => {
       fetchProducts(); 
    },[])

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BaseUrl}/products/GetProducts`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const visibleProducts = response.data.productsList.filter(product => product.isVisible === true);
            setProducts(visibleProducts);
        } catch (error) {
            setError(error.response ? error.response.data.message : error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedShopId) {
            fetch(`${BaseUrl}/Dealer/DealerDetails/${selectedShopId}`)
                .then((response) => response.json())
                .then((data) => setDealerDetails(data.data)) 
                .catch((error) => console.error('Error fetching dealer details:', error));
        }
    }, [selectedShopId]);

    const handleShopChange = (event) => {
        setSelectedShopId(event.target.value); 
    };

    const openModal = () => {
        setModalIsOpen(true);
    };
    
    const closeModal = () => {
        setModalIsOpen(false);
    };

    // Handle selected products from modal
 const handleProductSelection = (product, isSelected) => {
        if (isSelected) {
            // Add selected product
            setSelectedProducts((prevProducts) => [...prevProducts, product]);
            // Initialize quantities for the new product
            // setSelectedQuantities((prevQuantities) => ({
            //     ...prevQuantities,
            //     [product.productCode]: {  }
            // }));
        } else {
            // Remove unselected product
            setSelectedProducts((prevProducts) => prevProducts.filter(p => p._id !== product._id));
            // Remove quantities for the unselected product
            // setSelectedQuantities((prevQuantities) => {
            //     const newQuantities = { ...prevQuantities };
            //     delete newQuantities[product._id];
            //     return newQuantities;
            // });
        }
    };
const handleQuantityChange = (productId, size, value) => {
  const quantity = value ? Number(value) : 0; // Convert value to number, default to 0 if empty

  setSelectedQuantities(prev => {
    // Find the existing product in the state
    const existingProduct = prev.find(item => item.productId === productId);

    if (existingProduct) {
      // Update the selection array for the product
      const updatedSelection = existingProduct.selection
        .map(selection => {
          if (selection.size === size) {
            return { ...selection, quantity }; // Update quantity if size matches
          }
          return selection; // Return original selection if size doesn't match
        })
        // If quantity is greater than 0 for the size, include it; otherwise, exclude it
        .filter(selection => selection.quantity > 0);

      // Check if we need to add a new selection if the size doesn't exist in the selections
      if (!existingProduct.selection.some(selection => selection.size === size) && quantity > 0) {
        updatedSelection.push({ size, quantity });
      }

      // Return the updated state with the modified product selection
      return prev.map(item =>
        item.productId === productId
          ? { ...item, selection: updatedSelection }
          : item
      );
    } else {
      // If the product doesn't exist in the state, add it only if quantity is greater than 0
      if (quantity > 0) {
        return [
          ...prev,
          {
            productId,
            selection: [{ size, quantity }],
          },
        ];
      }
      return prev; // If quantity is 0, don't add anything
    }
  });
};

  
   
   
    const handleOrder = async () => {
         if (!dealerDetails) {
            alert("Please select a Shop!");
            return; // Exit the function early
        }

     if (selectedProducts.length === 0) {
    setError(true);
    setErrorMsg("No products selected!");
    return;
        }
        
  let invalidProduct = null;

  // Check if every selected product has at least one size/quantity in selectedQuantities and quantity > 0
  const isValid = selectedProducts.every((product) => {
    const quantityForProduct = selectedQuantities.find(
      (q) => q.productId === product._id
    );

    // Check if the product has a valid selection with quantity > 0
    const hasValidSelection =
      quantityForProduct &&
      quantityForProduct.selection.some((sel) => sel.quantity > 0);

    // If invalid, save the product info to show the error message
    if (!hasValidSelection) {
      invalidProduct = product; // Capture the first invalid product
    }

    return hasValidSelection;
  });

  if (!isValid && invalidProduct) {
    setError(true);
    setErrorMsg(
      `Please add at least one valid bag size with a quantity greater than 0 for product "${invalidProduct.productName}" (ID: ${invalidProduct.productCode
}).`
    );
  } else {
    setError(false);
    setErrorMsg("");
    // console.log("All products have valid sizes and quantities added.");
      // Proceed with the order
       const orderData = {
      orderId: `ORD-${Date.now()}-${Math.floor(Math.random() * 10000)}`, // Generate a unique order ID
      Total: totalAmount,
        ShopId: dealerDetails.shopId,
        PaymentDoneAmount: "0", // Set the payment done amount
       PaymentMethod: "",
      Duepayment: totalAmount, 
  
        customerInfo: {
          CustomerName: dealerDetails.contactPerson,
          ShopName: dealerDetails.shopName || "",
          ShopID: dealerDetails.shopId || "",
          ContactNo: dealerDetails.contactNumber,
            EmailID: dealerDetails.email || "",
            ShopAddress: dealerDetails.ShopAddress || "",
             gstNumber: dealerDetails.gstNumber || "",
              FSSAINumber: dealerDetails.FSSAINumber || "",

        },
           ProductDetails: selectedProducts.map((product) => {
           
            const matchingProductTotal = totalPrices.find(item => item.productId === product._id);
            return {
                ProductID: product.productCode,
                productTotalAmount: matchingProductTotal ? String(matchingProductTotal.totalPrice) : "0", // Convert to string 
                OrderDate: orderDate || new Date().toISOString().split("T")[0],
                ProductName: product.productName,
                MRP: product.price,
                discount: product.discount,
               selection: selectedQuantities.find(item => item.productId === product._id)?.selection || [],
                dispatchShippingDetails: {
                    DispatchID: "",
                    EstimatedDeliveryDate: "",
                    DriverName: "",
                    ContactNumber: "",
                    VehicleNumber: "",
                    TaxAndDuties: "",
                    Insurance: "",
                    RefundStatus: "",
                    RefundReasons: "",
                    RejectRefundReasons: "",
                    RefundDate: "",
                    OrderStatus: "Pending",
                    weight: "", // Calculate if needed
                    Category: product.category,
                    DispatchStatus: "",
                    OrderCancelReason: "",
                    OrderPendingReason: "",
                },
                OrderTrackingDetails: {
                    Place: true,
                    PlaceNote: "Order placed successfully.",
                    Shipped: false,
                    ShippedNote: "",
                    Delivered: false,
                    DeliveredNote: "",
                    Out_for_Delivery: false,
                    Out_for_Delivery_Note: "",
                    payment: false,
                },
                SupplierInfo: {
                    FactoryName: product.supplierName,
                    FactoryAddress: product.supplierCity,
                    FactoryContact: product.supplierContactNumber,
                    FactoryId: product.FactoryId,

                     FactoryFSSAI: product.FactoryFSSAI,
                      FactoryGST: product.FactoryGST,
                },
                commission: product.Commission,
                selectedImages: product.selectedImages,
            };
        }),
    };

     navigate("/Place_Order", { state: { orderData ,totalSaving ,orderDate} });
//  const token = localStorage.getItem('token'); 
//      try {
//         const response = await fetch(`${BaseUrl}/Order/CreateOrder`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
//             },
//             body: JSON.stringify(orderData),
//         });

//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }

//         const data = await response.json();
//         console.log("Order created successfully:", data);

        
//     } catch (error) {
//         console.error("There was a problem with the fetch operation:", error);
//     }
  }
    }
    
  const handleDeleteProduct = (productId) => {
    // Remove the product from the selectedProducts state
    setSelectedProducts(selectedProducts.filter(product => product._id !== productId));
    
    // Also remove the product ID from the selectedProductIds in the modal
    setSelectedProductIds(selectedProductIds.filter(id => id !== productId));
};

    useEffect(() => {
        // Calculate the total amount whenever selectedProducts or selectedQuantities change
        const calculatedTotal = selectedProducts.reduce((acc, product) => {
            const hasDiscount = product.discount && parseFloat(product.discount) > 0;
            const discountedPrice = hasDiscount
                ? product.price - (product.price * (parseFloat(product.discount) / 100))
                : product.price;

            const getTotalPrice = (size, sizeInKg) => {
                const quantity =
                    selectedQuantities.find(item => item.productId === product._id)?.selection.find(sel => sel.size === size)?.quantity || 0;
                return quantity * discountedPrice * sizeInKg;
            };

            // Sum the total price for all sizes
            const totalPrice =
                getTotalPrice('30kg', 30) +
                getTotalPrice('50kg', 50) +
                getTotalPrice('25kg', 25) +
                getTotalPrice('100kg', 100);
            
            return acc + totalPrice;
        }, 0);

        settotalAmount(calculatedTotal.toFixed(2)); // Store the calculated total amount
    }, [selectedProducts, selectedQuantities]); 
    return (
//         <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
//             <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] ">
//                 <div className="pt-10 ">
//                     <div className="w-[1205px] bg-white rounded-[18px] p-5">
//                         <div>
//                             <p>Add a Shop</p>
//                             <select
//                                 id="shop-select"
//                                 value={selectedShopId}
//                                 onChange={handleShopChange}
//                                 className="block px-3 py-2 text-gray-900 bg-[#F7F7F7] border shadow-sm mt-2 w-[560px] rounded-[18px] h-[53px]"
//                             >
//                                 <option value="" disabled>Add shop name</option>
//                                 {dealers.map((dealer) => (
//                                     <option key={dealer._id} value={dealer.shopId}>
//                                         {dealer.shopName}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>

//                         <div className="flex gap-10 mt-5">
//                             <div className="w-[512px] border h-[196px] rounded-[10px] border-gray-300 p-3">
//                                 <p className="font-poppins text-[14px] font-semibold leading-[21px]">
//                                     Customer info
//                                 </p>
//                                 {dealerDetails ? (
//                                     <>
//                                         <p className="text-[14px] flex mt-2">
//                                             <span className="font-[500] w-[150px]">Customer Name:</span>
//                                             <span>{dealerDetails.contactPerson}</span>
//                                         </p>
//                                         <p className="text-[14px] flex mt-1">
//                                             <span className="font-[500] w-[150px]">Shop Name:</span>
//                                             <span>{dealerDetails.shopName}</span>
//                                         </p>
//                                        <p className="text-[14px] flex mt-1">
//                                             <span className="font-[500] w-[150px]">Contact No:</span>
//                                             <span>{dealerDetails?.contactNumber}</span>
//                                         </p>
//                                         <p className="text-[14px] mt-1 flex">
//                                             <span className="font-[500] w-[150px]">Email ID:</span>
//                                             <span>{dealerDetails.email}</span>
//                                         </p>
//                                         <p className="text-[14px] mt-1 flex">
//                                             <span className="font-[500] w-[150px]">GST Number:</span>
//                                             <span>{dealerDetails.gstNumber}</span>
//                                         </p>
//                                         <p className="text-[14px] mt-1 flex">
//                                             <span className="font-[500] w-[150px]">FSSAI Number:</span>
//                                             <span>{dealerDetails.FSSAINumber}</span>
//                                         </p>
//                                     </>
//                                 ) : (
//                                     <p>No customer info available.</p>
//                                 )}
//                             </div>

//                           <div className="w-[512px] h-[196px] border border-gray-300 rounded-[10px] p-3 overflow-y-auto scrollbar-thin scrollbar-thumb-blue-400 scrollbar-track-blue-200">
//     <p className="font-poppins text-[14px] font-semibold leading-[21px]">Seller Info</p>
    
//     {selectedProducts.length > 0 ? (
//         selectedProducts.map((product, index) => (
//             <div key={index} className="mt-2">
//                 <p className='font-semibold'>
//                     <span>{product.productName} ({product.productCode})</span>
//                 </p>
//                 <p className="text-[14px] mt-1 flex">
//                     <span className="font-[500] w-[250px]">Supplier Name:</span>
//                     <span>{product.supplierName}</span>
//                 </p>
//                 <p className="text-[14px] flex mt-2">
//                     <span className="font-[500] w-[250px]">Factory ID:</span>
//                     <span>{product.FactoryId}</span>
//                 </p>
//                 <p className="text-[14px] flex mt-1">
//                     <span className="font-[500] w-[250px]">Supplier City:</span>
//                     <span>{product.supplierCity}</span>
//                 </p>
//                 <p className="text-[14px] flex mt-1">
//                     <span className="font-[500] w-[250px]">Supplier Contact Number:</span>
//                     <span>{product.supplierContactNumber}</span>
//                 </p>
//                 <hr className="my-2" />
//             </div>
//         ))
//     ) : (
//         <p>Please select a product.</p>
//     )}
// </div>


//                         </div>

//                     </div>
//                        <div className="flex justify-end">
//                             <button
//                                 onClick={openModal}
//                                 className="bg-[#C6A26F] text-white px-4 py-2 rounded-md mt-5 w-[168px] h-[40px]"
//                             >
//                                 Add Products
//                             </button>
//                     </div>
//             <div className="overflow-x-auto mt-5 bg-white h-[300px] mb-5">
//               <NewComponent selectedProducts={selectedProducts} selectedQuantities={selectedQuantities} handleQuantityChange={handleQuantityChange} handleDeleteProduct={handleDeleteProduct} totalPrices={totalPrices} setTotalPrices={setTotalPrices} />
    
// </div>   {error && <p className="mt-2 mb-2 text-center text-red-500">{errorMsg}</p>} <hr></hr>
//                    <div className="flex justify-center mt-1">
//     <p className="text-[20px]">Grand Total:</p>
//     <p className="ml-2 text-[20px] font-semibold">
//             ₹{totalAmount}
//         </p>
//                     </div>
//                     <div>
//                          <p className="text-[#3E8E3C] text-center text-[18px] mt-4 pb-5" style={{ fontFamily: "DM Sans, sans-serif" }}>
//             Your Total Saving on this order ₹{totalSaving}
//         </p>
//                     </div>

//                      <div className="flex justify-center mb-2">
//   {/* <button
//     className="w-[306px] h-[50px] bg-[#FF9F00] text-white rounded-[26.5px] relative overflow-hidden"
//      onClick={handleOrder}
//   >
//     <span className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-60 transform translate-x-[-150%] animate-shine"></span>
//     PLACE ORDER
//   </button> */}
//                         <button  onClick={handleOrder} className='w-[179px] h-[40px] bg-[#4C3F35] p-2 text-white rounded-[8px]'>

//                             Confirm & Proceed
//                         </button>
// </div>

//                 </div>
//             </div>
          
//                 <ProductSearchModal
//                 modalIsOpen={modalIsOpen}
//                 closeModal={closeModal}
//                 products={products}
//                 onProductSelect={handleProductSelection} // Pass the selection handler
//                 selectedProductIds={selectedProductIds}
//                 setSelectedProductIds={setSelectedProductIds}

//             />
            
           
      //         </div>
      <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
    <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] sm:w-full">
        <div className="pt-10">
                    <div className="bg-white rounded-[18px] p-5 max-w-[1205px] w-full mx-auto">
                        <div className='flex gap-10 '>

                            <div>
                    <p className="text-base sm:text-lg">Add a Shop</p>
                    <select
                        id="shop-select"
                        value={selectedShopId}
                        onChange={handleShopChange}
                        className="block px-3 py-2 text-gray-900 bg-[#F7F7F7] border shadow-sm mt-2 w-full md:w-[560px] rounded-[18px] h-[53px]"
                    >
                        <option value="" disabled>Add shop name</option>
                        {dealers?.map((dealer) => (
                            <option key={dealer._id} value={dealer.shopId}>
                                {dealer.shopName}
                            </option>
                        ))}
                    </select>
                </div>
                            <div className="flex flex-col items-start mt-5">
      <label htmlFor="order-date" className="mb-2 text-sm font-medium text-gray-700">
        Order Date
      </label>
      <input
        type="date"
        id="order-date"
        name="orderDate"
        value={orderDate}
        onChange={handleDateChange}
        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
                        </div>
                

                <div className="flex flex-col gap-5 mt-5 md:flex-row">
                    <div className="border h-[215px] rounded-[10px] border-gray-300 p-3 w-full md:w-[512px]">
                        <p className="font-poppins text-[14px] font-semibold leading-[21px]">
                            Customer info
                        </p>
                        {dealerDetails ? (
                            <>
                                <p className="text-[14px] flex mt-2">
                                    <span className="font-[500] w-[150px]">Customer Name:</span>
                                    <span>{dealerDetails.contactPerson}</span>
                                </p>
                                <p className="text-[14px] flex mt-1">
                                    <span className="font-[500] w-[150px]">Shop Name:</span>
                                    <span>{dealerDetails.shopName}</span>
                                        </p>
                                         <p className="text-[14px] mt-1 flex">
                                    <span className="font-[500] w-[150px]">Shop Address:</span>
                                    <span>{dealerDetails.ShopAddress}</span>
                                </p>
                                <p className="text-[14px] flex mt-1">
                                    <span className="font-[500] w-[150px]">Contact No:</span>
                                    <span>{dealerDetails?.contactNumber}</span>
                                </p>
                                <p className="text-[14px] mt-1 flex">
                                    <span className="font-[500] w-[150px]">Email ID:</span>
                                    <span>{dealerDetails.email}</span>
                                </p>
                                <p className="text-[14px] mt-1 flex">
                                    <span className="font-[500] w-[150px]">GST Number:</span>
                                    <span>{dealerDetails.gstNumber}</span>
                                </p>
                                <p className="text-[14px] mt-1 flex">
                                    <span className="font-[500] w-[150px]">FSSAI Number:</span>
                                    <span>{dealerDetails.FSSAINumber}</span>
                                        </p>
                                       
                            </>
                        ) : (
                            <p>No customer info available.</p>
                        )}
                    </div>

                    <div className="w-full md:w-[512px] h-[215px] border border-gray-300 rounded-[10px] p-3 overflow-y-auto scrollbar-thin scrollbar-thumb-blue-400 scrollbar-track-blue-200">
                        <p className="font-poppins text-[14px] font-semibold leading-[21px]">Seller Info</p>
                        {selectedProducts.length > 0 ? (
                            selectedProducts.map((product, index) => (
                                <div key={index} className="mt-2">
                                    <p className='font-semibold'>
                                        <span>{product.productName} ({product.productCode})</span>
                                    </p>
                                    <p className="text-[14px] mt-1 flex">
                                        <span className="font-[500] w-[250px]">Supplier Name:</span>
                                        <span>{product.supplierName}</span>
                                    </p>
                                    <p className="text-[14px] flex mt-2">
                                        <span className="font-[500] w-[250px]">Factory ID:</span>
                                        <span>{product.FactoryId}</span>
                                    </p>
                                    <p className="text-[14px] flex mt-1">
                                        <span className="font-[500] w-[250px]">Supplier City:</span>
                                        <span>{product.supplierCity}</span>
                                    </p>
                                    <p className="text-[14px] flex mt-1">
                                        <span className="font-[500] w-[250px]">Supplier Contact Number:</span>
                                        <span>{product.supplierContactNumber}</span>
                                    </p>
                                    <hr className="my-2" />
                                </div>
                            ))
                        ) : (
                            <p>Please select a product.</p>
                        )}
                            </div>
                            
                </div>
 
                    </div>
                    
            <div className="flex justify-end">
                <button
                    onClick={openModal}
                    className="bg-[#C6A26F] text-white px-4 py-2 rounded-md mt-5 w-[168px] h-[40px]"
                >
                    Add Products
                </button>
            </div>
            <div className="overflow-x-auto mt-5 bg-white h-[300px] mb-5">
                <NewComponent
                    selectedProducts={selectedProducts}
                    selectedQuantities={selectedQuantities}
                    handleQuantityChange={handleQuantityChange}
                    handleDeleteProduct={handleDeleteProduct}
                    totalPrices={totalPrices}
                setTotalPrices={setTotalPrices}
                 setSelectedProducts={setSelectedProducts}
                />
            </div>
            {error && <p className="mt-2 mb-2 text-center text-red-500">{errorMsg}</p>}
            <hr />
            <div className="flex justify-center mt-1">
                <p className="text-[20px]">Grand Total:</p>
                <p className="ml-2 text-[20px] font-semibold">₹{totalAmount}</p>
            </div>
            <div>
                <p className="text-[#3E8E3C] text-center text-[18px] mt-4 pb-5" style={{ fontFamily: "DM Sans, sans-serif" }}>
                    Your Total Saving on this order ₹{totalSaving}
                </p>
            </div>
            <div className="flex justify-center mb-2">
                <button onClick={handleOrder} className='w-[179px] h-[40px] bg-[#4C3F35] p-2 text-white rounded-[8px]'>
                    Confirm & Proceed
                </button>
            </div>
        </div>
    </div>

    <ProductSearchModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        products={products}
        onProductSelect={handleProductSelection} // Pass the selection handler
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
    />
</div>

    );
}

export default AddManuallyOrder;
