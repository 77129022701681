import React, { useEffect, useState } from "react";
import { IoPrintOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchProductDetails } from "../../Service/ManageInventoryAdmin/ManageInventoryApi";

function ViewInventory() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedProductImages, setSelectedProductImages] = useState([]);
  console.log("selectedProductImages",selectedProductImages)
  const [productObject, setProductObject] = useState({
    productName: "",
    productDescription: "",
    category: "",
    subCategory: "",
    unit: "",
    productCode: "",
    price: "",
    FactoryId:"",
    qualityVariety: "",
    selectedImages: [],
    supplierName: "",
    supplierCity: "",
    contactNumber: "",
    discount: "",
    effectiveDate: "",
    expiryDate: "",
    visibility: false, // Assuming visibility is a boolean
  });
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

 
   useEffect(() => {
     const loadProductDetails = async () => {
      debugger
      if (location.state && location.state.Productcode) {
        // setLoading(true);
        // setError(null);

        try {
          const singleproductData = await fetchProductDetails(location.state.Productcode);
const productData = singleproductData.SingleProductList;
          setProductObject({
            productName: productData.productName || '',
            productDescription: productData.productDescription || '',
            category: productData.category || '',
            subCategory: productData.subCategory || '',
            unit: productData.unit || '',
            productCode: productData.productCode || '',
            price: productData.price || '',
            qualityVariety: productData.qualityVariety || '',
            selectedImages: productData.selectedImages || [],
            supplierName: productData.supplierName || '',
            supplierCity: productData.supplierCity || '',
            contactNumber: productData.supplierContactNumber || '',
            discount: productData.discount || '',
            effectiveDate: productData.effectiveDate || '',
            expiryDate: productData.expiryDate || '',
            FactoryId: productData.FactoryId || '',
            visibility: productData.isVisible || false,
          });

          setSelectedProductImages(productData.selectedImages || []);
        } catch (error) {
          // setError(error.message);
        } finally {
          // setLoading(false);
        }
      } else {
        console.error('Missing Productcode in location state');
      }
    };

    loadProductDetails();
  }, [location.state, token]);

  // Function to handle printing
  const handlePrint = () => {
    window.print();
  };

  // Function to navigate to manage inventory page
  const handleNavigate = () => {
    navigate("/manageinventory");
  };

  // Function to navigate to previous image
  const goToPrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedProductImages.length - 1 : prevIndex - 1
    );
  };

  // Function to navigate to next image
  const goToNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedProductImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to handle removing an image from selected images
  const handleRemoveImage = (indexToRemove) => {
    setSelectedProductImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <>
    <div style={{ backgroundColor: "#D9D9D9", minHeight: "100vh" }}>
  <div className="mx-auto px-4 md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] pt-5">
    <div className="flex flex-col gap-5 p-5 mt-10 md:flex-row">
      <div className="flex-1 p-4 bg-white border border-gray-300 rounded-lg shadow-md">
        <div className="w-full mt-10 border print-container">
          <div className="mt-2">
            <p className="p-2 font-semibold">Supplier’s Info</p>
            <div>
              <div className="flex flex-col p-2">
                <div className="flex justify-between">
                  <p className="w-1/2">Factory Name:</p>
                  <p className="w-1/2">{productObject.supplierName}</p>
                </div>
                <div className="flex justify-between">
                  <p className="w-1/2">Address:</p>
                  <p className="w-1/2">{productObject.supplierCity}</p>
                </div>
                <div className="flex justify-between">
                  <p className="w-1/2">Contact:</p>
                  <p className="w-1/2">{productObject.contactNumber}</p>
                </div>
                <div className="flex justify-between">
                  <p className="w-1/2">Factory ID:</p>
                  <p className="w-1/2">{productObject.FactoryId}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between p-2">
            <p className="w-1/2">Product Name:</p>
            <p className="w-1/2">{productObject.productName}</p>
          </div>

          <hr className="my-2" />
          
          {[
            { label: "Category", value: productObject.category },
            { label: "Sub Category", value: productObject.subCategory },
            { label: "Product ID", value: productObject.productCode },
            { label: "Unit", value: productObject.unit },
            { label: "Base Price", value: `₹${productObject.price}/kg` },
            { label: "Discount", value: productObject.discount },
            { label: "Status", value: productObject.visibility ? "Active" : "Inactive" },
            { label: "Description", value: productObject.productDescription },
          ].map((item, index) => (
            <div key={index}>
              <div className="flex justify-between gap-4 p-2">
                <p className="w-1/2">{item.label}:</p>
                <p className="w-1/2">{item.value}</p>
              </div>
              <hr className="my-2" />
            </div>
          ))}
        </div>
      </div>

      <div className="flex-1">
        <div className="p-5 bg-white border border-gray-300 rounded-lg shadow-md h-[350px] md:w-50 w-full">
          <p className="font-bold text-start">Product Images</p>
          <div className="flex items-center justify-center w-full mt-5 h-60">
            <div className="relative w-full h-full p-12 bg-gray-100">
              {selectedProductImages.length > 0 ? (
                <img
                  src={selectedProductImages[currentImageIndex]}
                  alt="Product"
                  className="object-cover w-full h-full rounded-lg"
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <p>No images selected</p>
                </div>
              )}
              <div className="absolute inset-y-0 left-0 flex items-center justify-center w-12">
                <button onClick={goToPrevious} className="text-2xl text-white focus:outline-none">
                  <span className="p-3 text-black bg-gray-300 border rounded-lg">&lt;</span>
                </button>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center justify-center w-12">
                <button onClick={goToNext} className="text-2xl text-white focus:outline-none">
                  <span className="p-3 text-black bg-gray-300 border rounded-lg">&gt;</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-2">
          <button
            className="flex gap-3 p-3 text-white rounded-lg"
            style={{ backgroundColor: "#FB3232" }}
            onClick={handlePrint}
          >
            <IoPrintOutline className="w-5 h-5" />
            Print
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
}

export default ViewInventory;
