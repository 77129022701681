import React, { useState } from 'react';
import PurchaseRecordsPage from './PurchaseRecordsPage';
import PaymentRequest from './PaymentRequest';

function TrasactionList() {
  const [activeTab, setActiveTab] = useState(''); // Initialize state for active tab

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] p-5 md:p-0">
        <div className='pt-5'>
          {/* <p className='text-[28px] font-semibold'>Transaction Records</p> */}
          <p>Please choose the type of transaction records you would like to view. You can select either "Purchases by Factory" to review all purchase 
          transactions made from various factories or "Sales to Shops" to see the sales transactions completed with different shops.</p>
        </div>
        <div className='flex gap-5 mt-5'>
          <button
            className={`p-3 border rounded-lg ${activeTab === 'PurchaseRecords' ? 'bg-[#C6A26F] text-white' : ' bg-white'}`}
            onClick={() => setActiveTab('PurchaseRecords')}
          >
            Purchase & Sale Records 
          </button>
          <button
            className={`p-3 border rounded-lg ${activeTab === 'PaymentRequest' ? 'bg-[#C6A26F] text-white' : 'bg-white'}`}
            onClick={() => setActiveTab('PaymentRequest')}
          >
            Payment Request 
          </button>
        </div>
        <div className='mt-5'>
          {activeTab === "PurchaseRecords" && (
            <div>
              <PurchaseRecordsPage />
            </div>
          )}

          {activeTab === "PaymentRequest" && (
            <div>
              <PaymentRequest />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TrasactionList
