import axios from 'axios';
import React, { useState } from "react";
import * as XLSX from 'xlsx';
import { BaseUrl } from '../../auth/Url';

const FactoryModal = ({ isOpen, onClose, factory, selectedFactoryCommission }) => {
    console.log(factory, "factory");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    console.log(startDate, endDate);

    if (!isOpen || !factory) return null;

//    const handleDownloadInvoice = async () => {
//     if (startDate && endDate) {
//         try {
//             // Create date objects from selected dates
//             const start = new Date(startDate + 'T00:00:00Z'); // Treat it as UTC
//             const end = new Date(endDate + 'T23:59:59Z'); // Treat it as UTC

//             // Format dates as YYYY-MM-DD
//             const formattedStartDate = start.toISOString().split('T')[0];
//             const formattedEndDate = end.toISOString().split('T')[0];

//             const response = await axios.post(
//                 `${BaseUrl}/Factory/generate-factory-invoice`,
//                 {
//                     factoryId: factory.factoryId,
//                     startDate: formattedStartDate,
//                     endDate: formattedEndDate,
//                     factoryName: factory.factoryName,
//                     factoryAddress: factory.factoryAddress,
//                     factoryContact: factory.contactNo,
//                 },
//                 {
//                     responseType: 'blob', // To handle the PDF file response
//                 }
//             );

//             const blob = new Blob([response.data], { type: 'application/pdf' });
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = `factory_invoice_${factory.factoryId}.pdf`;
//             link.click();
//         } catch (error) {
//             console.error('Error downloading invoice:', error);
//             alert('Failed to download the invoice');
//         }
//     } else {
//         alert("Please select a valid date range");
//     }
// };

//     const handleDownloadInvoice = async () => {
//        debugger
//     if (startDate && endDate) {
//         try {
//             const start = new Date(startDate + 'T00:00:00Z');
//             const end = new Date(endDate + 'T23:59:59Z');

//             const formattedStartDate = start.toISOString().split('T')[0];
//             const formattedEndDate = end.toISOString().split('T')[0];

//             const response = await axios.post(
//                 `${BaseUrl}/Factory/generate-factory-invoice`,
//                 {
//                     factoryId: factory.factoryId,
//                     startDate: formattedStartDate,
//                     endDate: formattedEndDate,
//                     factoryName: factory.factoryName,
//                     factoryAddress: factory.factoryAddress,
//                     factoryContact: factory.contactNo,
//                 }
//             );

//             console.log('API response:', response.data); // Debugging log

//             const orders = response.data.orders || response.data; // Adjust based on actual response
//             if (!Array.isArray(orders)) {
//                 throw new Error('Invalid data format: orders is not an array');
//             }

//           const sheetData = orders.map(order => {
//     return order.ProductDetails.map(product => {
//         // Check if product.selection and product.selection.sizes are defined
//         // const sizeQuantity = product.selection && product.selection 
//         //     ? Object.entries(product.selection)
//         //         .map(([size, qty]) => `${size}: ${qty}`)
//         //         .join(', ')
//         //     : 'No size data'; // Fallback value if sizes are not available

//         return {
//             'Order ID': order.orderId,
//             'Order Date': new Date(product.OrderDate).toLocaleDateString(),
//             'Product Name': product.ProductName,
//             // s'Size-Quantity': sizeQuantity,
//             'Customer Name': order.customerInfo.CustomerName,
//             'Shop Name': order.customerInfo.ShopName,
//             'Email': order.customerInfo.EmailID,
//             'Commission': order.totalCommission
//  ? order.totalCommission
//  : 'N/A', // Check for totalCommission
//         };
//     });
// }).flat();


//             const workbook = XLSX.utils.book_new();
//             const worksheet = XLSX.utils.json_to_sheet(sheetData);
//             XLSX.utils.book_append_sheet(workbook, worksheet, 'Factory Invoice');
//             XLSX.writeFile(workbook, `factory_invoice_${factory.factoryName}_${factory.factoryId}.xlsx`);
//         } catch (error) {
//             console.error('Error downloading invoice:', error);
//             alert('Failed to download the invoice');
//         }
//     } else {
//         alert('Please select a valid date range');
//     }
// };
const handleDownloadInvoice = async () => {
    if (startDate && endDate) {
        try {
            const start = new Date(startDate + 'T00:00:00Z');
            const end = new Date(endDate + 'T23:59:59Z');

            const formattedStartDate = start.toISOString().split('T')[0];
            const formattedEndDate = end.toISOString().split('T')[0];

            const response = await axios.post(
                `${BaseUrl}/Factory/generate-factory-invoice`,
                {
                    factoryId: factory.factoryId,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    factoryName: factory.factoryName,
                    factoryAddress: factory.factoryAddress,
                    factoryContact: factory.contactNo,
                }
            );

            console.log('API response:', response.data);

            const orders = response.data.orders || response.data;
            if (!Array.isArray(orders)) {
                throw new Error('Invalid data format: orders is not an array');
            }

            // Preparing the data for the table
            const sheetData = orders.flatMap(order => {
                return order.ProductDetails.flatMap(product => {
                    return product.selection.map(selectionItem => ({
                        'Order ID': order.orderId,
                        'Order Date': new Date(product.OrderDate).toLocaleDateString(),
                        'Product Name': product.ProductName,
                        'Bag Size': selectionItem.size,
                        'Quantity': selectionItem.quantity,
                        'Customer Name': order.customerInfo.CustomerName,
                        'Shop Name': order.customerInfo.ShopName,
                        'Email': order.customerInfo.EmailID,
                        'Commission': order.totalCommission || 'N/A',
                    }));
                });
            });

            // Creating workbook and worksheet
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet([]);

            // Adding a custom header
            XLSX.utils.sheet_add_aoa(worksheet, [
                [`Factory Name: ${factory.factoryName}`],
                [`Factory Address: ${factory.factoryAddress}`],
                [`Contact: ${factory.contactNo}`],
                [`Date Range: ${formattedStartDate} to ${formattedEndDate}`],
                [] // Empty row before table
            ], { origin: 0 });

            // Adding the table data
            XLSX.utils.sheet_add_json(worksheet, sheetData, {
                origin: -1, // Append after existing content
                skipHeader: false // Include headers from JSON
            });

            // Adjusting column widths for better visibility
            const columnWidths = [
                { wch: 15 }, // Order ID
                { wch: 15 }, // Order Date
                { wch: 30 }, // Product Name
                { wch: 10 }, // Bag Size
                { wch: 10 }, // Quantity
                { wch: 20 }, // Customer Name
                { wch: 20 }, // Shop Name
                { wch: 25 }, // Email
                { wch: 15 }  // Commission
            ];
            worksheet['!cols'] = columnWidths;

            // Append worksheet to workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Factory Invoice');

            // Save file
            XLSX.writeFile(workbook, `factory_invoice_${factory.factoryName}_${factory.factoryId}.xlsx`);
        } catch (error) {
            console.error('Error downloading invoice:', error);
            alert('Failed to download the invoice');
        }
    } else {
        alert('Please select a valid date range');
    }
};


    return (
//         <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//             <div className="w-1/3 p-6 bg-white rounded shadow-lg">
//                 <h2 className="mb-4 text-xl font-bold">Factory Details</h2>
//                 <p><strong>Factory Name:</strong> {factory.factoryName}</p>
//                 <p><strong>Factory ID:</strong> {factory.factoryId}</p>
//                 <p><strong>Total Commission:</strong> {selectedFactoryCommission}</p>

//                 {/* Date range input fields */}
//                 <div className="mt-4">
//                     <p><strong>Select Date Range:</strong></p>
//                     <div className="flex space-x-4">
//                    <div>
//     <label className="block mb-2">From:</label>
//     <input
//         type="date"
//         value={startDate}
//         onChange={(e) => setStartDate(e.target.value)}
//         className="p-2 border rounded"
//         onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
//     />
// </div>
// <div>
//     <label className="block mb-2">To:</label>
//     <input
//         type="date"
//         value={endDate}
//         onChange={(e) => setEndDate(e.target.value)}
//         min={startDate} // Prevent selecting an end date before the start date
//         className="p-2 border rounded"
//         onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
//     />
// </div>

//                     </div>
//                 </div>

//                 {/* Download invoice button */}
//                 <button
//                     className="px-4 py-2 mt-4 text-white bg-green-500 rounded"
//                     onClick={handleDownloadInvoice}
//                 >
//                     Download Report
//                 </button>

//                 <button className="px-4 py-2 mt-4 ml-4 text-white bg-blue-500 rounded" onClick={onClose}>
//                     Close
//                 </button>
//             </div>
        //         </div>
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="w-full max-w-md p-6 bg-white rounded shadow-lg">
        <h2 className="mb-4 text-xl font-bold">Factory Details</h2>
        <p><strong>Factory Name:</strong> {factory.factoryName}</p>
        <p><strong>Factory ID:</strong> {factory.factoryId}</p>
        <p><strong>Total Commission:</strong> {selectedFactoryCommission}</p>

        {/* Date range input fields */}
        <div className="mt-4">
            <p><strong>Select Date Range:</strong></p>
            <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                <div className="flex-1">
                    <label className="block mb-2">From:</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="w-full p-2 border rounded"
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                    />
                </div>
                <div className="flex-1">
                    <label className="block mb-2">To:</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={startDate} // Prevent selecting an end date before the start date
                        className="w-full p-2 border rounded"
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                    />
                </div>
            </div>
        </div>

        {/* Download invoice button */}
        <button 
            className="w-full px-4 py-2 mt-4 text-white bg-green-500 rounded sm:w-auto"
            onClick={handleDownloadInvoice}
        >
            Download Report
        </button>

        <button 
            className="w-full px-4 py-2 mt-4 text-white bg-blue-500 rounded sm:w-auto sm:ml-4"
            onClick={onClose}
        >
            Close
        </button>
    </div>
</div>

    );
};

export default FactoryModal;
