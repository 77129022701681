import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import { IoDownload } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import AddInstallMent from "../Component/AddInstallMent";

function DetailsPurchaseReport() {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState(null);
  
  const location = useLocation();
  const { itemData } = location.state || {};
  useEffect(() => {
    if (itemData) {
      setData(itemData);
    }
    console.log(itemData);
  }, [itemData]);

  const HandleClose = () => {
    setIsModelOpen(false);
  };
  const HandleOpen = () => {
    setIsModelOpen(true);
  };
 const isDisabled = 
  parseFloat(data?.TransactionData?.[data.TransactionData.length - 1]?.Duepayment).toFixed(2) === "0.00";



  return (
    <div
      style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}
      className="pt-5"
    >
      <div className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] ">
      

        <div className="p-4 mt-5 bg-white rounded-lg">
  <div className="flex flex-col justify-between pt-2 pl-2 md:flex-row md:pl-10">
    <div className="w-full md:w-auto">
      <p className="text-2xl ">Order ID {data?.orderId}</p>
      <div className="flex items-center gap-5 mt-2">
        <div>
          <p className="">
            <span className="text-gray-500">Payment status</span>
          </p>
        </div>

        <div className="flex items-center justify-center">
          {
            data?.TransactionData?.[data?.TransactionData.length - 1]?.Total === '0.00' || data?.TransactionData?.[data?.TransactionData.length - 1]?.Total === '0' ?
              <div className='p-1 text-center text-yellow-500 border border-yellow-500 rounded-lg cursor-pointer hover:bg-yellow-600 hover:text-white' style={{ width: '80px', height: '30px', opacity: '1' }}>Return</div> :
              <div className={`p-1 text-center border rounded-lg cursor-pointer  ${
                parseFloat(data?.TransactionData[data?.TransactionData.length - 1]?.Duepayment).toFixed(2) === "0.00"
                  ? 'text-green-500 hover:bg-green-600 hover:text-white border-green-500' // Paid in green
                  : 'text-orange-500 hover:bg-orange-600 hover:text-white border-orange-500' // Pending in orange
              }`} style={{ width: '80px', height: '30px', opacity: '1' }}>
                {parseFloat(data?.TransactionData[data?.TransactionData.length - 1]?.Duepayment).toFixed(2) === "0.00" ? 'Paid' : 'Pending'}
              </div>
          }
        </div>
      </div>
    </div>
  </div>
  
  <hr className="mt-2 " />
  
  <div className="flex flex-col gap-4 pl-2 mt-5 md:flex-row md:pl-10">
    <div className="p-2 border w-full md:w-[500px] rounded-lg">
      <p className="font-semibold">Supplier Information</p>
      <div className={`overflow-hidden ${!showMore ? "h-[100px]" : "h-auto"}`}>
        {data?.ProductDetails.map((product, index) => (
          <div key={index} className="mb-4">
            <p className="font-semibold">Product Name: {product.ProductName}</p>
            <p>Factory Name: {product.SupplierInfo.FactoryName}</p>
            <p>Factory Address: {product.SupplierInfo.FactoryAddress}</p>
            <p>Contact No: {product.SupplierInfo.FactoryContact}</p>
          </div>
        ))}
      </div>
      {data?.ProductDetails.length > 1 && (
        <button
          onClick={() => setShowMore(!showMore)}
          className="mt-2 text-blue-500"
        >
          {showMore ? "Show Less" : "Show More"}
        </button>
      )}
    </div>

    <div className="p-2 border w-full md:w-[500px] h-[170px] rounded-lg overflow-y-auto">
      <p className="font-semibold">Product Details</p>
      {data?.ProductDetails.length > 0 ? (
        data.ProductDetails.map((product, index) => {
          // Calculate the discount price if a discount is available
          const discountedPrice = product.discount
            ? (product.MRP - (product.MRP * product.discount) / 100).toFixed(2)
            : product.MRP;

          return (
            <div key={index} className="mb-4">
              <p className="font-semibold">Product Name: {product.ProductName}</p>
              <p>
                MRP/kg:
                {product.discount ? (
                  <>
                    <del> ₹{product.MRP} </del>
                    <span> ₹{discountedPrice} ({product.discount}% off)</span>
                  </>
                ) : (
                  <span> ₹{product.MRP}</span>
                )}
              </p>
              {product.selection.length > 0 ? (
                product.selection.map((item, itemIndex) => (
                  <div key={itemIndex} className="mb-2">
                    <span>Bag Size: {item.size}</span>{" "}
                    <span>Quantity: {item.quantity}</span>
                  </div>
                ))
              ) : (
                <p>No selection available</p>
              )}
            </div>
          );
        })
      ) : (
        <p>No products available</p>
      )}
    </div>

    <div className="p-2 border w-full md:w-[500px] h-[170px] rounded-lg overflow-y-auto">
      <p className="font-semibold">Customer Information</p>
      <div>
        <p>
          <span className="font-semibold">Customer Name:</span>{" "}
          {data?.customerInfo?.CustomerName}
        </p>
        <p>
          <span className="font-semibold">Shop Name: </span>
          {data?.customerInfo?.ShopName}
        </p>
        <p>
          <span className="font-semibold">Contact No:</span>{" "}
          {data?.customerInfo?.ContactNo}
        </p>
        <p>
          <span className="font-semibold">Email ID: </span>
          {data?.customerInfo?.EmailID}
        </p>
        <p>
          <span className="font-semibold">Billing Address:</span>{" "}
          {data?.customerInfo?.Billing_Address}
        </p>
        <p>
          <span className="font-semibold">Shipping Address:</span>{" "}
          {data?.customerInfo?.Shipping_Address}
        </p>
      </div>
    </div>
  </div>
</div>


        {/* <div className="p-2 mt-5 bg-white rounded-lg">
          <div className="flex justify-between pt-2 pl-10">
            <div>
              <p className="text-[16px] font-semibold">Payment Details</p>
            </div>
            <div>
              <button
                className={`flex gap-2 px-4 py-2 text-white rounded-lg ${
                  isDisabled
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-[rgba(198,162,111,1)]"
                }`}
                onClick={isDisabled ? undefined : HandleOpen}
                disabled={isDisabled}
                style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
              >
                <FaPlus className="mt-1" />
                <p>ADD INSTALLMENT</p>
              </button>
            </div>
          </div>

      <div className="mt-5">
  <table className="w-full bg-white border border-gray-300 rounded-lg">
    <thead>
      <tr>
        <th className="p-2 border-b">Total Payment</th>
        <th className="p-2 border-b">Payment Status</th>
        <th className="p-2 border-b">Complete Payment</th>
        <th className="p-2 border-b">Payment Mode</th>
        <th className="p-2 border-b">Payment Date</th>
        <th className="p-2 border-b">Due Payment</th>
      </tr>
    </thead>
    <tbody>
      {data?.TransactionData?.filter(record => typeof record.PaymentMethod === 'string' && record.PaymentMethod.trim() !== '').length > 0 ? (
        data.TransactionData
          .filter(record => typeof record.PaymentMethod === 'string' && record.PaymentMethod.trim() !== '')
          .map((record, index) => (
            <tr key={index} className="border-t">
              <td className="p-2 text-center">
                <span>₹ {record.Total}</span>
              </td>
              <td className="p-2 text-center">
                <div
                  className={`p-2 text-center border rounded-md ${
                    record.Duepayment === "0.00"
                      ? "text-green-500 border-green-500"
                      : record.PaymentDoneAmount !== "0"
                      ? "text-yellow-500 border-yellow-500"
                      : "text-red-500 border-red-500"
                  }`}
                >
                  {record.Duepayment === "0.00"
                    ? "Paid"
                    : record.PaymentDoneAmount !== "0.00"
                    ? "Partially Paid"
                    : "Pending"}
                </div>
              </td>
              <td className="p-2 text-center">₹ {record.PaymentDoneAmount}</td>
              <td className="flex justify-center gap-2 p-2 mt-2 cursor-pointer hover:underline">
                <span>{record.PaymentMethod}</span>
                <span className="mt-1.5"><FiInfo /></span>
              </td>
              <td className="p-2 text-center">
                {new Date(record.TransactionDate).toLocaleDateString()}
              </td>
              <td className="p-2 text-center text-red-500">
  ₹ {record.Duepayment ? parseFloat(record.Duepayment).toFixed(2) : '0.00'}
</td>

            </tr>
          ))
      ) : (
        <tr>
          <td colSpan="6" className="p-2 text-center text-red-500">No records found!</td>
        </tr>
      )}
    </tbody>
  </table>
</div>





        </div> */}
        <div className="p-2 mt-5 bg-white rounded-lg">
  <div className="flex flex-row justify-between gap-2 pt-2 pl-10">
    <div>
      <p className="text-[16px] font-semibold hidden md:block">Payment Details</p>
    </div>
    <div className="mt-2 md:mt-0">
      <button
        className={`flex gap-2 px-4 py-2 text-white rounded-lg ${
          isDisabled
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-[rgba(198,162,111,1)]"
        }`}
        onClick={isDisabled ? undefined : HandleOpen}
        disabled={isDisabled}
        style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
      >
        <FaPlus className="mt-1" />
        <p className="text-sm md:text-md">ADD INSTALLMENT</p>
      </button>
    </div>
  </div>

  <div className="mt-5 overflow-x-auto">
    <table className="w-full bg-white border border-gray-300 rounded-lg">
      <thead>
        <tr>
          <th className="p-2 border-b">Total Payment</th>
          <th className="p-2 border-b">Payment Status</th>
          <th className="p-2 border-b">Complete Payment</th>
          <th className="p-2 border-b">Payment Mode</th>
          <th className="p-2 border-b">Payment Date</th>
          <th className="p-2 border-b">Due Payment</th>
        </tr>
      </thead>
      <tbody>
        {data?.TransactionData?.filter(record => typeof record.PaymentMethod === 'string' && record.PaymentMethod.trim() !== '').length > 0 ? (
          data.TransactionData
            .filter(record => typeof record.PaymentMethod === 'string' && record.PaymentMethod.trim() !== '')
            .map((record, index) => (
              <tr key={index} className="border-t">
                <td className="p-2 text-center">
                  <span>₹ {record.Total}</span>
                </td>
                <td className="p-2 text-center">
                  <div
                    className={`p-2 text-center border rounded-md ${
                      record.Duepayment === "0.00"
                        ? "text-green-500 border-green-500"
                        : record.PaymentDoneAmount !== "0"
                        ? "text-yellow-500 border-yellow-500"
                        : "text-red-500 border-red-500"
                    }`}
                  >
                    {record.Duepayment === "0.00"
                      ? "Paid"
                      : record.PaymentDoneAmount !== "0.00"
                      ? "Partially Paid"
                      : "Pending"}
                  </div>
                </td>
                <td className="p-2 text-center">₹ {record.PaymentDoneAmount}</td>
                <td className="flex justify-center gap-2 p-2 mt-2 cursor-pointer hover:underline">
                  <span>{record.PaymentMethod}</span>
                  <span className="mt-1.5"><FiInfo /></span>
                </td>
                <td className="p-2 text-center">
                  {new Date(record.TransactionDate).toLocaleDateString()}
                </td>
                <td className="p-2 text-center text-red-500">
                  ₹ {record.Duepayment ? parseFloat(record.Duepayment).toFixed(2) : '0.00'}
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan="6" className="p-2 text-center text-red-500">No records found!</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>

      </div>
      <div className="flex justify-end gap-5 mt-8">
        <Link
          to={"/transactionrecords"}
          //  onClick={onClose}
          className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg mr-9 hover:bg-gray-400 hover:text-white"
        >
          Back
        </Link>
        {/* <button
          
          className="px-4 py-2 mt-4 text-white transition rounded-lg"
          style={{ backgroundColor: "#4C3F35" }}
        >
          UPDATE & SAVE
        </button> */}
      </div>

      <AddInstallMent isModalOpen={isModelOpen} onClose={HandleClose} data={data} />
        </div>
    
  );
}

export default DetailsPurchaseReport;
