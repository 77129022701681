import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchOrderData } from '../../Service/OrderManagment/OrderManagmentApi';
import Order_Managment_Model from '../Component/Order_Managment_Model';
function View_Order() {
  const location = useLocation();
  const { orderId, productId,Action } = location.state || {};
  console.log(Action,"Action")
  const [selectedActions, setSelectedActions] = useState({});
  console.log(selectedActions,"selectedActions")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderData, setOrderData] = useState(null);
  console.log(orderData,"orderData")
console.log(orderData)
  useEffect(() => {
    
    // Fetch data when component mounts or when orderId/productId change
    if (orderId && productId) {
      const getData = async () => {
        try {
          const data = await fetchOrderData(orderId, productId);
          setOrderData(data);
        } catch (error) {
          console.error('Error fetching order data:', error);
        }
      };

      getData();
    }
  }, [orderId, productId]);

  const handleActionChange = (productId, action) => {
    setSelectedActions((prevState) => ({
      ...prevState,
      ProductID: productId,
      Action: action,
     
      
    }));
  };

const openModal = () => {
  // Check if the selectedActions object is empty
  if (Object.keys(selectedActions).length === 0) {
    alert("Please select an action before proceeding.");
    return;
  }else {
    // Action is selected, proceed to open the modal
    setIsModalOpen(true);
  }
};

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getButtonClass = (action) => {
    switch (action) {
      case 'accept':
        return 'bg-green-500 text-white';
      case 'Pending':
        return 'bg-orange-500 text-white';
      case 'Cancel':
        return 'bg-red-500 text-white';
      default:
        return 'text-black';
    }
  };

  return (
//     <div style={{ backgroundColor: 'rgba(217, 217, 217, 1)', minHeight: '100vh' }}>
//       <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
//         <div className="p-5">
//           <div className="p-5 mt-5 bg-white rounded-lg">
//             <div className="flex justify-between">
//               <div>
//                <p className="text-[28px] font-semibold">Order {orderId}</p>
//                 <p>
//                   <span className="text-gray-500">Ordered on</span> {orderData?.ProductDetails
// .OrderDate || 'N/A'}
//                 </p>
//               </div>
//             </div>
//             <hr className="mt-5 border-1" />
//             <div className="flex gap-10 pl-10 mt-5">
//               {/* <div className="p-2 border w-[300px] h-[250px] rounded-lg">
//                 <p className="font-semibold">Customer info-</p>
//                 <div>
//                   <p>Customer Name: {orderData?.customerInfo?.CustomerName || 'N/A'}</p>
//                   <p>Shop Name: {orderData?.customerInfo?.ShopName || 'N/A'}</p>
//                   <p>Contact No: {orderData?.customerInfo?.ContactNo || 'N/A'}</p>
//                   <p>Email ID: {orderData?.customerInfo?.EmailID || 'N/A'}</p>
                 
                  
                 
//                 </div>
//                 <p className="font-semibold">Supplier info-</p>
//                  <div>
//                   <p>Supplier Name: {orderData?.ProductDetails
// ?.SupplierInfo.FactoryName
//  || 'N/A'}</p>
//                   <p>Shop Name: {orderData?.customerInfo?.ShopName || 'N/A'}</p>
//                   <p>Contact No: {orderData?.customerInfo?.ContactNo || 'N/A'}</p>
//                   <p>Email ID: {orderData?.customerInfo?.EmailID || 'N/A'}</p>
                 
                  
                 
//                 </div>
//               </div> */}
//               <div className="p-2 border w-[300px] h-[250px] rounded-lg flex flex-col overflow-auto">
//   <div className="mb-2">
//     <p className="font-semibold">Dealer info-</p>
//     <div>
//       <p>Customer Name: {orderData?.customerInfo?.CustomerName || 'N/A'}</p>
//       <p>Shop Name: {orderData?.customerInfo?.ShopName || 'N/A'}</p>
//       <p>Contact No: {orderData?.customerInfo?.ContactNo || 'N/A'}</p>
//       <p>Email ID: {orderData?.customerInfo?.EmailID || 'N/A'}</p>
//     </div>
//   </div>
  
// </div>

//              <div className="p-2 border w-[300px] h-[250px] rounded-lg">
//     <p className="font-semibold">Billing Address</p>
//     <div
//         className="h-20 overflow-y-auto" // Fixed height for the scrollable area
//         title={orderData?.customerInfo.Billing_Address || 'N/A'}
//     >
//         {orderData?.customerInfo.Billing_Address
//             ? orderData.customerInfo.Billing_Address.split(' ').slice(0, 20).join(' ')
//             : 'N/A'}
//     </div>
//     <div className='mt-5'>
//         <p className="font-semibold">Shipping Address</p>
//         <div
//             className="h-20 overflow-y-auto" // Fixed height for the scrollable area
//             title={orderData?.customerInfo.Shipping_Address || 'N/A'}
//         >
//             {orderData?.customerInfo.Shipping_Address
//                 ? orderData.customerInfo.Shipping_Address.split(' ').slice(0, 20).join(' ')
//                 : 'N/A'}
//         </div>
//     </div>
// </div>

//               {/* <div className="p-2 border w-[300px] h-[250px] rounded-lg">
//                 <p className="font-semibold">Shipping Address</p>
//                 <div>{orderData?.customerInfo.Shipping_Address || 'N/A'}</div>
//               </div> */}
//                <div className="p-2 border w-[300px] h-[250px] rounded-lg">
//                 {/* <p className="font-semibold">Order Details</p>
//                 <div>
//                   <p>Order Amount : {orderData?.Total}</p>
//                   <p>PaymentDoneAmount : {orderData?.PaymentDoneAmount}</p>
//                    <p>
//                     Duepayment : {orderData?.Duepayment}</p>
//                   <p className='flex gap-2 '> Payment Method:<span className='cursor-pointer hover:underline'> {orderData?.PaymentMethod || 'N/A'} </span><span className='mt-1.5'><FiInfo /></span></p>
//                    <div className="flex gap-5 mt-2">
//                     <p>Payment Status: </p>
//                    {
//   orderData?.Duepayment > 0 ? (
//     <div className="p-1 text-center text-white bg-[#FF9900] border border-[#FF9900] rounded-lg cursor-pointer" style={{ width: '80px', height: '30px', opacity: '1' }}>
//       Pending
//     </div>
//   ) : (
//     <div className="p-1 text-center text-white bg-green-600 border border-green-800 rounded-lg cursor-pointer" style={{ width: '80px', height: '30px', opacity: '1' }}>
//       Paid
//     </div>
//   )
// }

                    
//                   </div>
//                 </div> */}
//                 <p className="font-semibold">Supplier’s Details</p>
//                 <div className="mb-2">
   
//                   <div>
//                     <p>Supplier Id: {orderData?.ProductDetails
// ?.SupplierInfo.FactoryId

//  || 'N/A'}</p>
//       <p>Supplier Name: {orderData?.ProductDetails
// ?.SupplierInfo.FactoryName
//  || 'N/A'}</p>
      
//       <p>Supplier Contact No: {orderData?.ProductDetails
// ?.SupplierInfo.FactoryContact


//  || 'N/A'}</p>
//       <p> Supplier Address: {orderData?.ProductDetails
// ?.SupplierInfo.FactoryAddress



//  || 'N/A'}</p>
//     </div>
//   </div>
//               </div>
//             </div>
//             <div className="flex items-center pl-5 pr-5 mt-10">
//               <span className="mr-2 font-semibold">Product Details</span>
//               <hr className="flex-grow" />
//             </div>
//             <div className="mt-10">
//               <table className="min-w-full border-collapse">
//                 <thead style={{ borderBottom: '2px solid black' }}>
//                   <tr>
//                     <th className="p-2 font-normal">Product ID</th>
//                     <th className="p-2 font-normal">Supplier/ Factory</th>
//                     <th className="p-2 font-normal">Bag Size & Qty</th>
                    
//                     <th className="p-2 font-normal">MRP/kg</th>
                    
//                     <th className="p-2 font-normal">Payment</th>
//                     <th className="p-2 font-normal">Due Payment</th>
                   
//                     <th className="p-2 font-normal">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {orderData?.ProductDetails ? (
//                     <tr className="text-center">
//                       <td className="p-2">
//                         <div className='flex gap-1'>
//                            <img
//     src={orderData.ProductDetails.selectedImages[0]}
//     alt={orderData.ProductDetails.productName}
//     className="w-20 h-20"
//                           /><div className='flex items-center'>
//                           {orderData.ProductDetails.ProductName}<br></br>{orderData.ProductDetails.ProductID}
//                           </div>
//                         </div>
//                        </td>
                     
//                       <td className="p-2">{orderData?.ProductDetails.SupplierInfo?.FactoryName || 'N/A'}</td>
                     
//   {/* <td className="p-2">{orderData.ProductDetails.ProductName}</td>  */}
//   <td className="p-2">
//   {orderData.ProductDetails.selection.map((item, index) => (
//     <div key={index}>
//       {item.size} - {item.quantity}
//     </div>
//   ))}
// </td>

//  {/* Displaying the quantity */}
//                     <td className="p-2">
//   {orderData.ProductDetails.discount ? (
//     <>
//       <del>MRP: {orderData.ProductDetails.MRP}/kg</del>
   
//     {" "} {(orderData.ProductDetails.MRP - (orderData.ProductDetails.MRP * (orderData.ProductDetails.discount / 100))).toFixed(2)}/kg
//     </>
//   ) : (
//     `MRP:${orderData.ProductDetails.MRP}/kg`
//   )}
// </td>


                     
//                       <td className="p-2"> ₹ {orderData?.Total}</td>
//                       <td className="p-2 text-red-500">₹ {orderData?.Duepayment}</td>
//                       {
//                         Action === '' && (
//                           <td className="p-2">
//                         <button
//                           className={`border border-[#28C76F] p-2 rounded-lg text-black mr-2 mb-2 w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'accept' ? 'accept' : '')}`}
//                           onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'accept')}
//                         >
//                           Confirm
//                         </button><br></br>
//                         <button
//                           className={`border border-[#FF9900] p-2 rounded-lg mr-2  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Pending' ? 'Pending' : '')}`}
//                           onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Pending')}
//                         >
//                           Pending
//                         </button><br></br>
//                         <button
//                           className={`border border-[#FB3232] mt-2 p-2 rounded-lg  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Cancel' ? 'Cancel' : '')}`}
//                           onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Cancel')}
//                         >
//                           Cancel
//                         </button>
//                       </td>
//                         )
//                       }
//                       {
//                         Action === 'Pending' && (
//                           <td className="p-2">
//                         <button
//                           className={`border border-[#28C76F] p-2 rounded-lg text-black mr-2 mb-2 w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'accept' ? 'accept' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'accept')}
//                         >
//                           Confirm
//                         </button><br></br>
//                         <button
//                           className={`border border-[#FF9900] bg-orange-500 text-white p-2 rounded-lg mr-2  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Pending' ? 'Pending' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Pending')}
//                         >
//                           Pending
//                         </button><br></br>
//                         <button
//                           className={`border border-[#FB3232] mt-2 p-2 rounded-lg  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Cancel' ? 'Cancel' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Cancel')}
//                         >
//                           Cancel
//                         </button>
//                       </td>
//                         )
//                       }
//                       {
//                         Action === 'Shipped' && (
//                           <td className="p-2">
//                         <button
//                           className={`border border-[#28C76F] p-2 rounded-lg  mr-2 mb-2 w-[100px] h-[40px]  bg-green-500 text-white ${getButtonClass(selectedActions.Action === 'accept' ? 'accept' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'accept')}
//                         >
//                           Confirm
//                         </button><br></br>
//                         <button
//                           className={`border border-[#FF9900]  p-2 rounded-lg mr-2  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Pending' ? 'Pending' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Pending')}
//                         >
//                           Pending
//                         </button><br></br>
//                         <button
//                           className={`border border-[#FB3232] mt-2 p-2 rounded-lg  w-[100px] h-[40px]  ${getButtonClass(selectedActions.Action === 'Cancel' ? 'Cancel' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Cancel')}
//                         >
//                           Cancel
//                         </button>
//                       </td>
//                         )
//                       }
//                        {
//                         Action === 'Canceled' && (
//                           <td className="p-2">
//                         <button
//                           className={`border border-[#28C76F] p-2 rounded-lg  mr-2 mb-2 w-[100px] h-[40px]   ${getButtonClass(selectedActions.Action === 'accept' ? 'accept' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'accept')}
//                         >
//                           Confirm
//                         </button><br></br>
//                         <button
//                           className={`border border-[#FF9900]  p-2 rounded-lg mr-2  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Pending' ? 'Pending' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Pending')}
//                         >
//                           Pending
//                         </button><br></br>
//                         <button
//                           className={`border border-[#FB3232] mt-2 p-2 rounded-lg  w-[100px] h-[40px] bg-red-500 text-white ${getButtonClass(selectedActions.Action === 'Cancel' ? 'Cancel' : '')}`}
//                           // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Cancel')}
//                         >
//                           Cancel
//                         </button>
//                       </td>
//                         )
//                       }
                     
//                     </tr>
//                   ) : (
//                     <tr>
//                       <td colSpan="12" className="p-4 text-center">No product details available</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//               <div className="flex justify-end gap-5 mt-8">
//                 <Link className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg" to={"/ordermanagement"}>Back</Link>
//               <button
//   className={`px-4 py-2 mt-4 transition rounded-lg ${
//     Action === '' ? 'text-white' : 'text-gray-400 cursor-not-allowed'
//   }`}
//   style={{
//     backgroundColor: Action === '' ? '#4C3F35' : '#E0E0E0', // Different colors for enabled/disabled states
//   }}
//   onClick={openModal}
//   disabled={Action !== ''} // Disable the button when Action is not empty
// >
//   Confirm & Proceed
// </button>

//               </div>
//             </div>
//           </div>
//         </div>
//         <Order_Managment_Model isModalOpen={isModalOpen} onClose={closeModal} ModelAction={selectedActions.Action} orderData={orderData} selectedActions={selectedActions} />
//       </div>
//         </div>
    <div style={{ backgroundColor: 'rgba(217, 217, 217, 1)', minHeight: '100vh' }}>
  <div className="container mx-auto md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
    <div className="p-5">
      <div className="p-5 mt-5 bg-white rounded-lg shadow-md">
        <div className="flex flex-col justify-between md:flex-row">
          <div>
            <p className="text-[28px] font-semibold">Order {orderId}</p>
            <p>
              <span className="text-gray-500">Ordered on</span> {orderData?.ProductDetails.OrderDate || 'N/A'}
            </p>
          </div>
        </div>
        <hr className="mt-5 border-1" />
        <div className="flex flex-col gap-10 mt-5 md:flex-row">
          <div className="p-2 border flex-1 rounded-lg flex flex-col overflow-auto h-[250px]">
            <div className="mb-2">
              <p className="font-semibold">Dealer info-</p>
              <div>
                <p>Customer Name: {orderData?.customerInfo?.CustomerName || 'N/A'}</p>
                <p>Shop Name: {orderData?.customerInfo?.ShopName || 'N/A'}</p>
                <p>Contact No: {orderData?.customerInfo?.ContactNo || 'N/A'}</p>
                <p>Email ID: {orderData?.customerInfo?.EmailID || 'N/A'}</p>
              </div>
            </div>
          </div>

          <div className="flex-1 p-2 border rounded-lg">
            <p className="font-semibold">Billing Address</p>
            <div
              className="h-20 overflow-y-auto"
              title={orderData?.customerInfo.Billing_Address || 'N/A'}
            >
              {orderData?.customerInfo.Billing_Address 
                ? orderData.customerInfo.Billing_Address.split(' ').slice(0, 20).join(' ')
                : 'N/A'}
            </div>
            <div className='mt-5'>
              <p className="font-semibold">Shipping Address</p>
              <div 
                className="h-20 overflow-y-auto"
                title={orderData?.customerInfo.Shipping_Address || 'N/A'}
              >
                {orderData?.customerInfo.Shipping_Address 
                  ? orderData.customerInfo.Shipping_Address.split(' ').slice(0, 20).join(' ')
                  : 'N/A'}
              </div>
            </div>
          </div>

          <div className="flex-1 p-2 border rounded-lg">
            <p className="font-semibold">Supplier’s Details</p>
            <div className="mb-2">
              <div>
                <p>Supplier Id: {orderData?.ProductDetails?.SupplierInfo.FactoryId || 'N/A'}</p>
                <p>Supplier Name: {orderData?.ProductDetails?.SupplierInfo.FactoryName || 'N/A'}</p>
                <p>Supplier Contact No: {orderData?.ProductDetails?.SupplierInfo.FactoryContact || 'N/A'}</p>
                <p>Supplier Address: {orderData?.ProductDetails?.SupplierInfo.FactoryAddress || 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center pl-5 pr-5 mt-10">
          <span className="mr-2 font-semibold">Product Details</span>
          <hr className="flex-grow" />
        </div>
        <div className="mt-10 overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead style={{ borderBottom: '2px solid black' }}>
              <tr>
                <th className="p-2 font-normal">Product ID</th>
                <th className="p-2 font-normal">Supplier/ Factory</th>
                <th className="p-2 font-normal">Bag Size & Qty</th>
                <th className="p-2 font-normal">MRP/kg</th>
                <th className="p-2 font-normal">Payment</th>
                <th className="p-2 font-normal">Due Payment</th>
                <th className="p-2 font-normal">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orderData?.ProductDetails ? (
                <tr className="text-center">
                  <td className="p-2">
                    <div className='flex gap-1'>
                      <img
                        src={orderData.ProductDetails.selectedImages[0]}
                        alt={orderData.ProductDetails.productName}
                        className="hidden md:block md:h-20 md:w-20"

                      />
                      <div className='flex items-center'>
                        {orderData.ProductDetails.ProductName}<br />{orderData.ProductDetails.ProductID}
                      </div>
                    </div>
                  </td>
                  <td className="p-2">{orderData?.ProductDetails.SupplierInfo?.FactoryName || 'N/A'}</td>
                  <td className="p-2">
                    {orderData.ProductDetails.selection.map((item, index) => (
                      <div key={index}>
                        {item.size} - {item.quantity}
                      </div>
                    ))}
                  </td>
                  <td className="p-2">
                    {orderData.ProductDetails.discount ? (
                      <>
                        <del>MRP: {orderData.ProductDetails.MRP}/kg</del>
                        {" "} {(orderData.ProductDetails.MRP - (orderData.ProductDetails.MRP * (orderData.ProductDetails.discount / 100))).toFixed(2)}/kg
                      </>
                    ) : (
                      `MRP: ${orderData.ProductDetails.MRP}/kg`
                    )}
                  </td>
                  <td className="p-2"> ₹ {orderData?.Total}</td>
                  <td className="p-2 text-red-500">₹ {orderData?.Duepayment}</td>
                  <td className="p-2">
                     {
                        Action === '' && (
                          <td className="p-2">
                        <button
                          className={`border border-[#28C76F] p-2 rounded-lg text-black mr-2 mb-2 w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'accept' ? 'accept' : '')}`}
                          onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'accept')}
                        >
                          Confirm
                        </button><br></br>
                        <button
                          className={`border border-[#FF9900] p-2 rounded-lg mr-2  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Pending' ? 'Pending' : '')}`}
                          onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Pending')}
                        >
                          Pending
                        </button><br></br>
                        <button
                          className={`border border-[#FB3232] mt-2 p-2 rounded-lg  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Cancel' ? 'Cancel' : '')}`}
                          onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Cancel')}
                        >
                          Cancel
                        </button>
                      </td>
                        )
                      }
                      {
                        Action === 'Pending' && (
                          <td className="p-2">
                        <button
                          className={`border border-[#28C76F] p-2 rounded-lg text-black mr-2 mb-2 w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'accept' ? 'accept' : '')}`}
                          // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'accept')}
                        >
                          Confirm
                        </button><br></br>
                        <button
                          className={`border border-[#FF9900] bg-orange-500 text-white p-2 rounded-lg mr-2  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Pending' ? 'Pending' : '')}`}
                          // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Pending')}
                        >
                          Pending
                        </button><br></br>
                        <button
                          className={`border border-[#FB3232] mt-2 p-2 rounded-lg  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Cancel' ? 'Cancel' : '')}`}
                          // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Cancel')}
                        >
                          Cancel
                        </button>
                      </td>
                        )
                      }
                      {
                        Action === 'Shipped' && (
                          <td className="p-2">
                        <button
                          className={`border border-[#28C76F] p-2 rounded-lg  mr-2 mb-2 w-[100px] h-[40px]  bg-green-500 text-white ${getButtonClass(selectedActions.Action === 'accept' ? 'accept' : '')}`}
                          // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'accept')}
                        >
                          Confirm
                        </button><br></br>
                        <button
                          className={`border border-[#FF9900]  p-2 rounded-lg mr-2  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Pending' ? 'Pending' : '')}`}
                          // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Pending')}
                        >
                          Pending
                        </button><br></br>
                        <button
                          className={`border border-[#FB3232] mt-2 p-2 rounded-lg  w-[100px] h-[40px]  ${getButtonClass(selectedActions.Action === 'Cancel' ? 'Cancel' : '')}`}
                          // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Cancel')}
                        >
                          Cancel
                        </button>
                      </td>
                        )
                      }
                        {
                          Action === 'Canceled' && (
                            <td className="p-2">
                              <button
                                className={`border border-[#28C76F] p-2 rounded-lg  mr-2 mb-2 w-[100px] h-[40px]   ${getButtonClass(selectedActions.Action === 'accept' ? 'accept' : '')}`}
                              // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'accept')}
                              >
                                Confirm
                              </button><br></br>
                              <button
                                className={`border border-[#FF9900]  p-2 rounded-lg mr-2  w-[100px] h-[40px] ${getButtonClass(selectedActions.Action === 'Pending' ? 'Pending' : '')}`}
                              // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Pending')}
                              >
                                Pending
                              </button><br></br>
                              <button
                                className={`border border-[#FB3232] mt-2 p-2 rounded-lg  w-[100px] h-[40px] bg-red-500 text-white ${getButtonClass(selectedActions.Action === 'Cancel' ? 'Cancel' : '')}`}
                              // onClick={() => handleActionChange(orderData.ProductDetails.ProductID, 'Cancel')}
                              >
                                Cancel
                              </button>
                            </td>
                          )}
                   
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="7" className="p-4 text-center">No products found</td>
                </tr>
              )}
            </tbody>
              </table>
              <div className="flex justify-end gap-5 mt-8">
                <Link className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg" to={"/ordermanagement"}>Back</Link>
              <button
  className={`px-4 py-2 mt-4 transition rounded-lg ${
    Action === '' ? 'text-white' : 'text-gray-400 cursor-not-allowed'
  }`}
  style={{
    backgroundColor: Action === '' ? '#4C3F35' : '#E0E0E0', // Different colors for enabled/disabled states
  }}
  onClick={openModal}
  disabled={Action !== ''} // Disable the button when Action is not empty
>
  Confirm & Proceed
</button>

              </div>
        </div>
      </div>
        </div>
         <Order_Managment_Model isModalOpen={isModalOpen} onClose={closeModal} ModelAction={selectedActions.Action} orderData={orderData} selectedActions={selectedActions} />
  </div>
</div>

  );
}

export default View_Order;
