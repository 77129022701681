import React, { useEffect, useState } from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
import { addCommissionSlab, deleteCommissionSlab, fetchCommissionSlabs, toggleCommissionSlab } from '../../Service/CommissionSlab/CommisionManagmentSlab';
import Loader from '../Component/Loader';
import Swal from 'sweetalert2';
const CommissionSlab = () => {
  const [commissionSlabs, setCommissionSlabs] = useState([]);
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
    const [loading, setLoading] = useState(false);
  const [selectedBagSize, setSelectedBagSize] = useState('30 Kg');
  const [commissionData, setCommissionData] = useState({
    dealer30Kg: '',
    supplier30Kg: '',
    dealer50Kg: '',
    supplier50Kg: '',
    dealer25Kg: '',
    supplier25Kg: '',
     dealer100Kg: '',
     supplier100Kg: ''
  });

  console.log(commissionData,"commissionData")
  const [error, setError] = useState('');

  useEffect(() => {
   
    fetchData();
  }, []);
   const fetchData = async () => {
      try {
        const slabs = await fetchCommissionSlabs();
        setCommissionSlabs(slabs.reverse());
      } catch (error) {
        console.error('Error fetching commission slabs', error);
      }
    };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value) && value >= 0) {
        // Normalize name to use consistent casing
        const normalizedKey = name[0].toLowerCase() + name.slice(1);
        setCommissionData({ ...commissionData, [normalizedKey]: value });
    }
};


  const handleAddCommissionSlab = async () => {
  setLoading(true)
  if (!category.trim() || !subcategory.trim()) {
    setError('Category and Subcategory are required');
    hideErrorAfterDelay();
     setLoading(false)
    return;
  }

  // Check if at least one commission is filled
  const hasCommission = Object.keys(commissionData).some(
    (key) => commissionData[key] && commissionData[key].trim() !== ''
  );

  if (!hasCommission) {
    setError('At least one commission value must be provided');
    hideErrorAfterDelay();
     setLoading(false)
    return;
  }

 try {
  const newSlab = {
    category,
    subcategory,
    commissions: { ...commissionData },
    isActive: true,
  };

  const addedSlab = await addCommissionSlab(newSlab);
  setCommissionSlabs((prevSlabs) => [...prevSlabs, addedSlab]);
  resetForm();
  setLoading(false);
  fetchData();

  // Show success message after adding the slab
  Swal.fire({
    icon: 'success',
    title: 'Success!',
    text: 'The commission slab has been added.',
    confirmButtonText: 'OK',
  });
} catch (error) {
  console.error('Error adding commission slab', error);
  setLoading(false);

  // Show error message
  Swal.fire({
    icon: 'error',
    title: 'Error!',
    text: 'There was a problem adding the commission slab.',
    confirmButtonText: 'OK',
  });
}

};



  const hideErrorAfterDelay = () => {
    setTimeout(() => setError(''), 5000);
  };

 const handleDeleteCommissionSlab = async (id) => {
  try {
    // Show a confirmation alert
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this commission slab?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    // If confirmed, proceed with deletion
    if (result.isConfirmed) {
      await deleteCommissionSlab(id);
      setCommissionSlabs((prevSlabs) => prevSlabs.filter((slab) => slab._id !== id));

      // Show success message after deletion
      Swal.fire('Deleted!', 'The commission slab has been deleted.', 'success');
    }
  } catch (error) {
    console.error('Error deleting commission slab', error);
    Swal.fire('Error!', 'There was a problem deleting the commission slab.', 'error');
  }
};

  const handleToggleCommissionSlab = async (id) => {
  const slab = commissionSlabs.find(slab => slab._id === id);
  if (!slab) return;

  try {
    const updatedSlab = await toggleCommissionSlab(id, slab.isActive);
    setCommissionSlabs((prevSlabs) =>
      prevSlabs.map((s) => (s._id === id ? { ...s, isActive: !s.isActive } : s))
    );
  } catch (error) {
    console.error('Error updating commission slab status', error);
  }
};

  const resetForm = () => {
    setCategory('');
    setSubcategory('');
    setCommissionData({
      dealer30Kg: '',
      supplier30Kg: '',
      dealer50Kg: '',
      supplier50Kg: '',
      dealer25Kg: '',
      supplier25Kg: '',
      dealer100Kg: '',
      supplier100Kg: ''
    });
    setError('');
  };

  return (
    <div style={{ backgroundColor: 'rgba(217, 217, 217, 1)', minHeight: "100vh" }} className='pt-10'>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] p-5 md:p-0">
        {/* <div className='p-5 mt-5 bg-white rounded-lg'>
          <h2 className='mb-4 text-2xl font-semibold'>Add Commission Slab</h2>
          <div className='flex gap-8'>
            <div>
              <InputField label="Category" value={category} onChange={(e) => setCategory(e.target.value)} />
              <div className='mt-2'>
                <InputField label="Subcategory" value={subcategory} onChange={(e) => setSubcategory(e.target.value)} />
              </div>
              {error && <p className='mt-2 text-center text-red-600'>{error}</p>}
            </div>

            <table className="w-full bg-white rounded-lg shadow-md">
              <thead>
                <tr className="text-left border-b">
                  <th className="p-4 font-semibold text-center">Bag Size</th>
                  <th className="p-4 font-semibold text-center">Dealer Commission </th>
                  <th className="p-4 font-semibold text-center">Supplier Commission </th>
                </tr>
              </thead>
              <tbody>
                {['30 Kg', '50 Kg', '70 Kg'].map((size) => (
                  <tr key={size}>
                    <td className="p-4 text-center">{size}</td>
                    <td className="p-4 text-center">
                      <input
                        type="number"
                        name={`dealer${size.replace(' ', '')}`}
                        value={commissionData[`dealer${size.replace(' ', '')}`]}
                        onChange={handleInputChange}
                        className="w-full p-2 bg-gray-100 border rounded-lg"
                        placeholder="Dealer Commission"
                      />
                    </td>
                    <td className="p-4 text-center">
                      <input
                        type="number"
                        name={`supplier${size.replace(' ', '')}`}
                        value={commissionData[`supplier${size.replace(' ', '')}`]}
                        onChange={handleInputChange}
                        className="w-full p-2 bg-gray-100 border rounded-lg"
                        placeholder="Supplier Commission"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-6">
            <button onClick={handleAddCommissionSlab} className='bg-[#0EA652] text-white py-2 px-6 rounded-lg'>
              Add Commission Slab
            </button>
          </div>
        </div> */}
        <div className='p-5 mt-5 bg-white rounded-lg'>
  <h2 className='mb-4 text-2xl font-semibold'>Add Commission Slab</h2>
  <div className='flex flex-col gap-8 md:flex-row'>
    <div className='flex-1'>
      <InputField 
        label="Category" 
        value={category} 
        onChange={(e) => setCategory(e.target.value)} 
      />
      <div className='mt-2'>
        <InputField 
          label="Subcategory" 
          value={subcategory} 
          onChange={(e) => setSubcategory(e.target.value)} 
        />
      </div>
      {error && <p className='mt-2 text-center text-red-600'>{error}</p>}
    </div>

    <div className='flex-1'>
      <table className="w-full bg-white rounded-lg shadow-md ">
        <thead>
          <tr className="text-left border-b">
            <th className="p-4 font-semibold text-center">Bag Size</th>
           <th className="p-4 text-sm font-semibold text-center md:text-base lg:text-lg">Dealer Commission</th>
<th className="p-4 text-sm font-semibold text-center md:text-base lg:text-lg">Supplier Commission</th>

          </tr>
        </thead>
        <tbody>
          {[ '25 Kg','30 Kg', '50 Kg' ,'100 Kg'].map((size) => (
            <tr key={size}>
              <td className="p-4 text-center">{size}</td>
              <td className="p-4 text-center">
                <input
                  type="number"
                  name={`dealer${size.replace(' ', '')}`}
                  value={commissionData[`dealer${size.replace(' ', '')}`]}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-100 border rounded-lg"
                  placeholder="Dealer Commission"
                />
              </td>
              <td className="p-4 text-center">
                <input
                  type="number"
                  name={`supplier${size.replace(' ', '')}`}
                  value={commissionData[`supplier${size.replace(' ', '')}`]}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-100 border rounded-lg"
                  placeholder="Supplier Commission"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  <div className="mt-6">
    <button onClick={handleAddCommissionSlab} className='bg-[#0EA652] text-white py-2 px-6 rounded-lg'>
      Add Commission Slab
    </button>
  </div>
</div>


        {/* <div className='mt-10'>
          <h2 className='mb-4 text-2xl font-semibold'>Existing Commission Slabs</h2>
          <table className='w-full mb-5 rounded-lg'>
            <thead className='font-normal text-white' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
              <tr>
                <th className='p-2 font-normal rounded-tl-lg'>Category</th>
                <th className='p-2 font-normal'>Subcategory</th>
                <th className='p-2 font-normal'>Bag</th>
                <th className='p-2 font-normal'>Supplier Commission</th>
                <th className='p-2 font-normal'>Dealer Commission</th>
                <th className='p-2 font-normal'>Visibility</th>
                <th className='p-2 font-normal rounded-tr-lg'>Delete</th>
              </tr>
            </thead>
            <tbody className='bg-white'>
  {commissionSlabs.map((slab) => {
    const selectedBagSize = slab.selectedBagSize || "30 Kg"; // Default to "30 Kg" if not set
    const supplierCommission = slab.commissions[`supplier${selectedBagSize.replace(' ', '')}`];
    const dealerCommission = slab.commissions[`dealer${selectedBagSize.replace(' ', '')}`];

    return (
      <tr key={slab._id} className="border-b">
        <td className="p-2 text-center">{slab.category}</td>
        <td className="p-2 text-center">{slab.subcategory}</td>
        <td className="p-2 text-center">
          <select
            value={selectedBagSize}
            onChange={(e) => {
              const newSize = e.target.value;
              setCommissionSlabs(prevSlabs =>
                prevSlabs.map(s =>
                  s._id === slab._id ? { ...s, selectedBagSize: newSize } : s
                )
              );
            }}
            className="p-2 bg-gray-100 border rounded-lg"
            id={`bag-size-${slab._id}`} // Unique ID for each select dropdown
          >
            <option value="30 Kg">30 Kg</option>
            <option value="50 Kg">50 Kg</option>
            <option value="70 Kg">70 Kg</option>
          </select>
        </td>
        <td className="p-2 text-center">
          {supplierCommission ? supplierCommission : '----'}
        </td>
        <td className="p-2 text-center">
          {dealerCommission ? dealerCommission : '----'}
        </td>
        <td className="p-2 text-center">
          <ToggleSwitch
            isActive={slab.isActive}
            onToggle={() => handleToggleCommissionSlab(slab._id)}
            id={`toggle-${slab._id}`} // Unique ID for each toggle switch
          />
        </td>
        <td className="flex justify-center p-2 text-center">
          <button onClick={() => handleDeleteCommissionSlab(slab._id)} className="p-1 text-red-500 rounded-lg">
            <RiDeleteBin6Line className="h-[20px] w-[20px]" />
          </button>
        </td>
      </tr>
    );
  })}
</tbody>

          </table>
        </div> */}
        <div className='mt-10'>
  <h2 className='mb-4 text-2xl font-semibold'>Existing Commission Slabs</h2>
  <div className="overflow-x-auto">
    <table className='min-w-full mb-5 rounded-lg'>
      <thead className='font-normal text-white' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
        <tr>
          <th className='p-2 font-normal rounded-tl-lg'>Category</th>
          <th className='p-2 font-normal'>Subcategory</th>
          <th className='p-2 font-normal'>Bag</th>
          <th className='p-2 font-normal'>Supplier Commission</th>
          <th className='p-2 font-normal'>Dealer Commission</th>
          <th className='p-2 font-normal'>Visibility</th>
          <th className='p-2 font-normal rounded-tr-lg'>Delete</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {commissionSlabs.map((slab) => {
          const selectedBagSize = slab.selectedBagSize || "30 Kg"; // Default to "30 Kg" if not set
          const supplierCommission = slab.commissions[`supplier${selectedBagSize.replace(' ', '')}`];
          const dealerCommission = slab.commissions[`dealer${selectedBagSize.replace(' ', '')}`];

          return (
            <tr key={slab._id} className="border-b">
              <td className="p-2 text-center">{slab.category}</td>
              <td className="p-2 text-center">{slab.subcategory}</td>
              <td className="p-2 text-center">
                <select
                  value={selectedBagSize}
                  onChange={(e) => {
                    const newSize = e.target.value;
                    setCommissionSlabs(prevSlabs =>
                      prevSlabs.map(s =>
                        s._id === slab._id ? { ...s, selectedBagSize: newSize } : s
                      )
                    );
                  }}
                  className="p-2 bg-gray-100 border rounded-lg"
                  id={`bag-size-${slab._id}`} // Unique ID for each select dropdown
                >
                   <option value="25 Kg">25 Kg</option>
                  <option value="30 Kg">30 Kg</option>
                  <option value="50 Kg">50 Kg</option>
                 
                  <option value="100 Kg">100 Kg</option>
                </select>
              </td>
              <td className="p-2 text-center">
                {supplierCommission ? supplierCommission : '----'}
              </td>
              <td className="p-2 text-center">
                {dealerCommission ? dealerCommission : '----'}
              </td>
              <td className="p-2 text-center">
                <ToggleSwitch
                  isActive={slab.isActive}
                  onToggle={() => handleToggleCommissionSlab(slab._id)}
                  id={`toggle-${slab._id}`} // Unique ID for each toggle switch
                />
              </td>
              <td className="flex justify-center p-2 text-center">
                <button onClick={() => handleDeleteCommissionSlab(slab._id)} className="p-1 text-red-500 rounded-lg">
                  <RiDeleteBin6Line className="h-[20px] w-[20px]" />
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
</div>

      </div>
       {loading && <Loader loading={loading}  />}
    </div>
  );
};

const InputField = ({ label, value, onChange }) => (
  <div>
    <label className='block mb-2 font-medium w-[300px]'>{label}</label>
    <input
      type="text"
      value={value}
      onChange={onChange}
      className="w-full p-2 bg-gray-100 border rounded-lg"
    />
  </div>
);

// const ToggleSwitch = ({ isActive, onToggle, id }) => (
//   <label className='relative inline-flex items-center cursor-pointer' htmlFor={id}>
//     <input
//       type="checkbox"
//       id={id}
//       className='sr-only'
//       checked={isActive}
//       onChange={onToggle}
//     />
//     <div className='h-6 bg-gray-200 rounded-full w-11 peer-focus:outline-none peer peer-checked:bg-green-600'></div>
//     <span className='ml-3 text-sm font-medium'>{isActive ? 'Active' : 'Inactive'}</span>
//   </label>
// );
const ToggleSwitch = ({ isActive, onToggle, id }) => (
  <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
    <input
      type="checkbox"
      name="toggle"
      id={id} // Use the unique ID for each toggle
      checked={isActive}
      onChange={onToggle} // Call the toggle function directly
      className="sr-only"
    />
    <label
      htmlFor={id} 
      className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${isActive ? "bg-green-500" : ""}`}
    >
      <span
        className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${isActive ? "translate-x-6 bg-green-400" : ""}`}
      ></span>
    </label>
    
  </div>
  
);

export default CommissionSlab;
