import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BaseUrl } from '../auth/Url';

function FactoryCommission() {
  const [totalCommission, setTotalCommission] = useState(0);
  const [productCommissionDetails, setProductCommissionDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const factoryId = JSON.parse(localStorage.getItem('personalInfo')).factoryId;
  const [totalCompletedCommission, setTotalCompletedCommission] = useState(0);
const [duePayment, setDuePayment] = useState(0); // State for due payment
useEffect(() => {
  const fetchCommissionData = async () => {
    try {
      const response = await axios.post(`${BaseUrl}/Factory/GetFactorycommission`, { factoryId });
      // Assuming response contains total commission and product details
      const { totalFactoryCommission, productCommissionDetails, factoriesPaymentDoneAmount } = response.data;

      setTotalCommission(totalFactoryCommission.toFixed(2));
      setProductCommissionDetails(productCommissionDetails);
      setTotalCompletedCommission(Number(factoriesPaymentDoneAmount)); // Ensure it's a number

      // Calculate due payment
      const calculatedDuePayment = totalFactoryCommission - Number(factoriesPaymentDoneAmount);
      // Convert due payment to string with 2 decimal places
      setDuePayment(calculatedDuePayment.toFixed(2)); // Set the due payment state as string with 2 decimal places

      setLoading(false);
    } catch (error) {
      console.error('Error fetching commission data:', error);
      setLoading(false);
    }
  };

  fetchCommissionData();
}, [factoryId]);


  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-gray-600">Loading commission data...</div>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh", padding: "20px" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <h2 className="mb-6 text-3xl font-bold text-center text-gray-800">Aakar canvassing Commission</h2>

        {/* Display total commission */}
        <div className="flex items-center justify-between gap-10 p-5 mb-6 bg-white border border-gray-300 rounded-lg shadow-lg">
    <p className="font-semibold text-gray-700 md:text-2xl">
      Total Commission: <span className="text-green-500">₹{totalCommission}</span>
    </p>
    <p className="font-semibold text-gray-700 md:text-2xl">
      Due Commission: <span className="text-green-500">₹{duePayment}</span>
    </p>
  </div>

        {/* Table to display product commission details */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="px-4 py-2 text-sm font-semibold text-center text-gray-600 border-b">Order ID</th>
                <th className="px-4 py-2 text-sm font-semibold text-center text-gray-600 border-b">Product Name</th>
                <th className="px-4 py-2 text-sm font-semibold text-center text-gray-600 border-b">Size</th>
                <th className="px-4 py-2 text-sm font-semibold text-center text-gray-600 border-b">Quantity</th>
                <th className="px-4 py-2 text-sm font-semibold text-center text-gray-600 border-b">Commission Rate (₹)</th>
                <th className="px-4 py-2 text-sm font-semibold text-center text-gray-600 border-b"> Aakar canvassing Commission  (₹)</th>
              </tr>
            </thead>
            <tbody>
              {productCommissionDetails.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="px-4 py-2 text-sm text-center text-gray-700 border-b">{item.orderId}</td>
                  <td className="px-4 py-2 text-sm text-center text-gray-700 border-b">{item.productName}</td>
                  <td className="px-4 py-2 text-sm text-center text-gray-700 border-b">{item.size}</td>
                  <td className="px-4 py-2 text-sm text-center text-gray-700 border-b">{item.quantity}</td>
                  <td className="px-4 py-2 text-sm text-center text-gray-700 border-b">₹{item.commissionRate}</td>
                  <td className="px-4 py-2 text-sm text-center text-gray-700 border-b">₹{item.totalCommission}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Display factory ID */}
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-500">Factory ID: {factoryId}</p>
        </div>
      </div>
    </div>
  );
}

export default FactoryCommission;
