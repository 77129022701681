import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
  const [status, setStatus] = useState(() => {
    // Retrieve status from session storage or default to 'pending'
    return sessionStorage.getItem('status') || 'pending';
  });

  // Function to change the status
  const changeStatus = (newStatus) => {
    setStatus(newStatus);
    // Store the new status in session storage
    sessionStorage.setItem('status', newStatus);
  };

  // Optional: Sync session storage with the state whenever it changes
  useEffect(() => {
    sessionStorage.setItem('status', status);
  }, [status]);

  return (
    <GlobalContext.Provider value={{ status, changeStatus }}>
      {children}
    </GlobalContext.Provider>
  );
};
