import React, { useEffect, useState } from 'react';
import { BiDownArrowAlt } from "react-icons/bi";
import { IoAddOutline, IoArrowUpOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import CommisionSearch from "../../asset/CommisionSearch.png";
import { BaseUrl } from '../../auth/Url';
import { fetchAllOrders } from '../../Service/OrderManagment/OrderManagmentApi';
import DealerInvoice from './DealerInvoice';
import FactoryModal from './FactoryInvoice';
import Swal from 'sweetalert2';
function Commission() {
  const [activeTab, setActiveTab] = useState('AllFactory');
  const [searchTermFactory, setSearchTermFactory] = useState('');
  const [searchTermDealer, setSearchTermDeale] = useState('');
  const [amount, setAmount] = useState("");  // Initialize the amount state

  const [dealers, setDealers] = useState([]);
 
  const [timePeriod, setTimePeriod] = useState('daily');
 const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalDealerCommission, settotalDealerCommission] = useState('');
  const [totalFactoryCommission, settotalFactoryCommission] = useState('');
  const [totalCommissionDifference, setTotalCommissionDifference] = useState({
  total: 0,
  percentageChange: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
   const [PaymentModel, setPaymentModel] = useState(false);
   const [PaymentModelDealer, setPaymentModelDealer] = useState(false);
  const [isModalOpenDealer, setIsModalOpenDealer] = useState(false);
  const [selectedFactory, setSelectedFactory] = useState(null);
   const [selectedDealer, setSelectedDealer] = useState(null);
  const [selectedFactoryCommission, setSelectedFactoryCommission] = useState(null);
  const [selectedDealerCommissiondata, setSelectedDealerCommissiondata] = useState(null);
  const [selectedDealerCommission, setSelectedDealerCommission] = useState(null);
  console.log(selectedFactory,"selectedFactory commission")
  const [orders, setOrders] = useState([]);
 const [errorPayment, setErrorPayment] = useState("");
  const [commissions, setCommissions] = useState([]);
   const [Dealercommissions, setDealerCommissions] = useState([]);
  
  const [factories, setFactories] = useState([]);
  
  const token = localStorage.getItem("token");
  const [FromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [searchQuery, setSearchQuery] = useState('');
const [searchQueryDealer, setSearchQueryDealer] = useState('');
  // Handle the search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const HandleClosePayment = () => {
    setPaymentModel(false)
    setErrorPayment("")
    setAmount('')
  }
  const HandleClosePaymentDealer = () => {
    setPaymentModelDealer(false)
    setErrorPayment("")
    setAmount('')
  }

  const handleSearchChangeForDealer = (e) => {
    setSearchQueryDealer(e.target.value);
  };
  useEffect(() => {
    fetchFactories();
    fetchCommissions()
    fetchCommissionsDealer()
  }, []);

  const fetchFactories = async () => {
    try {
      const response = await fetch(`${BaseUrl}/GetAllFActories`, {
        headers: {
          'Authorization': `Bearer ${token}` // Pass token in the Authorization header
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch factories');
      }

      const data = await response.json();
      setFactories(data.FactoryList.reverse());
    } catch (error) {
      console.error('Error fetching factories:', error);
      // Handle error state or feedback
    }
  };

  // useEffect(() => {
  //   fetch(`${BaseUrl}/Dealer/Getdealers`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.result && data.statusCode === 200) {
  //         setDealers(data.data.reverse()); // Save dealers data in state
  //       }
  //     })
  //     .catch((error) => console.error('Error fetching dealers:', error));
  // }, []);
   useEffect(() => {
        

        fetchDealers();
   }, []);
  const fetchDealers = async () => {
            try {
                const response = await fetch(`${BaseUrl}/Dealer/Getdealers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (data.result && data.statusCode === 200) {
                    setDealers(data.data.reverse()); // Save dealers data in state
                }
            } catch (error) {
                console.error('Error fetching dealers:', error);
                
            }
        };
    const fetchCommissions = async () => {
            try {
                const response = await fetch( `${BaseUrl}/Factory/FactoryCommission`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCommissions(data.commissions); // Adjust according to your response structure
            } catch (err) {
                console.log('Failed to fetch data');
                console.error(err);
            } finally {
                 console.log(false);
            }
  };
  const fetchCommissionsDealer = async () => {
            try {
                const response = await fetch(`${BaseUrl}/Dealer/DealerCommission`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDealerCommissions(data.commissions); // Adjust according to your response structure
            } catch (err) {
                console.log('Failed to fetch data');
                console.error(err);
            } finally {
                 console.log(false);
            }
        };
 useEffect(() => {
  const getOrders = async () => {
    try {
      const response = await fetchAllOrders();
      // Reverse the list to get the latest orders first
      const reversedOrders = response.OrderList.reverse();
      setOrders(reversedOrders);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  };
  getOrders();
 }, []);
  //  const flattenedOrders = orders.flatMap(order =>
  //   order.ProductDetails.map(productDetail => ({
   
  //     ...productDetail
  //   }))
  //   );
  // console.log(flattenedOrders, "flattenedOrders")
  const flattenedOrders = orders.flatMap(order =>
  order.ProductDetails.map(productDetail => ({
    // Spread the product details
    ...productDetail,
    ShopId:order.ShopId,

    // Add the related customerInfo to the flattened product details
    customerName: order.customerInfo.CustomerName,
    shopName: order.customerInfo.ShopName,
    contactNumber: order.customerInfo.ContactNo,
    email: order.customerInfo.EmailID,
    
   
  }))
);

console.log(flattenedOrders, "flattenedOrders");

useEffect(() => {
  // Get the current and previous year
  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;

  // Filter orders for the current year and last year with completed dispatch status
  const currentYearOrders = flattenedOrders.filter(order => {
    const orderYear = new Date(order.OrderDate).getFullYear();
    return orderYear === currentYear && order.dispatchShippingDetails.DispatchStatus === "Completed";
  });

  const lastYearOrders = flattenedOrders.filter(order => {
    const orderYear = new Date(order.OrderDate).getFullYear();
    return orderYear === lastYear && order.dispatchShippingDetails.DispatchStatus === "Completed";
  });

  // Calculate commissions for the current and last year
  const currentYearCommissions = calculateTotalCommission(currentYearOrders);
  const lastYearCommissions = calculateTotalCommission(lastYearOrders);

  // Calculate totals for the current year
  let totalDealerCommission = 0;
  let totalFactoryCommission = 0;

  currentYearCommissions.forEach(order => {
    totalDealerCommission += order.totalDealerCommission;
    totalFactoryCommission += order.totalSupplierCommission;
  });

  // Calculate totals for the last year
  let lastYearTotalDealerCommission = 0;
  let lastYearTotalFactoryCommission = 0;

  lastYearCommissions.forEach(order => {
    lastYearTotalDealerCommission += order.totalDealerCommission;
    lastYearTotalFactoryCommission += order.totalSupplierCommission;
  });

  // Calculate the total commission for both years
  const currentYearTotalCommission = totalDealerCommission + totalFactoryCommission;
  const lastYearTotalCommission = lastYearTotalDealerCommission + lastYearTotalFactoryCommission;

  // Calculate the percentage difference
  const percentageChange = lastYearTotalCommission > 0 
    ? ((currentYearTotalCommission - lastYearTotalCommission) / lastYearTotalCommission) * 100 
    : 100; // If no last year data, assume 100% growth

  // Update state with formatted values
  settotalFactoryCommission(totalFactoryCommission.toFixed(2));
  settotalDealerCommission(totalDealerCommission.toFixed(2));
  setTotalCommissionDifference({
    total: currentYearTotalCommission.toFixed(2),
    percentageChange: percentageChange.toFixed(1),
  });

  console.log('Current Year Total Commission:', currentYearTotalCommission.toFixed(2));
  console.log('Last Year Total Commission:', lastYearTotalCommission.toFixed(2));
  console.log('Percentage Change:', percentageChange.toFixed(1));

}, [orders]);

const calculateTotalCommission = (orders) => {
  return orders.map(order => {
    let totalDealerCommission = 0;
    let totalSupplierCommission = 0;

    order.selection.forEach(item => {
      const { quantity, size } = item;
      const commissionRates = order.commission;

      // Assuming 'size' matches the keys in the commission object
      if (size === "30kg") {
        totalDealerCommission += commissionRates.dealer30Kg * quantity;
        totalSupplierCommission += commissionRates.supplier30Kg * quantity;
      } else if (size === "50kg") {
        totalDealerCommission += commissionRates.dealer50Kg * quantity;
        totalSupplierCommission += commissionRates.supplier50Kg * quantity;
      } else if (size === "25kg") {
        totalDealerCommission += commissionRates.dealer25Kg * quantity;
        totalSupplierCommission += commissionRates.supplier25Kg * quantity;
      }
      else if (size === "100kg") {
        totalDealerCommission += commissionRates.dealer100Kg * quantity;
        totalSupplierCommission += commissionRates.supplier100Kg * quantity;
      }
      // Add more conditions for other sizes as needed
    });

    return {
      ...order,
      totalDealerCommission,
      totalSupplierCommission,
    };
  });
};




  
 const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

   const filteredFactories = factories.filter((factory) =>
    factory.factoryName.toLowerCase().includes(searchQuery.toLowerCase())
  );
 const filtereddealer = dealers.filter((factory) =>
    factory.shopName.toLowerCase().includes(searchQueryDealer.toLowerCase())
  );

const handlePayment = async (amount) => {
    const dueAmount =
        parseFloat(selectedFactoryCommission.totalCommission || 0) -
        parseFloat(selectedFactoryCommission.CommissionDoneAmount || 0);

    if (amount <= 0) {
        // Set error message if the amount is invalid (<= 0)
        setErrorPayment("Please enter a valid amount greater than 0.");
    } else if (amount > dueAmount) {
        // Set error message if the amount is greater than the due amount
        setErrorPayment(`Amount cannot exceed the due amount of ${dueAmount}.`);
    } else {
        // Perform the payment logic here
        console.log(`Payment submitted: ${amount}`);

        // Reset error state
        setErrorPayment("");

        try {
            // Call the API to update CommissionDoneAmount
            const response = await fetch(`${BaseUrl}/UpdateCommissionAmount`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    factoryId: selectedFactoryCommission.factoryId, // The selected factory's ID
                    amount: amount // The amount being paid
                })
            });

            const data = await response.json();

            if (response.ok) {
                // Successfully updated the CommissionDoneAmount
                console.log("CommissionDoneAmount updated:", data);

                // Optionally, update the state/UI to reflect the changes
                setSelectedFactoryCommission((prevState) => ({
                    ...prevState,
                    CommissionDoneAmount: prevState.CommissionDoneAmount + amount
                }));

                // Show success alert
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Commission amount updated successfully!',
                    confirmButtonText: 'OK'
                });

                // Close the modal
                setPaymentModel(false);
              fetchCommissions();
              fetchFactories();
              setAmount("")
            } else {
                // Handle error response
                setErrorPayment(data.message || "Failed to update commission.");
            }
        } catch (error) {
            // Handle any network or server errors
            console.error("API Error:", error);
            setErrorPayment("An error occurred while updating commission.");
        }
    }
};
const handlePaymentDealer = async (amount) => {
    const dueAmount =
        parseFloat(selectedDealerCommissiondata.totalCommission || 0) -
        parseFloat(selectedDealerCommissiondata.CommissionDoneAmount || 0);

    if (amount <= 0) {
        // Set error message if the amount is invalid (<= 0)
        setErrorPayment("Please enter a valid amount greater than 0.");
    } else if (amount > dueAmount) {
        // Set error message if the amount is greater than the due amount
        setErrorPayment(`Amount cannot exceed the due amount of ${dueAmount}.`);
    } else {
        // Perform the payment logic here
        console.log(`Payment submitted: ${amount}`);

        // Reset error state
        setErrorPayment("");

        try {
            // Call the API to update CommissionDoneAmount
            const response = await fetch(`${BaseUrl}/Dealer/UpdateCommissionAmountDealer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    shopId: selectedDealerCommissiondata.shopId, // The selected factory's ID
                    CommissionDoneAmount: amount // The amount being paid
                })
            });

            const data = await response.json();

            if (response.ok) {
                // Successfully updated the CommissionDoneAmount
                console.log("CommissionDoneAmount updated:", data);

               
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Commission amount updated successfully!',
                    confirmButtonText: 'OK'
                });

                // Close the modal
                setPaymentModelDealer(false);
             
              fetchDealers();
              setAmount("")
            } else {
                // Handle error response
                setErrorPayment(data.message || "Failed to update commission.");
            }
        } catch (error) {
            // Handle any network or server errors
            console.error("API Error:", error);
            setErrorPayment("An error occurred while updating commission.");
        }
    }
};


  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
       <div  className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] ">
      <div className="p-5">
        <div className="flex justify-between ">
          <div>
            <p className='text-2xl'>Commission Management</p>
          </div>
        </div>
     <div className='p-5 mt-5 bg-white rounded-lg'>
  <p className='text-sm text-blue-600'>YEARLY COMMISSION</p>
  <div>
    <div className='flex flex-col gap-5 p-2 md:flex-row md:gap-10'>
      <div className='p-2 border-2 rounded-lg md:w-[450px] w-full'>
        <div className='flex gap-10'>
          <div className='font-semibold'>
            Total<br />Factory Commission:
          </div>
          <div className='mt-5 text-xl font-semibold'>₹ {totalFactoryCommission}</div>
        </div>
      </div>
      <div className='p-2 border-2 rounded-lg md:w-[450px] w-full'>
        <div className='flex gap-10'>
          <div className='font-semibold'>
            Total<br />Dealer Commission:
          </div>
          <div className='mt-5 text-xl font-semibold'>₹ {totalDealerCommission}</div>
        </div>
      </div>
      <div className='p-2 rounded-lg w-full md:w-[350px]'>
        <div className='flex gap-2'>
          <div className='font-semibold'>
            Total:<br />
          </div>
          <div className='flex gap-5 text-xl font-semibold'>
            <div>₹{totalCommissionDifference.total}</div>
            <div className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded flex 
              ${totalCommissionDifference.percentageChange >= 0 
                ? 'bg-green-100 text-green-800' 
                : 'bg-red-100 text-red-800'}`}>
              {totalCommissionDifference.percentageChange >= 0 ? (
                <>
                  <IoArrowUpOutline className='w-5 h-5' />
                  <p className='mt-1'>{totalCommissionDifference.percentageChange}%</p>
                </>
              ) : (
                <>
                  <BiDownArrowAlt className='w-5 h-5' />
                  <p className='mt-1'>-{Math.abs(totalCommissionDifference.percentageChange)}%</p>
                </>
              )}
            </div>
          </div>
        </div>
        <p>Compared to last year</p>
      </div>
    </div>
  </div>
</div>


        {/* <div className='flex justify-between mt-2'>
            <div className='flex gap-5'>
               <button
                // className={`p-2 border rounded-lg ${activeTab === 'AllFactory' ? 'bg-gray-800 text-white' : 'bg-white'}`}
                 className={`p-3 border rounded-lg ${activeTab === 'AllFactory' ? 'bg-[#C6A26F] text-white' : 'bg-white'}`}
              onClick={() => setActiveTab('AllFactory')}
            >
             All Factory
              </button>
               <button
                // className={`p-2 border rounded-lg ${activeTab === 'AllDealer' ? 'bg-gray-800 text-white' : 'bg-white'}`}
                className={`p-3 border rounded-lg ${activeTab === 'AllDealer' ? 'bg-[#C6A26F] text-white' : 'bg-white'}`}
              onClick={() => setActiveTab('AllDealer')}
            >
              All Dealer
            </button>
           
            </div>
             <div className='pr-2'>
                                      <Link className='flex bg-[#4C3F35] p-2 text-white gap-2 rounded-lg hover:scale-105 ' to="/Add_Commission_Slab"><IoAddOutline  className='w-5 h-5 mt-0.5'/> Add Commission Slab</Link>
                                  </div>
            
        </div> */}
        <div className='flex flex-col justify-between mt-2 md:flex-row'>
    <div className='flex gap-5 mb-4 md:mb-0'>
        <button
            className={`p-3 border rounded-lg ${activeTab === 'AllFactory' ? 'bg-[#C6A26F] text-white' : 'bg-white'}`}
            onClick={() => setActiveTab('AllFactory')}
        >
            All Factory
        </button>
        <button
            className={`p-3 border rounded-lg ${activeTab === 'AllDealer' ? 'bg-[#C6A26F] text-white' : 'bg-white'}`}
            onClick={() => setActiveTab('AllDealer')}
        >
            All Dealer
        </button>
    </div>
    <div className='pr-2'>
        <Link className='flex bg-[#4C3F35] p-2 text-white gap-2 rounded-lg hover:scale-105' to="/Add_Commission_Slab">
            <IoAddOutline className='w-5 h-5 mt-0.5' /> Add Commission Slab
        </Link>
    </div>
</div>

          <div className="mt-5">
            {activeTab === 'AllFactory' && (
  <div className='p-4 bg-white h-[500px] rounded-lg'>
    <div className='flex justify-between'>
     
     
    </div>

   <div className='flex items-center justify-between mb-4'>
     
                   <div className='md:flex gap-[180px]'> 
<div className='p-2'>
     
       <input
        type='text'
        placeholder='Search Factory'
        value={searchQuery}
        onChange={handleSearchChange}
        className='px-4 py-2 border border-gray-300 rounded-md md:w-[500px]'
      />

     
    
    </div>

                    <div className='flex justify-end p-5 text-xl font-semibold'>
            <p>All Factory Commission: ₹ {totalFactoryCommission}</p>
          </div>
                  </div>
    </div>

    {factories ? (
                  factories.length > 0 ? (
                    <>
                  <div className="overflow-y-auto max-h-80">
 
  <table className='w-full rounded-lg'>
    <thead className='sticky top-0 text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
      <tr>
        <th className='p-2 font-semibold rounded-tl-lg'>Factory Id</th>
        <th className='p-2 font-semibold'>Factory Name</th>
        <th className='p-2 font-semibold'>Email</th>
        <th className='p-2 font-semibold'>Contact No</th>
        <th className='p-2 font-semibold'>Order Count</th>
                              <th className='p-2 font-semibold'>Commission</th>
                              <th className='p-2 font-semibold'>Commission Done </th>
                              <th className='p-2 font-semibold '>Report</th>
                              <th className='p-2 font-semibold rounded-tr-lg'>Action</th>
      </tr>
    </thead>
  <tbody>
    {filteredFactories
        .map(item => {
           
            const orderCount = flattenedOrders.filter(order =>
                order.SupplierInfo.FactoryId === item.factoryId &&
                order.dispatchShippingDetails &&
                order.dispatchShippingDetails.DispatchStatus === 'Completed'
            ).length;

          
            const factoryCommission = commissions[item.factoryId] || 0; 

            
            return {
                ...item,
                orderCount,
                commission: factoryCommission,
            };
        })
        // Sort factories by orderCount in descending order
        .sort((a, b) => b.orderCount - a.orderCount)
        .map((item, index) => (
            <tr key={item.factoryId} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} text-center border-b`}>
                <td className='p-2 text-center'>{item.factoryId}</td>
                <td className='p-2 text-center'>{item.factoryName}</td>
                <td className='p-2 text-center'>{item.email}</td>
                <td className='p-2 text-center'>{item.contactNo}</td>
                {/* Display order count where DispatchStatus is 'Completed' */}
                <td className='p-2 text-center'>{item.orderCount}</td>
                {/* Display the commission for the factory */}
            <td className='p-2 text-center'>{item.commission}</td>
          <td className='p-2 text-center'>
  {item.CommissionDoneAmount === "" || item.CommissionDoneAmount == null ? 0 : item.CommissionDoneAmount}
</td>


                {/* Display subtotal or any other data you need */}
                <td className='p-2 text-center'>
        <button
            onClick={() => {
                if (item.commission > 0) {
                  setSelectedFactory(item);
                  setSelectedFactoryCommission(item.commission)// Pass factory details to the modal
                 
                    setIsModalOpen(true);      // Open the modal
                }
            }}
            className={`px-2 py-1 text-white rounded  ${item.commission > 0 ? 'bg-blue-500' : 'bg-gray-400 cursor-not-allowed'}`}
            disabled={item.commission <= 0} // Disable button if commission is 0
        >
            Report
        </button>
            </td>
             <td className='p-2 text-center'>
    <button
        onClick={() => {
            if (item.commission > 0) {
                setSelectedFactory(item);
              setSelectedFactoryCommission({
                  factoryId:item.factoryId,
                  totalCommission: item.commission, 
                  CommissionDoneAmount: item.CommissionDoneAmount || 0  // Set 0 if undefined or empty
                });
                setPaymentModel(true);  // Open the modal
            }
        }}
        className={`px-2 py-1 text-white rounded ${item.commission > 0 ? 'bg-blue-500' : 'bg-gray-400 cursor-not-allowed'}`}
        disabled={item.commission <= 0}  // Disable button if commission is 0
    >
       Payment
    </button>
</td>

            </tr>
        ))
    }
</tbody>


  </table>
</div>


                      {/* <div className='flex justify-end p-5 text-xl font-semibold'>
            <p>All Factory Commission: ₹ {totalFactoryCommission}</p>
          </div> */}
                      
                      
                    </>
       
      ) : (
        <div className='flex justify-center'>
          <img src={CommisionSearch} alt="No Results" className="h-30 w-30" />
        </div>
      )
    ) : (
      <div className='flex justify-center'>
        <img src={CommisionSearch} alt="No Search" className="h-30 w-30" />
      </div>
    )}
  </div>
)}

             {activeTab === 'AllDealer' && (
            <div className='p-4 bg-white rounded-lg '>
              <div className='flex justify-between'>
                 
                  {/* <div>
                     <select
                    value={timePeriod}
                    onChange={(e) => setTimePeriod(e.target.value)}
                    className='px-4 py-2 border-gray-300 rounded-md'
                  >
                    <option value='daily'>Daily</option>
                    <option value='weekly'>Weekly</option>
                    <option value='monthly'>Monthly</option>
                  </select>
                  </div> */}
                  </div>
              
              <div className='flex items-center justify-between mb-4'>
                
                
                  {/* <div className='flex items-center justify-between gap-10 mb-4'>
      <div className='flex items-center space-x-4'>
        <input
          type='date'
          value={startDate}
          onChange={handleStartDateChange}
          className='px-4 py-2 border border-gray-300 rounded-md'
        />
        <input
          type='date'
          value={endDate}
          onChange={handleEndDateChange}
          className='px-4 py-2 border border-gray-300 rounded-md'
        />
      </div>
      <button
                      // onClick={handleSearch}
                       style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }}
        className='px-4 py-2 text-white rounded-md'
      >
        Search
      </button>
                  </div> */}
                  <div className='md:flex gap-[180px]'> 
<div className='p-2'>
      {/* Search input field */}
      <input
        type='text'
        placeholder='Search Dealer'
        value={searchQueryDealer}
        onChange={handleSearchChangeForDealer}
        className='px-4 py-2 border border-gray-300 rounded-md md:w-[500px]'
      />

     
    
    </div>

                    <div className='flex justify-end p-5 text-xl font-semibold'>
            <p>All Dealer Commission: ₹ {totalDealerCommission}</p>
          </div>
                  </div>
  
                     
              </div>
         {filtereddealer ? (
                  filtereddealer.length > 0 ? (
                    <>
                 <div className="overflow-y-auto max-h-80">
  {/* Adjust height as needed */}
  <table className='w-full rounded-lg'>
    <thead className='sticky top-0 text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
      <tr>
                              <th className='p-2 font-semibold rounded-tl-lg'>shop Id</th>
                               <th className='p-2 font-semibold'>Shop Name</th>
        <th className='p-2 font-semibold'>GST Number</th>
        <th className='p-2 font-semibold'>FSSAI Number</th>
        <th className='p-2 font-semibold'>Contact No</th>
        <th className='p-2 font-semibold'>Order Count</th>
                              <th className='p-2 font-semibold'>Commission</th>
                              <th className='p-2 font-semibold'>Commission Done </th>
                              <th className='p-2 font-semibold '>Report</th>
                              <th className='p-2 font-semibold rounded-tr-lg'>Action</th>
      </tr>
    </thead>
  <tbody>
    {filtereddealer
        .map(item => {
            // Calculate the order count for the current factory where dispatchShippingDetails.DispatchStatus is 'Completed'
            const orderCount = flattenedOrders.filter(order =>
                order.ShopId === item.shopId &&
                order.dispatchShippingDetails &&
                order.dispatchShippingDetails.DispatchStatus === 'Completed'
            ).length;

            
           const dealerCommission = Dealercommissions[item.shopId] || 0; // Assuming 'commissions' is your commission object

            // Return factory with the computed orderCount and commission
            return {
                ...item,
                orderCount,
                commission: dealerCommission,
            };
        })
        // Sort factories by orderCount in descending order
        .sort((a, b) => b.orderCount - a.orderCount)
        .map((item, index) => (
            <tr key={item.factoryId} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} text-center border-b`}>
            <td className='p-2 text-center'>{item.shopId}</td>
             <td className='p-2 text-center' title={item.shopName}>
  {item.shopName.length > 8 ? `${item.shopName.slice(0, 8)}...` : item.shopName}
</td>

                <td className='p-2 text-center'>{item.gstNumber}</td>
                <td className='p-2 text-center'>{item.FSSAINumber}</td>
                <td className='p-2 text-center'>{item.contactNumber}</td>
                {/* Display order count where DispatchStatus is 'Completed' */}
                <td className='p-2 text-center'>{item.orderCount}</td>
                {/* Display the commission for the factory */}
            <td className='p-2 text-center'>{item.commission}</td>
            <td className='p-2 text-center'>
  {item.CommissionDoneAmount === "" || item.CommissionDoneAmount == null ? 0 : item.CommissionDoneAmount}
</td>
                {/* Display subtotal or any other data you need */}
                {/* <td className='p-2 text-center'>
                    <button
                        // onClick={() => item.commission > 0 && downloadInvoice(item)}
                        className={`px-2 py-1 text-white rounded ${item.commission > 0 ? 'bg-blue-500' : 'bg-gray-400 cursor-not-allowed'}`}
                        disabled={item.commission <= 0} // Disable button if commission is 0
                    >
                        Download Invoice
                    </button>
                </td> */}
            <td className='p-2 text-center'>
        <button
            onClick={() => {
                if (item.commission > 0) {
                  setSelectedDealer(item);
                  setSelectedDealerCommission(item.commission)// Pass factory details to the modal
                    setIsModalOpenDealer(true);      // Open the modal
                }
            }}
            className={`px-2 py-1 text-white rounded ${item.commission > 0 ? 'bg-blue-500' : 'bg-gray-400 cursor-not-allowed'}`}
            disabled={item.commission <= 0} // Disable button if commission is 0
        >
             Report
        </button>
            </td>
             <td className='p-2 text-center'>
        <button
            onClick={() => {
                if (item.commission > 0) {
                  setSelectedDealerCommissiondata({
                  shopId:item.shopId,
                  totalCommission:item.commission, 
                  CommissionDoneAmount: item.CommissionDoneAmount || 0  // Set 0 if undefined or empty
                });
                setPaymentModelDealer(true);    // Open the modal
                }
            }}
            className={`px-2 py-1 text-white rounded ${item.commission > 0 ? 'bg-blue-500' : 'bg-gray-400 cursor-not-allowed'}`}
            disabled={item.commission <= 0} // Disable button if commission is 0
        >
            payment
        </button>
    </td>
            </tr>
        ))
    }
</tbody>


  </table>
</div>


                      
                    </>
       
      ) : (
        <div className='flex justify-center'>
          <img src={CommisionSearch} alt="No Results" className="h-30 w-30" />
        </div>
      )
    ) : (
      <div className='flex justify-center'>
        <img src={CommisionSearch} alt="No Search" className="h-30 w-30" />
      </div>
    )}
            </div>
          )}
          
        </div>
      </div>
      </div>
      {
        isModalOpen && (
            <FactoryModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
        factory={selectedFactory}
        selectedFactoryCommission={selectedFactoryCommission}
      />
          
        )
      }
      {
        isModalOpenDealer && (
          <DealerInvoice
      
        isOpen={isModalOpenDealer}
                onClose={() => setIsModalOpenDealer(false)}
        selectedDealer={selectedDealer}
        selectedDealerCommission={selectedDealerCommission}
      />

        )
      }
      
      {PaymentModel && (
//     <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
//         <div className="w-1/3 p-6 bg-white rounded-lg shadow-lg">
//             <h2 className="mb-4 text-xl font-bold">Commission Payment(Factory Id -{selectedFactoryCommission.factoryId})</h2>
            
//             <div className="mb-4">
//                 <label className="block text-sm font-medium text-gray-700">
//                     Total Commission:
//                 </label>
//                 <p>{selectedFactoryCommission.totalCommission}</p>
//             </div>

//             <div className="mb-4">
//                 <label className="block text-sm font-medium text-gray-700">
//                     Commission Done Amount:
//                 </label>
//                 <p>{selectedFactoryCommission.CommissionDoneAmount}</p>
//             </div>
            
//             <div className="mb-4">
//     <label className="block text-sm font-medium text-gray-700">
//         Commission Due Amount:
//     </label>
//     <p>
//         {parseFloat(selectedFactoryCommission.totalCommission || 0) -
//          parseFloat(selectedFactoryCommission.CommissionDoneAmount || 0)}
//     </p> {/* Subtract Done Amount from Total Commission */}
// </div>

//             <div className="mb-4">
//                 <label className="block text-sm font-medium text-gray-700">
//                     Add Amount:
//                 </label>
//                 <input
//                     type="number"
//                     className="block w-full p-2 mt-1 border rounded"
//                     placeholder="Enter amount"
//                     value={amount}
//                     onChange={(e) => setAmount(e.target.value)}
//                     min="0" // Prevent negative numbers
//                     onKeyPress={(e) => {
//                         if (e.key === 'e' || e.key === '+' || e.key === '-') {
//                             e.preventDefault(); // Prevent non-numeric inputs
//                         }
//                     }}
//                     required
//                 />
//             </div>
//  {errorPayment && (
//             <p className="mb-2 text-sm text-red-500">{errorPayment}</p>
//           )} {/* Show error message */}
//             <div className="flex justify-end">
//                 <button
//                     className="px-4 py-2 mr-2 text-white bg-gray-500 rounded"
//                     // onClick={() => setPaymentModel(false)}
//                     onClick={HandleClosePayment}
//                 >
//                     Cancel
//                 </button>
//                 <button
//                     className="px-4 py-2 text-white bg-blue-500 rounded"
//                     onClick={() => handlePayment(amount)} // Call function to handle payment submission
//                 >
//                     Submit
//                 </button>
//             </div>
//         </div>
        //     </div>
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-gray-500 bg-opacity-50">
    <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-xl font-bold text-center">
            Commission Payment (Factory Id - {selectedFactoryCommission.factoryId})
        </h2>
        
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                Total Commission:
            </label>
            <p>{selectedFactoryCommission.totalCommission}</p>
        </div>

        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                Commission Done Amount:
            </label>
            <p>{selectedFactoryCommission.CommissionDoneAmount}</p>
        </div>
        
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                Commission Due Amount:
            </label>
            <p>
                {parseFloat(selectedFactoryCommission.totalCommission || 0) -
                parseFloat(selectedFactoryCommission.CommissionDoneAmount || 0)}
            </p> {/* Subtract Done Amount from Total Commission */}
        </div>

        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                Add Amount:
            </label>
            <input 
                type="number" 
                className="block w-full p-2 mt-1 border rounded" 
                placeholder="Enter amount" 
                value={amount} 
                onChange={(e) => setAmount(e.target.value)} 
                min="0" // Prevent negative numbers
                onKeyPress={(e) => {
                    if (e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault(); // Prevent non-numeric inputs
                    }
                }}
                required
            />
        </div>
        
        {errorPayment && (
            <p className="mb-2 text-sm text-red-500">{errorPayment}</p>
        )} {/* Show error message */}
        
        <div className="flex justify-end mt-4">
            <button 
                className="px-4 py-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                onClick={HandleClosePayment} // Close payment modal
            >
                Cancel
            </button>
            <button 
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                onClick={() => handlePayment(amount)} // Call function to handle payment submission
            >
                Submit
            </button>
        </div>
    </div>
</div>

)}

       {PaymentModelDealer && (
//     <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
//         <div className="w-1/3 p-6 bg-white rounded-lg shadow-lg">
//             <h2 className="mb-4 text-xl font-bold">Commission Payment(Shop Id -{selectedDealerCommissiondata.shopId})</h2>
            
//             <div className="mb-4">
//                 <label className="block text-sm font-medium text-gray-700">
//                     Total Commission:
//                 </label>
//                 <p>{selectedDealerCommissiondata.totalCommission}</p>
//             </div>

//             <div className="mb-4">
//                 <label className="block text-sm font-medium text-gray-700">
//                     Commission Done Amount:
//                 </label>
//                 <p>{selectedDealerCommissiondata.CommissionDoneAmount}</p>
//             </div>
            
//             <div className="mb-4">
//     <label className="block text-sm font-medium text-gray-700">
//         Commission Due Amount:
//     </label>
//     <p>
//         {parseFloat(selectedDealerCommissiondata.totalCommission || 0) -
//          parseFloat(selectedDealerCommissiondata.CommissionDoneAmount || 0)}
//     </p> {/* Subtract Done Amount from Total Commission */}
// </div>

//             <div className="mb-4">
//                 <label className="block text-sm font-medium text-gray-700">
//                     Add Amount:
//                 </label>
//                 <input
//                     type="number"
//                     className="block w-full p-2 mt-1 border rounded"
//                     placeholder="Enter amount"
//                     value={amount}
//                     onChange={(e) => setAmount(e.target.value)}
//                     // Prevent negative numbers
//                     onKeyPress={(e) => {
//                         if (e.key === 'e' || e.key === '+' || e.key === '-') {
//                             e.preventDefault(); // Prevent non-numeric inputs
//                         }
//                     }}
//                     required
//                 />
//             </div>
//  {errorPayment && (
//             <p className="mb-2 text-sm text-red-500">{errorPayment}</p>
//           )} {/* Show error message */}
//             <div className="flex justify-end">
//                 <button
//                     className="px-4 py-2 mr-2 text-white bg-gray-500 rounded"
//                     // onClick={() => setPaymentModel(false)}
//                     onClick={HandleClosePaymentDealer}
//                 >
//                     Cancel
//                 </button>
//                 <button
//                     className="px-4 py-2 text-white bg-blue-500 rounded"
//                     onClick={() => handlePaymentDealer(amount)} // Call function to handle payment submission
//                 >
//                     Submit
//                 </button>
//             </div>
//         </div>
        //     </div>
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
    <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-xl font-bold">Commission Payment (Shop Id - {selectedDealerCommissiondata.shopId})</h2>
        
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                Total Commission:
            </label>
            <p>{selectedDealerCommissiondata.totalCommission}</p>
        </div>

        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                Commission Done Amount:
            </label>
            <p>{selectedDealerCommissiondata.CommissionDoneAmount}</p>
        </div>
        
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                Commission Due Amount:
            </label>
            <p>
                {parseFloat(selectedDealerCommissiondata.totalCommission || 0) -
                 parseFloat(selectedDealerCommissiondata.CommissionDoneAmount || 0)}
            </p> {/* Subtract Done Amount from Total Commission */}
        </div>

        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                Add Amount:
            </label>
            <input 
                type="number" 
                className="block w-full p-2 mt-1 border rounded" 
                placeholder="Enter amount" 
                value={amount} 
                onChange={(e) => setAmount(e.target.value)} 
                // Prevent negative numbers
                onKeyPress={(e) => {
                    if (e.key === 'e' || e.key === '+' || e.key === '-') {
                        e.preventDefault(); // Prevent non-numeric inputs
                    }
                }}
                required
            />
        </div>

        {errorPayment && (
            <p className="mb-2 text-sm text-red-500">{errorPayment}</p>
        )} {/* Show error message */}

        <div className="flex justify-end">
            <button 
                className="px-4 py-2 mr-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                onClick={HandleClosePaymentDealer}
            >
                Cancel
            </button>
            <button 
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                onClick={() => handlePaymentDealer(amount)} // Call function to handle payment submission
            >
                Submit
            </button>
        </div>
    </div>
</div>

)}
      
    </div>
   
  );
}

export default Commission;
