import React, { useEffect, useState } from 'react';
import { FaEye } from "react-icons/fa";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import filterSVG from "../../asset/filter.svg";
import { fetchAllOrders } from '../../Service/OrderManagment/OrderManagmentApi';
import Order_Managment_Model from '../Component/Order_Managment_Model';
import Pagination from '../Component/pagination';
import { FaFilePdf } from "react-icons/fa";
import AakaarLogo from "../../asset/AakaarLogo.png";
import { BaseUrl } from '../../auth/Url';
import { MdDelete } from "react-icons/md";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Loader from '../Component/Loader';
import axios from 'axios';
function Order_management_page() {
  
 const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [factoryData, setFactoryData] = useState([]);
   const [DealerData, setDealerData] = useState([]);
  console.log(orders,"orderDetailsin the file")
  const [perPage] = useState(6);
   const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('Pending');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModelAction, setModelAction] = useState('');
  const generatePDF = async(order ,productId) => {
    debugger
      const Factorydata = await fetchFactoryData(order.SupplierInfo.FactoryId
    );
     const Dealerdata = await fetchDealerDetails(order.customerInfo.ShopID
);
    console.log(order,"order for map ")
    setLoading(true)
    const orderId = order?.orderId || 'N/A';
    const orderDate = order?.ProductDetails?.[0]?.OrderDate || 'No order date available';
    const customerName = order?.customerInfo?.ShopName || 'N/A';
    const customerAddress = order?.customerInfo?.Billing_Address || 'N/A';
    const customerContact = order?.customerInfo?.ContactNo || 'N/A';
    const customerGST = order?.customerInfo?.
gstNumber
 ||Dealerdata.gstNumber || 'N/A';
    const customerFSSAI  = order?.customerInfo?.
FSSAINumber
 || Dealerdata.
FSSAINumber || 'N/A';

     const filteredProducts = order.ProductDetails.filter(
        (product) => product.ProductID === productId
    );
    const htmlContent = `
    <html>
        <head>
            <title>Order Quotation</title>
            <style>
                body { 
                    font-family: Arial, sans-serif; 
                    padding: 50px; 
                    font-size: 25px; /* Increased font size */
                    line-height: 1.6; /* Increased line-height */
                }
                .header { 
                    text-align: center; 
                    margin-bottom: 20px; 
                    font-size: 30px; /* Increased font size for the header */
                }
                .headernew { 
                    display: flex; 
                    justify-content: center; 
                    align-items: center; 
                    border-bottom: 2px solid #eee; 
                    padding-bottom: 20px; 
                }
              .headernew img {
    width: 350px;
    height:100px;
    margin-top: 50px;
    background-color: transparent; /* Ensure no background color */
}

                .OrderSummary{
                font-size: 30px;
                }
                .details-container { 
                    display: flex; 
                    justify-content: space-between; 
                    gap: 20px; 
                    margin-bottom: 20px; 
                    padding:20px;
                }
                .details-section { 
                    flex: 1; 
                    border: 1px solid #000; 
                    padding: 20px; /* Increased padding */
                    border-radius: 5px; 
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); 
                    margin-top: 10px; 
                }
              .products-section {
                margin-bottom: 20px;
                  padding:20px;
            }
                .productName{
                 font-size: 25px;
                 margin-bottom: 20px;
                }
                .products-table { 
                    width: 100%; 
                    border-collapse: collapse; 
                    margin-bottom: 20px; 
                      font-size: 25px; /* Increased font size */
                }
                .products-table th, .products-table td { 
                    border: 1px solid #000; 
                    padding: 12px; /* Increased padding */
                    text-align: center; 
                }
                .products-table th { 
                    background-color: #f0f0f0; 
                      font-size: 25px; /* Increased font size */
                }
                .footer { 
                    text-align: center; 
                    margin-top: 40px;
                    margin-bottom: 40px; 
                    font-size: 30px; /* Increased font size */
                    padding:20px
                }
                     .amount{
                     padding:20px;
                     }
                .total-amount { 
                    color: gray; 
                    font-weight: bold; 
                      font-size: 25px; /* Increased font size */
                }
                .due-amount { 
                    color: red; 
                    font-weight: bold; 
                       font-size: 25px; /* Increased font size */
                }
            </style>
        </head>
        <body>
            <div class="headernew">
            
              <img src="${AakaarLogo}" alt="Aakar Logo" />
 
            </div>
           
            <div class="header">
            
                <h1 class='OrderSummary'><strong>Order Summary</strong></h1>
                <p>Order ID: ${orderId}</p>
                <p>Order Date: ${new Date(orderDate).toLocaleDateString("en-GB").replace("/", "-").replace("/", "-")}</p>

            </div>
            <div class="details-container">
                <div class="details-section">
                    <h2>Customer Details</h2>
                  
                     <p><strong>Shop Name:</strong> ${customerName}</p>
                    <p><strong>Address:</strong> ${customerAddress}</p>
                    <p><strong>Contact Number:</strong> ${customerContact}</p>
                     <p><strong>GST Number:</strong> ${customerGST}</p>
                      <p><strong>FSSAI Number:</strong> ${customerFSSAI}</p>
                </div>
                <div class="details-section">
                    <h2>Factory Details</h2>
                     ${filteredProducts.map((product, index) => `
            <div>
                <h3><strong>${product.ProductName || 'N/A'}</strong></h3>
                <p><strong>Factory Name:</strong> ${product.SupplierInfo?.FactoryName || 'N/A'}</p>
                <p><strong>Factory Address:</strong> ${product.SupplierInfo?.FactoryAddress || 'N/A'}</p>
                <p><strong>Factory Contact:</strong> ${product.SupplierInfo?.FactoryContact || 'N/A'}</p>
                <p><strong>Factory GST Number:</strong> ${product.SupplierInfo?.FactoryGST ||Factorydata?.GSTNumber||'N/A'}</p>
                <p><strong>Factory FSSAI Number:</strong> ${product.SupplierInfo?.FactoryFSSAI ||Factorydata?.FASSAINumber|| 'N/A'}</p>
            </div>
        `).join('')}
                </div>
            </div>
            <div class="products-section">
                <h2 class='productName'><strong>Product Details</strong></h2>
                <table class="products-table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Product Name</th>
                            <th>Product ID</th>
                            <th>MRP/Kg</th>
                            <th>Discount</th>
                            <th>Bag Size Quantity</th>
                            <th>Product Costing</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${filteredProducts.map((product, index) => {
                            const sizeQuantityMap = product.selection.reduce((map, selection) => {
                                map[selection.size] = (map[selection.size] || 0) + selection.quantity;
                                return map;
                            }, {});
                            const sizeQuantityString = Object.entries(sizeQuantityMap).map(([size, quantity]) => `${size} - ${quantity}`).join(', ');
                            return `
                                <tr>
                                    <td>${index + 1}</td>
                                    <td>${product.ProductName || 'N/A'}</td>
                                    <td>${product.ProductID || 'N/A'}</td>
                                    <td>${product.MRP || 'N/A'}</td>
                                    <td>${product.discount || 'N/A'}</td>
                                    <td>${sizeQuantityString || 'N/A'}</td>
                                    <td>${product.productTotalAmount || 'N/A'}</td>
                                </tr>
                            `;
                        }).join('')}
                    </tbody>
                </table>
            </div>
            <div class='amount'>
                <p class="total-amount">Order Amount: ${order.Total}</p>
                <p class="due-amount">Order Due Amount: ${order.Duepayment}</p>
            </div>
            <div class="footer">
                <strong>Thank you for your business!</strong>
            </div>
        </body>
    </html>`;

  const doc = new jsPDF('p', 'pt', 'a4');

const pdfContainer = document.createElement('div');
pdfContainer.style.width = '1300.28px'; // Match A4 width
pdfContainer.innerHTML = htmlContent;
document.body.appendChild(pdfContainer);

const scale = window.devicePixelRatio || 2; // Use device pixel ratio for scaling
html2canvas(pdfContainer, { scale }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = 595.28; // A4 width in points
    const pageHeight = 1200.89; // A4 height in points
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
    }

    doc.save(`Order_Quotation_${orderId}.pdf`);
    document.body.removeChild(pdfContainer); // Clean up
    setLoading(false);

    });
};


 const fetchFactoryData = async (factoryId) => {
    try {
        const response = await axios.get(`${BaseUrl}/factory/${factoryId}`);
        if (response.data?.result && response.data?.statusCode === 200) {
            return response.data.Factory;
        } else {
            throw new Error('Failed to fetch factory data');
        }
    } catch (error) {
        console.error('Error fetching factory data:', error);
        return null;
    }
};
  const fetchDealerDetails = async (selectedShopId) => {
       
            try {
                const dealerResponse = await fetch(`${BaseUrl}/Dealer/DealerDetails/${selectedShopId}`);
                const dealerResult = await dealerResponse.json();
              if (dealerResult?.data) {
                   return dealerResult.data;
                   
                } else {
                    console.error('Dealer data not found');
                }
            } catch (error) {
                console.error('Error fetching dealer details:', error);
            }
        
    };
const navigate = useNavigate();
useEffect(() => {
 
  getOrders();
}, []);
 const getOrders = async () => {
    try {
      const response = await fetchAllOrders();
      // Reverse the list to get the latest orders first
      const reversedOrders = response.OrderList.reverse();
      setOrders(reversedOrders);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  };

  function getPaymentStatusColor(paymentStatus) {
    switch (paymentStatus) {
      case 'Paid':
        return 'border-green-500 text-green-500';
      case 'Partial':
        return 'border-orange-500 text-orange-500';
      case 'Unpaid':
        return 'border-red-500 text-red-500';
      default:
        return 'border-gray-300 text-gray-300';
    }
  }

  


  const openModal = (orderId, productId) => {
   navigate("/View_order", { state: { orderId, productId } });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const flattenedOrders = orders.flatMap(order =>
    order.ProductDetails.map(productDetail => ({
      ...order,
      ...productDetail
    }))
  );

  const filteredOrders = flattenedOrders.filter(order => {
    const matchesSearchTerm = order.customerInfo.CustomerName.toLowerCase().includes(searchTerm.toLowerCase());
    if (activeTab === 'All Orders') {
      return matchesSearchTerm;
    } else {
      const matchesStatus = (activeTab === 'Pending' && order.dispatchShippingDetails.OrderStatus === 'Pending') ||
                            (activeTab === 'Confirm' && order.dispatchShippingDetails.OrderStatus === 'Shipped') ||
                            (activeTab === 'Canceled' && order.dispatchShippingDetails.OrderStatus === 'Canceled');
      return matchesSearchTerm && matchesStatus;
    }
  });

  const sortedOrders = filteredOrders.sort((a, b) => {
    if (sortOrder === 'asc') {
      return new Date(a.date) - new Date(b.date);
    } else {
      return new Date(b.date) - new Date(a.date);
    }
  });

  const indexOfLastRecord = currentPage * perPage;
  const indexOfFirstRecord = indexOfLastRecord - perPage;
  const currentRecords = sortedOrders.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(sortedOrders.length / perPage);
  console.log(currentRecords)
  
//   const NavigateViewOrder = (orderId, productId) => {
//   // Create a URL with query parameters or pathname parameters
//   navigate(`/View_order?orderId=${orderId}&productId=${productId}`);
  // };
  
  const NavigateViewOrder = (orderId, productId,Action) => {
    navigate("/View_order", { state: { orderId, productId,Action } });
  }

  const countOrdersByStatus = (status) => {
    return orders.reduce((count, order) => {
      return count + order.ProductDetails.filter(product => product.dispatchShippingDetails.OrderStatus === status).length;
    }, 0);
  };

  // Calculate counts for different statuses
  const pendingCount = countOrdersByStatus('Pending');
  const shippedCount = countOrdersByStatus('Shipped');
  const canceledCount = countOrdersByStatus('Canceled');
  const HandleNavigateNewOrders = () => {
   navigate("/Add_New_Order")
  }
  let lastOrderId = null;


  const handleDeleteOrder = async (orderId) => {
    try {
        const response = await axios.post(`${BaseUrl}/Order/DeleteOrder`, { orderId }); // Send the orderId in the request body

        if (response.status === 200) {
            getOrders(); // Refresh the order list after deletion
            alert('Order and transaction record deleted successfully.');
        }
    } catch (error) {
        console.error('Error deleting order:', error);
        alert('Failed to delete order.');
    }
};
  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className="flex items-center justify-between pt-5">
          <div className="md:flex items-center  pt-5 md:gap-[200px] grid ">
            <div className='pb-2 pl-5 pr-5 '>
              <p className="text-[14px]">Order Summary</p>
            <div className="flex bg-white rounded-lg md:w-[750px] justify-evenly mt-2  w-[350px] overflow-x-auto">
              
                <div className="p-5 " >
                <p>Total 
                 </p>
                  <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold"> {orders.length}</p>
              </div>
              <div class="flex items-center justify-center ">
  <div class="h-10 w-px bg-gray-400"></div>
</div>
              <div className="p-5">
                <p>Completed
                 </p>
                  <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{shippedCount}</p>
                </div>
                

                 <div class="flex items-center justify-center ">
  <div class="h-10 w-px bg-gray-400"></div>
</div>
              <div className="p-5">
                <p>Pending
                 </p>
                  <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{pendingCount}</p>
                </div>
                                 <div class="flex items-center justify-center ">
  <div class="h-10 w-px bg-gray-400"></div>
</div>
              <div className="p-5">
                <p>Canceled
                 </p>
                  <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{canceledCount}</p>
              </div>
            </div>
            </div>
            <div className='flex justify-center'> <button onClick={HandleNavigateNewOrders} className='md:w-[175px] md:h-[56px] p-2 bg-[#C6A26F] text-white rounded-[8px] mt-2 md:mt-0 ' >Add Orders</button>
           </div>
           
          </div>
        </div>
        <div className='flex justify-between pb-2 pl-5 pr-5'>
          <div>
            <p className='mt-2 font-bold'>Total Orders ({orders.length})</p>
          </div>
          
        </div>
        <div className="px-3 sm:px-5">
  <div className="p-2 bg-white">
    <div className="flex flex-col justify-between md:flex-row">
      <div className="flex flex-wrap gap-2 mt-2 ml-0 sm:gap-5 md:ml-4">
        {['Pending', 'Confirm', 'Canceled', 'All Orders'].map((tab) => (
          <p
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`cursor-pointer ${
              activeTab === tab ? 'underline underline-green' : ''
            }`}
          >
            {tab} (
            {flattenedOrders.filter((order) =>
              tab === 'All Orders'
                ? true
                : (tab === 'Pending' && order.dispatchShippingDetails.OrderStatus === 'Pending') ||
                  (tab === 'Confirm' && order.dispatchShippingDetails.OrderStatus === 'Shipped') ||
                  (tab === 'Canceled' && order.dispatchShippingDetails.OrderStatus === 'Canceled')
            ).length}
            )
          </p>
        ))}
      </div>
      <div className="flex gap-3 mt-2 sm:gap-5 md:mt-0">
        <div className="w-full sm:w-64">
          <input
            type="text"
            placeholder="Search by Customer name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 pl-10 bg-gray-300 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
          />
         
        </div>
        {/* <div className="p-3 mr-1 bg-gray-300 rounded-lg">
          <img src={filterSVG} alt="Filter" className="w-5 h-5" />
        </div> */}
      </div>
    </div>
  </div>
</div>

        <div className='p-2 pb-102 md:p-0'>
  <div className="overflow-x-auto md:pr-5 md:pl-5">
    <table className="w-full border-collapse min-w-[600px]">
      <thead style={{ backgroundColor: '#4C3F35' }}>
        <tr className="text-white">
          <th className="px-4 py-2 font-normal">Sr.No</th>
          <th className="px-4 py-2 font-normal">Order ID</th>
          <th className="px-4 py-2 font-normal">Customer</th>
          <th className="px-4 py-2 font-normal">Product Details</th>
          <th className="px-4 py-2 font-normal">Date</th>
          <th className="px-4 py-2 font-normal">Supplier</th>
          <th className="px-4 py-2 font-normal">Actions</th>
                  <th className="px-4 py-2 font-normal">View</th>
                  <th className="px-4 py-2 font-normal">PDF</th>
                  {activeTab === 'All Orders' && (
 <th className="px-4 py-2 font-normal">Delete</th>
                  )}
                  
        </tr>
      </thead>
      <tbody className="bg-white">
    {currentRecords.map((order, index) => {
      const shouldShowDeleteButton = order.orderId !== lastOrderId;
      lastOrderId = order.orderId; // Update lastOrderId for the next row

      return (
        <tr key={index} className="border-b border-gray-200">
          <td className="px-4 py-2">{indexOfFirstRecord + index + 1}</td>
          <td className="px-4 py-2" title={order.orderId}>
            {order.orderId.length > 10 ? `${order.orderId.slice(0, 10)}...` : order.orderId}
          </td>

          <td className="px-4 py-2">
            {order.customerInfo.CustomerName.length > 10
              ? `${order.customerInfo.CustomerName.slice(0, 10)}...`
              : order.customerInfo.CustomerName}
          </td>

          <td className="flex items-center gap-2 px-4 py-2 text-center">
            <img
              src={order.selectedImages[0]}
              alt={order.productName}
              className="w-10 h-10"
            />
            <div title={order.ProductName}>
              {order.ProductName.length > 10 ? `${order.ProductName.slice(0, 10)}...` : order.ProductName}
              <span className="ml-2">{order.ProductID}</span>
            </div>
          </td>
          <td className="px-4 py-2">{order.OrderDate}</td>
          <td className="px-4 py-2">{order.SupplierInfo.FactoryName}</td>
          <td className="flex justify-center px-4 py-2">
            {order.dispatchShippingDetails.OrderStatus === "Pending" && (
              <button onClick={() => NavigateViewOrder(order.orderId, order.ProductID, "")} className="p-2 text-[#0855FF] border border-[#0855FF] rounded-lg hover:bg-[#0855FF] hover:text-white">
                View Orders
              </button>
            )}
            {order.dispatchShippingDetails.OrderStatus === "Shipped" && (
              order.dispatchShippingDetails.RefundStatus === "Refund List" ? (
                <p className="p-2 text-white border border-red-600 rounded-lg bg-red-500 w-[100px] text-center">Refund</p>
              ) : (
                <p className="p-2 text-white border border-[#28C76F] rounded-lg bg-[#28C76F] w-[100px] text-center">Confirm</p>
              )
            )}
            {order.dispatchShippingDetails.OrderStatus === "Canceled" && (
              <p className="p-2 text-white border border-[#FB3232] rounded-lg bg-[#FB3232] w-[100px] text-center">Cancel</p>
            )}
          </td>
          <td className="px-4 py-2 border-gray-300">
            <FaEye className="text-blue-500 cursor-pointer" onClick={() => NavigateViewOrder(order.orderId, order.ProductID, order.dispatchShippingDetails.OrderStatus)} />
          </td>
          <td className="px-4 py-2">
            <FaFilePdf
              title="Download PDF"
              className="w-5 h-5 text-red-500 cursor-pointer hover:scale-105"
              onClick={() => generatePDF(order, order.ProductID)}
            />
          </td>
          {activeTab === 'All Orders' && (
 <td className="px-4 py-2 border-gray-300">
            {/* Show Delete only if it's the first occurrence of the orderId */}
            {shouldShowDeleteButton && (
              <MdDelete className="text-blue-500 cursor-pointer" onClick={()=>handleDeleteOrder(order.orderId)}  />
            )}
          </td>
          )}
         
        </tr>
      );
    })}
  </tbody>
    </table>

    <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
  </div>
</div>

        {isModalOpen && <Order_Managment_Model onClose={closeModal} action={ModelAction} />}
         <Loader loading={loading} message={"Please Wait Downloading the Order PDF."} />
      </div>
    </div>
  );
}

export default Order_management_page;
