import React from 'react';
import { FaCamera } from "react-icons/fa";
import backgroundImage from '../asset/profileimage.png'; // Adjust the path to your background image
import wheateImage from "../asset/sihorewheate.png";

function Profile() {
    const personalInfo = JSON.parse(localStorage.getItem('personalInfo')) || {};

    return (
        <div style={{ backgroundColor: "#F6F6F6", minHeight: "90vh" }} className='pt-10'>
            <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
                <div
                    className="relative h-48 bg-center bg-cover rounded-t-md bg-[#FFF9F0]"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                >
                    <div className="absolute mt-10 text-white left-4">
                        <p className='text-[#9E9DA1]'>Factory Name</p>
                        <h1 className="mt-2 text-2xl font-bold text-[#333333]">{personalInfo.factoryName || 'Grain Factory'}</h1>
                        <p className="text-lg text-[#333333]">
                            {personalInfo.email || 'grainfactory@gmail.com'}
                            {personalInfo.email && ' | '}
                            {personalInfo.contactNumber || '+91 96568956965'}
                        </p>
                    </div>
                </div>
                <div className="flex gap-24 p-6 bg-white border-b-2 border-gray-300 rounded-b-lg">
                    <div className="relative w-[220px] h-[220px]">
                        <img
                            src={wheateImage}
                            alt="Logo"
                            className="object-cover w-full h-full rounded-lg"
                        />
                        <button className="absolute flex justify-center p-2 text-black transform -translate-x-1/2 bg-gray-200 rounded-full bottom-2 left-1/2 hover:bg-gray-300">
                            <FaCamera />
                        </button>
                    </div>
                    <div className="mt-6 md:mt-0">
                        <div className="flex gap-10">
                            <div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">Factory Name: </span>
                                    <span>{personalInfo.factoryName || 'N/A'}</span>
                                </div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">Supplier Name: </span>
                                    <span>{personalInfo.contactPerson || 'N/A'}</span>
                                </div>
                               
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">Contact No: </span>
                                    <span>{personalInfo.contactNo || 'N/A'}</span>
                                </div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">Email Id: </span>
                                    <span>{personalInfo.email || 'N/A'}</span>
                                </div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">Website: </span>
                                    <span>{personalInfo.website || 'N/A'}</span>
                                </div>
                            </div>
                            <div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">Factory Address: </span>
                                    <span>{personalInfo.factoryAddress || 'N/A'}</span>
                                </div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">GST Number: </span>
                                    <span>{personalInfo.GSTNumber || 'N/A'}</span>
                                </div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">FSSAI Number: </span>
                                    <span>{personalInfo.FASSAINumber
 || 'N/A'}</span>
                                </div>
                                {/* <div className="mt-2">
                                    <span className="w-40 font-semibold">State: </span>
                                    <span>{personalInfo.State || 'N/A'}</span>
                                </div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">City: </span>
                                    <span>{personalInfo.city || 'N/A'}</span>
                                </div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">Country: </span>
                                    <span>{personalInfo.country || 'N/A'}</span>
                                </div>
                                <div className="mt-2">
                                    <span className="w-40 font-semibold">Postal Code: </span>
                                    <span>{personalInfo.postalCode || 'N/A'}</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
