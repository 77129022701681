import React, { useEffect, useRef, useState } from 'react';
import { FaPlus } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert
import { BaseUrl } from '../../auth/Url';
import { fetchAllOrders } from '../../Service/OrderManagment/OrderManagmentApi';

function FactoryList() {
  const [factories, setFactories] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // For search input
  const [filter, setFilter] = useState('All'); // State for filter
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const token = localStorage.getItem("token");
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
 const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(null); // Close the dropdown
    }
  };

  useEffect(() => {
    if (dropdownOpen !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);
  // Fetch factories on mount
  useEffect(() => {
    fetchFactories();
  }, []);

  // Fetch orders on mount
  useEffect(() => {
    const getOrders = async () => {
      try {
        const response = await fetchAllOrders();
        setOrders(response.OrderList.reverse()); // Reverse to get latest orders first
      } catch (error) {
        console.error('Failed to fetch orders:', error);
      }
    };
    getOrders();
  }, []);

  const fetchFactories = async () => {
    try {
      const response = await fetch(`${BaseUrl}/GetAllFActories`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch factories');

      const data = await response.json();
      setFactories(data.FactoryList.reverse());
    } catch (error) {
      console.error('Error fetching factories:', error);
    }
  };

  // Handle filtering by 'All', 'Active', 'Inactive'
  const handleFilterChange = (filterType) => {
    setFilter(filterType);
  };

  // Apply both filtering and search logic
  const filteredFactories = factories.filter(factory => {
    if (filter === 'Active' && factory.products.length === 0) return false;
    if (filter === 'Inactive' && factory.products.length > 0) return false;
    return factory.factoryName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // Handle navigation to the View page
  const navigateView = (data, id) => {
    navigate("/factory/View_Factory", { state: { data, id } });
  };

  // Delete a factory
  const deleteFactory = async (factoryId) => {
    try {
      const response = await fetch(`${BaseUrl}/DeleteFactory/${factoryId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        Swal.fire('Deleted!', 'Factory has been deleted successfully.', 'success');
        fetchFactories(); // Refetch factories after deletion
      } else {
        Swal.fire('Error!', 'Failed to delete factory.', 'error');
      }
    } catch (error) {
      Swal.fire('Error!', 'An error occurred while deleting the factory.', 'error');
    }
  };

  // Confirm delete action
  const handleDelete = (factoryId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFactory(factoryId);
      }
    });
  };

  // Flatten order data for displaying order count
  const flattenedOrders = orders.flatMap(order =>
    order.ProductDetails.map(productDetail => ({
      ...productDetail,
      ShopId: order.ShopId,
      customerName: order.customerInfo.CustomerName,
      shopName: order.customerInfo.ShopName,
      contactNumber: order.customerInfo.ContactNo,
      email: order.customerInfo.EmailID,
    }))
  );
 const handleNavigateEditFactory = (factory,Action) => {
  navigate('/factory/Add_Update_factory', { state: { factory,Action } });
};

  
   const handleLoginAccessToggle = async (factoryId, isAllowLogin) => {
    try {
      const response = await fetch(`${BaseUrl}/FactoryLoginAccess`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ factoryId, isAllowLogin: !isAllowLogin }),
      });

      const data = await response.json();

      if (data.result && data.statusCode === 200) {
        // Update the local state with the new isAllowLogin value
        setFactories((prevDealers) =>
          prevDealers.map((dealer) =>
            dealer.factoryId === factoryId ? { ...dealer, isAllowLogin: !isAllowLogin } : dealer
          )
        );
      } else {
        console.error('Error updating login access:', data.message);
      }
    } catch (error) {
      console.error('Error updating login access:', error);
    }
  };

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] p-5 md:p-0">
        <div className="grid items-center justify-between pt-5 md:flex">
          <div>
            <p className="text-[14px]">Factory Summary</p>
            <div className="flex bg-white rounded-lg md:w-[500px] justify-evenly mt-2 overflow-x-auto w-[350px]">
              {/* Filter options */}
              <div className="p-5 cursor-pointer" onClick={() => handleFilterChange('All')} >
                <p className='text-[12px]'>All Factories</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{factories.length}</p>
              </div>
              <div className="flex items-center justify-center"><div className="h-14 bg-gray-400 text-gray-400 w-0.5"></div></div>
              <div className="p-5 cursor-pointer" onClick={() => handleFilterChange('Active')} >
                <p className='text-[12px]'>Active</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{factories.filter(factory => factory.products.length > 0).length}</p>
              </div>
              <div className="flex items-center justify-center"><div className="h-14 bg-gray-400 text-gray-400 w-0.5"></div></div>
              <div className="p-5 cursor-pointer" onClick={() => handleFilterChange('Inactive')} >
                <p className='text-[12px]'>In-Active</p>
                <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{factories.filter(factory => factory.products.length === 0).length}</p>
              </div>
            </div>
          </div>
          <Link className="flex gap-2 px-4 py-2 mt-5 text-white rounded-lg md:mt-0" style={{ backgroundColor: 'rgba(198, 162, 111, 1)' }} to="/factory/Add_Update_factory">
            <FaPlus className="mt-1 text-[16px]" /><p>Add new Factory</p>
          </Link>
        </div>

        {/* Search bar */}
        <div className='mt-5'>
  <input
    type="text"
    placeholder="Search by Factory name"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    className="w-full px-4 py-2 mb-4 border rounded-lg"
  />
  <div className="overflow-x-auto"> {/* Enable horizontal scrolling */}
    <table className='min-w-full rounded-lg'>
      <thead className='font-normal text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
        <tr>
          <th className='p-2 font-normal rounded-tl-lg'>Factory ID</th>
          <th className='p-2 font-normal'>Factory Name</th>
          <th className='p-2 font-normal'>Email</th>
          <th className='p-2 font-normal'>Phone No</th>
          <th className='p-2 font-normal'>Location</th>
                  <th className='p-2 font-normal'>Order Count</th>
                  <th className='p-2 font-normal'>Access</th>
          <th className='p-2 font-normal'>View</th>
          <th className='p-2 font-normal'>Edit</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {filteredFactories
          .map(factory => {
            const orderCount = flattenedOrders.filter(order =>
              order.SupplierInfo.FactoryId === factory.factoryId &&
              order.dispatchShippingDetails &&
              order.dispatchShippingDetails.DispatchStatus === 'Completed'
            ).length;

            return { ...factory, orderCount }; // Add orderCount to factory object
          })
          .sort((a, b) => b.orderCount - a.orderCount) // Sort by order count descending
          .map((factory, index) => (
            <tr key={index} className='border-b-2'>
              <td className='p-2 text-center'>
                <span className='text-blue-600 underline'>{factory.factoryId}</span>
              </td>
              <td className='p-2 text-center'>{factory.factoryName}</td>
              <td className='p-2 text-center'>{factory.email}</td>
              <td className='p-2 text-center'>{factory.contactNo}</td>
              <td className='p-2 text-center'>
                {factory.factoryAddress.length > 15 ? `${factory.factoryAddress.slice(0, 15)}...` : factory.factoryAddress}
              </td>
              <td className='p-2 text-center'>{factory.orderCount}</td>
              <td className="p-2 text-center border">
              <div className="relative inline-block align-middle transition duration-200 ease-in select-none w-14">
                <input
                  type="checkbox"
                  name="toggle"
                  id={`toggle-${factory.factoryId}`}
                  checked={factory.isAllowLogin}
                  onChange={() => handleLoginAccessToggle(factory.factoryId, factory.isAllowLogin)}
                  className="sr-only"
                />
                <label
                  htmlFor={`toggle-${factory.factoryId}`}
                  className={`block overflow-hidden h-8 rounded-full cursor-pointer bg-gray-300 ${factory.isAllowLogin ? 'bg-green-400' : ''}`}
                >
                  <span
                    className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transform transition-transform ${factory.isAllowLogin ? 'translate-x-6 bg-green-400' : ''}`}
                  ></span>
                </label>
              </div>
            </td>
              <td className='p-2 text-center'>
                <button onClick={() => navigateView(factory, factory.factoryId)}>
                  <IoEyeOutline className="text-[20px] ml-2" />
                </button>
              </td>
              <td className='p-2 text-center'>
                <div className="relative inline-block">
                  <HiDotsVertical className="text-[20px] ml-2 cursor-pointer" onClick={() => setDropdownOpen(dropdownOpen === index ? null : index)} />
                  {dropdownOpen === index && (
                    <div ref={dropdownRef} className="absolute right-0 z-10 w-32 p-2 bg-white border rounded shadow-lg">
                      <div
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleNavigateEditFactory(factory, 'Edit')}
                      >
                        Edit
                      </div>
                      <div
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleDelete(factory._id)}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
</div>

      </div>
    </div>
  );
}

export default FactoryList;
