
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { fetchDispatchList } from '../../Service/OrderManagment/OrderManagmentApi';
import OrderTrackingModal from '../Pages/Component/OrderTrackingModal';
// import Pagination from '../Component/pagination';
import Pagination from '../Pages/Component/pagination';
import { fetchDispatchListOfFactory } from '../Service/OrderManagment/OrderManagmentApi';
function DispatchManagmentFactory() {
 const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(6);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('pending');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dispatchList, setDispatchList] = useState([]);
  console.log(dispatchList,"dispatchList")
   const [selectedOrder, setSelectedOrder] = useState(null);
  
  useEffect(() => {
    getDispatchList();
  }, []);
  
   const getDispatchList = async () => {
  try {
    const personalInfo = JSON.parse(localStorage.getItem('personalInfo')); // Parse the JSON string to an object
    const factoryId = personalInfo?.factoryId; // Safely access factoryId

    if (!factoryId) {
      console.error('No factory ID found in personalInfo');
      return; 
    }

    const data = await fetchDispatchListOfFactory(factoryId); // Pass factoryId to the API call
    if (data.result) {
      setDispatchList(data.DispatchOrderList.reverse());
    }
  } catch (error) {
    console.error('Error fetching dispatch list:', error);
  }
};


const filteredOrders = dispatchList.filter(order => {
  // For 'All Orders' or matching DispatchStatus
  if (activeTab === 'All Orders' || order.dispatchShippingDetails.DispatchStatus === activeTab) {
    return order.dispatchShippingDetails.DispatchID.toLowerCase().includes(searchTerm.toLowerCase());
  }

  // Check for 'Due Payment' tab and apply the search filter
  if (activeTab === 'Due Payment' && order.dispatchShippingDetails.DispatchStatus === 'Completed' && order.Duepayment !== "0") {
    // Apply search term filter
    return order.dispatchShippingDetails.DispatchID.toLowerCase().includes(searchTerm.toLowerCase());
  }

  return false;
});


  const sortedOrders = filteredOrders.sort((a, b) => {
    if (sortOrder === 'asc') {
      return new Date(a.OrderDate) - new Date(b.OrderDate);
    } else {
      return new Date(b.OrderDate) - new Date(a.OrderDate);
    }
  });

  const indexOfLastRecord = currentPage * perPage;
  const indexOfFirstRecord = indexOfLastRecord - perPage;
  const currentRecords = sortedOrders.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(sortedOrders.length / perPage);

  const openModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };;

     const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };
const naviagteAddDetails = (data, id) => {
    navigate("/Fill_Order_details", { state: { data, id } });
  }
  const countOrdersByStatus = (key, value) => {
    return dispatchList.filter(order => order.dispatchShippingDetails[key] === value).length;
  };

  // Calculate counts for different statuses
  const allDispatchedCount = countOrdersByStatus('DispatchStatus', 'Dispatched');
  const yetToDispatchCount = countOrdersByStatus('DispatchStatus', 'pending');
  const inTransitionCount = countOrdersByStatus('DispatchStatus', 'pending');
  const deliveredCount = countOrdersByStatus('DispatchStatus', 'Completed');


  const countDuePayments = () => {
  return dispatchList.filter(order => 
    order.dispatchShippingDetails.DispatchStatus === 'Completed' && order.Duepayment !== "0"
  ).length;
};
  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className='p-5 overflow-x-auto w-[350px] md:w-full'>
          <p className="text-[14px]">Factory Summary</p>
          <div className="flex bg-white rounded-lg w-[900px] justify-evenly mt-2">
            <div className="p-5">
              <p className='text-[12px]'>All Dispatched</p>
              <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{allDispatchedCount}</p>
            </div>
            <div className="flex items-center justify-center">
              <div className="h-14 bg-gray-400 text-gray-400 w-0.5"></div>
            </div>
            <div className="p-5">
              <p className='text-[12px]'>Yet to Dispatch</p>
              <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{yetToDispatchCount}</p>
            </div>
            <div className="flex items-center justify-center">
              <div className="h-14 bg-gray-400 text-gray-400 w-0.5"></div>
            </div>
            <div className="p-5">
              <p className='text-[12px]'>In-Transition</p>
              <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{inTransitionCount}</p>
            </div>
            <div className="flex items-center justify-center">
              <div className="h-14 bg-gray-400 text-gray-400 w-0.5"></div>
            </div>
            <div className="p-5">
              <p className='text-[12px]'>Delivered</p>
              <p style={{ color: 'rgba(198, 162, 111, 1)' }} className="text-[24px] font-semibold">{deliveredCount}</p>
            </div>
          </div>
        </div>

        <div className='flex justify-between pb-2 pl-5 pr-5'>
          <div>
  <p className='mt-2 font-bold'>Total Orders ({dispatchList.length})</p>
</div>

          {/* <div className='flex gap-2 p-2 text-sm text-black bg-white border rounded-lg'>
            <ImSortAmountAsc className='mt-1' />
            <button>
              Sort by Date
            </button>
            <FaChevronDown className='mt-1' />
          </div> */}
        </div>

       <div className='px-4 sm:px-5 lg:px-6'>
  <div className='p-2 bg-white'>
    <div className='flex flex-col justify-between md:flex-row'>
      <div className='flex flex-wrap gap-5 mt-2 ml-4'>
        {['pending', 'Dispatched', 'Due Payment', 'Completed', 'Return'].map(tab => (
          <p
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`cursor-pointer text-sm sm:text-base md:text-lg ${activeTab === tab ? 'underline underline-green' : ''}`}
          >
            {tab === 'Due Payment' 
              ? `Due Payment (${countDuePayments()})` 
              : `${tab} (${dispatchList.filter(order => order.dispatchShippingDetails.DispatchStatus === tab).length})`
            }
          </p>
        ))}
      </div>
      <div className='flex gap-5 mt-4 md:mt-0'>
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 pl-10 bg-gray-300 border border-gray-300 rounded-full sm:w-64 focus:outline-none focus:border-blue-500"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


        <div className='pb-10'>
          <div className="px-4 sm:px-5">
            
            <div className='overflow-x-auto'>
 <div className="">
            <table className="w-full border border-collapse border-gray-300 ">
              <thead style={{ backgroundColor: '#4C3F35' }} className="text-white">
                <tr>
                  <th className="p-2 font-normal">Sr. No.</th>
                  <th className="p-2 font-normal">Dispatch ID</th>
                  <th className="p-2 font-normal">Shop/ Customer</th>
                  <th className="p-2 font-normal">Products</th>
                  <th className="p-2 font-normal">Order Date</th>
                  
                  <th className="p-2 font-normal">Supplier</th>
                  <th className="p-2 font-normal">Actions</th>
                </tr>
              </thead>
              <tbody className="text-black bg-white">
                {currentRecords.map((order, index) => (
                  <tr key={`${order.ProductID}-${index}`} className="border-b border-gray-200">
                    <td className="p-2 text-center">{index + 1}</td>
                    <td className="p-2 text-center">{order.dispatchShippingDetails.DispatchID}</td>
                    <td className="p-2 text-center">{order.customerInfo.CustomerName}</td>
                     <td className="p-2 text-center">
                      <div className="flex items-center justify-center gap-5">
                         <img
    src={order.selectedImages[0]}
    alt={order.productName}
    className="w-10 h-10"
                          />
                        <span className="">
                          {order.ProductID}<br></br>
    {order.ProductName.length > 10 
      ? `${order.ProductName.substring(0, 10)}...` 
      : order.ProductName
    }
    
  </span>
</div>

                    </td>
                    <td className="p-2 text-center">{order.OrderDate}</td>
                   
                    <td className="p-2 text-center">{order.SupplierInfo.FactoryName}</td>
                     <td className="flex justify-center p-2 text-center ">
                 
                      {
                          order.dispatchShippingDetails.DispatchStatus === "pending" && (
                          <button
                             onClick={() => naviagteAddDetails(order, order._id)}
                           
                            // to={"/Fill_Order_Details"}
                    className={`  border-[#0855FF] rounded-lg  text-[#0855FF]  border p-1 cursor-pointer text-[15px] hover:bg-[#0855FF] hover:text-white`}
                   
                  >
                  <span className=''>Add Details</span>
                  </button>  
                         )
                      }
                       {
                         order.dispatchShippingDetails.DispatchStatus === "Dispatched" && (
                             <div
                    className={` border-[#0855FF] rounded-lg  text-[#0855FF]  border p-1 cursor-pointer text-[15px] hover:bg-[#0855FF] hover:text-white`}
                                  
                                  onClick={() => openModal(order)}
                  >
                  <span className='text-[15px]'>Track Order</span>
                  </div>  
                         )
                      }
                       {
                          order.dispatchShippingDetails.DispatchStatus === "Completed" && (
                             <div
                    className={` border-green-600 rounded-lg  text-white  border p-1 cursor-pointer text-[15px] bg-green-600 hover:text-white mt-2`}
                   
                  >
                  <span className='text-[15px]'>Completed</span>
                  </div>  
                         )
                 }
               {
                          order.dispatchShippingDetails.DispatchStatus === "Return" && (
                             <div
                    className={` border-red-600 rounded-lg  text-white  border p-1 cursor-pointer text-[15px] bg-red-600 hover:text-white mt-2`}
                   
                  >
                  <span className='text-[15px]'>Return</span>
                  </div>  
                         )
                 }
                      
                    
                 
             
              </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
            
          </div>
           </div>
          
         
        </div>
        
  <OrderTrackingModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
          orderData={selectedOrder}
          getDispatchList={getDispatchList}
            />
        {/* <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        /> */}
        <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
}

export default DispatchManagmentFactory;
