import React, { useEffect, useState } from 'react';
import { BaseUrl } from '../../auth/Url';

const ViewQuate = ({ quotation, onClose }) => {
    const [quotationDetails, setQuotationDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQuotationDetails = async () => {
            try {
                const response = await fetch(`${BaseUrl}/Quatation/GetQuoteDetails/${quotation}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch quotation details');
                }
                const data = await response.json();
                setQuotationDetails(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchQuotationDetails();
    }, [quotation]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!quotationDetails) return null;

    return (
        // <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        //     <div className="w-full max-w-4xl p-4 bg-white rounded shadow-lg">
        //         <h2 className="mb-2 text-xl">Quotation Details</h2>
                
        //         <div className="mb-4">
        //             <h3 className="text-lg font-semibold">Shop Information</h3>
        //             <p><strong>Shop Name:</strong> {quotationDetails.ShopInformation.ShopName}</p>
        //             <p><strong>Contact Person:</strong> {quotationDetails.ShopInformation.ShopOwnerContactPerson}</p>
        //             <p><strong>Contact:</strong> {quotationDetails.ShopInformation.Contact}</p>
        //             <p><strong>Email:</strong> {quotationDetails.ShopInformation.EmailID}</p>
        //             <p><strong>Billing Address:</strong> {quotationDetails.ShopInformation.BillingAddress}</p>
        //         </div>

        //         <div className="mb-4">
        //             <h3 className="text-lg font-semibold">Quotation Details</h3>
        //             <p><strong>Quotation ID:</strong> {quotationDetails.AddDetails.QuotationID}</p>
        //             <p><strong>Quotation Date:</strong> {new Date(quotationDetails.AddDetails.QuotationDate).toLocaleDateString()}</p>
        //             <p><strong>Expiry Date:</strong> {new Date(quotationDetails.AddDetails.ExpiryDate).toLocaleDateString()}</p>
        //         </div>

        //         <div className="mb-4">
        //             <h3 className="text-lg font-semibold">Product Details</h3>
        //             <table className="min-w-full border border-gray-300">
        //                 <thead>
        //                     <tr className="bg-gray-200">
        //                         <th className="px-4 py-2 border border-gray-300">Product Name</th>
        //                         <th className="px-4 py-2 border border-gray-300">Category</th>
        //                         <th className="px-4 py-2 border border-gray-300">Price</th>
        //                         <th className="px-4 py-2 border border-gray-300">Subtotal</th>
        //                         <th className="px-4 py-2 border border-gray-300">Discount</th>
        //                         <th className="px-4 py-2 border border-gray-300">Selection</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {quotationDetails.ProductDetails.selectedProducts.map((product) => (
        //                         <tr key={product._id} className="border-b border-gray-300">
        //                             <td className="px-4 py-2 border border-gray-300">{product.productName}</td>
        //                             <td className="px-4 py-2 border border-gray-300">{product.category}</td>
        //                             <td className="px-4 py-2 border border-gray-300">₹{product.price.toFixed(2)}</td>
        //                             <td className="px-4 py-2 border border-gray-300">₹{product.Total.toFixed(2)}</td>
        //                             <td className="px-4 py-2 border border-gray-300">₹{product.Dis_Amt.toFixed(2)}</td>
        //                             <td className="px-4 py-2 border border-gray-300">
        //                                 <ul>
        //                                     {product.selection.map((item) => (
        //                                         <li key={item._id}>
        //                                             Size: {item.size}, Quantity: {item.quantity}
        //                                         </li>
        //                                     ))}
        //                                 </ul>
        //                             </td>
        //                         </tr>
        //                     ))}
        //                 </tbody>
        //             </table>
        //             <p className="mt-4"><strong>Total Subtotal:</strong> ₹{quotationDetails.ProductDetails.Subtotal.toFixed(2)}</p>
        //             <p><strong>Total Discount:</strong> ₹{quotationDetails.ProductDetails.TotalDiscount.toFixed(2)}</p>
        //         </div>

        //         <div className="flex justify-end mt-4">
        //             <button onClick={onClose} className="px-4 py-2 text-white bg-blue-500 rounded">Close</button>
        //         </div>
        //     </div>
        // </div>
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="w-full max-w-4xl p-4 bg-white rounded shadow-lg sm:p-6 lg:p-8 h-[600px] overflow-auto">
        <h2 className="mb-2 text-xl text-center sm:text-left">Quotation Details</h2>
        
        <div className="mb-4">
            <h3 className="text-lg font-semibold">Shop Information</h3>
            <p><strong>Shop Name:</strong> {quotationDetails.ShopInformation.ShopName}</p>
            <p><strong>Contact Person:</strong> {quotationDetails.ShopInformation.ShopOwnerContactPerson}</p>
            <p><strong>Contact:</strong> {quotationDetails.ShopInformation.Contact}</p>
            <p><strong>Email:</strong> {quotationDetails.ShopInformation.EmailID}</p>
            <p><strong>Billing Address:</strong> {quotationDetails.ShopInformation.BillingAddress}</p>
        </div>

        <div className="mb-4">
            <h3 className="text-lg font-semibold">Quotation Details</h3>
            <p><strong>Quotation ID:</strong> {quotationDetails.AddDetails.QuotationID}</p>
            <p><strong>Quotation Date:</strong> {new Date(quotationDetails.AddDetails.QuotationDate).toLocaleDateString()}</p>
            <p><strong>Expiry Date:</strong> {new Date(quotationDetails.AddDetails.ExpiryDate).toLocaleDateString()}</p>
        </div>

        <div className="mb-4">
            <h3 className="text-lg font-semibold">Product Details</h3>
            <div className="overflow-x-auto">
                <table className="min-w-full border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">Product Name</th>
                            <th className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">Category</th>
                            <th className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">Price</th>
                            <th className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">Subtotal</th>
                            <th className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">Discount</th>
                            <th className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">Selection</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quotationDetails.ProductDetails.selectedProducts.map((product) => (
                            <tr key={product._id} className="border-b border-gray-300">
                                <td className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">{product.productName}</td>
                                <td className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">{product.category}</td>
                                <td className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">₹{product.price.toFixed(2)}</td>
                                <td className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">₹{product.Total.toFixed(2)}</td>
                                <td className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">₹{product.Dis_Amt.toFixed(2)}</td>
                                <td className="px-4 py-2 text-xs border border-gray-300 sm:text-sm md:text-base">
                                    <ul>
                                        {product.selection.map((item) => (
                                            <li key={item._id}>
                                                Size: {item.size}, Quantity: {item.quantity}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <p className="mt-4 text-sm sm:text-base"><strong>Total Subtotal:</strong> ₹{quotationDetails.ProductDetails.Subtotal.toFixed(2)}</p>
            <p className="text-sm sm:text-base"><strong>Total Discount:</strong> ₹{quotationDetails.ProductDetails.TotalDiscount.toFixed(2)}</p>
        </div>

        <div className="flex justify-end mt-4">
            <button onClick={onClose} className="px-4 py-2 text-sm text-white bg-blue-500 rounded sm:text-base">Close</button>
        </div>
    </div>
</div>

    );
};

export default ViewQuate;
