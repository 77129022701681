import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BaseUrl } from '../../auth/Url';

function Factory_Details() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const { data, id } = location.state || {};
  const [factoryDetails, setFactoryDetails] = useState(null);

  useEffect(() => {
    if (id) {
      fetchFactoryDetails(id);
    }
  }, [id]);

  const fetchFactoryDetails = async (factoryId) => {
    try {
      const response = await fetch(`${BaseUrl}/factory/${factoryId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch factory details');
      }

      const data = await response.json();
      setFactoryDetails(data.Factory);
    } catch (error) {
      console.error('Error fetching factory details:', error);
    }
  };

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }} className='pt-10'>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] pl-5 pr-5">
  <div className='justify-between md:flex'>
    <div className='p-5 bg-white rounded-lg w-full md:w-[620px]'>
      <p className='text-[16px] font-semibold'>Factory Information</p>
      {factoryDetails && (
        <div className="mt-5 space-y-2">
          <p><span className="inline-block w-40">Factory Name:</span> {factoryDetails.factoryName}</p>
          <p><span className="inline-block w-40">Owner Name:</span> {factoryDetails.contactPerson}</p>
          <p><span className="inline-block w-40">Contact No:</span> {factoryDetails.contactNo}</p>
          <p><span className="inline-block w-40">Email ID:</span> {factoryDetails.email}</p>
                <p><span className="inline-block w-40">Website (if any):</span> {factoryDetails.website}</p>
                  <p><span className="inline-block w-40">Password:</span> {factoryDetails.password}</p>
        </div>
      )}
      <p className='text-[16px] font-semibold mt-10'>Factory Address</p>
      {factoryDetails && (
        <div className="mt-5 space-y-2">
          <p><span className="inline-block w-40">Address:</span> {factoryDetails.factoryAddress}, {factoryDetails.city}, {factoryDetails.State}, {factoryDetails.postalCode}, {factoryDetails.country}</p>
        </div>
      )}
      <p className='text-[16px] font-semibold mt-10'>Business Information</p>
      {factoryDetails && (
        <div className="mt-5 space-y-2">
          <p><span className="inline-block w-40">GST Number:</span> {factoryDetails.GSTNumber}</p>
          <p><span className="inline-block w-40">FSSAI Number:</span> {factoryDetails.FASSAINumber}</p>
        </div>
      )}
    </div>
    <div className='p-5 bg-white rounded-lg w-full md:w-[444px] mt-5 md:mt-0 md:ml-5'>
      <div className='flex justify-between'>
        <p className='text-[18px] font-semibold'>Products</p>
        {/* <FaRegEdit className='w-5 h-5' /> */}
      </div>
      {factoryDetails && (
        <div className="mt-2 space-y-2">
          {factoryDetails.products.map((product, index) => (
            <p key={product._id}>
              <span className="inline-block">{index + 1}.</span> {product.brandName} - {product.category} - {product.subCategory}
            </p>
          ))}
          {/* <p className='text-blue-600'>see More</p> */}
        </div>
      )}
    </div>
  </div>
</div>

    </div>
  );
}

export default Factory_Details;
