import { useEffect, useState } from "react";
import { BaseUrl } from "../../auth/Url";
import Swal from 'sweetalert2'; 
function HomeBanner() {
  const [banners, setBanners] = useState([]);
  const [showModal, setShowModal] = useState(false); 
  const [newBannerText, setNewBannerText] = useState('');
  const [newBannerImage, setNewBannerImage] = useState(null);

  const fetchBanners = async () => {
    try {
      const response = await fetch(`${BaseUrl}/HomeBanner/GetBanner`);
      const data = await response.json();
      setBanners(data);
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const handleAddBanner = async () => {
    const formData = new FormData();
    formData.append('text', newBannerText);
    formData.append('image', newBannerImage);

    try {
      const response = await fetch(`${BaseUrl}/HomeBanner/add`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Banner added:', result);
        fetchBanners();
        setShowModal(false); 
        setNewBannerText('');
        setNewBannerImage(null);
      } else {
        console.error('Failed to add banner');
      }
    } catch (error) {
      console.error('Error adding banner:', error);
    }
  };

  // Handle deleting a banner
  const handleDeleteBanner = async (id) => {
    const confirmDelete = await Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this banner!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    });

    if (confirmDelete.isConfirmed) {
        try {
            const response = await fetch(`${BaseUrl}/HomeBanner/delete/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                Swal.fire(
                    'Deleted!',
                    'Your banner has been deleted.',
                    'success'
                );
                // Optionally, you can refresh banners after deletion
                fetchBanners();
            } else {
                Swal.fire(
                    'Error!',
                    'Failed to delete banner.',
                    'error'
                );
            }
        } catch (error) {
            console.error('Error deleting banner:', error);
            Swal.fire(
                'Error!',
                'An error occurred while deleting the banner.',
                'error'
            );
        }
    }
};

  return (
    <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh", padding: "20px" }}>
      <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%]">
        <div className='flex justify-between p-2'>
          <h1 className="mb-4 text-xl font-bold">Manage Home Banners</h1>
          {/* Disable "Add Banner" button if a banner already exists */}
          {banners.length === 0 && (
            <button className='p-2 text-white bg-orange-400 border rounded-lg' onClick={() => setShowModal(true)}>
              Add Banner
            </button>
          )}
        </div>

        <table className="min-w-full bg-white border">
          <thead className="font-normal text-white rounded-lg" style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
            <tr>
              <th className="px-4 py-2 border-b">Banner Image</th>
              <th className="px-4 py-2 border-b">Banner Text</th>
              <th className="px-4 py-2 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {banners.map((banner) => (
              <tr key={banner._id}>
                 <td className="flex justify-center px-4 py-2 border-b">
              {banner.image ? (
                <img 
                  src={banner.image} // Use the URL directly
                  alt="Banner" 
                  width="100" 
                />
              ) : 'No Image'}
            </td>
                <td className="px-4 py-2 text-center border-b">{banner.text}</td>
                <td className="px-4 py-2 text-center border-b">
                  <button
                    className="px-2 py-1 text-white bg-red-500"
                    onClick={() => handleDeleteBanner(banner._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="p-6 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Add New Banner</h2>
              <input
                type="text"
                placeholder="Enter banner text"
                value={newBannerText}
                onChange={(e) => setNewBannerText(e.target.value)}
                className="w-full p-2 mb-4 border"
              />
              <input
                type="file"
                onChange={(e) => setNewBannerImage(e.target.files[0])}
                className="w-full p-2 mb-4 border"
              />
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 mr-2 text-white bg-gray-500"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 text-white bg-blue-500"
                  onClick={handleAddBanner}
                >
                  Add Banner
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeBanner;
