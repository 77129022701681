import React, { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

const ProductSearchModal = ({ modalIsOpen, closeModal, products, onProductSelect,selectedProductIds,setSelectedProductIds }) => {
    const [searchTerm, setSearchTerm] = useState('');
    // const [selectedProductIds, setSelectedProductIds] = useState([]);
console.log(selectedProductIds,"selectedProductIds")
    // Filter products based on the search term
    const filteredProducts = products.filter(product =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Handle checkbox change
    const handleCheckboxChange = (product) => {
        if (selectedProductIds.includes(product._id)) {
            // Uncheck the product, remove it from selected list
            setSelectedProductIds(selectedProductIds.filter(id => id !== product._id));
            onProductSelect(product, false); // Remove product from selection
        } else {
            // Check the product, add it to selected list
            setSelectedProductIds([...selectedProductIds, product._id]);
            onProductSelect(product, true); // Add product to selection
        }
    };

    return (
        // <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${modalIsOpen ? '' : 'hidden'}`}>
        //     <div className="w-1/2 p-4 bg-white rounded-md h-[400px]">
        //         {/* Close button */}
        //         <div className="flex justify-end">
        //             <IoCloseOutline className="cursor-pointer w-7 h-7" onClick={closeModal} />
        //         </div>

        //         {/* Search input */}
        //         <div className="mb-4">
        //             <input
        //                 type="text"
        //                 placeholder="Search product"
        //                 value={searchTerm}
        //                 onChange={(e) => setSearchTerm(e.target.value)}
        //                 className="w-full p-2 mt-3 border border-gray-300 rounded-md"
        //             />
        //         </div>

        //         {/* Display search results */}
        //         <div className="h-[250px] overflow-y-auto">
        //             {filteredProducts.length > 0 ? (
        //                 <ul>
        //                     {filteredProducts.map((product) => {
        //                         const hasDiscount = product.discount && parseFloat(product.discount) > 0;
        //                         const discountedPrice = hasDiscount
        //                             ? product.price - (product.price * (parseFloat(product.discount) / 100))
        //                             : product.price;

        //                         return (
        //                             <li key={product._id} className="flex items-center justify-between p-5 py-2 border-b border-gray-200">
        //                                 <img src={product.selectedImages[0]} alt="Product" className="w-[65px] h-[65px] mr-2 rounded-lg" />
        //                                 <span>{product.productName} ({product.productCode})</span>
        //                                 <span>{product.category}</span>
        //                                 <span>
        //                                     {hasDiscount ? (
        //                                         <>
        //                                             <del className="mr-2 text-gray-500">₹{product.price}</del>
        //                                             <span className="text-green-500">₹{discountedPrice.toFixed(2)}</span>
        //                                         </>
        //                                     ) : (
        //                                         <span>₹{product.price}</span>
        //                                     )}
        //                                 </span>

        //                                 <input
        //                                     type="checkbox"
        //                                     checked={selectedProductIds.includes(product._id)} // Check if the product ID is in the selected list
        //                                     onChange={() => handleCheckboxChange(product)}
        //                                 />
        //                             </li>
        //                         );
        //                     })}
        //                 </ul>
        //             ) : (
        //                 <p>No products found.</p>
        //             )}
        //         </div>
        //     </div>
        // </div>
        <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${modalIsOpen ? '' : 'hidden'}`}>
    <div className="w-full max-w-[95%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] p-4 bg-white rounded-md h-auto max-h-[90vh] overflow-y-auto">
        {/* Close button */}
        <div className="flex justify-end">
            <IoCloseOutline className="cursor-pointer w-7 h-7" onClick={closeModal} />
        </div>

        {/* Search input */}
        <div className="mb-4">
            <input
                type="text"
                placeholder="Search product"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 mt-3 border border-gray-300 rounded-md"
            />
        </div>

        {/* Display search results */}
        <div className="h-[250px] overflow-y-auto">
            {filteredProducts.length > 0 ? (
                <ul>
                    {filteredProducts.map((product) => {
                        const hasDiscount = product.discount && parseFloat(product.discount) > 0;
                        const discountedPrice = hasDiscount
                            ? product.price - (product.price * (parseFloat(product.discount) / 100))
                            : product.price;

                        return (
                            <li key={product._id} className="flex items-center justify-between p-5 py-2 border-b border-gray-200">
                                <img src={product.selectedImages[0]} alt="Product" className="w-[50px] h-[50px] sm:w-[65px] sm:h-[65px] mr-2 rounded-lg" />
                                <span className="text-sm sm:text-base">{product.productName} ({product.productCode})</span>
                                <span className="hidden text-sm md:block">{product.category}</span>
                                <span>
                                    {hasDiscount ? (
                                        <>
                                            <del className="mr-2 text-sm text-gray-500">₹{product.price}</del>
                                            <span className="text-sm text-green-500 sm:text-base">₹{discountedPrice.toFixed(2)}</span>
                                        </>
                                    ) : (
                                        <span className="text-sm sm:text-base">₹{product.price}</span>
                                    )}
                                </span>
                                <input
                                    type="checkbox"
                                    checked={selectedProductIds.includes(product._id)} // Check if the product ID is in the selected list
                                    onChange={() => handleCheckboxChange(product)}
                                    className="ml-2"
                                />
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p>No products found.</p>
            )}
        </div>
    </div>
</div>

    );
};

export default ProductSearchModal;
