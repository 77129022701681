
// import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { BaseUrl } from '../../auth/Url';

// function AddCategoryProduct() {
//   const location = useLocation();
//   const token = localStorage.getItem("token");
// //   const { data, id } = location.state || {};
//     const [factoryDetails, setFactoryDetails] = useState(null);
//       const [isModalOpen, setIsModalOpen] = useState(false);

//   const openModal = () => setIsModalOpen(true);
//   const closeModal = () => setIsModalOpen(false);
//  const personalInfo = JSON.parse(localStorage.getItem('personalInfo')); // Parse the JSON string to an object
//     const id = personalInfo?.factoryId;
//   useEffect(() => {
//     if (id) {
//       fetchFactoryDetails(id);
//     }
//   }, [id]);

//   const fetchFactoryDetails = async (factoryId) => {
//     try {
//       const response = await fetch(`${BaseUrl}/factory/${factoryId}`, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         }
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch factory details');
//       }

//       const data = await response.json();
//       setFactoryDetails(data.Factory);
//     } catch (error) {
//       console.error('Error fetching factory details:', error);
//     }
//   };

//   return (
//     <div style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }} className='pt-10'>
//       <div className="md:ml-[20%] xl:w-[80%] lg:w-[60%] lg:ml-[19%] pl-5 pr-5">
//   <div className='justify-between md:flex'>
//     <div className='p-5 bg-white rounded-lg w-full md:w-[620px]'>
//       <p className='text-[16px] font-semibold'>Factory Information</p>
//       {factoryDetails && (
//         <div className="mt-5 space-y-2">
//           <p><span className="inline-block w-40">Factory Name:</span> {factoryDetails.factoryName}</p>
//           <p><span className="inline-block w-40">Owner Name:</span> {factoryDetails.contactPerson}</p>
//           <p><span className="inline-block w-40">Contact No:</span> {factoryDetails.contactNo}</p>
//           <p><span className="inline-block w-40">Email ID:</span> {factoryDetails.email}</p>
//                 <p><span className="inline-block w-40">Website (if any):</span> {factoryDetails.website}</p>
//                   <p><span className="inline-block w-40">Password:</span> {factoryDetails.password}</p>
//         </div>
//       )}
//       <p className='text-[16px] font-semibold mt-10'>Factory Address</p>
//       {factoryDetails && (
//         <div className="mt-5 space-y-2">
//           <p><span className="inline-block w-40">Address:</span> {factoryDetails.factoryAddress}, {factoryDetails.city}, {factoryDetails.State}, {factoryDetails.postalCode}, {factoryDetails.country}</p>
//         </div>
//       )}
//       <p className='text-[16px] font-semibold mt-10'>Business Information</p>
//       {factoryDetails && (
//         <div className="mt-5 space-y-2">
//           <p><span className="inline-block w-40">GST Number:</span> {factoryDetails.GSTNumber}</p>
//           <p><span className="inline-block w-40">FSSAI Number:</span> {factoryDetails.FASSAINumber}</p>
//         </div>
//       )}
//     </div>
//     <div className='p-5 bg-white rounded-lg w-full md:w-[444px] mt-5 md:mt-0 md:ml-5'>
//       <div className='flex justify-between'>
//         <p className='text-[18px] font-semibold'>Products</p>
//         {/* <FaRegEdit className='w-5 h-5' /> */}
//       </div>
//      {factoryDetails && (
//   <div className="mt-2 space-y-2">
//     {factoryDetails.products && factoryDetails.products.length > 0 ? (
//       factoryDetails.products.map((product, index) => (
//         <p key={product._id}>
//           <span className="inline-block">{index + 1}.</span> {product.brandName} - {product.category} - {product.subCategory}
//         </p>
//       ))
//     ) : (
//       <button
//         onClick={() => {/* Add your logic for adding a category */}}
//         className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
//       >
//         Add Category of Product
//       </button>
//     )}
//   </div>
// )}

//     </div>
//   </div>
// </div>

//     </div>
//   );
// }

// export default AddCategoryProduct;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { BaseUrl } from "../../auth/Url";
function AddCategoryProduct() {
  const navigate = useNavigate();
  const location = useLocation();
   const [factory, setfactory] = useState([]);
 
 const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
     
      const factoryId = personalInfo?.factoryId;
  const [brandName, setBrandName] = useState("");
  const [errorMessage, setErrorMessage] = useState('Field is Required');
  const [error, setError] = useState(false);
   const [EmailValidation, setEmailValidation] = useState("");
  const [numberValidation, setnumberValidation] = useState("");
  const [GstValidation, setGstValidation] = useState("");
   const [FASSAIValidation, setFASSAIValidation] = useState("");
   const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categories, setCategories] = useState([]);
   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [EmailRegister, setEmailRegister] = useState('');
  console.log(EmailRegister, "EmailRegister")
   const [passwordValidation, setpasswordValidation] = useState("");
  // const [category, setCategory] = useState("");
  const Action ='Edit'
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
   const generateFactoryId = () => {
    return `F${Math.floor(1000 + Math.random() * 9000)}`; // Example: Generates IDs like F1001, F2345, etc.
  };
  useEffect(() => {
    const fetchFactoryDetails = async () => {
    try {
      const response = await fetch(`${BaseUrl}/factory/${factoryId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch factory details');
      }

      const data = await response.json();
      setfactory(data.Factory);
    } catch (error) {
      console.error('Error fetching factory details:', error);
    }
    };
    fetchFactoryDetails();
  },[factoryId])
 
  const [factoryData, setFactoryData] = useState({
  factoryId:generateFactoryId(),
    factoryName: "",
    contactPerson: "",
    contactNo:"",
    email: "",
    website: "",
    factoryAddress: "",
    city: "",
    postalCode: "",
    country: "",
    State: "",
   FASSAINumber: "",
    GSTNumber: "",
    password: "",
    CommissionDoneAmount:"",
      products: [],
  role:"Factory"
  });
  console.log(factoryData,"factoryData")
  useEffect(() => {
  if (Action === "Edit" && factory) {
    // Set the factory data from location.state when editing
    setFactoryData({
      factoryId: factory.factoryId || generateFactoryId(),
      factoryName: factory.factoryName || "",
      contactPerson: factory.contactPerson || "",
      contactNo: factory.contactNo || "",
      email: factory.email || "",
      website: factory.website || "",
      factoryAddress: factory.factoryAddress || "",
      city: factory.city || "",
      postalCode: factory.postalCode || "",
      country: factory.country || "",
      State: factory.State || "",
      FASSAINumber: factory.FASSAINumber || "",
      GSTNumber: factory.GSTNumber || "",
      password: factory.password || "",
      products: factory.products || [],
      role: factory.role || "Factory"
    });
  }
}, [Action, factory]);
 useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetCommission-slab`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
          setCategories(result.CategoryList);
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [token]);
  const processedData = categories.reduce((acc, curr) => {
    if (!acc[curr.category]) {
      acc[curr.category] = {
        category: curr.category,
        subCategories: []
      };
    }
    acc[curr.category].subCategories.push(curr.subcategory);
    return acc;
  }, {});
  const categoryData = Object.values(processedData);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setSubCategory(''); // Reset subcategory when category changes
  };
  const handleInputChange = (e) => {
  
  const { name, value } = e.target;
    if (name === 'contactNo') {
  setnumberValidation('')
    }
     if (name === 'email') {
  setEmailValidation('')
    }
      if (name === 'GSTNumber') {
  setGstValidation('')
    }
     if (name === 'FASSAINumber') {
  setFASSAIValidation('')
}
  // Validate and clean the input value if it's for 'contactNo'
  const cleanedValue = name === 'contactNo' 
    ? value.replace(/[^0-9]/g, '') // Remove non-numeric characters
    : value;

  setFactoryData({
    ...factoryData,
    [name]: cleanedValue,
  });
};

 const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

const handleAdd = () => {
  if (brandName && category && subCategory) {
    const isProductExist = factoryData.products.some(
      (product) =>
        product.brandName === brandName &&
        product.category === category &&
        product.subCategory === subCategory
    );

    if (!isProductExist) {
      setFactoryData({
        ...factoryData,
        products: [...factoryData.products, { brandName, category, subCategory }],
      });

      setData([...data, { brandName, category, subCategory }]);

      setBrandName("");
      setCategory("");
      setSubCategory("");
    } else {
      alert("Product with the same Brand Name, Category, and Subcategory already exists.");
    }
  }
};

  // const handleCategoryChange = (e) => {
  //   setCategory(e.target.value);
  //   setSubCategory(""); 
  // };
  const handleSave = async () => {
  
     if (factoryData.factoryName === "" || factoryData.contactPerson === "" || factoryData.contactNo === ""  || factoryData.email === "" ||factoryData.factoryAddress === "" || factoryData.city === "" || factoryData.State === "" || factoryData.postalCode === "" || factoryData.country === "" || factoryData.GSTNumber === "" || factoryData.FASSAINumber === "" || factoryData.password === "") {
    setError(true);
    return;
    }
     if (factoryData.contactNo.length !== 10) {
       // Optionally set an error state or message
       setError(true);
       setnumberValidation("Contact number must be exactly 10 digits.")
   
    return; // Exit if validation fails
  }
     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(factoryData.email)) {
      debugger
      // alert('Please enter a valid email address.');
      setError(true);
      setEmailValidation("Please enter a valid email address.")
        return;
    }
    if (factoryData.GSTNumber.length !== 15) {
       // Optionally set an error state or message
       setError(true);
       setGstValidation("GST Number must be exactly 15 digits.")
   
    return; // Exit if validation fails
    }
    if (factoryData.FASSAINumber.length !== 14) {
       // Optionally set an error state or message
       setError(true);
       setFASSAIValidation("FSSAI Number must be exactly 14 digits.")
   
    return; // Exit if validation fails
  }
     setLoading(true);
    if (Action === "Edit") {
       
    try {
    const response = await axios.put(`${BaseUrl}/update-factory`, factoryData);
    if (response.data.result) {
      // Show success alert with SweetAlert
      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Factory updated successfully.',
      });
      navigate('/factory'); // Navigate to the /factory route
    } else {
      // Show error alert with SweetAlert
      await Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to update factory.',
      });
    }
  } catch (error) {
    console.error('Error updating factory:', error);
    // Show error alert with SweetAlert
    await Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Error updating factory.',
    });
  }

    } else {
       try {
  const response = await fetch(`${BaseUrl}/Register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Pass token in the Authorization header
    },
    body: JSON.stringify(factoryData),
  });

  if (response.status === 200) {
    // Show success alert with SweetAlert
    await Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: 'Data saved successfully!',
    });

    // Reset factoryData after successful registration
    setFactoryData({
      productName: '',
      productDescription: '',
      category: '',
      subCategory: '',
      unit: '',
      price: '',
      discount: '',
      effectiveDate: '',
      expiryDate: '',
      qualityVariety: '',
      supplierName: '',
      supplierCity: "",
      contactNumber: "",
      Brand_Name: "",
      selectedImages: [],
    });

    // Navigate to the /factory route
    navigate("/factory");
    setLoading(false);
  } else {
    console.error("Failed to save data:", response.statusText);
    const data = await response.json(); // Parse the JSON response
    if (response.status === 400 || data.message === 'User already exists') {
      setEmailRegister('Email ID already exists. Use another email.');
    } else {
      setEmailRegister('');
    }
    setLoading(false);
  }
} catch (error) {
  console.error("Error saving data:", error);
  await Swal.fire({
    icon: 'error',
    title: 'Error!',
    text: 'Error saving data. Please try again later.',
  });
  setLoading(false);
}

     }
   
  };
   

  
  return (
    <div
      style={{ backgroundColor: "#F6F6F6", minHeight: "100vh" }}
      className="pt-10"
    >
      <div className="md:ml-[20%] xl:w-[80%]  lg:w-[60%] lg:ml-[19%] md:pl-10 md:pr-10 p-5 md:p-0">
        <div className="p-5 pt-5 bg-white rounded-lg">
          {/* <p className="text-[28px] font-semibold">Factory ID #F001</p> */}

          <div className="grid gap-20 md:flex">
            <div>
              <div>
                <p className="text-[16px] font-semibold mt-2">
                  Factory Information
                </p>

                <div className="mt-2">
                  <p>Factory Name<span className="text-red-500">*</span> </p>
                  <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                  placeholder="Name"
                  name="factoryName"
                  value={factoryData.factoryName}
                  onChange={handleInputChange}
                />
                </div>
                {factoryData.factoryName ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                <div className="mt-2">
                  <p>Contact Person <span className="text-red-500">*</span></p>
                   <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                  placeholder="contact Person"
                  name="contactPerson"
                  value={factoryData.contactPerson}
                  onChange={handleInputChange}
                />
                </div>
                 {factoryData.contactPerson ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                <div className="mt-2">
                  <p>Contact No <span className="text-red-500">*</span></p>
                 <input
   type="text" // Change to "text" to allow custom input handling
  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
  placeholder="contact No"
  name="contactNo"
  value={factoryData.contactNo}
  onChange={handleInputChange}
  inputMode="numeric" // For numeric keyboard on mobile devices
  pattern="\d{10}" // Regex pattern for validation
  maxLength="10" // Limits the input length to 10 characters
/>

                </div>
                {numberValidation !=="" && error && (
                <p className='mt-2 text-red-500'>{numberValidation}</p>
              )}
                 {factoryData.contactNo ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                <div className="mt-2">
                  <p>Email ID <span className="text-red-500">*</span></p>
                   <input
                  type="email"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                  placeholder="email"
                  name="email"
                  value={factoryData.email}
                  onChange={handleInputChange}
                />
                </div>
                {factoryData.email ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
                )}
                {EmailValidation !=="" && error && (
                <p className='mt-2 text-red-500'>{EmailValidation}</p>
                )}
                {
                  EmailRegister !== "" && (
                      <p className='mt-2 text-red-500'>{EmailRegister}</p>
                  )
                }
                <div className="mt-2">
                  <p>Website (If any) </p>
                  <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                  placeholder="website"
                  name="website"
                  value={factoryData.website}
                  onChange={handleInputChange}
                />
                </div>
                 <div className="mt-2">
      <p>Create Password <span className="text-red-500">*</span></p>
      <div className="relative">
        <input
          type={isPasswordVisible ? 'text' : 'password'}
          className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
          placeholder="Password"
          name="password"
          value={factoryData.password}
          onChange={handleInputChange}
        />
        {/* <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute text-gray-600 transform -translate-y-1/2 right-2 top-1/2"
        >
        {isPasswordVisible ? <FaRegEye /> :  <FaRegEyeSlash />}
        </button> */}
                  </div>
                  {passwordValidation !== '' && (
                    <p className="text-red-600">{passwordValidation}</p>
                  )}
    </div>
                   {factoryData.password ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
              </div>
            </div>
            <div className="text-start">
              <div>
                <div>
                  <p className="text-[16px] font-semibold mt-2">
                   Location Information
                  </p>
                  <div className="mt-2">
                    <p>Factory Address<span className="text-red-500">*</span> </p>
                     <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[400px] mt-2"
                  placeholder="factory Address"
                  name="factoryAddress"
                  value={factoryData.factoryAddress}
                  onChange={handleInputChange}
                />
                  </div>
                  {factoryData.factoryAddress ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                  <div className="grid justify-between gap-3 mt-5 md:flex">
                     <div>
            <label className="block">
              {" "}
              City<span className="text-red-500">*</span>
            </label>
            <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                  placeholder="city"
                  name="city"
                  value={factoryData.city}
                  onChange={handleInputChange}
                      />
                       {factoryData.city ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
          </div>

                    <div>
                      <p>
                       State<span className="text-red-500 ">*</span>
                      </p>

                       <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                  placeholder="State"
                  name="State"
                  value={factoryData.State}
                  onChange={handleInputChange}
                      />
                       {factoryData.State ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                    </div>
                    
                  </div>

                  <div className="justify-between mt-5 md:flex">
                    <div>
                      <label className="block">
                        {" "}
                        Postal Code<span className="text-red-500 ">*</span>
                      </label>
                      <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                  placeholder="postal Code"
                  name="postalCode"
                  value={factoryData.postalCode}
                        onChange={handleInputChange}
                        maxLength='6'
                      />
                       {factoryData.postalCode ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                    </div>
                    

                    <div>
                      <p>
                        Country<span className="text-red-500 ">*</span>
                      </p>

                      <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                  placeholder="country"
                  name="country"
                  value={factoryData.country}
                  onChange={handleInputChange}
                      />
                        {factoryData.country ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <p className="text-[16px] font-semibold mt-2">
                  Business Information
                </p>
                <div className="justify-between gap-5 md:flex">
                  <div className="mt-2">
                    <p>GST Number<span className="text-red-500">*</span> </p>
                      <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
                  placeholder="GSTNumber"
                  name="GSTNumber"
                  value={factoryData.GSTNumber}
                      onChange={handleInputChange}
                      maxLength='15'
                    />
                     {GstValidation !=="" && error && (
                <p className='mt-2 text-center text-red-500'>{GstValidation}</p>
              )}
                     {factoryData.GSTNumber ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                  </div>
                    
                  <div className="mt-2">
                    <p>FSSAI Number<span className="text-red-500">*</span></p>
                  <input
  type="text"
  className="p-2 bg-gray-100 border rounded-xl md:w-[234px] mt-2"
  placeholder="FASSAINumber"
  name="FASSAINumber"
  maxLength='14'
  value={factoryData.FASSAINumber}
  onChange={handleInputChange}
  pattern="\d{14}" // Regex pattern to allow exactly 14 digits
  title="Please enter a 14-digit number"
  inputMode="numeric" // Show numeric keyboard on mobile devices
/>
{factoryData.FASSAINumber ==="" && error && (
                <p className='mt-2 text-red-500'>{errorMessage}</p>
              )}
                     {FASSAIValidation !=="" && error && (
                <p className='mt-2 text-center text-red-500 w-[200px]'>{FASSAIValidation}</p>
              )}
                    
                  </div>
                    
                </div>
              </div>
            </div>
          </div>

        {/* <div className="mt-10">
      <p className="text-[16px] font-semibold mt-2">Products Information</p>
      <div className="flex gap-10">
        <div className="mt-2">
          <p>Add Category</p>
          <select
            className="p-2 bg-gray-100 border rounded-xl w-[200px] mt-2"
            value={category}
            onChange={handleCategoryChange}
          >
            <option value="" disabled>Select Category</option>
            {categoryData.map((cat) => (
              <option key={cat.category} value={cat.category}>
                {cat.category}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <p>Add Subcategory</p>
          <select
            className="p-2 bg-gray-100 border rounded-xl w-[200px] mt-2"
            value={subCategory}
            onChange={(e) => setSubCategory(e.target.value)}
            disabled={!category}
          >
            <option value="" disabled>Select Subcategory</option>
            {category && categoryData.find((cat) => cat.category === category)?.subCategories.map((sub) => (
              <option key={sub} value={sub}>
                {sub}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2">
          <p>Add Brand Name</p>
          <input
            type="text"
            className="p-2 bg-gray-100 border rounded-xl w-[200px] mt-2"
            placeholder="Brand Name"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
          />
        </div>
        <div className="mt-10">
          <button className="rounded-lg bg-[#0EA652] text-white w-[110px] h-[40px]" onClick={handleAdd}>
            ADD
          </button>
        </div>
      </div>
    </div> */}
          <div className="mt-10">
  <p className="text-[16px] font-semibold mt-2">Products Information</p>
  <div className="gap-10 md:flex">
    <div className="mt-2">
      <p>Add Category</p>
      <select
        className="p-2 bg-gray-100 border rounded-xl md:w-[200px] mt-2"
        value={category}
        onChange={handleCategoryChange}
      >
        <option value="" disabled>Select Category</option>
        {categoryData.map((cat) => (
          <option key={cat.category} value={cat.category}>
            {cat.category}
          </option>
        ))}
      </select>
    </div>
    <div className="mt-2">
      <p>Add Subcategory</p>
      <select
        className="p-2 bg-gray-100 border rounded-xl md:w-[200px] mt-2"
        value={subCategory}
        onChange={(e) => setSubCategory(e.target.value)}
        disabled={!category}
      >
        <option value="" disabled>Select Subcategory</option>
        {category && categoryData.find((cat) => cat.category === category)?.subCategories.map((sub) => (
          <option key={sub} value={sub}>
            {sub}
          </option>
        ))}
      </select>
    </div>
    <div className="mt-2">
      <p>Add Brand Name</p>
      <input
        type="text"
        className="p-2 bg-gray-100 border rounded-xl md:w-[200px] mt-2"
        placeholder="Brand Name"
        value={brandName}
        onChange={(e) => setBrandName(e.target.value)}
      />
    </div>
    <div className="mt-10">
      <button
        className={`rounded-lg w-[110px] h-[40px] ${
          brandName && category && subCategory ? 'bg-[#0EA652] text-white' : 'bg-gray-400 text-gray-600 cursor-not-allowed'
        }`}
        onClick={handleAdd}
        disabled={!brandName || !category || !subCategory}
      >
        ADD
      </button>
    </div>
  </div>
</div>
                  {factoryData.products.length > 0 && (
          //             <div className=" w-[720px] h-[242px] mt-5 border-gray-200 border-2 overflow-x-auto">
          //   <div className="mt-5">
          //      <table className="w-full bg-white rounded-lg">
          //       <thead className="">
          //         <tr>
          //           <th className="p-2">Sr. No.</th>
          //             <th className="p-2">Category</th>
          //              <th className="p-2">Sub Category</th>
          //           <th className="p-2">Brand Name</th>
          //         </tr>
          //       </thead>
          //       <tbody>
          //         {factoryData.products.map((item, index) => (
          //           <tr
          //             key={index}
          //             className={`border-t ${
          //               index % 2 === 0 ? "bg-white" : "bg-gray-100"
          //             }`}
          //           >
          //             <td className="p-2 text-center">{index + 1}</td>
          //             <td className="p-2 text-center">{item.category}</td>
          //             <td className="p-2 text-center">{item.subCategory}</td>
          //             <td className="p-2 text-center">{item.brandName}</td>
          //           </tr>
          //         ))}
          //       </tbody>
          //     </table>
          //   </div>
          // </div>
          <div className="w-full max-w-[720px] h-auto mt-5 border-gray-200 border-2 overflow-x-auto">
  <div className="mt-5">
    <table className="min-w-full bg-white rounded-lg">
      <thead>
        <tr>
          <th className="p-2 text-left">Sr. No.</th>
          <th className="p-2 text-left">Category</th>
          <th className="p-2 text-left">Sub Category</th>
          <th className="p-2 text-left">Brand Name</th>
        </tr>
      </thead>
      <tbody>
        {factoryData.products.map((item, index) => (
          <tr
            key={index}
            className={`border-t ${
              index % 2 === 0 ? "bg-white" : "bg-gray-100"
            }`}
          >
            <td className="p-2 text-center">{index + 1}</td>
            <td className="p-2 text-center">{item.category}</td>
            <td className="p-2 text-center">{item.subCategory}</td>
            <td className="p-2 text-center">{item.brandName}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

                  )}
          

          <div className="flex justify-end gap-5 mt-2">
             <Link className="px-4 py-2 mt-4 text-black transition bg-gray-300 rounded-lg" to={"/factory"}>Back</Link>
            <button
                onClick={handleSave}
              className="px-4 py-2 mt-4 text-white transition rounded-lg"
              style={{ backgroundColor: "#4C3F35" }}
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCategoryProduct;
