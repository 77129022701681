import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { BaseUrl } from '../../auth/Url';
import { IoMdClose } from "react-icons/io";
const NotificationBell = ({
  isModalOpen,
  notifications,
  visibleNotifications,
  setIsModalOpen,
  setNotifications,
  setVisibleNotifications,
  notificationsCount,
  fetchContactDetails
}) => {
  const [showAll, setShowAll] = useState(false);
const modalRef = useRef(null);

  // Function to handle clicks outside the modal
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };
  const HandleCloseModel = () => {
   setIsModalOpen(false);
}
 
  // Toggle modal visibility when bell icon is clicked
  const handleBellClick = () => {
    setIsModalOpen(!isModalOpen);

    // If opening the modal, make the PUT request to reset the count
    if (!isModalOpen) {
      axios.put(`${BaseUrl}/ViewRecords/reset-count`)
        .then((response) => {
          console.log('Count reset successfully:', response.data);
          fetchContactDetails(); // Refresh the contact details after reset
        })
        .catch((error) => {
          console.error('Error resetting count:', error);
        });
    }
  };

  // Handle the "See more" button click
  const handleSeeMore = () => {
    setShowAll(!showAll);
    if (!showAll) {
      setVisibleNotifications(notifications); // Show all notifications
    } else {
      setVisibleNotifications(notifications.slice(0, 2)); // Show only the first 2
    }
  };

  // Handle deleting a notification
  const handleDeleteNotification = (contactId) => {
    axios.delete(`${BaseUrl}/DeleteRecords/${contactId}`)
      .then((response) => {
        console.log('Notification deleted successfully:', response.data);
        fetchContactDetails(); // Refresh contact details after deleting
      })
      .catch((error) => {
        console.error('Error deleting notification:', error);
      });
  };

  return (
    <div className="relative ">
      {/* Bell Icon */}
      <div className="p-2 mr-4 rounded-lg cursor-pointer" style={{ backgroundColor: '#F6F3EE' }} onClick={handleBellClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-bell"
          viewBox="0 0 16 16"
        >
          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
        </svg>
      </div>

      {/* Notification Count */}{
        notificationsCount > 0 && (
           <div className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 mr-1 -mt-1 text-xs text-white bg-red-600 rounded-full">
        {notificationsCount} {/* Dynamic notification count */}
      </div>
        )
      }
     

      
      {
        isModalOpen && (
      <div
        // ref={modalRef}
        className="absolute right-0 z-10 hidden p-4 mt-2 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg w-80 max-h-96 md:block"
      >
        <div className="flex justify-between">
          <h3 className="mb-2 text-lg font-semibold">Notifications</h3>
              <button
                 onClick={HandleCloseModel}
              >
            {/* Close icon here, e.g., an IoMdClose component */}
            Close
          </button>
        </div>
        <ul>
          {visibleNotifications.length > 0 ? (
            visibleNotifications.map((notification) => (
              <li key={notification._id} className="pb-2 mb-4 text-sm border-b">
                <p><strong>Name:</strong> {notification.name}</p>
                <p><strong>Email:</strong> {notification.email}</p>
                <p><strong>Phone:</strong> {notification.phone}</p>
                <p><strong>Subject:</strong> {notification.subject}</p>
                <p><strong>Message:</strong> {notification.message}</p>
                <button
                  className="mt-2 text-red-500 hover:underline"
                  onClick={() => handleDeleteNotification(notification._id)}
                >
                  Delete
                </button>
              </li>
            ))
          ) : (
            <li>No notifications</li>
          )}
        </ul>
        {notifications.length > 2 && (
          <button
            onClick={handleSeeMore}
            className="mt-2 text-sm text-blue-500 hover:underline"
          >
            {showAll ? 'See less' : 'See more'}
          </button>
        )}
      </div>
    )
      }
       {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center p-10 bg-black bg-opacity-50 md:hidden"
            ref={modalRef}
          // onClick={handleClickOutside}
        >
          <div className="p-4 bg-white rounded shadow-lg h-[400px] overflow-y-auto w-full">
            <div className='flex justify-between'>
 <h3 className="mb-2 text-lg font-semibold">Notifications</h3>
              <IoMdClose onClick={HandleCloseModel}/>
            </div>
 
  <ul>
    {visibleNotifications.length > 0 ? (
      visibleNotifications.map((notification) => (
        <li key={notification._id} className="pb-2 mb-4 text-sm border-b">
          <p><strong>Name:</strong> {notification.name}</p>
          <p><strong>Email:</strong> {notification.email}</p>
          <p><strong>Phone:</strong> {notification.phone}</p>
          <p><strong>Subject:</strong> {notification.subject}</p>
          <p><strong>Message:</strong> {notification.message}</p>
          <button
            className="mt-2 text-red-500 hover:underline"
            onClick={() => handleDeleteNotification(notification._id)}
          >
            Delete
          </button>
        </li>
      ))
    ) : (
      <li>No notifications</li>
    )}
  </ul>

  {notifications.length > 2 && (
    <button
      onClick={handleSeeMore}
      className="mt-2 text-sm text-blue-500 hover:underline"
    >
      {showAll ? 'See less' : 'See more'}
    </button>
  )}
</div>

        </div>
      )}
    </div>
  );
};

export default NotificationBell;
