import React, { useState, useEffect } from 'react';
import { FaSortAmountDown } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import { BaseUrl } from '../../auth/Url';
import { useNavigate } from 'react-router-dom';
import AddInstallMent from '../Component/AddInstallMent';
function PurchaseRecordsPage() {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track the expanded row
 const navigate = useNavigate();

  const handleEditClick = (data) => {
    navigate('/detailsPurchaseReport', { state: { itemData: data } });
  };
  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const token = localStorage.getItem('token'); 
        const response = await fetch(`${BaseUrl}/transactions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setRecords(data.data.reverse()); 
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <div className="py-4 text-lg text-gray-500">
          No records found!
        </div>;

  return (
//     <div>
//       <div className='flex justify-end'>
//         {/* <button className='flex gap-1 p-2 text-black bg-white'>
//           <FaSortAmountDown className='w-4 h-4 mt-1' /> Sort by Date <IoIosArrowDown className='w-4 h-4 mt-1' />
//         </button> */}
//       </div>
//       <div className='mt-5'>
//         <table className='w-full rounded-lg'>
//           <thead className='font-normal text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
//             <tr>
//               <th className='p-2 font-normal rounded-tl-lg'>Order Id</th>
//               <th className='p-2 font-normal'>Date</th>
//               <th className='p-2 font-normal'>Purchase From</th>
//               <th className='p-2 font-normal'>Product</th>
//               <th className='p-2 font-normal'>Sale to</th>
//               <th className='p-2 font-normal'>Total Amt</th>
//                <th className='p-2 font-normal'>Payment Done Amt</th>
//               <th className='p-2 font-normal'>Payment Status</th>
//               <th className='p-2 font-normal rounded-tr-lg'>Action</th>
//             </tr>
//           </thead>
//           <tbody className='bg-white'>
//             {records.map((record, index) => (
//               <React.Fragment key={index}>
//                 <tr className='border-b-2 cursor-pointer' onClick={() => toggleExpand(index)}>
//                   {/* <td className='p-2 text-center'>
//                     <span className='text-blue-600 underline'>{record.orderId}</span>
//                   </td> */}
//                   <td className="px-4 py-2">
//   {record.orderId.length > 10 ? `${record.orderId.slice(0, 10)}...` : record.orderId}
// </td>
//                   <td className='p-2 text-center'>{record.ProductDetails[0]?.OrderDate}</td>
//                   <td className='p-2 text-center'>{record.ProductDetails[0]?.SupplierInfo?.FactoryName}</td>
//                   <td className='p-2 text-center'>{record.ProductDetails[0]?.ProductName}</td>
//                   <td className='p-2 text-center'>{record.customerInfo?.ShopName}</td>
//                   <td className='p-2 text-center'>{record.Total}</td>
//                   {/* <td className='p-2 text-center'>{record?.TransactionData[0]?.PaymentDoneAmount}</td> */}
//              <td className='p-2 text-center'>
//   ₹ {record.TransactionData?.reduce((total, transaction) => total + parseFloat(transaction.PaymentDoneAmount || 0), 0).toFixed(2)}
// </td>




//                   <td className='p-2 text-center'>
//                     <div className="flex items-center justify-center">
//                       {
//                         record.TransactionData[record.TransactionData.length - 1]?.Total ==='0.00'||  record.TransactionData[record.TransactionData.length - 1]?.Total ==='0'? <div className='p-1 text-center text-yellow-500 border border-yellow-500 rounded-lg cursor-pointer hover:bg-yellow-600 hover:text-white' style={{ width: '80px', height: '30px', opacity: '1' }}>Return</div>:<div
//   className={`p-1 text-center border rounded-lg cursor-pointer  ${
//     parseFloat(record.TransactionData[record.TransactionData.length - 1]?.Duepayment).toFixed(2) === "0.00"
//       ? 'text-green-500 hover:bg-green-600 hover:text-white border-green-500' // Paid in green
//       : 'text-orange-500 hover:bg-orange-600 hover:text-white border-orange-500' // Pending in orange
//   }`}
//   style={{ width: '80px', height: '30px', opacity: '1' }}
// >
//   {parseFloat(record.TransactionData[record.TransactionData.length - 1]?.Duepayment).toFixed(2) === "0.00"
//     ? 'Paid'
//     : 'Pending'}
// </div>

//                       }
                    



//                     </div>
//                   </td>
//                   <td className='relative p-2'>
//                     <HiDotsVertical className='cursor-pointer' onClick={() => toggleDropdown(index)} />
//                     {dropdownOpen === index && (
//         <div className="absolute right-0 z-10 w-32 p-2 bg-white border rounded shadow-lg">
//           <div
//             className="p-2 cursor-pointer hover:bg-gray-200"
//             onClick={() => handleEditClick(record)}
//           >
//             Edit
//           </div>
//           <div className="p-2 cursor-pointer hover:bg-gray-200">Delete</div>
//         </div>
//       )}
//                   </td>
//                 </tr>
               
//               </React.Fragment>
//             ))}
//           </tbody>
//         </table>
//       </div>
     
    //     </div>
    
    <div>
  <div className='flex justify-end'>
    {/* Uncomment this button if needed for sorting */}
    {/* <button className='flex gap-1 p-2 text-black bg-white'>
      <FaSortAmountDown className='w-4 h-4 mt-1' /> Sort by Date <IoIosArrowDown className='w-4 h-4 mt-1' />
    </button> */}
  </div>
  <div className='mt-5 overflow-x-auto'>
    <table className='min-w-full rounded-lg'>
      <thead className='font-normal text-white rounded-lg' style={{ backgroundColor: 'rgba(76, 63, 53, 1)' }}>
        <tr>
          <th className='p-2 font-normal rounded-tl-lg'>Order Id</th>
          <th className='p-2 font-normal'>Date</th>
          <th className='p-2 font-normal'>Purchase From</th>
          <th className='p-2 font-normal'>Product</th>
          <th className='p-2 font-normal'>Sale to</th>
          <th className='p-2 font-normal'>Total Amt</th>
          <th className='p-2 font-normal'>Payment Done Amt</th>
          <th className='p-2 font-normal'>Payment Status</th>
          {/* <th className='p-2 font-normal rounded-tr-lg'>Action</th> */}
        </tr>
      </thead>
      <tbody className='bg-white'>
        {records.map((record, index) => (
          <React.Fragment key={index}>
            <tr className='border-b-2 cursor-pointer' onClick={() => toggleExpand(index)}>
              <td className="px-4 py-2">
                {/* {record.orderId.length > 10 ? `${record.orderId.slice(0, 10)}...` : record.orderId}
                 */}
               { record.orderId}
              </td>
              <td className='p-2 text-center'>{record.ProductDetails[0]?.OrderDate}</td>
              <td className='p-2 text-center'>{record.ProductDetails[0]?.SupplierInfo?.FactoryName}</td>
              <td className='p-2 text-center'>{record.ProductDetails[0]?.ProductName}</td>
              <td className='p-2 text-center'>{record.customerInfo?.ShopName}</td>
              <td className='p-2 text-center'>{record.Total}</td>
              <td className='p-2 text-center'>
                ₹ {record.TransactionData?.reduce((total, transaction) => total + parseFloat(transaction.PaymentDoneAmount || 0), 0).toFixed(2)}
              </td>
              <td className='p-2 text-center'>
                <div className="flex items-center justify-center">
                  {record.TransactionData[record.TransactionData.length - 1]?.Total === '0.00' || record.TransactionData[record.TransactionData.length - 1]?.Total === '0' ? (
                    <div className='p-1 text-center text-yellow-500 border border-yellow-500 rounded-lg cursor-pointer hover:bg-yellow-600 hover:text-white' style={{ width: '80px', height: '30px', opacity: '1' }}>Return</div>
                  ) : (
                    <div onClick={() => handleEditClick(record)} className={`p-1 text-center border rounded-lg cursor-pointer ${parseFloat(record.TransactionData[record.TransactionData.length - 1]?.Duepayment).toFixed(2) === "0.00" ? 'text-green-500 hover:bg-green-600 hover:text-white border-green-500' : 'text-orange-500 hover:bg-orange-600 hover:text-white border-orange-500'}`} style={{ width: '80px', height: '30px', opacity: '1' }}>
                      {parseFloat(record.TransactionData[record.TransactionData.length - 1]?.Duepayment).toFixed(2) === "0.00" ? 'Paid' : 'Pending'}
                    </div>
                  )}
                </div>
              </td>
              {/* <td className='relative p-2'>
                <HiDotsVertical className='cursor-pointer' onClick={() => toggleDropdown(index)} />
                {dropdownOpen === index && (
                  <div className="absolute right-0 z-10 w-32 p-2 bg-white border rounded shadow-lg">
                    <div className="p-2 cursor-pointer hover:bg-gray-200" onClick={() => handleEditClick(record)}>Edit</div>
                    <div className="p-2 cursor-pointer hover:bg-gray-200">Delete</div>
                  </div>
                )}
              </td> */}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
</div>

  );
}

export default PurchaseRecordsPage;
